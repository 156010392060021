/* eslint-disable no-var, object-shorthand */

function decorateSwalFire(swalMixin, defaultCustomClass) {
  var superFire = swalMixin.fire.bind(swalMixin);

  swalMixin.fire = function fireDecorator(config) {
    if (!config.customClass) {
      config.customClass = {};
    }
    config.customClass = Object.assign({}, defaultCustomClass, config.customClass);
    return superFire(config);
  };

  return swalMixin;
}

var showClass = {
  popup: 'swal2-show',
  backdrop: 'swal2-backdrop-show',
};

var hideClass = {
  popup: 'swal2-hide',
};

function setupModals(Swal) {
  var common = Swal.mixin({
    buttonsStyling: false,
    showCloseButton: true,
    showClass: showClass,
    hideClass: hideClass,
  });

  var commonCustomClass = {
    title: 'swal2-title-custom',
    icon: 'swal2-icon-custom',
  };

  var information = common.mixin({
    confirmButtonText: 'Okay, got it',
  });
  decorateSwalFire(
    information,
    Object.assign({}, commonCustomClass, {
      confirmButton: 'button secondary',
    })
  );

  var confirmation = common.mixin({
    title: 'Are you sure?',
    showCancelButton: true,
    reverseButtons: true,
  });
  decorateSwalFire(
    confirmation,
    Object.assign({}, commonCustomClass, {
      confirmButton: 'button primary',
      cancelButton: 'button secondary',
    })
  );

  var destruction = common.mixin({
    title: 'Are you sure?',
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: 'Delete now',
  });
  decorateSwalFire(
    destruction,
    Object.assign({}, commonCustomClass, {
      confirmButton: 'button negative',
      cancelButton: 'button secondary',
    })
  );

  var custom = Swal.mixin({
    showClass: showClass,
    hideClass: hideClass,
    showConfirmButton: false,
  });
  decorateSwalFire(custom, commonCustomClass);

  return {
    information: information,
    confirmation: confirmation,
    destruction: destruction,
    custom: custom,
  };
}

export default setupModals;
