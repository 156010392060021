import { action } from '@ember/object';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class NotFoundRoute extends Route.extend(FastbootHelpersMixin) {
  @action
  didTransition() {
    if (this.isFastBoot) {
      this.set('fastboot.response.statusCode', 404);
    }
  }
}
