import { asyncScriptLoader } from 'ember-simplepractice/utils/async-script-loader';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import ENV from 'client-portal/config/environment';
import Modifier from 'ember-modifier';
import RecaptchaMock from '../mocks/recaptcha-mock';

export default class SetupRecaptcha extends Modifier {
  @service currentPractice;

  constructor(owner, args) {
    super(owner, args);
    this.#setupRecaptcha();
  }

  async #setupRecaptcha() {
    let sitekey = this.currentPractice.get('recaptchaSiteKey');

    if (!isEmberTesting() && ENV.railsEnv !== 'test') {
      await asyncScriptLoader('https://www.google.com/recaptcha/api.js');
    } else {
      window.grecaptcha = new RecaptchaMock();
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha.render('recaptcha-container', { sitekey });
    });
  }
}
