module.exports = [
  'Accelerated Experiential Dynamic Psychotherapy (AEDP)',
  'Acceptance and Commitment (ACT)',
  'Adlerian',
  'Applied Behavioral Analysis',
  'Art Therapy',
  'Attachment-Based',
  'Ayurvedic',
  'Behavioral Therapy',
  'Biofeedback',
  'Brainspotting',
  'Breathwork',
  'Chairwork',
  'Child-Parent Psychotherapy (CPP)',
  'Christian Counseling',
  'Clinical Supervision and Licensed Supervisors',
  'Coaching',
  'Cognitive Behavioral Therapy (CBT)',
  'Cognitive Processing Therapy (CPT)',
  'Compassion Focused',
  'Court Evaluations',
  'Culturally Sensitive',
  'Dance/Movement Therapy',
  'Dialectical (DBT)',
  'Disability Evaluation',
  'Drama Therapy',
  'Eastern Medicine',
  'Eclectic',
  'Emotional Freedom Technique (EFT)',
  'Emotionally Focused',
  'Equine Therapy',
  'Executive Function Coaching',
  'Existential',
  'Experiential Therapy',
  'Exposure Response Prevention (ERP)',
  'Expressive Arts',
  'Eye Movement Desensitization & Reprocessing Therapy (EMDR)',
  'Family Systems',
  'Family/Marital Therapy',
  'Feminist Therapy',
  'Forensic Psychology',
  'Functional Capacity Evaluation',
  'Gestalt Therapy',
  'Gottman Method',
  'Group Therapy',
  'Health at Every Size',
  'Herbalist',
  'Holistic Therapy',
  'Homeopathy',
  'Humanistic Therapy',
  'Hypnotherapy',
  'Imago',
  'Integrative Therapy',
  'Internal Family Systems (IFS)',
  'Interpersonal',
  'Intervention Therapy',
  'Jungian Therapy',
  'Mindfulness-Based Cognitive Therapy (MBCT)',
  'Mindfulness-Based Stress Reduction (MBSR)',
  'Motivational Interviewing',
  'Multicultural',
  'Music Therapy',
  'Narrative',
  'Neuro-Linguistic',
  'Neurofeedback',
  'Neuropsychological Testing',
  'Parent-Child Interaction (PCIT)',
  'Pastoral Counseling',
  'Pelvic Health',
  'Person-Centered',
  'Play Therapy',
  'Poetry Therapy',
  'Positive Psychology',
  'Prolonged Exposure Therapy',
  'Psychiatric Medication Review',
  'Psychiatry',
  'Psychoanalytic Therapy',
  'Psychobiological Approach Couple Therapy',
  'Psychodynamic Therapy',
  'Psychological Testing and Evaluation',
  'Psychotherapy',
  'Rational Emotive Behavior (REBT)',
  'Reality Therapy',
  'Reiki',
  'Relational Therapy',
  'Sandplay',
  'Schema Therapy',
  'Sensorimotor Therapy',
  'Sex Therapy',
  'Social Therapy',
  'Social Work',
  'Solution Focused Brief (SFBT)',
  'Somatic',
  'Speech Therapy',
  'Strength-Based',
  'Structural Family Therapy',
  'Supportive Therapy',
  'Therapeutic Journaling',
  'Traditional Chinese Medicine (TCM)',
  'Transcranial Magnetic Stimulation (TMS)',
  'Transpersonal',
  'Trauma Focused',
];
