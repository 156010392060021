import { STEP_IDS } from 'client-portal/services/widget-steps';
import { action } from '@ember/object';
import { callbackError } from 'client-portal/utils/error-handling';
import { error } from 'client-portal/utils/banners';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';

@classic
export default class RequestConfirmation extends RequestBaseRoute {
  @service analytics;
  @service session;
  @service request;
  @service mixpanel;
  @service appointment;
  @service clientTimezone;

  templateName = '-request/confirmation';
  stepId = STEP_IDS.CONFIRMATION;

  @(task(function* (reservation) {
    try {
      try {
        yield this.appointment.cancel(reservation.appointment);
      } catch (err) {
        callbackError(err, ({ status }) => {
          // This means session has expired, but we can try once more since
          // cancelling is also allowed with valid Session-Id header
          if (status === '401') {
            return true;
          }
        });
        yield this.appointment.cancel(reservation.appointment);
      }
      this.mixpanel.track(`user: ${this.request.trackingPrefix} Client Cancelled Appointment`);
      this.request.transitionTo(this, 'cancelled');
    } catch (err) {
      callbackError(err, message => {
        error({ title: message.title });
        return true;
      });
    }
  })
    .keepLatest()
    .maxConcurrency(1))
  destroyAppointmentTask;

  model() {
    return this.modelFor(this.request.baseRouteName);
  }

  afterModel(model) {
    let appointment = model.appointment;
    if (!appointment || appointment.get('isNew')) {
      return this.request.transitionTo(this, 'information');
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('destroyAppointmentTask', this.destroyAppointmentTask);
    model.deleteRecord();
  }

  async resetController(_controller, isExiting, transition) {
    super.resetController(...arguments);

    if (isExiting) {
      await transition;
      this.send('refreshEnvironment');
    }
  }

  @action
  openUrl(url) {
    window.open(url);
  }

  @action
  firstStep() {
    this.request.transitionTo(this);
  }

  @action
  destroyModel() {
    this.destroyAppointmentTask.perform(this.currentModel);
  }

  @action
  willTransition() {
    this.clientTimezone.reset();
  }
}
