/* import __COLOCATED_TEMPLATE__ from './edit-menu.hbs'; */
import { action, computed, set } from '@ember/object';
import { buildValidations, validator } from 'ember-cp-validations';
import { classNames } from '@ember-decorators/component';
import { createElement } from 'client-portal/utils/banners';
import { getDuration } from 'client-portal/transitions';
import { later } from '@ember/runloop';
import { menuError } from 'client-portal/utils/practice-website-banners';
import { modifier } from 'ember-modifier';
import { service } from '@ember/service';
import Component from '@ember/component';
import PracticeWebsiteEditMenuAbout from './edit-menu/about';
import PracticeWebsiteEditMenuContact from './edit-menu/contact';
import PracticeWebsiteEditMenuDesign from './edit-menu/design';
import PracticeWebsiteEditMenuExpertises from './edit-menu/expertises';
import PracticeWebsiteEditMenuFees from './edit-menu/fees';
import PracticeWebsiteEditMenuHighlights from './edit-menu/highlights';
import PracticeWebsiteEditMenuInsurances from './edit-menu/insurances';
import PracticeWebsiteEditMenuIntro from './edit-menu/intro';
import PracticeWebsiteEditMenuLocations from './edit-menu/locations';
import PracticeWebsiteEditMenuPracticeDetails from './edit-menu/practice-details';
import PracticeWebsiteEditMenuSections from './edit-menu/sections';
import PracticeWebsiteEditMenuSeo from './edit-menu/seo';
import PracticeWebsiteEditMenuServices from './edit-menu/services';
import PracticeWebsiteEditMenuSocialMedia from './edit-menu/social-media';
import PracticeWebsiteEditMenuSpecialties from './edit-menu/specialties';
import PracticeWebsiteEditMenuTestimonials from './edit-menu/testimonials';
import classic from 'ember-classic-decorator';
import styles from './edit-menu.module.scss';

const menus = [
  {
    id: 'global',
    header: 'Website',
    nav: [
      {
        label: 'Design',
        menuId: 'design',
      },
      {
        label: 'Sections',
        menuId: 'sections',
      },
      {
        label: 'Practice Details',
        menuId: 'practice-details',
      },
      {
        label: 'Contact Options',
        menuId: 'contact',
      },
      {
        label: 'Social Media',
        menuId: 'social-media',
      },
      {
        label: 'Online Discoverability (SEO)',
        menuId: 'seo',
      },
    ],
  },
  {
    id: 'design',
    header: 'Design',
    parent: 'global',
    contentComponent: PracticeWebsiteEditMenuDesign,
    footer: true,
  },
  {
    id: 'sections',
    header: 'Sections',
    parent: 'global',
    contentComponent: PracticeWebsiteEditMenuSections,
    footer: true,
  },
  {
    id: 'practice-details',
    header: 'Practice Details',
    parent: 'global',
    contentComponent: PracticeWebsiteEditMenuPracticeDetails,
    footer: true,
  },
  {
    id: 'contact',
    header: 'Contact Options',
    parent: 'global',
    contentComponent: PracticeWebsiteEditMenuContact,
    conditionallyRequiredFields: ['contactEmail', 'showContactCta'],
    footer: true,
  },
  {
    id: 'social-media',
    header: 'Social Media',
    parent: 'global',
    contentComponent: PracticeWebsiteEditMenuSocialMedia,
    footer: true,
  },
  {
    id: 'seo',
    header: 'Online Discoverability (SEO)',
    parent: 'global',
    contentComponent: PracticeWebsiteEditMenuSeo,
    footer: true,
  },
  {
    id: 'intro',
    header: 'Home',
    contentComponent: PracticeWebsiteEditMenuIntro,
    footer: true,
  },
  {
    id: 'highlights',
    header: 'Highlights',
    contentComponent: PracticeWebsiteEditMenuHighlights,
    footer: true,
  },
  {
    id: 'about',
    header: 'About',
    contentComponent: PracticeWebsiteEditMenuAbout,
    conditionallyRequiredFields: ['profileName', 'profileTitle'],
    footer: true,
  },
  {
    id: 'specialties',
    header: 'Services',
    contentComponent: PracticeWebsiteEditMenuSpecialties,
    footer: true,
    nav: [
      {
        label: 'Focus Areas',
        menuId: 'expertises',
      },
      {
        label: 'Approaches',
        menuId: 'services',
      },
      {
        label: 'Insurance Accepted',
        menuId: 'insurances',
      },
      {
        label: 'Fees',
        menuId: 'fees',
      },
    ],
  },
  {
    id: 'expertises',
    header: 'Focus Areas',
    contentComponent: PracticeWebsiteEditMenuExpertises,
    parent: 'specialties',
    footer: true,
  },
  {
    id: 'services',
    header: 'Approaches',
    contentComponent: PracticeWebsiteEditMenuServices,
    parent: 'specialties',
    footer: true,
  },
  {
    id: 'insurances',
    header: 'Insurance Accepted',
    contentComponent: PracticeWebsiteEditMenuInsurances,
    parent: 'specialties',
    footer: true,
  },
  {
    id: 'fees',
    header: 'Fees',
    contentComponent: PracticeWebsiteEditMenuFees,
    parent: 'specialties',
    footer: true,
  },
  {
    id: 'testimonials',
    header: 'Testimonials',
    contentComponent: PracticeWebsiteEditMenuTestimonials,
    footer: true,
  },
  {
    id: 'locations',
    header: 'Location(s)',
    contentComponent: PracticeWebsiteEditMenuLocations,
    footer: true,
  },
];

const Validations = buildValidations({
  showContactCta: [
    validator('inline', {
      validate(_value, _options, model) {
        let { activeMenu, isMissingShowContactCta } = model || {};
        return activeMenu.conditionallyRequiredFields?.includes('showContactCta') &&
          isMissingShowContactCta
          ? 'You must enable your contact form'
          : true;
      },
      dependentKeys: ['model.{activeMenu,isMissingShowContactCta}'],
    }),
  ],
});

@classic
@classNames(styles.component)
export default class PracticeWebsiteEditMenu extends Component.extend(Validations) {
  styles = styles;

  @service fileUpload;

  menus = menus;
  activeMenuId = 'global';

  @computed('activeMenuId')
  get activeMenu() {
    return this.menus.findBy('id', this.activeMenuId);
  }

  @computed('isShowContactCtaRequired', 'model.showContactCta')
  get isMissingShowContactCta() {
    return this.isShowContactCtaRequired && !this.model?.showContactCta;
  }

  @computed('hasEffectiveBookingWidget', 'model.showRequestAppointmentLink')
  get isShowContactCtaRequired() {
    return !this.hasEffectiveBookingWidget || !this.model?.showRequestAppointmentLink;
  }

  @action
  setAndNotify(path, value) {
    set(this.model, path, value);
    this.notifyDirtyStatus(this.model);
  }

  @action
  showErrorBanner(config) {
    menuError(config);
  }

  @action
  async persistModel() {
    let { model, validations } = this;
    await Promise.all([model.validate(), this.validate()]);
    if (validations.errors.isAny('attribute', 'showContactCta')) {
      return menuError({
        title: '',
        content: createElement('span', {
          innerText:
            "New clients need a way to reach you. Since you're not accepting online appointment requests, you must enable your contact form.",
        }),
      });
    }

    if (model.validations.isInvalid) {
      return;
    }

    this.persistModelTask.perform(model);
  }

  // delay accounts for flyout animation time
  get focusDelay() {
    return getDuration('slow') + 20;
  }

  focus = modifier(element => {
    let firstFocusable = element.querySelector('.menu-back-btn');
    if (!firstFocusable) {
      firstFocusable = element
        .querySelector('.content-wrapper')
        ?.querySelector('a, button, input, textarea, select');
    }

    later(() => {
      firstFocusable?.focus();
    }, this.focusDelay);
  });
}
