import { service } from '@ember/service';
import Component from '@glimmer/component';

class SharedLiLinkTo extends Component {
  @service router;

  get isActive() {
    let routeName = this.router.currentRoute?.name || this.router.currentRouteName;

    return routeName?.includes(this.args.params);
  }

  get ariaSelected() {
    return this.isActive.toString();
  }

  get shouldAutoscroll() {
    return !!this.args.autofocusWhenActive && this.isActive;
  }
}

export default SharedLiLinkTo;
