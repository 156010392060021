import Service from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class CsrfTokenService extends Service {
  token() {
    let tokenMeta = document.querySelector('meta[name="csrf-token"]');
    return tokenMeta ? tokenMeta.getAttribute('content') : '';
  }
}
