import { action, computed, set, setProperties } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { lte } from '@ember/object/computed';
import { modifier } from 'ember-modifier';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './preview.module.scss';

@classic
@classNames(styles.component)
export default class SharedImageCropPreview extends Component {
  cropDataUpdatedCount = 0;
  isTooltipVisible = true;
  boundaryPadding = 64;
  tooltipPaddingBottom = 16;

  @lte('cropDataUpdatedCount', 1) isNotUpdatedByUser;

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.destroyCropPreview();
  }

  @computed('prevCropData.points.[]', 'cropData.points.[]')
  get isMoved() {
    return (
      this.prevCropData?.points[0] !== this.cropData?.points[0] ||
      this.prevCropData?.points[1] !== this.cropData?.points[1]
    );
  }

  onSetup = modifier(
    element => {
      let opts = {
        viewport: { width: this.viewportWidth, height: this.viewportHeight },
        boundary: { height: this.viewportWidth + this.boundaryPadding },
        enableZoom: true,
        mouseWheelZoom: false,
        enforceBoundary: true,
        showZoomer: false,
        maxZoom: 100,
      };

      this.createCropPreview(element, opts);
      this.bindCropPreview({ zoom: this.zoom, url: this.fileUrl });
      this._adjustTooltipPosition();
    },
    { eager: false }
  );

  @action
  updatedCropPreview({ detail: newCropData }) {
    let isZoomed = newCropData.zoom !== this.cropData?.zoom;
    setProperties(this, {
      prevCropData: this.cropData,
      cropData: newCropData,
    });

    if (this.isNotUpdatedByUser) {
      set(this, 'cropDataUpdatedCount', this.cropDataUpdatedCount + 1);
      return;
    }

    if (isZoomed || !this.isMoved) {
      return;
    }

    this._manageTooltip();
  }

  _manageTooltip() {
    setProperties(this, {
      cropDataUpdatedCount: this.cropDataUpdatedCount + 1,
      isTooltipVisible: false,
    });
  }

  _adjustTooltipPosition() {
    let tooltip = this.element.querySelector('.reposition-tooltip');
    let viewport = this.element.querySelector('.cr-viewport');

    if (tooltip && viewport) {
      let offset = viewport.offsetTop + viewport.offsetHeight;
      tooltip.style.top = `${offset - this.tooltipPaddingBottom - tooltip.offsetHeight}px`;
    }
  }
}
