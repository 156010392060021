/* global FastBoot */
import { action, get, setProperties } from '@ember/object';
import { callbackError } from 'client-portal/utils/error-handling';
import { getDuration } from 'client-portal/transitions';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { later } from '@ember/runloop';
import { menuBannerError, menuSuccess } from 'client-portal/utils/practice-website-banners';
import { namespace } from 'client-portal/adapters/application';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';
import SiteRedirectsMixin from 'client-portal/mixins/route/site-redirects';
import classic from 'ember-classic-decorator';

const ExtendedRoute = Route.extend(SiteRedirectsMixin, FastbootHelpersMixin);

@classic
export default class Home extends ExtendedRoute {
  @service router;
  @service request;
  @service mixpanel;
  @service currentPractice;
  @service headData;
  @service penpal;
  @service store;
  @service session;

  queryParams = {
    edit: { refreshModel: true },
    preview: { refreshModel: false },
  };

  @reads('currentPractice.websiteEnabled') websiteEnabled;
  @reads('currentPractice.websitePublished') websitePublished;

  beforeModel(transition) {
    let { edit } = transition.to.queryParams;
    if (!edit) {
      this._handleCustomDomain();
    }

    // NOTE: prevent hooks from SiteRedirectsMixin
    let { websiteEnabled, websitePublished } = this;
    if ((edit && websiteEnabled) || websitePublished) {
      return;
    }

    super.beforeModel(...arguments);
    this.session.prohibitAuthentication('site.home');
  }

  async model(params) {
    let officesPromise;
    if (this.hasShoebox) {
      officesPromise = this.store.peekAll('office').sortBy('id');
    } else {
      officesPromise = this.store.query('office', {});
    }

    if (params.edit) {
      if (this.isFastBoot) {
        return { offices: await officesPromise };
      }

      let parentFrame = await this._connectToParent();
      let model = await parentFrame.fetchModel();
      this.store.pushPayload(model);
      let [practiceWebsite, offices] = await Promise.all([
        this.store.peekRecord('practice-website', model.data.id),
        officesPromise,
      ]);
      this.currentPractice.set('practiceWebsite', practiceWebsite);

      return { practiceWebsite, offices };
    } else {
      let practiceWebsitePromise;
      if (this.websitePublished) {
        practiceWebsitePromise = get(this, 'currentPractice.practiceWebsite');
      }
      let [practiceWebsite, offices] = await Promise.all([practiceWebsitePromise, officesPromise]);

      return { offices, practiceWebsite };
    }
  }

  afterModel(model) {
    if (model.practiceWebsite) {
      let { practiceWebsite, offices } = model;
      setProperties(this.headData, {
        offices,
        practiceWebsite,
      });
      this.parentFrame?.onReady();
    }
  }

  async setupController(controller) {
    super.setupController(...arguments);

    if (controller.edit) {
      let {
        parentFrame,
        persistModelTask,
        notifyDirtyStatus,
        notifyIsEditing,
        notifyMenuBlur,
        confirmation,
        requestUpload,
        navigateTo,
        resetErrors,
      } = this;
      setProperties(controller, {
        parentFrame,
        persistModelTask,
        notifyDirtyStatus,
        notifyIsEditing,
        notifyMenuBlur,
        confirmation,
        requestUpload,
        navigateTo,
        resetErrors,
      });

      let hasEffectiveBookingWidget = await parentFrame?.hasEffectiveBookingWidget();
      setProperties(controller, { hasEffectiveBookingWidget });
    }

    if (this.websitePublished && !(controller.preview || controller.edit)) {
      this.mixpanel.track(`prof_site: Page viewed`);
    }

    let { submitContactTask } = this;
    setProperties(controller, { submitContactTask });
  }

  @action
  willTransition(transition) {
    if (this.controller.preview) {
      transition.abort();
      let {
        to: { name, params, queryParams },
      } = transition;
      let transitionPath = this.router.urlFor(name, ...Object.values(params), { queryParams });
      !isEmberTesting() && window.open(transitionPath);
    }
  }

  @action
  notifyDirtyStatus(model) {
    this.parentFrame.notifyDirtyStatus(model.hasDirtyAttributes);
  }

  @action
  notifyIsEditing(isEditing) {
    this.parentFrame.notifyIsEditing(isEditing);
  }

  @action
  notifyMenuBlur() {
    // delay allows proper activeElement identification
    later(() => {
      let isFocusedOnControl = ['input', 'textarea', 'select'].some(tagName => {
        return document.activeElement?.tagName.toLowerCase() === tagName;
      });
      if (!isFocusedOnControl) {
        this.parentFrame.notifyMenuBlur();
      }
    }, 0);
  }

  @action
  confirmation() {
    return this.parentFrame.confirmation(...arguments);
  }

  @action
  requestUpload() {
    return this.parentFrame.requestUpload(...arguments);
  }

  @action
  setPreview(value) {
    this.router.transitionTo({ queryParams: { preview: value ? true : undefined } });
  }

  @action
  navigateTo(route, isNewWindow) {
    return this.parentFrame.navigateTo(route, isNewWindow);
  }

  @action
  revealMenu({ section, menu, focus }) {
    if (section) {
      section === 'home' ? this._scrollToTop() : this._scrollToSection(section);
      later(() => {
        this._revealMenu(menu, focus);
      }, getDuration('slow'));
    } else {
      this._revealMenu(menu, focus);
    }
  }

  async _connectToParent() {
    let connection = this.penpal.connectToParent({
      methods: {
        setPreview: this.setPreview,
        revealMenu: this.revealMenu,
      },
    });

    let parentFrame = await connection.promise;
    setProperties(this, { parentFrame });
    return parentFrame;
  }

  @action
  resetErrors(model) {
    setProperties(model, {
      practiceLogoIdError: undefined,
      practiceNppIdError: undefined,
      profileImgIdError: undefined,
    });
  }

  _handleCustomDomain() {
    if (!this.isFastBoot || !get(this, 'currentPractice.id')) {
      return;
    }

    let URL = FastBoot.require('url').URL;
    let practiceUrl = get(this, 'currentPractice.practiceUrl');
    let practiceHost = new URL(practiceUrl).host;
    let currentHost = this.fastboot.request.headers?.headers?.['custom-domain']?.[0];
    if (!currentHost) {
      [currentHost] = this.fastboot.request.host.split(':');
    }
    if (practiceHost !== currentHost) {
      this.fastboot.response.headers.set('location', practiceUrl);
      this.set('fastboot.response.statusCode', 302);
    }
  }

  @(task(function* (model) {
    try {
      model.validate();
      if (model.validations.isInvalid) {
        return;
      }
      let serialized = model.serialize({ includeId: true });
      // main app performs record persistence
      yield this.parentFrame.save(serialized);
      // cleans record but does not make API request
      model.save();
      this.resetErrors(model);
      menuSuccess({
        title: 'Updates successfully saved!',
      });
    } catch (err) {
      callbackError(err, message => {
        return menuBannerError(err, {
          title: message.title || 'Website settings could not be saved',
        });
      });
    }
  })
    .cancelOn('deactivate')
    .keepLatest()
    .maxConcurrency(1))
  persistModelTask;

  @(task(function* (data) {
    yield this.store.adapterFor('application').ajax(`/${namespace}/contact-request`, 'POST', {
      data: { contact: data },
    });
  })
    .cancelOn('deactivate')
    .keepLatest()
    .maxConcurrency(1))
  submitContactTask;

  _scrollToSection(section) {
    document
      .querySelector(`[data-anchor-name=${section}]`)
      .scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  _scrollToTop() {
    document.querySelector('.pw-inner')?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  _revealMenu(menu, focusSelector) {
    this.controller.editSection(menu);
    if (focusSelector) {
      later(
        () => {
          document.querySelector(focusSelector)?.focus();
        },
        getDuration('slow') * 2
      );
    }
  }
}
