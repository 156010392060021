import { service } from '@ember/service';
import Route from '@ember/routing/route';
import SiteRedirectsMixin from 'client-portal/mixins/route/site-redirects';
import classic from 'ember-classic-decorator';

@classic
export default class SiteHomeRoute extends Route.extend(SiteRedirectsMixin) {
  @service router;
  @service session;

  async beforeModel(transition) {
    await super.beforeModel(...arguments);

    let { currentClient } = this.session;
    let redirect = this.session.get('data.redirect');
    let redirectTo = 'site.appointments';
    let { hasIncompleteDocument, permissions } = currentClient || {};
    let { isHeaderFooterHidden } = transition.to.queryParams;
    let params = isHeaderFooterHidden ? { isHeaderFooterHidden: true } : {};

    if (redirect) {
      let redirectUrl = new URL(`${location.origin}/${decodeURIComponent(redirect)}`);
      isHeaderFooterHidden && redirectUrl.searchParams.append('isHeaderFooterHidden', true);
      redirectTo = redirectUrl.pathname + redirectUrl.search;
      params = {};
      this.session.set('data.redirect', undefined);
    } else if (hasIncompleteDocument || !permissions?.appointments) {
      redirectTo = 'site.documents';
    }

    this.router.transitionTo(redirectTo, { queryParams: params });
  }
}
