import { classNameBindings, classNames } from '@ember-decorators/component';
import { scheduleOnce } from '@ember/runloop';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './stripe-input.module.scss';

const globalStyle = {
  base: {
    color: '#333',
    fontSize: '14px',
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    fontSmoothing: 'antialiased',

    '::placeholder': {
      color: '#bbb',
    },
  },
  invalid: {
    color: '#ea3917',
  },
};

@classic
@classNames('stripe-input', styles.component)
@classNameBindings('model.isFocused:focused')
export default class SharedStripeInput extends Component {
  didInsertElement() {
    super.didInsertElement(...arguments);

    scheduleOnce('afterRender', this, this.mountStripeElement);
  }

  mountStripeElement() {
    try {
      this.model.set('isMounted', false);
      this.model.element.update(this.elementConfig).mount(this.element);
    } catch (err) {
      // error getting stripe element -- library probably failed to load
    }
  }

  get elementConfig() {
    let style = {
      base: Object.assign({}, globalStyle.base, this.styleBase || {}),
      invalid: globalStyle.invalid,
    };

    let config = { style, placeholder: this.placeholder };

    // add the property to config only when param is received
    if (this.showIcon === true) {
      config.showIcon = true;
    }

    return config;
  }
}
