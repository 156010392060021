import { camelize } from '@ember/string';
import JSONAPISerializer from '@ember-data/serializer/json-api';
import SerializeAttributesMixin from 'client-portal/mixins/serializer/serialize-attributes';
import classic from 'ember-classic-decorator';

@classic
export default class Application extends JSONAPISerializer.extend(SerializeAttributesMixin) {
  keyForAttribute(raw) {
    return camelize(raw);
  }

  keyForRelationship(raw) {
    return camelize(raw);
  }

  normalize(modelClass, resourceHash) {
    let { meta } = resourceHash;
    if (meta) {
      resourceHash.attributes.meta = meta;
    }

    return super.normalize(...arguments);
  }

  serialize(snapshot, options) {
    if (!snapshot.adapterOptions && options.adapterOptions) {
      snapshot.adapterOptions = options.adapterOptions;
    }

    return super.serialize(...arguments);
  }

  serializeBelongsTo(snapshot, json, relationship) {
    if (!this._shouldEmbed(snapshot, relationship)) {
      return super.serializeBelongsTo(...arguments);
    }

    let key = relationship.key;
    let belongsTo = snapshot.belongsTo(key);

    if (belongsTo) {
      let payloadKey = this._getMappedKey(key, this.store.modelFor(snapshot.modelName));

      if (payloadKey === key && this.keyForRelationship) {
        payloadKey = this.keyForRelationship(key, 'belongsTo', 'serialize');
      }

      payloadKey = this.keyForAttribute(`${payloadKey}_attributes`);

      belongsTo._internalModel._isEmbedded = true;

      if (belongsTo.record.get('isMarkedForDeletion')) {
        json.attributes[payloadKey] = { id: belongsTo.id, _destroy: true };
      } else {
        let serialized = belongsTo.serialize({
          includeId: true,
          adapterOptions: snapshot.adapterOptions,
        });
        json.attributes[payloadKey] = Object.assign(
          { id: serialized.data.id },
          serialized.data.attributes
        );
        this._addBelongsToIds(belongsTo.record, json.attributes[payloadKey]);
      }
    }
  }

  serializeHasMany(snapshot, json, relationship) {
    if (!this._shouldEmbed(snapshot, relationship)) {
      return super.serializeHasMany(...arguments);
    }

    let key = relationship.key;

    let hasMany = snapshot.hasMany(key);
    if (hasMany !== undefined) {
      let payloadKey = this._getMappedKey(key, this.store.modelFor(snapshot.modelName));

      if (payloadKey === key && this.keyForRelationship) {
        payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');
      }

      payloadKey = this.keyForAttribute(`${payloadKey}_attributes`);

      let data = new Array(hasMany.length);

      for (let i = 0; i < hasMany.length; i++) {
        let item = hasMany[i];
        item._internalModel._isEmbedded = true;

        if (item.record.get('isMarkedForDeletion')) {
          data[i] = { id: item.id, _destroy: true };
        } else {
          let serialized = item.serialize({
            includeId: true,
            adapterOptions: snapshot.adapterOptions,
          });
          data[i] = Object.assign({ id: serialized.data.id }, serialized.data.attributes);
          this._addBelongsToIds(item.record, data[i]);
        }
      }

      if (!json.attributes) {
        json.attributes = {};
      }
      json.attributes[payloadKey] = data;
    }
  }

  _shouldEmbed(snapshot, relationship) {
    if (
      snapshot.adapterOptions &&
      snapshot.adapterOptions.embed &&
      snapshot.adapterOptions.embed.includes(relationship.key)
    ) {
      return true;
    }

    return false;
  }

  _addBelongsToIds(record, json) {
    record.eachRelationship((name, relationship) => {
      if (relationship.kind !== 'belongsTo') {
        return;
      }

      let reference = record.belongsTo(name);
      let id = reference.id();

      if (id) {
        json[this.keyForAttribute(`${name}_id`)] = id;
      }
    });
  }

  extractErrors(_store, _typeClass, payload, _id) {
    if (payload && typeof payload === 'object' && payload.errors) {
      payload.errors.forEach(this._aliasError);
    }

    return super.extractErrors(...arguments);
  }

  _aliasError(error) {
    // Replace errors `detail` with shorter `title`
    error.detail = error.title;
  }
}
