import { schedule } from '@ember/runloop';
import { set } from '@ember/object';
import Service from '@ember/service';
import classic from 'ember-classic-decorator';

const RESETTABLE_PROPERTIES = [
  '_boundHandler',
  'focusableElements',
  'focusableElementsActiveIndex',
  'keyPresed',
];

@classic
export default class KeyboardNavigationService extends Service {
  focusableElements = null;
  focusableElementsActiveIndex = null;
  keyPressed = null;
  resettableProperties = RESETTABLE_PROPERTIES;

  _calculateNewIndex(index, { key, shiftKey }) {
    let isDown = !shiftKey || key === 'ArrowDown';
    let isUp = shiftKey || key === 'ArrowUp';
    let lastIndex = this.focusableElements.length - 1;

    switch (true) {
      case isUp && index <= 0:
        return lastIndex;
      case isUp && index > 0 && index <= lastIndex:
        return index - 1;
      case isDown && index >= 0 && index < lastIndex:
        return index + 1;
      default:
        return 0;
    }
  }

  _getActiveElementIndex() {
    let activeListItemIndex = this.focusableElements.findIndex(element =>
      element.classList?.contains('active')
    );

    switch (true) {
      case this.focusableElementsActiveIndex !== null:
        return this.focusableElementsActiveIndex;
      case activeListItemIndex >= 0:
        return activeListItemIndex;
      default:
        return 0;
    }
  }

  _handleNavKey(evt) {
    let { key, shiftKey } = evt;
    let index = this._getActiveElementIndex();
    let newIndex = this._calculateNewIndex(index, { key, shiftKey });

    set(this, 'focusableElementsActiveIndex', newIndex);

    this._manageFocus(newIndex, index);
  }

  _keyboardNavigation(evt) {
    let isEnterPressed = evt.key === 'Enter';
    let isNavBtnPressed = ['ArrowDown', 'ArrowUp', 'Tab'].includes(evt.key) || evt.keyCode === 9;
    let skipExecution =
      !(isNavBtnPressed || isEnterPressed) || this.isDestroyed || this.isDestroying;

    if (skipExecution) {
      return;
    }

    evt.preventDefault();

    this.keyPressed = true;

    if (isEnterPressed && this.focusableElementsActiveIndex !== null) {
      this.focusableElements[this.focusableElementsActiveIndex].click();
    }

    if (isNavBtnPressed) {
      this._handleNavKey(evt);
    }
  }

  _manageElementFocus(element, action) {
    let managedElement = element.tagName === 'A' ? element.parentElement : element;

    if (action === 'add') {
      managedElement.focus({ focusVisible: true });
      managedElement.classList.add(this.keyPressed ? 'focused' : 'focused-invisible');
    } else {
      managedElement.blur();
      managedElement.classList.remove('focused', 'focused-invisible');
    }
  }

  _manageFocus(index, prev) {
    if (prev >= 0) {
      this._manageElementFocus(this.focusableElements[prev], 'remove');
    }

    this._manageElementFocus(this.focusableElements[index], 'add');
  }

  _resetElementFocus() {
    this.focusableElements?.forEach(element => this._manageElementFocus(element, 'remove'));
  }

  setup(selectors) {
    set(this, '_boundHandler', this._keyboardNavigation.bind(this));
    set(
      this,
      'focusableElements',
      selectors.map(selector => [...document.querySelectorAll(selector)]).flat()
    );

    schedule('afterRender', () => {
      this._resetElementFocus();
      this._manageFocus(this._getActiveElementIndex());
      document.addEventListener('keydown', this._boundHandler);
    });
  }

  teardown() {
    document.removeEventListener('keydown', this._boundHandler);

    this._resetElementFocus();

    this.resettableProperties.forEach(property => set(this, property, null));
  }
}
