import { and, bool, not, or, reads } from '@ember/object/computed';
import { isMobileApp } from 'client-portal/utils/mobile-detect';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import SiteRedirectsMixin from 'client-portal/mixins/route/site-redirects';
import classic from 'ember-classic-decorator';

@classic
export default class SiteRoute extends Route.extend(SiteRedirectsMixin) {
  @service router;
  @service session;
  @service currentPractice;
  @service prospectiveClient;

  queryParams = {
    isHeaderFooterHidden: {},
  };

  @reads('currentPractice.featureClientPortalLegalAgreements') featureClientPortalLegalAgreements;
  @bool('session.currentClientAccess') hasClientAccess;
  @not('session.currentClientAccess.eulaAgreementAccepted') hasNotAcceptedEula;
  @not('session.currentClientAccess.termsOfServiceAgreementAccepted') hasNotAcceptedTerms;
  @not('session.currentClientAccess.privacyPolicyAgreementAccepted') hasNotAcceptedPrivacy;
  @not('session.data.skipLicenseAgreement') canShowLicenseAgreement;
  @not('session.data.skipLegalAgreements') canShowLegalAgreements;
  @or('hasNotAcceptedEula', 'hasNotAcceptedTerms', 'hasNotAcceptedPrivacy')
  hasNotAcceptedLegalAgreements;
  @and('hasClientAccess', 'canShowLicenseAgreement', 'hasNotAcceptedEula') showLicenseAgreement;
  @and(
    'hasClientAccess',
    'canShowLegalAgreements',
    'hasNotAcceptedLegalAgreements',
    'featureClientPortalLegalAgreements'
  )
  showLegalAgreements;

  async beforeModel(transition) {
    let { isHeaderFooterHidden, redirect } = transition.to.queryParams;
    let inAppOrRedirectToViewDocuments =
      isMobileApp() || isHeaderFooterHidden || redirect?.includes('documents');

    if (
      (this.showLegalAgreements || this.showLicenseAgreement) &&
      !inAppOrRedirectToViewDocuments
    ) {
      this.featureClientPortalLegalAgreements
        ? this.router.transitionTo('site.legal-agreements')
        : this.router.transitionTo('site.license-agreement');
    }

    super.beforeModel(...arguments);
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.setProperties({
      showingAgreements: this.showLicenseAgreement || this.showLegalAgreements,
      hideFooterOnEula: this.showLicenseAgreement && !this.featureClientPortalLegalAgreements,
    });
  }
}
