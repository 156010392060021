export function initialize(applicationInstance) {
  if (typeof FastBoot === 'undefined') {
    let booleanInputHandler = function _booleanInputHandler(evt) {
      let input = evt.target.matches('label.boolean input');
      if (input) {
        evt.preventDefault();
      }
    };

    let booleanLabelHandler = function _booleanLabelHandler(evt) {
      let label = evt.target.matches('label.boolean');
      if (label) {
        evt.preventDefault();
        evt.target?.control.click();
      }
    };

    document.body.addEventListener('mousedown', booleanInputHandler);
    document.body.addEventListener('click', booleanLabelHandler);

    applicationInstance.reopen({
      willDestroy() {
        document.body.removeEventListener('mousedown', booleanInputHandler);
        document.body.removeEventListener('click', booleanLabelHandler);
        return this._super(...arguments);
      },
    });
  }
}

export default {
  name: 'boolean-input-focus',
  initialize,
};
