import ApplicationAdapter from './application';
import ENV from 'client-portal/config/environment';
import FormDataMixin from 'client-portal/mixins/adapters/form-data';
import classic from 'ember-classic-decorator';

@classic
export default class Appointment extends ApplicationAdapter.extend(
  ENV.environment === 'test' ? {} : FormDataMixin
) {
  getUpcomingAppointmentCounts() {
    return this.ajax('/client-portal-api/appointments/upcoming_appointment_counts', 'GET');
  }
}
