import { computed } from '@ember/object';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class DiagnosisTreatmentPlanModel extends Model {
  @attr() shortType;
  @attr() problem;
  @attr() goal;
  @attr() objective;
  @attr() structure;
  @attr() frequency;
  @attr('moment') notedAt;

  @computed('shortType')
  get type() {
    return this.shortType || 'empty';
  }
}
