import { pluralize } from 'ember-inflector';
import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class Session extends ApplicationAdapter {
  urlForCreateRecord(modelName, snapshot) {
    return `${this.buildURL()}/${pluralize(modelName)}/${snapshot.attr('type')}`;
  }
}
