import { bannerError } from 'client-portal/utils/error-handling';
import { error, success } from 'client-portal/utils/banners';

function buildOptions(options) {
  return {
    container: document.querySelector('.edit-menu .banners-container .content'),
    content: null,
    isSticky: false,
    isTransient: true,
    ...options,
  };
}

export function menuError(options) {
  return error(buildOptions(options));
}

export function menuSuccess(options) {
  return success(buildOptions(options));
}

export function menuBannerError(err, options) {
  bannerError(err, buildOptions(options));
  return true;
}
