import moment from 'moment-timezone';

export function initialize() {
  moment.updateLocale('en', {
    weekdaysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  });

  window.moment = moment;
}

export default {
  name: 'moment-setup',
  initialize,
};
