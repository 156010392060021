import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

@classic
export default class SlotModel extends Model {
  @service clientTimezone;

  @attr() date;
  @attr() spots;

  @reads('clientTimezone.timezone') timezone;

  @computed('spots.[]')
  get morningSpots() {
    let untilTime = this._getDateTime('12', '0');
    return this._filterSpots(null, untilTime);
  }

  @computed('spots.[]')
  get afternoonSpots() {
    let afterTime = this._getDateTime('12', '0');
    let untilTime = this._getDateTime('16', '0');
    return this._filterSpots(afterTime, untilTime);
  }

  @computed('spots.[]')
  get eveningSpots() {
    let afterTime = this._getDateTime('16', '0');
    return this._filterSpots(afterTime, null);
  }

  getFilterForDate(date) {
    let morning = this._getDateTime('12', '0');
    let evening = this._getDateTime('16', '0');
    if (date.isSameOrAfter(evening)) {
      return 'evening';
    } else if (date.isBefore(morning)) {
      return 'morning';
    } else {
      return 'afternoon';
    }
  }

  _filterSpots(after, until) {
    let spots = this.spots;
    return (
      spots &&
      spots.filter(spot => {
        let start = moment(spot.start).tz(this.timezone);
        let isAfter = after ? start.isSameOrAfter(after) : true;
        let isUntil = until ? start.isBefore(until) : true;
        return isAfter && isUntil;
      })
    );
  }

  _getDateTime(hour, minute) {
    let spots = this.spots;
    return (
      spots &&
      spots[0] &&
      moment(spots[0].start).tz(this.timezone).set({ hour, minute, second: '0' })
    );
  }
}
