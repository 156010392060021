import { action, computed } from '@ember/object';
import { bool, or } from '@ember/object/computed';
import { resetScroll } from 'client-portal/utils/reset-scroll';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class RequestMessageController extends Controller {
  @service session;

  @bool('model.reasonsForVisit.length') hasReasonsForVisit;
  @bool('model.mentalHealthHistory.length') hasMentalHealthHistory;
  @bool('model.careTypes.length') hasCareTypesOptions;
  @or('hasReasonsForVisit', 'hasMentalHealthHistory', 'model.message', 'hasCareTypesOptions')
  hasNextLabel;

  @computed(
    'session.reorderedNavEnabled',
    'model.validations.isValid',
    'validateAndPersistAppointmentTask.isRunning'
  )
  get enableDesktopSubmit() {
    let {
      model,
      session: { reorderedNavEnabled },
    } = this;
    let isTaskStateValid =
      !reorderedNavEnabled || !this.validateAndPersistAppointmentTask.isRunning;
    return model.validations.isValid && isTaskStateValid;
  }

  @action
  onInsertForm(element) {
    resetScroll(element);
  }
}
