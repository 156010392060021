import { action } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class SiteLicenseAgreementController extends Controller {
  @service store;
  @service router;
  @service mixpanel;

  @action
  async acceptEula() {
    try {
      let adapter = this.store.adapterFor('client-access');

      await adapter.acceptEula();

      this.send('refreshEnvironment');
      this.router.transitionTo('site.home');
      this.trackEulaAccepted();
    } catch (err) {
      bannerError(err, { title: 'Could not accept the license agreement' });
    }
  }

  @action
  trackEulaAccepted() {
    this.mixpanel.track('client: EULA accepted', { origin: 'client portal' });
  }
}
