/* import __COLOCATED_TEMPLATE__ from './stripe-payment-element.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './stripe-payment-element.module.scss';

export default class SiteBillingPaymentModalStripePaymentElement extends Component {
  @service stripePaymentElementService;

  styles = styles;

  willDestroy() {
    super.willDestroy(...arguments);

    this.stripePaymentElementService.destroyElement();
  }

  get isPaymentElementLoaded() {
    return this.stripePaymentElementService.isLoaded;
  }

  @action
  async attachPaymentElement(_element) {
    if (!this.args.stripeElement) {
      return;
    }

    let paymentElement = this.stripePaymentElementService.getOrCreateElement(
      this.args.stripeElement
    );
    paymentElement.mount('#payment-element');
  }
}
