import { fragment } from 'ember-data-model-fragments/attributes';
import Model, { attr } from '@ember-data/model';

export const DIAGNOSIS_CODE_UNAVAILABLE = 'Diagnosis not selected';

export function hasPhysicalAddress(model) {
  return [model.street, model.city, model.state, model.zip].filter(Boolean).length;
}

export default class GoodFaithEstimateModel extends Model {
  @fragment('good-faith-estimate/submission-data') submissionData;
  @attr('moment') dateProvided;
  @attr('moment') expirationDate;
}
