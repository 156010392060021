import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@classNames('content')
export default class SharedSideFlyoutContent extends Component {
  @service inViewport;

  didInsertElement() {
    super.didInsertElement(...arguments);

    let { onEnter, onExit } = this.inViewport.watchElement(
      document.querySelector('[data-overflow-before]'),
      {
        scrollableArea: this.element,
      }
    );

    let wrapper = this.element.parentElement;
    onEnter(() => wrapper.classList.remove('has-shadow'));
    onExit(() => wrapper.classList.add('has-shadow'));
  }

  willDestroyElement() {
    this.inViewport.stopWatching(document.querySelector('[data-overflow-before]'));
    super.willDestroyElement(...arguments);
  }
}
