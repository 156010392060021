/* import __COLOCATED_TEMPLATE__ from './info-form.hbs'; */
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './info-form.module.scss';

@classic
@tagName('form')
@classNames('text-left', styles.component)
export default class RequestInformationInfoForm extends Component {
  submit(evt) {
    evt.preventDefault();
    this.nextStep();
  }
}
