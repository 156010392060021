import { service } from '@ember/service';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Route from '@ember/routing/route';
import SiteRedirectsMixin from 'client-portal/mixins/route/site-redirects';
import classic from 'ember-classic-decorator';

const ExtendedRoute = Route.extend(SiteRedirectsMixin);

@classic
export default class SignIn extends ExtendedRoute {
  @service session;
  @service fastboot;
  @service router;

  queryParams = {
    signedOut: {},
  };

  beforeModel() {
    super.beforeModel(...arguments);
    this.session.prohibitAuthentication('site.home');
  }

  model(params) {
    if (params.signedOut && !this.fastboot.isFastBoot) {
      this.#handleSignedOut();
    }
  }

  async #handleSignedOut() {
    await waitForRender();
    this.session.signedOutBanner();
    this.router.replaceWith({ queryParams: { signedOut: null } });
  }
}
