/* import __COLOCATED_TEMPLATE__ from './textarea.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed, get } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './textarea.module.scss';

@classNames('row', styles.component)
@classic
export default class PracticeWebsiteEditMenuTextarea extends Component {
  @computed('model', 'defaultMaxLength')
  get maxLength() {
    if (this.defaultMaxLength) {
      return this.defaultMaxLength;
    }

    let attrs = get(this.model.validations.attrs, this.path);
    return attrs?.options?.length?.max;
  }

  @computed('value', 'maxLength')
  get remainingCharacters() {
    let currentLength = get(this, 'value.length') || 0;
    return `${this.maxLength - currentLength} characters left`;
  }

  @computed('model.validations.errors.@each.attribute', 'path')
  get hasError() {
    return get(this, 'model.validations.errors')?.isAny('attribute', this.path);
  }
}
