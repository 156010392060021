import { htmlSafe } from '@ember/template';
import Helper from '@ember/component/helper';

export function formatMoney([value], options = {}) {
  if (options.format === 'en-US') {
    value = value ? value : 0;

    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    });

    return formatter.format(value).replace(/\D00$/, '');
  }

  if (value === null) {
    return '--';
  }

  let prefix = '';
  if (value < 0 && !options.noPrefix) {
    prefix = 'CR ';
  }

  let formattedValue = Math.abs(value);

  if (options.precision) {
    formattedValue = formattedValue.toFixed(options.precision);
  }

  let formatted = `${prefix}$${formattedValue}`;
  if (options.isPayment) {
    formatted = htmlSafe(`<span class="muted">(${formatted})</span>`);
  }

  return formatted;
}

export function convertToCents(amount) {
  return Math.round(amount * 100);
}

export function roundToCurrency(amount) {
  return Math.round(amount * 100) / 100;
}

export default Helper.helper(formatMoney);
