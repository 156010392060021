import { pluralize } from 'ember-inflector';
import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class DocumentRequest extends ApplicationAdapter {
  urlForQueryRecord(query, modelName) {
    return `${this.buildURL()}/${pluralize(modelName)}/${query.id}`;
  }

  urlForUpdateRecord(id, _modelName, snapshot) {
    return this._insertQueryParams(`${this.buildURL()}/document-requests/${id}`, snapshot);
  }
}
