import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { isEmpty } from '@ember/utils';
import { waitForNext } from '../../utils/waiters';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import filterByTerm from '../../utils/filter-by-term';
import styles from './input-with-options.module.scss';

@classic
@classNames(styles.component)
export default class SharedInputWithOptions extends Component {
  filteredOptions = [];

  @action
  handleSearch(query, sb) {
    this.setValue(query);

    if (sb.isOpen) {
      this._filterOptions(query);
    }
  }

  @action
  handleSelect(value, sb) {
    this.setValue(value);
    sb.setInputValue(value);
    sb.close();
  }

  @action
  handleOpen(sb) {
    this._filterOptions();
    this._scrollToSelectedOption(sb);
  }

  @action
  open(_evt, sb) {
    if (!sb.isOpen) {
      sb.open();
    }
  }

  @action
  close(_evt, sb) {
    sb.close();
  }

  @action
  activateNextOption(evt, sb) {
    evt.preventDefault();
    sb.element.focus();
    sb.activateNextOption();
  }

  @action
  activatePreviousOption(evt, sb) {
    evt.preventDefault();
    sb.element.focus();
    sb.activatePreviousOption();
  }

  @action
  preventFocus(evt) {
    evt.preventDefault();
  }

  _filterOptions(query) {
    let options = isEmpty(query) ? this.options : filterByTerm(this.options, query);
    this.set('filteredOptions', options);
  }

  async _scrollToSelectedOption(sb) {
    await waitForNext();
    if (this.value) {
      sb.activateOptionForValue(this.value, { scrollIntoView: true });
    }
  }
}
