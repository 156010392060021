/* import __COLOCATED_TEMPLATE__ from './section-title.hbs'; */
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './section-title.module.scss';

@tagName('h2')
@classic
@classNames(styles.component)
export default class PracticeWebsiteSectionTitle extends Component {}
