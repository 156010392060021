import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class SharedDropZoneDroparea extends Component {
  @service fileQueue;
  @tracked valid = true;

  get queue() {
    return this.fileQueue.find(this.args.queueName);
  }

  get allowedTypes() {
    return this.args.accept?.split(',');
  }

  @action
  validateFile(file) {
    return this.allowedTypes ? this.#isValidByTypes([file]) : true;
  }

  @action
  preValidateFiles(files, dataTransferWrapper) {
    if (!this.allowedTypes) return;

    let items = files?.length ? files : dataTransferWrapper?.itemDetails;

    if (!items?.length) return;
    this.valid = this.#isValidByTypes(items);
  }

  #isValidByTypes(items) {
    return items.every(item => this.allowedTypes.includes(item.type));
  }
}
