import { A as emberA } from '@ember/array';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class EnvironmentModel extends Model {
  @belongsTo('practice', { async: false }) currentPractice;
  @belongsTo('client-access', { async: false }) currentClientAccess;
  @belongsTo('treatable-client', { polymorphic: true, async: false }) currentClient;
  @hasMany('treatable-client', { polymorphic: true, async: false }) currentClientOptions;
  @attr() messagingEmbed;

  static fieldsFor(_context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    return fields;
  }
}
