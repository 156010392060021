import FormDataAdapterMixin from 'ember-cli-form-data/mixins/form-data-adapter';
import Mixin from '@ember/object/mixin';

// eslint-disable-next-line ember/no-mixins
// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create(FormDataAdapterMixin, {
  ajaxOptions() {
    let hash = this._super.apply(this, arguments);

    if (typeof FormData !== 'undefined' && hash.data instanceof FormData) {
      hash.body = hash.data;
    }

    return hash;
  },
});
