import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import SharedCard from './shared/card';
import SharedImage from './shared/image';
import SharedOfficeAddress from './shared/office-address';
import SharedOfficeDirections from './shared/office-directions';
import SharedOfficeStaticmap from './shared/office-staticmap';
import styles from './home-locations.module.scss';
export default template(`
  <div class={{styles.component}} data-test-home-locations>
    {{#each @model as |office|}}
      <SharedCard class="xs-with-shadow full-width-img">
        {{#if office.isVideo}}
          <SharedImage
            class="video-office"
            @src="/assets/images/telehealth@2x.png"
            @webpSrc="/assets/images/telehealth@2x.webp"
            @width="600"
            @height="400"
            @alt={{office.name}}
          />
        {{else}}
          <SharedOfficeStaticmap
            @office={{office}}
            @size={{hash width=404 height=198}}
            @sizeDesktop={{hash width=604 height=298}}
          />
        {{/if}}

        <div class="info">
          {{#if office.name}}
            <h3>{{office.name}}</h3>
          {{/if}}

          {{#unless office.isVideo}}
            <p><SharedOfficeAddress @model={{office}} /></p>
            {{#if office.isPublic}}
              <SharedOfficeDirections @model={{office}}>
                Directions
              </SharedOfficeDirections>
            {{/if}}
          {{/unless}}
        </div>
      </SharedCard>
    {{/each}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
