import { action, setProperties } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

const EMPTY_VALUE = '--';

@classic
export default class SharedQuestionnaireDropdownInput extends Component {
  emptyValue = EMPTY_VALUE;

  @action
  handleSetValue(value) {
    let isDirty = !this.isModelNew || value !== this.emptyValue;
    setProperties(this.answer, { isDirty, freeTextAnswer: value });
  }
}
