import {
  DEFAULT_DOCUMENTS_MAX_FILE_SIZE,
  DEFAULT_MAX_FILE_SIZE_TEST,
} from 'client-portal/services/file-upload';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import ApplicationAdapter from './application';

export default class BaseInsuranceCardAdapter extends ApplicationAdapter {
  @service fileUpload;

  maxFileSize = isEmberTesting() ? DEFAULT_MAX_FILE_SIZE_TEST : DEFAULT_DOCUMENTS_MAX_FILE_SIZE;

  createRecord(_store, _type, record) {
    if (record.attributes().file) {
      return this._uploadFileTask.perform(...arguments);
    } else {
      return super.createRecord(...arguments);
    }
  }

  getData(/* record*/) {
    // Abstract method to retrieve the record's data
  }

  @task(function* (_store, type, record) {
    let file = record.attributes().file;
    let route = this.urlForCreateRecord(type.modelName, {});
    let data = this.getData(record);
    let { body } = yield this.fileUpload.validateAndUploadTask.perform(file, {
      route,
      headers: this.headers,
      data,
      fileKey: 'file',
      maxFileSize: this.maxFileSize,
    });
    return body;
  })
  _uploadFileTask;
}
