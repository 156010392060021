/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class ClientAttendanceAppointmentStatus extends Component {
  @reads('model.isConfirmed') isConfirmed;

  @computed(
    'model.{isConfirmed,hasDirtyAttributes}',
    'isCancellingAfterConfirming',
    'isCancelling',
    'isSavingConfirmed'
  )
  get showConfirmed() {
    let { model, isConfirmed } = this;
    if (model) {
      let confirmed = isConfirmed && !this.isSavingConfirmed;
      return confirmed || (this.isCancelingAfterConfirming && !model.isUnconfirmed);
    }
    return false;
  }

  @computed('isConfirmed', 'isCancelling')
  get isCancellingAfterConfirming() {
    return this.isConfirmed || this.isCancelling;
  }

  @computed('isSaving', 'isConfirmed')
  get isSavingConfirmed() {
    return this.isSaving && this.isConfirmed;
  }
}
