module.exports = [
  'Military/Veterans',
  'People with Disabilities',
  'Feminism',
  'Vegetarian/Vegan',
  'Racial Justice/Equity',
  'HIV/AIDS',
  'Recovery',
  'Lesbian, Gay, & Bisexual',
  'Trans & Non-Binary',
  'LGBTQIA+',
  'Kink/Diverse Sexualities',
  'Open Relationships/Polyamory',
  'Altered State of Consciousness',
];
