/* import __COLOCATED_TEMPLATE__ from './text-extraction-loading-modal-content.hbs'; */
import Component from '@glimmer/component';
import styles from './text-extraction-loading-modal-content.module.scss';

export default class SiteDocumentsContactInfoInsuranceSectionTextExtractionLoadingModalContent extends Component {
  styles = styles;

  get progressPercentage() {
    return this.args.progress * 100;
  }
}
