import { computed, get } from '@ember/object';
import { service } from '@ember/service';
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import classic from 'ember-classic-decorator';
import config from '../config/environment';

const { APP, internalLbUrl } = config;
const PROXY_HEADERS = [
  'cookie',
  'user-agent',
  'x-real-ip',
  'x-native-app',
  'access-token',
  'custom-domain',
  'x-forwarded-host',
];
export const namespace = 'client-portal-api';
const apiVersion = '2019-01-17';
const applicationVersion = APP.version;
const applicationPlatform = 'web';

@classic
export default class Application extends JSONAPIAdapter {
  namespace = namespace;

  @service fastboot;

  @service session;

  @computed('fastboot.{isFastBoot,request.host}')
  get host() {
    if (this.get('fastboot.isFastBoot') && config.environment === 'production') {
      return internalLbUrl || `https://${this.get('fastboot.request.host')}`;
    }

    // always use relative paths in browser
    // use relative paths for fastboot in development and test
    return undefined;
  }

  @computed('fastboot.request.{host,headers}', 'session.data.sessionId')
  get headers() {
    let apiHeaders = {
      'Api-Version': apiVersion,
      'Application-Build-Version': applicationVersion,
      'Application-Platform': applicationPlatform,
    };

    let sessionId = this.get('session.data.sessionId');
    if (sessionId) {
      apiHeaders['Session-Id'] = sessionId;
    }

    if (!this.get('fastboot.isFastBoot')) {
      return apiHeaders;
    }

    if (internalLbUrl) {
      apiHeaders['x-forwarded-host'] = this.get('fastboot.request.host');
    }

    let { headers } = this.get('fastboot.request.headers');
    let result = PROXY_HEADERS.reduce((result, name) => {
      if (headers[name] && headers[name][0]) {
        result[name] = headers[name][0];
      }

      return result;
    }, apiHeaders);

    return result;
  }

  handleResponse(status, headers) {
    this._ensureResponseAuthorized(status);
    // NOTE: proxy all cookies from API back to the client
    // Backend is the source of truth for session/cookies
    if (this.fastboot.isFastBoot && headers['set-cookie']) {
      this.fastboot.response.headers.set('set-cookie', headers['set-cookie']);
    }

    return super.handleResponse(...arguments);
  }

  // Allows to pass query params to /records/:id request
  urlForFindRecord(id, modelName, snapshot) {
    return this._insertQueryParams(super.urlForFindRecord(...arguments), snapshot);
  }

  // Allows to pass query params to POST /records request
  urlForCreateRecord(modelName, snapshot) {
    return this._insertQueryParams(super.urlForCreateRecord(...arguments), snapshot);
  }

  // Allows to pass query params to PATCH /records/:id request
  urlForUpdateRecord(id, modelName, snapshot) {
    return this._insertQueryParams(super.urlForUpdateRecord(...arguments), snapshot);
  }

  _insertQueryParams(url, snapshot) {
    let query = get(snapshot, 'adapterOptions.query');
    if (query) {
      // Assumes no query params are present already
      url += `?${this._stringifyQuery(query)}`;
    }
    return url;
  }

  _stringifyQuery(query, nesting = '') {
    let pairs = Object.keys(query).map(key => {
      let val = query[key];
      let nestedKey = nesting === '' ? key : `${nesting}[${key}]`;
      if (Array.isArray(val)) {
        return val.map(subVal => [nestedKey, subVal].map(encodeURIComponent).join('=')).join('&');
      } else if (typeof val === 'object') {
        return this._stringifyQuery(val, nestedKey);
      } else {
        return [nestedKey, val].map(encodeURIComponent).join('=');
      }
    });
    return pairs.join('&');
  }

  _ensureResponseAuthorized(status) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    }
  }
}
