import ApplicationAdapter from '../application';
import classic from 'ember-classic-decorator';

@classic
export default class PolymorphicBillingItem extends ApplicationAdapter {
  urlForQuery() {
    return super.urlForQuery(...arguments).replace('/polymorphic', '');
  }

  urlForQueryRecord({ cursorId }) {
    return `${this.urlForQuery(...arguments)}/${encodeURIComponent(cursorId)}`;
  }
}
