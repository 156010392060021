/* import __COLOCATED_TEMPLATE__ from './credit-card-info-form.hbs'; */
import { action, computed } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { buildStripeSetupModeElement } from 'client-portal/utils/stripe-payment-methods';
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './credit-card-info-form.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsCreditCardInfoForm extends Component {
  styles = styles;

  @service stripeCards;
  @service currentPractice;
  @service stripe;
  @service stripePaymentElementService;

  @tracked stripeElement;
  isDirty = false;

  @computed(
    'stripeCards.createCardTokenTask.isRunning',
    'stripeCards.areNewPaymentMethodTasksRunning',
    'completeRequestTask.isRunning',
    'model.card.areElementsReady',
    'stripePaymentElementService.isLoaded'
  )
  get isSubmitDisabled() {
    let isCardReady =
      this.stripePaymentElementService.isLoaded &&
      !this.stripeCards.areNewPaymentMethodTasksRunning;

    return this.get('completeRequestTask.isRunning') || !isCardReady;
  }

  async didInsertElement() {
    await super.didInsertElement(...arguments);

    if (!this.model.card) {
      let card = this.stripeCards.createCard();
      this.model.setProperties({ card });
    }

    try {
      this.stripeElement = buildStripeSetupModeElement(this.stripe.stripe);
    } catch (err) {
      this.loadingError = err;
    }
  }

  @action
  async nextStep() {
    this.set('isDirty', true);
    let card = this.model.card;

    try {
      card.set('_isDirty', true);
      await card.validate();
      let { error } = await this.stripeElement.submit();

      if (error || !card.name) {
        return;
      }

      let result = await this.stripeCards.addPaymentMethodTask.perform({
        elements: this.stripeElement,
        newCard: card,
        persistCard: false,
      });

      if (result) {
        await this._completeRequest();
      }
    } catch (err) {
      bannerError(err, { title: 'Card could not be saved' });
    }
  }

  async _completeRequest() {
    await this.completeRequestTask.perform(this.model, {
      adapterOptions: {
        embed: ['card'],
      },
    });
  }
}
