import { bannerEvents } from 'client-portal/utils/feedback-banner-events';
import { computed, set } from '@ember/object';
import { reads } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class FeedbackBannerService extends Service {
  bannerEvents = bannerEvents;
  currentStateId = '';
  states = [];

  @service session;
  @service store;

  @reads('session.currentClient') currentClient;
  @reads('currentClient.permissions') permissions;
  @reads('session.currentClientAccess.clientType') clientType;

  @computed(
    'permissions.{payments,billingDocuments,messaging,selfScheduling}',
    'currentStateId',
    'clientType',
    'states.[]'
  )
  get mixpanelEventData() {
    let { states, currentStateId } = this;
    let state = states.findBy('id', currentStateId);
    let { header: { subtitle } = {} } = state || {};
    return {
      'question_body': subtitle,
      'client_payment_type': this.permissions?.payments,
      'client_contact_type': this.clientType,
      'client_self_schedule': this.permissions?.selfScheduling,
      'client_secure_messaging': this.permissions?.messaging,
      'client_document_access': this.permissions?.billingDocuments,
    };
  }

  on(bannerEvent, handler) {
    if (this.bannerEvents.includes(bannerEvent)) {
      this[bannerEvent] = handler;
    } else {
      // this error should only appear during dev time
      // to avoid it, add your new event to the utils/feedback-banner-events event array
      throw `'${bannerEvent}' is not defined as an event for the feedback banner`;
    }
  }

  off(bannerEvent) {
    if (this.bannerEvents.includes(bannerEvent)) {
      this[bannerEvent] = null;
    }
  }

  setCurrentStateId(id) {
    set(this, 'currentStateId', id);
  }

  setStates(states) {
    states = states.map(state => {
      let { type, model } = state;
      if (type) {
        model = this.store.createRecord(type, {});
      }
      return { ...state, model };
    });
    set(this, 'states', states);
  }

  clearStates() {
    this.states.forEach(state => {
      let { model, type } = state;
      if (type && model) {
        model.unloadRecord?.();
      }
    });
    set(this, 'states', []);
  }
}
