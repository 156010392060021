/* import __COLOCATED_TEMPLATE__ from './service-code.hbs'; */
import Component from '@glimmer/component';

export default class SiteBillingOutOfNetworkFormAppointmentSectionServiceCode extends Component {
  get primaryCode() {
    return this.args.value?.firstObject;
  }

  get serviceCodeCount() {
    return this.args.value?.length - 1 || 1;
  }

  unitText(count) {
    return count > 1 ? 'units' : 'unit';
  }
}
