import { helper } from '@ember/component/helper';

function formatWithDots(phone) {
  let firstDot = phone.length > 3 ? '.' : '';
  let secondDot = phone.length > 6 ? '.' : '';
  return `${phone.substr(0, 3)}${firstDot}${phone.substr(3, 3)}${secondDot}${phone.substr(6, 4)}`;
}

export function formatPhone([phone], { format }) {
  if (!phone) {
    return phone;
  }

  let clean = phone.replace(/[\D]/gi, '');

  switch (format) {
    case 'no-separators':
      return clean;
    case 'dots':
      return formatWithDots(clean);
    default:
      return phone;
  }
}

export default helper(formatPhone);
