/* import __COLOCATED_TEMPLATE__ from './nav.hbs'; */
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import styles from './nav.module.scss';

@classNames(styles.component)
export default class PracticeWebsiteEditMenuNav extends Component {
  @action
  handleClick(id) {
    this.onClick?.(id);
  }
}
