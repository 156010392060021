import Transform from '@ember-data/serializer/transform';
import classic from 'ember-classic-decorator';

@classic
export default class JsonTransform extends Transform {
  deserialize(serialized) {
    if (!serialized) {
      return serialized;
    }

    return JSON.parse(serialized);
  }

  serialize(deserialized) {
    if (!deserialized) {
      return deserialized;
    }

    return JSON.stringify(deserialized);
  }
}
