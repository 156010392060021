/* import __COLOCATED_TEMPLATE__ from './insurance-card.hbs'; */
import { action } from '@ember/object';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class SharedInsuranceCard extends Component {
  @service fileUpload;
  @service media;
  @service store;

  @tracked isMarkedForDeletion = this.args.card?.isMarkedForDeletion;

  @reads('args.side') side;
  @reads('args.card') card;
  @reads('card.id') cardId;
  @reads('card.isNew') cardIsNew;
  @reads('card.documentExt') documentExt;
  @reads('args.client.primaryInsuranceInfo') primaryInsuranceInfo;
  @reads('card.file.file.type') cardImageType;

  get isImage() {
    let imageExtension = this.documentExt || this.cardImageType;
    return /^(jpeg|image\/jpeg|jpg|image\/jpg|png|image\/png)$/.test(imageExtension);
  }

  get fileUrl() {
    if (this.cardId && !this.isMarkedForDeletion) {
      let adapter = this.store.adapterFor('insurance-card');
      return adapter.urlForFindRecord(this.cardId, 'insurance-card', {});
    } else if (this.cardIsNew) {
      return URL.createObjectURL(this.card.file.file);
    } else {
      return undefined;
    }
  }

  @action
  removeAssociation() {
    return this.cardIsNew ? this.card.deleteRecord() : this.card.set('isMarkedForDeletion', true);
  }

  @action
  async uploadCard(file) {
    // [#187135093] to add file size validation
    let card = await this.store.createRecord('insurance-card', {
      file,
      insuranceInfo: this.primaryInsuranceInfo,
    });

    this.primaryInsuranceInfo.documents.pushObject(card);
  }
}
