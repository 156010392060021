module.exports = {
  'anxiety':
    'A mood disorder involving feelings of tension, worry, fear, intrusive negative thoughts, and behavioral disturbances.',
  'depression':
    'A mood disorder involving extreme sadness and loss of interest, feeling worthless or guilty, and may lead to thoughts of death.',
  'trauma':
    'An emotional response to a negative event. Shock, denial, flashbacks, and physical symptoms are associated with traumatic experiences.',
  'relationship issues':
    'Issues ranging from emotional or financial problems in a relationship to parenting frustrations or infidelity.',
  'life transitions':
    'Times in life when there is a lot of change such as entering into middle-age or re-evaluating life’s meaning.',
  'grief':
    'Feelings of pain and anguish after a significant loss, often the death of someone close.',
  'adjustment disorder':
    'A mental illness often caused by an extremely stressful event, such as divorce or job loss, that can lead to depression, social isolation, anxiety, substance abuse, or suicidal ideation.',
  'self esteem': 'Challenges with confidence in one’s own worth or abilities.',
  'coping skills':
    'Methods to deal with stressful situations to manage emotions and avoid overwhelm.',
  'couples counseling':
    'For intimate partners navigating conflict—also called marriage counseling.',
};
