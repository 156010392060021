import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { specialties } from '@simplepractice/marketing-options';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

export const maxMarketingSpecialtiesCount = 25;
export const maxMarketingSpecialtiesLength = 40;
export const globalMarketingSpecialties = specialties;

const Validations = buildValidations({
  name: [
    validator('length', {
      max: maxMarketingSpecialtiesLength,
      message: `Can't be more than ${maxMarketingSpecialtiesLength} characters.`,
    }),
  ],
});

@classic
export default class PracticeWebsiteMarketingSpecialty extends Fragment.extend(Validations) {
  @attr() name;
}
