import { base64Encode } from 'client-portal/utils/base64';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class SignInTokenRequestRoute extends Route {
  @service mixpanel;
  @service store;
  @service signIn;

  queryParams = {
    hasInvalidToken: { scope: 'controller' },
    redirect: { refreshModel: true },
    refresh: { refreshModel: true },
    failure: { refreshModel: true },
  };

  beforeModel() {
    super.beforeModel(...arguments);

    let storedEmail = this.signIn.storedEmail;
    if (storedEmail && !this.signIn.email) {
      this.signIn.email = storedEmail;
    }
  }

  model({ redirect }) {
    return this.store.createRecord('sign-in-token', {
      email: this.signIn.email,
      expiresIn: '15 minutes',
      redirect,
    });
  }

  activate() {
    super.activate(...arguments);

    if (this.paramsFor(this.routeName).hasInvalidToken === undefined) {
      this.mixpanel.track('visit: Client Portal Sign-in page');
    }
  }

  setupController(controller) {
    super.setupController(...arguments);
    let link = controller.hasInvalidToken ? { link: 'expired' } : {};
    let pageLocation = controller.hasInvalidToken !== undefined ? 'expiredSignIn' : 'signIn';

    controller.setProperties({
      showTips: false,
      refresh: null,
      pageLocation,
      oauthState:
        '?state=' + base64Encode(JSON.stringify({ host: window.location?.hostname, ...link })),
    });
    this.signIn.resetErrors();
  }

  resetController(controller) {
    super.resetController(...arguments);
    controller.set('hasInvalidToken', undefined);
  }
}
