/* import __COLOCATED_TEMPLATE__ from './appointments-page.hbs'; */
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SiteAppointmentsPage extends Component {
  @service media;
  @service session;
  @service prospectiveClient;
}
