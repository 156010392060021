import { registerDateLibrary } from 'ember-power-calendar';
import DateUtils from 'ember-power-calendar-moment';

export function initialize() {
  registerDateLibrary(DateUtils);
}

export default {
  initialize,
};
