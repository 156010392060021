/* global FastBoot */
export function base64Encode(str) {
  try {
    return btoa(str);
  } catch {
    let Buffer = FastBoot.require('buffer').Buffer;
    return Buffer.from(str).toString('base64');
  }
}

export function base64Decode(str) {
  try {
    return atob(str);
  } catch {
    let Buffer = FastBoot.require('buffer').Buffer;
    return Buffer.from(str, 'base64').toString();
  }
}
