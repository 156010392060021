import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class TreatableClient extends ApplicationAdapter {
  manage(id) {
    let baseUrl = this.buildURL('treatable-client', id);
    return this.ajax(`${baseUrl}/manage`, 'PATCH');
  }
}
