/* import __COLOCATED_TEMPLATE__ from './stripe-element-loading-display.hbs'; */
import { dismissSticky, error } from 'client-portal/utils/banners';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import Component from '@glimmer/component';

export default class SharedStripeElementLoadingDisplay extends Component {
  timeToError = 4000;

  constructor() {
    super(...arguments);
    this.handleError.perform();
  }

  handleError = task(async () => {
    let didShow = false;
    try {
      await timeout(this.timeToError);

      if (this.args.isElementLoaded) {
        return;
      }

      didShow = true;
      this.showErrorBanner();

      await waitForProperty(this.args, 'isElementLoaded');
    } finally {
      if (didShow) dismissSticky();
    }
  });

  showErrorBanner() {
    let content = document.createElement('p');
    content.innerText =
      'There was an error loading this page. Please check your internet connection, refresh your browser, and try again.';
    error({
      title: 'Error: Failed to load payment method form',
      content,
    });
  }
}
