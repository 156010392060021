import { computed } from '@ember/object';
import { isTest } from '../../utils/is-testing';
import { not } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  isTest: computed(function () {
    return isTest(this);
  }),

  isAnimated: not('isTest'),
});
