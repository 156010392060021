function setupPdf() {
  let renderPdf = async function (pdfjsLib, url, container) {
    if (!pdfjsLib) {
      throw new Error();
    }

    function renderPage(page) {
      // Double viewport is for retina
      let viewport = page.getViewport({
        scale: (container.clientWidth / page.getViewport({ scale: 1.0 }).width) * 2,
      });
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let renderContext = {
        canvasContext: ctx,
        viewport,
      };

      canvas.height = viewport.height;
      canvas.width = viewport.width;
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      container.appendChild(canvas);

      return page.render(renderContext).promise;
    }

    /* eslint-disable promise/prefer-await-to-then */
    async function renderPages(doc) {
      let promises = Array.apply(null, Array(doc.numPages)).map(async function (_, i) {
        return await doc.getPage(i + 1);
      });

      return Promise.all(promises).then(async pages => {
        for (let page of pages) {
          await renderPage(page);
        }
      });
    }

    let doc = await pdfjsLib.getDocument(url).promise;
    return renderPages(doc);
  };

  let printPdf = function (url) {
    let pdfWindow = window.open(url);

    // NOTE: calling print disabled for Safari because it just freezes
    if (window.safari !== undefined) {
      return;
    }

    // eslint-disable-next-line ember-cleanup/no-settimeout
    setTimeout(function () {
      if (pdfWindow) {
        pdfWindow.print();
      }
    }, 100);

    return false;
  };

  let loadPdfJsLib = async function () {
    let isES2022Supported = Array.prototype.at !== undefined;
    let pdfJsLib, pdfJsWorker;

    if (isES2022Supported) {
      [pdfJsLib, pdfJsWorker] = await Promise.all([
        import('pdfjs-dist/build/pdf'),
        import('pdfjs-dist/build/pdf.worker.entry'),
      ]);
    } else {
      [pdfJsLib, pdfJsWorker] = await Promise.all([
        import('pdfjs-dist/legacy/build/pdf'),
        import('pdfjs-dist/legacy/build/pdf.worker.entry'),
      ]);
    }

    pdfJsLib.GlobalWorkerOptions.workerSrc = pdfJsWorker.default;

    return pdfJsLib;
  };

  return {
    renderPdf,
    printPdf,
    loadPdfJsLib,
  };
}

export default setupPdf;
