import Helper from '@ember/component/helper';
import moment from 'moment-timezone';

export function momentFormat([value, format, timezone]) {
  if (!value) {
    return '';
  }

  if (!value.format) {
    value = moment(value);
  }

  if (timezone) {
    value = value.tz(timezone);
  }
  return value.format(format);
}

export default Helper.helper(momentFormat);
