import { TYPES } from 'client-portal/models/document-request';
import { service } from '@ember/service';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

const SHOEBOX_KEY = 'documents-index';

@classic
export default class SiteDocumentsIndexRoute extends Route.extend(FastbootHelpersMixin) {
  @service store;
  @service request;
  model() {
    if (this.hasShoebox) {
      let { ids } = this.shoebox.retrieve(SHOEBOX_KEY);
      return ids.map(id => {
        return TYPES.map(type => this.store.peekRecord(type, id)).compact().firstObject;
      });
    } else {
      return this.store.query('document-request', {
        fields: {
          documentRequests: this.store.modelFor('document-request').fieldsFor('index').join(','),
        },
      });
    }
  }

  afterModel(model) {
    super.afterModel(...arguments);

    if (this.isFastBoot) {
      this.shoebox.put(SHOEBOX_KEY, {
        meta: model.meta,
        ids: model.mapBy('id'),
      });
    } else if (!model.meta) {
      let { meta } = this.shoebox.retrieve(SHOEBOX_KEY);
      if (meta) {
        model.set('meta', meta);
      }
    }
  }
}
