import { copy } from 'ember-copy';
import { reject, resolve } from 'rsvp';
import { service } from '@ember/service';
import Super from 'ember-simple-auth/authenticators/base';
import classic from 'ember-classic-decorator';

// NOTE: order is important for the restore
export const environmentRelationships = [
  'currentPractice.defaultOffice',
  'currentPractice.defaultMarketingOffice',
  'currentPractice.defaultPhone',
  'currentPractice.practiceWebsite',
  'currentPractice',
  'currentClientAccess.client',
  'currentClientAccess',
  'currentClient',
  'currentClientOptions',
  'currentClient.globalMonarchChannel',
];

@classic
export default class Environment extends Super {
  @service session;

  @service store;

  @service router;

  @service fastboot;

  restore(data) {
    if (data && data.payloads) {
      try {
        // NOTE: we don't want to push stale payloads if we have new ones from the API/shoebox
        // So condition below is a heuristic to push payloads only on subsequent session restorations
        if (this.router.currentRouteName) {
          for (let payload of data.payloads) {
            this.store.pushPayload(copy(payload));
          }
        }

        let environment = this.store.peekAll('environment').firstObject;
        this.session.setProperties({ environment });

        return resolve(data);
      } catch (err) {
        console.error(err); // eslint-disable-line no-console
        window._bugsnagClient && window._bugsnagClient.notify(err);

        return reject();
      }
    } else {
      return reject();
    }
  }

  authenticate(environment) {
    if (environment && environment.currentClientAccess) {
      this.session.setProperties({ environment });

      // NOTE: session data only makes sense on client because it uses localStorage
      // So this prevents "Cookies larger than 4096 bytes are not supported" error
      if (this.fastboot.isFastBoot) {
        return resolve();
      }

      let models = [];
      for (let name of environmentRelationships) {
        let relationship = environment.get(name);
        let records =
          relationship && relationship.toArray ? relationship.toArray() : [relationship];
        models.push(...records);
      }
      models.push(environment);

      let payloads = models
        .compact()
        .uniq()
        .map(model => model.serialize({ includeId: true }));
      return resolve({ payloads });
    } else {
      return reject();
    }
  }
}
