/* import __COLOCATED_TEMPLATE__ from './services-list.hbs'; */
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class RequestServicesList extends Component {
  @service request;

  @reads('request.hasRecommendedServiceBadge') showRecommended;

  @computed('services', 'showRecommended')
  get orderedServices() {
    if (this.showRecommended) {
      return this.services.toArray().sort((serviceA, serviceB) => {
        if (serviceA.eligibleToRecommend && serviceB.eligibleToRecommend) {
          return 0;
        }

        return serviceA.eligibleToRecommend ? -1 : 1;
      });
    }

    return this.services;
  }
}
