/* import __COLOCATED_TEMPLATE__ from './effort-score.hbs'; */
import { action, computed, set } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './effort-score.module.scss';

@classic
@classNames(styles.component)
export default class FeedbackBannerEffortScore extends Component {
  @service feedbackBanner;
  @service mixpanel;

  phiAffirmation = false;

  @computed('model.{numCharsRemaining,maxCharsLimit}')
  get feedbackStatusLabel() {
    let { model: { numCharsRemaining, maxCharsLimit, feedback } = {} } = this;
    return numCharsRemaining === maxCharsLimit ? 'Optional' : `${feedback.length}/${maxCharsLimit}`;
  }

  @computed('phiAffirmation', 'model.{isFeedbackValid,hasFeedback}')
  get isDisabled() {
    let { phiAffirmation, model: { isFeedbackValid, hasFeedback } = {} } = this;
    return !isFeedbackValid || (hasFeedback && !phiAffirmation);
  }

  @action
  onStarted() {
    this.feedbackBanner.started?.();
  }

  @action
  onFocusFeedback(e) {
    this.onStarted();
    this.onInputFocus?.(e.target);
  }

  @action
  onBlurFeedback() {
    let { model: { numCharsRemaining, maxCharsLimit } = {} } = this;
    if (numCharsRemaining === maxCharsLimit) {
      set(this, 'phiAffirmation', false);
    }
    this.onInputBlur?.();
  }

  @action
  async onSubmit() {
    try {
      await this.model.save();
      this.mixpanel.trackAnonymously('client: survey banner completed', {
        'client_response': this.model.rating,
        ...this.feedbackBanner.mixpanelEventData,
      });
    } catch (error) {
      console.error('Feedback Banner Submit Error: ', error); // eslint-disable-line no-console
    } finally {
      this.feedbackBanner.next();
    }
  }
}
