/* import __COLOCATED_TEMPLATE__ from './auto-numeric.hbs'; */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SharedAutoNumeric extends Component {
  @tracked isDisabled = true;

  async didInsertElement() {
    super.didInsertElement(...arguments);

    let AutoNumeric = (await import('autonumeric')).default;
    let userOptions = this.options || {};
    let options = {
      currencySymbol: '$',
      allowDecimalPadding: false,
      showWarnings: false,
      ...userOptions,
    };

    this._autoNumeric = new AutoNumeric(this.element.querySelector('input'), options);
    this._autoNumeric.set(this.value);
    this.isDisabled = false;
  }

  didUpdateAttrs() {
    super.didUpdateAttrs(...arguments);
    if (this.value !== undefined && this.value !== this._autoNumeric.getNumber()) {
      this._autoNumeric.set(this.value);
    }
  }

  @action
  oninput(invokeAction, evt) {
    // NOTE: getNumericString does not work properly in:
    // - Chrome on Android
    // - Headless Chrome on CI
    let stringValue = evt.target.value.replace(/[^0-9\.\-]/g, '');
    let value = parseFloat(stringValue);

    if (isNaN(value)) {
      value = null;
    }

    invokeAction(value);
  }
}
