import { service } from '@ember/service';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class HomeLegacyRoute extends Route.extend(FastbootHelpersMixin) {
  @service router;
  beforeModel() {
    super.beforeModel(...arguments);
    if (this.isFastBoot) {
      this.fastboot.response.headers.set('location', '/');
      this.set('fastboot.response.statusCode', 301);
    } else {
      this.router.transitionTo('home');
    }
  }
}
