import { pluralize } from 'ember-inflector';
import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class UnauthenticatedAppointment extends ApplicationAdapter {
  urlForQueryRecord(query, modelName) {
    let url = `${this.buildURL()}/${pluralize(modelName)}/${query.token}`;
    delete query.token;
    return url;
  }

  _ensureResponseAuthorized() {}
}
