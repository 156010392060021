import { bool, not, reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class SiteRequestController extends Controller {
  @service session;

  @bool('session.currentClient') isSignedIn;
  @not('isSignedIn') isNotSignedIn;
  @reads('activeSteps.firstObject.isActive') isFirstStep;
}
