/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './form.module.scss';

const CLIENT_TYPES = [
  { type: 'client', label: 'Me' },
  { type: 'couple', label: 'My partner and me' },
  { type: 'contact', label: 'Someone else' },
];

const CLIENT_TYPES_FOR_CARE_COORDINATOR = [
  { type: 'client', label: 'My member' },
  { type: 'couple', label: 'My member and partner' },
  { type: 'contact', label: 'My member on behalf of someone else' },
];

const ALLOWED_TO_BOOK = {
  'client,contact,couple':
    'You can request this appointment for yourself, on behalf of someone else, or for both you and your partner. Please choose below.',
  'client,contact':
    'You can request this appointment for yourself or on behalf of someone else. Please choose below.',
  'client,couple':
    'You can request this appointment for yourself, or for both you and your partner. Please choose below.',
  'contact,couple':
    'You can request this appointment on behalf of someone else or for both you and your partner. Please choose below.',
};

@classic
@tagName('form')
@classNames('client-type-form text-left', styles.component)
export default class RequestClientTypeForm extends Component {
  @service currentPractice;

  @computed('currentPractice.clientTypesAllowedToBookAppt')
  get enabledClientTypes() {
    return this.currentPractice.get('clientTypesAllowedToBookAppt');
  }

  @computed('enabledClientTypes.[]', 'model.channel')
  get clientTypeOptions() {
    let types = this.model.channel?.careCoordinatorMode
      ? CLIENT_TYPES_FOR_CARE_COORDINATOR
      : CLIENT_TYPES;
    return types.filter(clientType => this.enabledClientTypes.includes(clientType.type));
  }

  @computed('enabledClientTypes.[]', 'model.channel')
  get allowedToBookAppointmentMessage() {
    if (this.model.channel?.careCoordinatorMode) {
      return 'Please choose from the options below.';
    } else {
      return ALLOWED_TO_BOOK[this.enabledClientTypes.sort().join(',')];
    }
  }
}
