/* eslint-disable ember/no-observers */
import * as modals from '../../utils/modals';
import { action } from '@ember/object';
import { bool } from '@ember/object/computed';
import { buildWaiter } from '@ember/test-waiters';
import { isArray } from '@ember/array';
import Component from '@ember/component';
import Swal from 'sweetalert2';
import classic from 'ember-classic-decorator';
import compactObject from '../../utils/compact-object';

const DEFAULT_TYPE = 'confirmation';
const animationTestWaiter = buildWaiter('popup-visible');

const POPUP_SELECTORS = {
  CONTENT: '#swal2-content',
  CONTENT_OUTER: '.swal2-content',
  HEADER: '.swal2-header',
  ACTIONS: '.swal2-actions',
  ACTION_CANCEL: '.swal2-cancel',
  ACTION_CLOSE: '.swal2-close',
  ACTION_CONFIRM: '.swal2-confirm',
  COMPONENT_CONTENT: '.content-wrapper',
};

@classic
export default class SharedModalDialog extends Component {
  modals = modals;

  @bool('actionDisabled') isActionDisabled;
  #waiterToken = null;
  popupElement = null;

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    let options = this.options;
    let popup = Swal.getPopup();

    if (!popup) return;
    let popupClasses = popup?.className;

    if (Swal.isVisible() && Object.keys(options).length !== 0) {
      Swal.update(options);
    }
    this.#updateClasses(popup, popupClasses);
  }

  didInsertElement() {
    super.didInsertElement(...arguments);

    let modalType = this.modalType || DEFAULT_TYPE;
    let onClose = this.onClose;
    let onConfirm = this.onConfirm;
    let options = this.options;

    options.closeButtonAriaLabel = this.title ? `close ${this.title} dialog` : undefined;
    // NOTE: allow autonumeric to receive events
    options.stopKeydownPropagation = false;

    if (this.keepOnConfirm) {
      options.preConfirm = () => {
        onConfirm?.();
        this._toggleActionsDisabled();
        return new Promise(() => {});
      };

      // Do not allow closing while action is disabled
      let allowEscapeKey = options.allowEscapeKey !== false;
      options.allowEscapeKey = () => {
        return allowEscapeKey && !this.actionDisabled;
      };

      let allowOutsideClick = options.allowOutsideClick !== false;
      options.allowOutsideClick = () => {
        return allowOutsideClick && !this.actionDisabled;
      };
    }

    for (let key in options) {
      if (options[key] === undefined) {
        delete options[key];
      }
    }

    if (onClose) {
      Swal.closeDialog = onClose;
    }

    options.willOpen = this._afterInsert;
    options.didOpen = this._onOpen;

    // eslint-disable-next-line promise/prefer-await-to-then
    this.modals[modalType](options).then(result => {
      let isAction = result.value && onConfirm;
      let isClose = modalType === 'custom' ? result.dismiss && onClose : onClose;
      if (isAction) {
        onConfirm();
      } else if (isClose) {
        onClose();
      }
    });
  }

  didRender() {
    super.didRender(...arguments);
    if (!this.popupElement) return;

    let content = this.popupElement.querySelector(POPUP_SELECTORS.CONTENT);
    let contentWrapper = this.popupElement.querySelector(POPUP_SELECTORS.COMPONENT_CONTENT);
    let contentOuter = this.popupElement.querySelector(POPUP_SELECTORS.CONTENT_OUTER);

    if (contentOuter && contentWrapper && content) {
      this.#manageMultipleActions();
      this.#replaceAction(contentWrapper, contentOuter);
      this.#manageScroll(contentOuter, content);
    }
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    animationTestWaiter.endAsync(this.#waiterToken);
    this.removeObserver('actionDisabled', this, '_toggleActionsDisabled');
    this.removeObserver('actionConfirmDisabled', this, '_toggleConfirmDisabled');
    Swal.close();
  }

  get options() {
    return compactObject({
      title: this.title,
      showCloseButton: this.showCloseButton,
      cancelButtonText: this.cancelButtonText,
      confirmButtonText: this.confirmButtonText,
      showCancelButton: this.showCancelButton,
      showConfirmButton: this.showConfirmButton,
      allowOutsideClick: this.allowOutsideClick,
      closeButtonAriaLabel: this.closeButtonAriaLabel,
      target: this.target,
    });
  }

  @action
  _afterInsert(el) {
    this.#waiterToken = animationTestWaiter.beginAsync();
    this.popupElement = el;
    let content = this.popupElement.querySelector(POPUP_SELECTORS.CONTENT);
    let contentWrapper = this.element.querySelector(POPUP_SELECTORS.COMPONENT_CONTENT);

    let popupClassList = this.popupElement.classList;
    if (this.classes && popupClassList) {
      let classList = isArray(this.classes) ? this.classes : this.classes.split(' ');
      popupClassList.add(...classList.filter(c => c));
    }
    content.insertBefore(contentWrapper, content.firstChild);
    content.style.display = 'block';

    if (!this.title) {
      this.popupElement.querySelector(POPUP_SELECTORS.HEADER).style.display = 'none';
      this.popupElement.querySelector(POPUP_SELECTORS.CONTENT_OUTER).classList.add('no-title');
    }

    this.isActionDisabled ? this._toggleActionsDisabled() : this._toggleConfirmDisabled();
    this.addObserver('actionDisabled', this, '_toggleActionsDisabled');
    this.addObserver('actionConfirmDisabled', this, '_toggleConfirmDisabled');
  }

  @action
  _onOpen() {
    animationTestWaiter.endAsync(this.#waiterToken);
  }

  @action
  _toggleActionsDisabled() {
    this.popupElement
      ?.querySelectorAll(
        `${POPUP_SELECTORS.ACTION_CANCEL}, ${POPUP_SELECTORS.ACTION_CLOSE}, ${POPUP_SELECTORS.ACTION_CONFIRM}`
      )
      .forEach(element => {
        element.disabled = this.isActionDisabled;
      });
  }

  @action
  _toggleConfirmDisabled() {
    let target = this.popupElement?.querySelector(POPUP_SELECTORS.ACTION_CONFIRM);
    if (!target) return;
    target.disabled = !!this.actionConfirmDisabled;
  }

  #manageMultipleActions() {
    let visibleActions = Array.from(
      this.popupElement.querySelectorAll(POPUP_SELECTORS.ACTIONS)
    ).filter(a => a.style.display !== 'none');

    if (visibleActions.length > 1) {
      visibleActions.pop().remove();
    }
  }

  #replaceAction(contentWrapper, contentOuter) {
    let actionsElement = contentWrapper.querySelector(POPUP_SELECTORS.ACTIONS);
    if (!this.isActionReplaceDisabled && actionsElement) {
      this.#insertAfter(actionsElement, contentOuter);
    }
  }

  #insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  #manageScroll(contentOuter, content) {
    if (this.isScrollDisabled) {
      contentOuter?.classList.add('no-scroll');
      return;
    }

    let hasVerticalScroll = contentOuter.clientHeight < content.scrollHeight;
    if (!hasVerticalScroll) return;

    this.popupElement
      .querySelectorAll(`${POPUP_SELECTORS.HEADER}, ${POPUP_SELECTORS.ACTIONS}`)
      .forEach(el => el.classList.add('has-shadow'));
    contentOuter?.classList.add('has-scroll');
  }

  #updateClasses(popup, popupClasses) {
    let content = popup.querySelector(POPUP_SELECTORS.CONTENT);
    if (content) {
      content.style.display = 'block';
    }

    if (popup && popupClasses) {
      popup.className = popupClasses;
    }

    let actions = popup.querySelector(POPUP_SELECTORS.ACTIONS);
    if (actions) {
      actions.style.removeProperty('display');
    }
  }
}
