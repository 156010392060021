import { assert } from '@ember/debug';
import { isEmpty } from '@ember/utils';

const nonexistentOptions = [undefined, null];

function forbiddenAssertion({ argument, message }) {
  assert(message, nonexistentOptions.includes(argument));
}

function optionalAssertion({ message, type, argument }) {
  assert(message, typeof argument === type || nonexistentOptions.includes(argument));
}

function parseArgumentName(args, name) {
  let argument = args;
  // handling for nested attributes
  name.split('.').forEach(segment => (argument = argument[segment]));

  return argument;
}

function requiredAssertion({ message, type, argument }) {
  assert(message, typeof argument === type && !isEmpty(argument));
}

function optionalAction({ action, component, message, name }) {
  optionalAssertion({
    message: message || `@${name} argument of ${component} must be an action or empty`,
    type: 'function',
    argument: action,
  });
}

function optionalBoolean({ argument, component, message, name }) {
  optionalAssertion({
    message: message || `@${name} argument of ${component} must be a Boolean value or empty`,
    type: 'boolean',
    argument,
  });
}

function optionalConfigOption({ options, selected, name, component }) {
  assert(
    `@${name} argument of ${component} must be one of ${options.join(', ')} or empty`,
    options.includes(selected) || nonexistentOptions.includes(selected)
  );
}

function optionalNumber({ argument, component, message, name }) {
  optionalAssertion({
    message: message || `@${name} argument of ${component} must be a number or empty`,
    type: 'number',
    argument,
  });
}

function optionalString({ argument, component, message, name }) {
  optionalAssertion({
    message: message || `@${name} argument of ${component} must be a string or empty`,
    type: 'string',
    argument,
  });
}

function requiredAction({ action, component, message, name }) {
  requiredAssertion({
    message: message || `@${name} argument of ${component} must be an action`,
    type: 'function',
    argument: action,
  });
}

function requiredBoolean({ argument, component, message, name }) {
  requiredAssertion({
    message: message || `@${name} argument of ${component} must be a Boolean value`,
    type: 'boolean',
    argument,
  });
}

function requiredConfigOption({ options, selected, name, component }) {
  assert(
    `@${name} argument of ${component} must be one of ${options.join(', ')}`,
    options.includes(selected) && !isEmpty(selected)
  );
}

function requiredNumber({ argument, component, message, name }) {
  requiredAssertion({
    message: message || `@${name} argument of ${component} must be a number`,
    type: 'number',
    argument,
  });
}

function requiredString({ argument, component, message, name }) {
  requiredAssertion({
    message: message || `@${name} argument of ${component} must be a string`,
    type: 'string',
    argument,
  });
}

function selectConfigOption(options, selected, fallback) {
  return options.find(option => option === selected) || fallback;
}

function validateArgumentList({ component, args, argumentList }) {
  assert(
    'component name must be present to properly compose error messages while validating components',
    typeof component === 'string' && component.length > 0
  );
  assert(
    `the args object for ${component} must be passed in to properly validate its arguments`,
    typeof args === 'object' && Object.keys(args).length > 0
  );
  assert(
    `argumentList must be an array to properly validate arguments for ${component}`,
    Array.isArray(argumentList)
  );

  argumentList.forEach(list => {
    if (list.type === 'configOption') {
      list.optional?.forEach(({ name, options }) => {
        optionalConfigOption({
          component,
          name,
          options,
          selected: parseArgumentName(args, name),
        });
      });

      list.required?.forEach(({ name, options }) => {
        requiredConfigOption({
          component,
          name,
          options,
          selected: parseArgumentName(args, name),
        });
      });
    } else {
      let { type } = list;
      let messageBase = `argument of ${component} must be a ${type} value`;
      let compareType = type === 'action' ? 'function' : type;

      list.forbidden?.forEach(({ name, reason }) => {
        forbiddenAssertion({
          argument: parseArgumentName(args, name),
          message: `@${name} argument of ${component} is not allowed because ${reason}`,
        });
      });

      list.optional?.forEach(name => {
        optionalAssertion({
          argument: parseArgumentName(args, name),
          message: `@${name} ${messageBase} or empty. Received ${args[name]}`,
          type: compareType,
        });
      });

      list.required?.forEach(name => {
        requiredAssertion({
          argument: parseArgumentName(args, name),
          message: `@${name} ${messageBase}. Received ${args[name]}`,
          type: compareType,
        });
      });
    }
  });
}

export {
  optionalAction,
  optionalBoolean,
  optionalConfigOption,
  optionalNumber,
  optionalString,
  requiredAction,
  requiredBoolean,
  requiredConfigOption,
  requiredNumber,
  requiredString,
  selectConfigOption,
  validateArgumentList,
};
