import { belongsTo } from '@ember-data/model';
import Super from './document-request';
import classic from 'ember-classic-decorator';

@classic
export default class DocumentRequestCreditCardInfo extends Super {
  documentIcon = 'credit-card';
  mixpanelType = 'Credit card form';

  @belongsTo('card', { async: false }) card;
}
