import { service } from '@ember/service';
import Super from './-resource';
import classic from 'ember-classic-decorator';

@classic
export default class SiteAppointmentsRequested extends Super {
  @service prospectiveClient;

  async beforeModel() {
    let { prospectiveClient } = this;

    if (prospectiveClient.useProspectiveClientFlow) {
      this.router.transitionTo(prospectiveClient.recommendedRoute);
    }
  }

  model() {
    return this._model({ hasPendingConfirmation: true });
  }
}
