import { get } from '@ember/object';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ClientSelectionRoute extends Route {
  @service session;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'home');
  }

  model() {
    return get(this, 'session.currentClientOptions');
  }
}
