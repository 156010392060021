import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class ClientAccess extends ApplicationAdapter {
  acceptEula() {
    let baseUrl = this.buildURL('client-access').replace('client-accesses', 'client_accesses');

    return this.ajax(`${baseUrl}/accept-eula`, 'PATCH', {});
  }

  acceptLegalAgreements(data) {
    let baseUrl = this.buildURL('client-access');

    return this.ajax(`${baseUrl}/accept-legal-agreements`, 'POST', { data });
  }
}
