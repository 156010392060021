export function asyncScriptLoader(src) {
  return new Promise((resolve, reject) => {
    let scripts = document.getElementsByTagName('script');

    for (let loadedScript of scripts) {
      if (loadedScript.src === src) {
        resolve(loadedScript);
        return;
      }
    }

    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;

    let el = scripts[0];
    el.parentNode.insertBefore(script, el);
    script.addEventListener('load', () => {
      resolve(script);
    });

    script.addEventListener('error', () => {
      reject(new Error(`"${src}" failed to load.`));
    });
  });
}
