import { STEP_IDS } from 'client-portal/services/widget-steps';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { trackAppointmentStep } from 'client-portal/utils/event-tracking';
import PersistAppointmentMixin from '../-mixins/persist-appointment';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class RequestInformationReviewRoute extends Route.extend(PersistAppointmentMixin) {
  @service analytics;
  @service session;
  @service mixpanel;
  @service request;
  @service currentPractice;

  templateName = '-request/information/review';

  beforeModel() {
    let appointment = this.getAppointment();
    let reservation = this.modelFor(this.request.baseRouteName);
    let isClientInvalid = appointment.client?.validations?.isInvalid;

    let isCardInvalid;

    if (this.session.canUsePaymentMethods) {
      isCardInvalid = reservation.isCreditCardRequired && !appointment.card?.paymentMethodId;
    } else {
      isCardInvalid = reservation.isCreditCardRequired && appointment.card?.validations?.isInvalid;
    }

    if (isClientInvalid || isCardInvalid) {
      return this.request.transitionTo(this, 'information');
    }
  }

  model() {
    return this.modelFor(this.request.baseRouteName);
  }

  @action
  didTransition() {
    let reservation = this.modelFor(this.request.baseRouteName);
    trackAppointmentStep({
      analytics: this.analytics,
      session: this.session,
      mixpanel: this.mixpanel,
      request: this.request,
      pageId: STEP_IDS.REVIEW_AND_CONFIRM,
      reservation,
    });

    return true;
  }
}
