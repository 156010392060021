import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';

export default class StickyFooterService extends Service {
  @tracked liveFooters = [];

  register(component) {
    if (!this.liveFooters.includes(component)) {
      this.liveFooters = [...this.liveFooters, component];
    }
  }

  unregister(footerComponent) {
    this.liveFooters = this.liveFooters.filter(liveComponent => liveComponent !== footerComponent);
  }

  isFooterLive(id) {
    return !!this.liveFooters.find(footerComponent => footerComponent.destinationId === id);
  }
}
