import { action, set } from '@ember/object';
import { service } from '@ember/service';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class SignInPinVerifyRoute extends Route.extend(FastbootHelpersMixin) {
  @service router;
  @service store;
  @service session;
  @service signIn;

  queryParams = {
    refresh: { refreshModel: true },
  };

  beforeModel() {
    if (this.fastboot.isFastBoot) {
      return;
    }
    if (this.isVerified) {
      set(this, 'isVerified', false);
      return this.router.transitionTo('site.home');
    }
  }

  model() {
    if (this.fastboot.isFastBoot) {
      return;
    }

    return this.store.createRecord('session', {
      id: Math.random(), // client-side only
      email: this.signIn.email,
      type: 'pin',
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('refresh', null);
    this.signIn.resetErrors();
  }

  @action
  async verify() {
    let success = await this.signIn.verifyPinTask.perform(this.currentModel);
    this.signIn.trackAttempt();
    if (success) {
      set(this, 'isVerified', true);
      this.send('refreshEnvironment');
    }
  }
}
