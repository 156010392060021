import { underscore } from '@ember/string';
import ENV from 'client-portal/config/environment';
import JSONSerializer from '@ember-data/serializer/json';
import classic from 'ember-classic-decorator';

@classic
export default class Fragment extends JSONSerializer {
  keyForAttribute(raw) {
    if (ENV.environment === 'test') {
      return raw;
    }

    return underscore(raw);
  }
}
