import { service } from '@ember/service';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class SiteBillingResourcesController extends Controller {
  @service fastboot;
  @service currentPractice;

  get hasOonClaimFiling() {
    return this.currentPractice.get('hasOonClaimFiling') || this.model.hasOonClaims;
  }
}
