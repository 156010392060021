import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class SharedAddressAutocomplete extends Component {
  onAddressSelect = null;

  @service store;
  @tracked query = null;
  // eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked searchValue = this.args.selectedAddress;

  syncSearchValue = modifier(
    () => {
      this.searchValue = this.args.selectedAddress;
    },
    { eager: false }
  );

  @action
  async handleSelect({ text }) {
    let places = await this.args.addressSearchPlaces(text);
    this.args.onAddressSelect?.(places[0], text);
    if (this.args.useAsInputField) {
      this.searchValue = places[0].street;
    }
  }

  @action
  handleFocusLeave(_evt) {
    if (this.args.useAsInputField && this.searchValue) {
      this.args.onStreetSelect?.(this.searchValue);
    }
  }

  @action
  handleFocusIn(_evt) {
    this.sb.open();
  }

  @action
  handleClick({ target }) {
    if (this.args.disabled) return;

    let isSelectBoxOption = target.classList.contains('select-box__option');
    if (!isSelectBoxOption) {
      this.sb.open();
    }
  }

  @action
  handleReady(sb) {
    this.sb = sb;
  }

  @action
  handleSearch(query) {
    this.searchValue = query;
  }

  @action
  handleSearched(_result, query, _sb) {
    this.query = query;
    if (query) {
      this.args.onSearched?.(query);
    }
  }
}
