import { EPHEMERAL_ATTRS } from 'client-portal/models/card';
import { callbackError } from 'client-portal/utils/error-handling';
import { createElement, error } from 'client-portal/utils/banners';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Mixin from '@ember/object/mixin';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  iframe: service(),
  pendingAppointment: service(),
  currentPractice: service(),
  analytics: service(),
  store: service(),
  session: service(),

  getAppointment() {
    return this.getInformationModel().appointment;
  },

  getInformationModel() {
    let { pendingAppointment } = this;
    if (!pendingAppointment.appointment) {
      let reservation = this.modelFor(this.request.baseRouteName);
      let clientParams = this.paramsFor('-request');
      pendingAppointment.updatePendingAppointmentData({ reservation, clientParams });
    }
    return pendingAppointment.informationModel;
  },

  errorContent(message) {
    let title, content;

    if (message.title.indexOf('Email is already in use') >= 0) {
      title = 'Email in use';
      content = 'Email is already in use. If this is your email,';

      let phoneNumber = this.currentPractice.get('defaultPhone.number');

      if (phoneNumber) {
        content = `${content} contact us at ${phoneNumber}, or`;
      }

      content = `${content} <a href="${this.request.router.urlFor(
        'sign-in'
      )}" target="_blank" rel="noopener">sign in to the Client Portal</a> to request a new appointment.`;
    } else if (message.title === 'duplicate-couple-email') {
      title = 'Duplicate Email';
      content =
        'Duplicate email address found. To request this appointment, please enter a unique email address for you and your partner. If you and your partner share an email, leave their email blank.';
    } else if (message.title === 'duplicate-minor-email') {
      title = 'Duplicate Email';
      content =
        'Duplicate email address found. To request this appointment, please enter a unique email address for you and the client. If the client does not have their own email or you share one, please leave theirs blank.';
    } else {
      title = 'We were unable to request this appointment.';
      content = message.title;
    }

    content = createElement('p', { innerHTML: content });

    return { title, content };
  },

  validateAndPersistAppointmentTask: task(function* (stripeElements) {
    try {
      yield this.validateFieldsTask.perform(stripeElements);
      yield this.persistAppointmentTask.perform();
    } catch (err) {
      if (err) console.error(err); // eslint-disable-line no-console
      // show validation messages
      // persistAppointmentTask handles its own errors
    }
  }),

  validateFieldsTask: task(function* (stripeElements) {
    let { card, client } = this.getAppointment();
    let isCardInvalid = false;
    let isClientInvalid = false;

    // show validation error messages for invalid inputs
    client.set('_isDirty', true);

    if (card) {
      isCardInvalid = yield this._handleCreditCardCreation(card, client, stripeElements);
    }

    if (client.isNew) {
      yield client.validate();
      isClientInvalid = client.validations.isInvalid;
    }

    if (isCardInvalid || isClientInvalid) {
      yield Promise.reject();
    }
  }),

  async _validateAndCreateCardPaymentMethod(card, stripeElements) {
    card.set('_isDirty', true);
    await card.validate();
    let { error } = await stripeElements.submit();

    if (error || !card.name) {
      return true;
    }

    return !(await this.stripeCards.addPaymentMethodTask.perform({
      elements: stripeElements,
      newCard: card,
      persistCard: false,
    }));
  },

  createCardTokenTask: task(function* (card) {
    yield card.validate();
    yield card.createToken();
  }),

  async _sendWidgetInsuranceCardFile(clientId, file) {
    let { store } = this;
    return !(await store
      .createRecord('unauthenticated-insurance-card', {
        file,
        clientId,
      })
      .save());
  },

  persistAppointmentTask: task(function* () {
    let appointment = this.getAppointment();

    try {
      let { card, client } = appointment;
      let { insuranceCardFrontSideFile, insuranceCardBackSideFile } = client;

      let opts = {};
      let { isNew: isNewClient } = client;

      if (isNewClient) {
        opts = {
          adapterOptions: {
            query: { include: 'client,card,office' },
            embed: ['client', 'card', 'clientRelationships', 'relatedClient', 'emails', 'phones'],
          },
        };
      }

      yield appointment.save(opts);

      let clientId = appointment.client.id;
      let relationship = client.clientRelationships.slice().pop();
      appointment.client.isMinor = client.isMinor;
      appointment.relatedClient = relationship?.relatedClient;
      if (isNewClient) {
        this.session.newProspectiveClient = appointment.client;
      }

      let files = [insuranceCardFrontSideFile, insuranceCardBackSideFile];
      for (let file of files) {
        if (file) {
          yield this._sendWidgetInsuranceCardFile(clientId, file);
        }
      }

      this.session.set('data.skipLicenseAgreement', isNewClient);
      this.session.set('data.skipLegalAgreements', isNewClient);

      // Re-attach data to a new card in order for validations to not
      // display invalid while we're transitioning
      if (card) {
        EPHEMERAL_ATTRS.forEach(x => appointment.card.set(x, card[x]));
      }

      let reservation = this.modelFor(this.request.baseRouteName);
      reservation.set('appointment', appointment);

      let {
        cptCode: { id, description, duration, rate },
        office: { isVideo },
        clinician: { id: clinicianId },
        message,
        reasonsForVisit,
        mentalHealthHistory,
        careTypes,
      } = reservation;
      let clientMessageSent = !!message;
      let { id: appointmentId, insurancePaymentMethod } = appointment;
      let hasReasonsForVisit = !!reasonsForVisit?.length;
      let hasMentalHealthHistory = !!mentalHealthHistory?.length;
      let hasPaymentMethod = ['Insurance', 'Self-pay'].includes(insurancePaymentMethod);
      let isPaymentInsurance = insurancePaymentMethod === 'Insurance';
      let {
        featureStickyCtaBookingWidget,
        featureBookingWidgetMedicationQuestion,
        featureExpandPrescreenerQuestions,
        featureWidgetPaymentMethod,
        featureWidgetPaymentMethodDesktop,
        featureWidgetPaymentMethodGaRollout,
      } = this.currentPractice.getProperties(
        'featureStickyCtaBookingWidget',
        'featureBookingWidgetMedicationQuestion',
        'featureExpandPrescreenerQuestions',
        'featureWidgetPaymentMethod',
        'featureWidgetPaymentMethodDesktop',
        'featureWidgetPaymentMethodGaRollout'
      );
      let hasCareTypes = !!careTypes?.length;
      let appointmentProperties = {
        'service_code': id,
        'service_duration': duration,
        'service_name': description,
        'service_price': rate,
        'source': this.request.sourceType,
        'client_message_sent': clientMessageSent,
        'is_telehealth': isVideo,
        'client_type': this.request.findClientType(this.session.currentClient),
        'clinician_id': clinicianId,
        'reason_for_visit_message': clientMessageSent,
        'feature_booking_widget_reordering_steps': this.session.reorderedNav,
        'feature_static_reason_for_visit': this.session.addReasonsForVisitFlag,
        'feature_sticky_cta_booking_widget': featureStickyCtaBookingWidget,
        'reason_for_visit_checkbox': hasReasonsForVisit,
        'feature_booking_widget_medication_question': featureBookingWidgetMedicationQuestion,
        'feature_expand_prescreener_questions': featureExpandPrescreenerQuestions,
        'feature_widget_payment_method': featureWidgetPaymentMethod,
        'feature_widget_payment_method_desktop': featureWidgetPaymentMethodDesktop,
        'feature_widget_payment_method_GA_rollout': featureWidgetPaymentMethodGaRollout,
        'risk_assessment': hasMentalHealthHistory,
        'medication_question': hasCareTypes,
        'payment_question': hasPaymentMethod,
      };
      if (insurancePaymentMethod === 'Insurance') {
        appointmentProperties = {
          ...appointmentProperties,
          ...(isPaymentInsurance && { 'insurance_front_upload': !!insuranceCardFrontSideFile }),
          ...(isPaymentInsurance && { 'insurance_back_upload': !!insuranceCardBackSideFile }),
        };
      }

      let analyticsEventName = 'Appointment Request Completed';
      let analyticsProperties = {
        object: 'Appointment',
        action: 'Completed',
        'object_id': appointmentId,
        ...appointmentProperties,
      };

      isNewClient
        ? this.analytics.trackAnonymously(analyticsEventName, analyticsProperties)
        : this.analytics.track(analyticsEventName, analyticsProperties);

      this.mixpanel.track(
        `user: ${this.request.trackingPrefix} Requested Appointment`,
        {
          'feature_booking_widget_reordering_steps': this.session.reorderedNav,
          'feature_static_reason_for_visit': this.session.addReasonsForVisitFlag,
          'feature_sticky_cta_booking_widget': featureStickyCtaBookingWidget,
          'feature_booking_widget_medication_question': featureBookingWidgetMedicationQuestion,
          'feature_expand_prescreener_questions': featureExpandPrescreenerQuestions,
          'feature_widget_payment_method': featureWidgetPaymentMethod,
          'feature_widget_payment_method_desktop': featureWidgetPaymentMethodDesktop,
          'feature_widget_payment_method_GA_rollout': featureWidgetPaymentMethodGaRollout,
          'risk_assessment': hasMentalHealthHistory,
          'medication_question': hasCareTypes,
          'payment_question': hasPaymentMethod,
          ...(isPaymentInsurance && { 'insurance_front_upload': !!insuranceCardFrontSideFile }),
          ...(isPaymentInsurance && { 'insurance_back_upload': !!insuranceCardBackSideFile }),
        },
        {
          'send_immediately': true,
        }
      );
      this.mixpanel.trackWidgetEvents('completed', {
        ...appointmentProperties,
        appointmentId,
      });
      this.iframe.appointmentRequested({
        clientMessageSent,
        appointmentId,
        reasonForVisitMessage: clientMessageSent,
        hasReasonsForVisit,
        hasMentalHealthHistory,
        hasCareTypes,
        hasPaymentMethod,
        featureWidgetPaymentMethod,
        isPaymentInsurance,
        insuranceFrontUpload: !!insuranceCardFrontSideFile,
        insuranceBackUpload: !!insuranceCardBackSideFile,
      });
      this.request.transitionTo(this, 'confirmation');
      this.send('refreshEnvironment');
    } catch (err) {
      let showErrorMessage =
        !appointment.card ||
        appointment.card.validations.isValid ||
        (this.session.canUsePaymentMethods && appointment.card.paymentMethodId);

      if (!appointment.client.isNew) {
        this.request.transitionTo(this, 'date');
      }

      if (showErrorMessage) {
        callbackError(err, message => {
          error(this.errorContent(message));
          return true;
        });
      }
    }
  })
    .keepLatest()
    .maxConcurrency(1),

  setupController(controller) {
    this._super(...arguments);
    controller.setProperties({
      validateFieldsTask: this.validateFieldsTask,
      persistAppointmentTask: this.persistAppointmentTask,
      validateAndPersistAppointmentTask: this.validateAndPersistAppointmentTask,
    });
  },

  async _handleCreditCardCreation(card, client, stripeElements) {
    if (this.session.canUsePaymentMethods) {
      if (!card.paymentMethodId) {
        return await this._validateAndCreateCardPaymentMethod(card, stripeElements, client);
      }
    } else {
      if (!card.customStripeCardId) {
        card.set('_isDirty', true);
        await card.validate();
        await this.createCardTokenTask.perform(card);
      }

      return card.validations.isInvalid;
    }
  },
});
