import {
  isAndroid,
  isChrome,
  isIosChrome,
  isIphone,
  isMobileApp,
  isSafari,
  isSamsung,
} from 'client-portal/utils/mobile-detect';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

const ANDROID_FIREBASE_DEEP_LINK = 'https://clientportalandroid.page.link/client-app';
const IOS_FIREBASE_DEEP_LINK = 'https://simplepracticeclient.page.link/client';

const CUSTOM_URL_SCHEME = 'simplepracticeclients:client-app';

export default class ClientAppBannerService extends Service {
  @service analytics;
  @service mixpanel;
  @service session;
  @service currentPractice;

  @tracked showBanner = false;
  @tracked clientAppBannerDismissed = false;
  @tracked location = null;
  @tracked documentId = null;

  get hasIosFeature() {
    return this.currentPractice.get('featureIosClientappPromotionBanner');
  }

  get hasAndroidFeature() {
    return this.currentPractice.get('featureAndroidClientappPromotionBanner');
  }

  get clientAccess() {
    return this.session.currentClientAccess;
  }

  get currentSignedInClient() {
    return this.clientAccess?.client;
  }

  get currentClient() {
    return this.session.currentClient;
  }

  get canDisplay() {
    return this.isIphoneSafariOrChrome || this.isAndroidChromeOrSamsung;
  }

  get isIphoneSafariOrChrome() {
    return this.hasIosFeature && isIphone() && (isSafari() || isIosChrome());
  }

  get isAndroidChromeOrSamsung() {
    return this.hasAndroidFeature && isAndroid() && (isChrome() || isSamsung());
  }

  resetDismiss() {
    this.clientAppBannerDismissed = false;
  }

  display({ location, documentId }) {
    let { session, clientAppBannerDismissed, canDisplay } = this;
    let isDocumentsRedirect =
      session.isDocumentsRouteRedirect || session.get('data.redirect')?.includes('documents');
    let isRedirectedFromOldAppVersions = isDocumentsRedirect && !isMobileApp();

    if (canDisplay && !clientAppBannerDismissed && !isRedirectedFromOldAppVersions) {
      this.showBanner = true;
      this.location = location;
      this.documentId = documentId;
    }
  }

  hide() {
    this.clientAppBannerDismissed = true;
    this.showBanner = false;
  }

  trackDismissEvent() {
    let { location, currentSignedInClient, mixpanel, analytics, isAndroidChromeOrSamsung } = this;

    let analyticsEventName = 'Client App Banner Dismissed';
    let eventProperties = {
      'click_location': location || '',
    };

    if (isAndroidChromeOrSamsung) {
      eventProperties = {
        ...eventProperties,
        'client_portal_client_access_id': currentSignedInClient?.id || '',
      };
    }

    let analyticsProperties = {
      object: 'Client App Banner',
      action: 'Dismissed',
      ...eventProperties,
    };

    mixpanel.track('user: dismissed client app banner', eventProperties);

    currentSignedInClient
      ? analytics.track(analyticsEventName, analyticsProperties)
      : analytics.trackAnonymously(analyticsEventName, analyticsProperties);
  }

  trackAccessAppEvent() {
    let { location, currentSignedInClient, mixpanel, analytics } = this;

    let analyticsEventName = 'App Accessed from Client App Banner';
    let eventProperties = {
      'client_portal_client_access_id': currentSignedInClient?.id || '',
      'click_location': location || '',
    };
    let analyticsProperties = {
      object: 'App',
      action: 'Accessed',
      ...eventProperties,
    };

    mixpanel.track('user: accessed app from client app banner', eventProperties);

    currentSignedInClient
      ? analytics.track(analyticsEventName, analyticsProperties)
      : analytics.trackAnonymously(analyticsEventName, analyticsProperties);
  }

  visitAppStore() {
    let {
      isAndroidChromeOrSamsung,
      isIphoneSafariOrChrome,
      currentSignedInClient,
      currentClient,
      location,
      documentId,
    } = this;

    let queryString = `?page=${location}`;

    this.trackAccessAppEvent();

    if (currentSignedInClient) {
      let { hashedId: clientHashedId } = currentSignedInClient;
      let { hashedId: profileId } = currentClient;
      queryString += `&client_id=${clientHashedId}&profile_id=${profileId}`;
    }

    if (documentId) {
      queryString += `&document_id=${documentId}`;
    }

    this.showBanner = false;

    let deepLinkHost = isAndroidChromeOrSamsung
      ? ANDROID_FIREBASE_DEEP_LINK
      : IOS_FIREBASE_DEEP_LINK;
    let firebaseDeepLink = `${deepLinkHost}${queryString}`;

    if (isIphoneSafariOrChrome) {
      this.#openLinkLocally(`${CUSTOM_URL_SCHEME}${queryString}`);
      this.#openLinkLocally(firebaseDeepLink);
    } else if (isChrome() || isSamsung()) {
      this.#openLinkLocally(firebaseDeepLink);
    } else {
      window.location.href = firebaseDeepLink;
    }
  }

  #openLinkLocally(deepLink) {
    window.open(deepLink, '_system');
  }
}
