import { action, setProperties } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SharedQuestionnaireShortAnswerInput extends Component {
  @action
  handleSetValue(value) {
    let isDirty = !this.isModelNew || Boolean(value);
    setProperties(this.answer, { isDirty, freeTextAnswer: value });
  }
}
