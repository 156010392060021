/* import __COLOCATED_TEMPLATE__ from './color-filter-button.hbs'; */
import { COLOR_FILTER_COLORS } from 'client-portal/components/practice-website/edit-menu/design';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { equal, reads } from '@ember/object/computed';
import { htmlSafe } from '@ember/template';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './color-filter-button.module.scss';

@classNames(styles.component)
@classic
export default class PracticeWebsiteEditMenuColorFilterButton extends Component {
  @service media;
  colorFilterColors = COLOR_FILTER_COLORS;

  @reads('model.filter') filter;
  @equal('model.buttonType', 'text') isTextButton;
  @equal('model.buttonType', 'dot') isDotButton;

  @computed('filter')
  get backgroundColorStyle() {
    return htmlSafe(`background-color: ${this.colorFilterColors[this.filter]}`);
  }
}
