export function resetErrors(record) {
  record.errors.clear();
  record.currentState.cleanErrorRequests();
}

export function applyValidationError(record) {
  if (record.validations.isValid) {
    return;
  }

  record.errors.add('base', record.validations.errors[0].message);
  record.currentState.notify('isValid');
}

export function formatValidationErrorsForMixpanel(errors) {
  return errors.map(error => {
    return { field: error.attribute, message: error.message };
  });
}

export function scrollToValidationError() {
  document
    .querySelector('.input.invalid')
    ?.parentElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
}
