// Glue code for ember-infinity fastboot shoebox support
// This just adds `peekAllPromise` method to the store which behaves exactly as `peekAll`
// but returns a promise so we can use it along with ember-infinity's `storeFindMethod`

export function initialize(application) {
  let store = application.lookup('service:store');

  store.peekAllPromise = modelName => {
    return new Promise(resolve => resolve(store.peekAll(modelName)));
  };
}

export default {
  name: 'patch-store',
  initialize,
};
