import { service } from '@ember/service';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class SiteBillingPaymentMethodsRoute extends Route {
  @service router;
  @service currentPractice;
  beforeModel() {
    super.beforeModel(...arguments);

    if (!this.currentPractice.get('hasCustomStripeAccount')) {
      this.router.transitionTo('site.billing');
    }
  }
}
