/* import __COLOCATED_TEMPLATE__ from './clinician-select.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './clinician-select.module.scss';

@classic
@classNames('clinician-select text-left justify-content-between', styles.component)
@classNameBindings('clinicianId', 'no-signin-box')
export default class RequestClinicianSelect extends Component {
  @service session;

  @notEmpty('session.currentClient') noSigninBox;
  @notEmpty('model.marketingSpecialties') hasSpecialties;

  @computed('model.id')
  get clinicianId() {
    let id = this.get('model.id');
    return `clinician-${id}`;
  }
}
