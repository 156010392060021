import { singularize } from 'ember-inflector';
import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class Environment extends ApplicationAdapter {
  urlForQueryRecord(_query, modelName) {
    return `${this.buildURL()}/${singularize(modelName)}`;
  }
}
