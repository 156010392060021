import Service from '@ember/service';
import moment from 'moment-timezone';

const SESSION_TIMEZONE = 'session_timezone';

export default class ClientTimezoneService extends Service {
  #timezone;
  #practiceTimezone;

  set #sessionTimezone(value) {
    try {
      sessionStorage.setItem(SESSION_TIMEZONE, value);
    } catch (e) {
      // no-op - will revert to local guess
    }
  }

  get #sessionTimezone() {
    let sessionTimezone;
    try {
      sessionTimezone = sessionStorage.getItem(SESSION_TIMEZONE);
    } catch (e) {
      // no-op - will revert to local guess
    }
    return sessionTimezone;
  }

  get #guessedTimezone() {
    let guess;
    try {
      guess = moment.tz.guess();
    } catch (e) {
      // ignoring
    }
    return guess;
  }

  get timezone() {
    if (!this.#timezone) {
      this.updateDefault();
    }
    return this.#timezone;
  }

  set practiceTimezone(timezone) {
    this.#practiceTimezone = timezone;
  }

  updateDefault(defaultTimezone = null) {
    let newDefault =
      defaultTimezone || this.#sessionTimezone || this.#guessedTimezone || this.#practiceTimezone;
    if (newDefault && newDefault !== this.#timezone && moment.tz.names().includes(newDefault)) {
      this.#timezone = newDefault;
      this.#sessionTimezone = newDefault;
      moment.tz.setDefault(newDefault);
    }
  }

  reset() {
    try {
      this.#timezone = null;
      sessionStorage.removeItem(SESSION_TIMEZONE);
    } catch (e) {
      // no-op - will revert to local guess
    }
  }
}
