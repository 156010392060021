export default function getDuration(speed) {
  switch (speed) {
    case 'slow':
      return 400;
    case 'medium':
      return 200;
    case 'fast':
      return 125;
    default:
      return 250;
  }
}
