/* import __COLOCATED_TEMPLATE__ from './insurance-section.hbs'; */
import { classNames } from '@ember-decorators/component';
import { dateFormat } from 'client-portal/utils/date-time';
import { gt, reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import ENV from 'client-portal/config/environment';
import classic from 'ember-classic-decorator';
import styles from './insurance-section.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsContactInfoInsuranceSection extends Component {
  @service currentPractice;
  @service store;

  dateFormat = dateFormat;

  @reads('currentClient.channelCoveredSessionsCountRemaining') channelCoveredSessionsCountRemaining;
  @reads('currentClient.globalMonarchChannel') globalMonarchChannel;
  @reads('globalMonarchChannel.displayName') channelName;
  @reads('currentPractice.featureClientPortalInsuranceEffectiveDates')
  featureInsuranceEffectiveDates;
  @reads('currentPractice.featureInsuranceTextRecognition') featureInsuranceTextRecognition;
  @gt('channelCoveredSessionsCountRemaining', 0) channelCovered;

  @(task(function* (search) {
    return this.store.query('insurance-provider-name', {
      filter: {
        search,
      },
      page: {
        size: ENV.maxPageSize,
      },
    });
  }).restartable())
  searchInsuranceProviderNamesTask;
}
