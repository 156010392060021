import { buildValidations, validator } from 'ember-cp-validations';
import { computed } from '@ember/object';
import { A as emberA } from '@ember/array';
import { formatZipcode } from 'ember-simplepractice/helpers/format-zipcode';
import { htmlSafe } from '@ember/template';
import { not, or } from '@ember/object/computed';
import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';

const markerKeys = ['street', 'city', 'state', 'zip'];

const Validations = buildValidations({
  street: [
    validator('presence', {
      presence: true,
    }),
  ],
  state: [
    validator('presence', {
      presence: true,
    }),
  ],
  city: [
    validator('presence', {
      presence: true,
    }),
  ],
  zip: [
    validator('presence', {
      presence: true,
    }),
  ],
});

@classic
export default class OfficeModel extends Model.extend(Validations) {
  @belongsTo('polymorphic/addressable', { polymorphic: true, async: false }) addressable;

  @attr() city;
  @attr() name;
  @attr() state;
  @attr() street;
  @attr() zip;
  @attr() phone;
  @attr('boolean') isVideo;
  @attr() geolocation;
  @attr('boolean') isPublic;
  @attr() insurancePlaceOfService;

  @not('isPublic') notPublic;

  @computed('geolocation')
  get coordinates() {
    if (this.geolocation) {
      return [this.geolocation.lat, this.geolocation.lng].join(',');
    } else {
      return undefined;
    }
  }

  @computed(...markerKeys)
  get geocodingAddress() {
    let markerProps = this.getProperties(markerKeys);
    markerProps.zip = this.formattedZip;
    return Object.keys(markerProps)
      .map(x => markerProps[x])
      .join(' ');
  }

  @or('coordinates', 'geocodingAddress') mapMarkerLocation;

  @computed('zip')
  get formattedZip() {
    return formatZipcode([this.zip]);
  }

  @computed('distance')
  get formattedDistance() {
    return Math.round(this.distance * 10) / 10;
  }

  @computed('phone')
  get callToBookMessage() {
    let msg = this.phone ? `call ${this.phone}` : `contact the practice directly`;
    return htmlSafe(`<span>To request this appointment,</span><br><span>${msg}</span>`);
  }

  static fieldsFor(_context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    fields.removeObject('client');
    return fields;
  }
}
