/* import __COLOCATED_TEMPLATE__ from './jump-link.hbs'; */
import { action, computed } from '@ember/object';
import { service } from '@ember/service';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@tagName('')
@classic
export default class PracticeWebsiteJumpLink extends Component {
  scrollOnLoadDelay = 700;

  @service practiceWebsiteNav;

  @computed('target', 'practiceWebsiteNav.activeLink')
  get isActive() {
    return this.target === this.practiceWebsiteNav.activeLink;
  }

  @action
  onDidInsert() {
    if (window.location.hash === `#${this.target}`) {
      let targetEl = document.querySelector(`[data-anchor-name=${this.target}]`);
      this.practiceWebsiteNav.setActiveLink(this.target);
      this.practiceWebsiteNav.scrollOnLoadTask.perform(targetEl, this.offset);
    }
  }

  @action
  onLinkClick(evt) {
    evt?.preventDefault();
    this.onClick?.();
    this.scrollTo();
  }

  @action
  scrollTo() {
    history.replaceState(null, null, `#${this.target}`);
    this.practiceWebsiteNav.setActiveLink(this.target);
    let el = document.querySelector(`[data-anchor-name=${this.target}]`);
    this.practiceWebsiteNav.scrollToElementTask.perform(el);
  }
}
