import Service from '@ember/service';
import classic from 'ember-classic-decorator';

const DEGLEN = 110.25;
const METRES_IN_MILE = 0.621;
const geoOptions = {
  maximumAge: 5 * 60 * 1000,
  timeout: 10 * 1000,
};

@classic
export default class GeolocationService extends Service {
  getCurrentPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, geoOptions);
    });
  }

  findDistanceTo(officeCoords, { coords }) {
    let { lat, lng } = officeCoords;
    let lat0 = coords.latitude;
    let lng0 = coords.longitude;

    let x = lat - lat0;
    let y = (lng - lng0) * Math.cos(lat0);
    return DEGLEN * Math.sqrt(x * x + y * y) * METRES_IN_MILE;
  }
}
