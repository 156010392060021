/* import __COLOCATED_TEMPLATE__ from './time-filters.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './time-filters.module.scss';

@classic
@classNameBindings('allDisabled:hidden')
@classNames(styles.component)
export default class RequestDateTimeFilters extends Component {
  @computed('filters.{morning,afternoon,evening}.disabled')
  get allDisabled() {
    return Object.keys(this.filters).every(x => this.get(`filters.${x}.disabled`));
  }

  @computed('filters.{morning,afternoon,evening}.checked')
  get allUnchecked() {
    return Object.keys(this.filters).every(x => !this.get(`filters.${x}.checked`));
  }

  @computed('filters.{morning,afternoon,evening}.checked')
  get availableFiltersTitle() {
    let titles = [];
    if (this.get('filters.morning.checked')) {
      titles.push('Mornings');
    }
    if (this.get('filters.afternoon.checked')) {
      titles.push('Afternoons');
    }
    if (this.get('filters.evening.checked')) {
      titles.push('Evenings');
    }
    if (titles.length === 3) {
      return `${titles[0]}, ${titles[1]} & ${titles[2]}`;
    }
    return titles.join(' & ');
  }
}
