import '@ember/object';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { observes } from '@ember-decorators/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import BillingItemMixin from 'client-portal/mixins/polymorphic/billing-item';
import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';

@classic
export default class PaymentModel extends Model.extend(BillingItemMixin) {
  @service stripe;

  @belongsTo({ async: false }) invoice;
  @belongsTo({ async: false }) card;
  @attr() displayName;
  @attr('moment') paidAt;
  @attr() amount;

  // Virtual
  @attr() idempotencyKey;
  @attr() customCardToken;
  @attr() radarSession;
  @reads('paidAt') activityDate;
  @reads('amount') activityPayments;
  activityCharges = null;

  // eslint-disable-next-line ember/no-observers
  @observes('card', 'customCardToken')
  cardChanged() {
    if (this.isDeleted) {
      return;
    }
    this.set('idempotencyKey', generateUUID());
  }

  createRadarSession() {
    return new Promise((resolve, _) => {
      if (isEmberTesting()) {
        return resolve(this);
      }

      // eslint-disable-next-line promise/prefer-await-to-then
      return this.stripe.createRadarSession().then(result => {
        if (result.error) {
          resolve(this);
          return;
        }

        this.set('radarSession', result.radarSession.id);
        resolve(this);
      });
    });
  }
}
