import { service } from '@ember/service';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Mixin from '@ember/object/mixin';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create(FastbootHelpersMixin, {
  session: service(),
  router: service(),

  beforeModel() {
    this._super(...arguments);

    let environment = this.modelFor('application');
    let currentPractice = environment.get('currentPractice');
    if (!currentPractice?.clientPortalEnabled) {
      if (currentPractice?.websitePublished) {
        return this.router.transitionTo('home');
      } else {
        return this.router.transitionTo('not-found', 'errors/404');
      }
    }
    let currentClientAccess = environment.get('currentClientAccess');
    if (
      currentClientAccess &&
      !this.get('session.currentClient') &&
      !this.session._noInvalidationBanner
    ) {
      return this.router.transitionTo('client-selection');
    }
  },
});
