/* import __COLOCATED_TEMPLATE__ from './signature.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { equal, or } from '@ember/object/computed';
import { humanDateTimeFormat } from 'client-portal/utils/date-time';
import { relationshipName } from 'client-portal/utils/relationship-types';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import getCursiveClass from 'ember-simplepractice/utils/signatures';
import moment from 'moment-timezone';
import styles from './signature.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsSharedSignature extends Component {
  @equal('signature.status', 'pending') isPendingSignature;
  @or('signature.title', 'title') signatureTitle;
  @or('signature.name', 'name') signatureName;

  @computed('signature.credentials', 'clientRelationshipType')
  get signatureCredentials() {
    return this.signature?.credentials || relationshipName(this.clientRelationshipType);
  }

  @computed('signatureName')
  get cursiveClass() {
    return getCursiveClass(this.signatureName?.length);
  }

  @computed('signature.signedAt')
  get signedOrSigningAt() {
    let signatureSignedAt = this.signature?.signedAt;
    return (signatureSignedAt ? moment(signatureSignedAt) : moment()).format(humanDateTimeFormat);
  }
}
