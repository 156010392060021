import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

@classic
export default class PendingAppointmentService extends Service {
  @service store;
  @service session;
  @service request;
  @service widgetToken;

  #informationModel = null;

  get appointment() {
    return this.informationModel?.appointment;
  }

  get informationModel() {
    return this.#informationModel;
  }

  reset() {
    this.#informationModel = null;
  }

  updatePendingAppointmentData({ reservation, clientParams }) {
    this.#informationModel = this.createAppointmentData(reservation, clientParams);
  }

  createAppointmentData(reservation, clientParams) {
    let card;
    let { store } = this;
    let { currentClient: client, channel, mld } = this.session;
    let widgetTokenData = this.widgetToken.tokenData;

    if (!client) {
      client = this._createClient(clientParams, reservation);

      if (reservation.isCreditCardRequired) {
        card = store.createRecord('card');
      }
    }

    if (reservation.channel?.skipBirthDate) {
      client.disableBirthDatePresenceValidation();
    }

    return {
      appointment: store.createRecord('appointment', {
        // NOTE: Preventing empty card relationship ("") for existing client.
        // JSON:API spec only allows it to be null, but not empty string, which is produced by FormDataMixin,
        // that is required to send Monarch channel uploads (custom field)
        ...(card && { card }),
        client,
        channel,
        mld,
        visitReason: reservation.message,
        visitTherapyReasons:
          widgetTokenData?.reasonsForVisit?.split(',') || reservation.reasonsForVisit,
        schedulingSource: this.request.schedulingSource,
        mentalHealthHistory: reservation.mentalHealthHistory,
        careTypes: reservation.careTypes,
        insurancePaymentMethod:
          widgetTokenData?.insurancePaymentMethod || reservation.insurancePaymentMethod,
        insuranceMemberId: widgetTokenData?.memberId,
        insuranceGroupId: widgetTokenData?.groupId,
      }),
      channel: reservation.channel,
    };
  }

  _createClient(params, reservation) {
    let clientQueryFields = this._clientQueryFields(params);
    let otherClientQueryFields = this._otherClientQueryFields(params);
    let { clientType, channelFields, channelUploads: uploads } = reservation;
    let channelCustomFields = {
      ...(channelFields || []).reduce((acc, f) => {
        acc[f.name] = f.value;
        return acc;
      }, {}),
      ...(uploads ? { uploads } : {}),
    };
    let client;

    let widgetTokenData = this.widgetToken.tokenData;
    if (widgetTokenData) {
      let { firstName, lastName, email, phoneNumber, birthDate } = widgetTokenData;

      clientQueryFields = {
        ...clientQueryFields,
        firstName,
        lastName,
        birthDate: moment(birthDate),
        email,
        phoneNumber,
      };
    }

    switch (clientType) {
      case 'couple':
        client = this._createCouple(channelCustomFields, clientQueryFields, otherClientQueryFields);
        break;
      case 'contact':
        client = this._createContact(
          channelCustomFields,
          clientQueryFields,
          otherClientQueryFields
        );
        break;
      default:
        client = this._createIndividualClient(channelCustomFields, clientQueryFields);
    }

    return client;
  }

  _createCouple(channelCustomFields, clientQueryFields, otherClientQueryFields) {
    let couple = this.store.createRecord('client-couple', {
      registrationSource: 'scheduling_widget',
      channelCustomFields,
    });

    couple.clientRelationships = [
      this.store.createRecord('client-relationship', {
        billingDelegation: true,
        relatedClient: this._createRelatedClient(false, clientQueryFields),
      }),
      this.store.createRecord('client-relationship', {
        relatedClient: this._createRelatedClient(true, otherClientQueryFields),
      }),
    ];

    return couple;
  }

  _createRelatedClient(skipValidation, otherClientQueryFields) {
    let { phoneNumber, email } = otherClientQueryFields;
    return this.store.createRecord('client-contact', {
      ...otherClientQueryFields,
      emails: [this.store.createRecord('email', { address: email, skipValidation })],
      phones: [this.store.createRecord('phone', { number: phoneNumber, skipValidation })],
    });
  }

  _createContact(channelCustomFields, clientQueryFields, otherClientQueryFields) {
    let client = this._createIndividualClient(channelCustomFields, clientQueryFields);
    client.set('isMinor', true);

    let clientRelationship = this.store.createRecord('client-relationship', {
      billingDelegation: true,
      relatedClient: this._createRelatedClient(false, otherClientQueryFields),
    });
    client.clientRelationships = [clientRelationship];

    return client;
  }

  _createIndividualClient(channelCustomFields, clientQueryFields) {
    return this.store.createRecord('client', {
      registrationSource: 'scheduling_widget',
      channelCustomFields,
      ...clientQueryFields,
    });
  }

  _clientQueryFields({ firstName, lastName, email, phoneNumber, birthDate }) {
    return { firstName, lastName, email, phoneNumber, birthDate: birthDate && moment(birthDate) };
  }

  _otherClientQueryFields({
    otherFirstName,
    otherLastName,
    otherEmail,
    otherPhoneNumber,
    otherBirthDate,
  }) {
    return {
      firstName: otherFirstName,
      lastName: otherLastName,
      email: otherEmail,
      phoneNumber: otherPhoneNumber,
      birthDate: otherBirthDate && moment(otherBirthDate),
    };
  }
}
