import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { insuranceProviders } from '@simplepractice/marketing-options';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

export const maxMarketingInsuranceCount = 15;
export const maxMarketingInsuranceLength = 40;
export const globalMarketingInsuranceProviders = insuranceProviders;

const maxLength = 40;

const Validations = buildValidations({
  name: [
    validator('length', {
      max: maxLength,
      message: `Can't be more than ${maxLength} characters.`,
    }),
  ],
});

const ExtendedFragment = Fragment.extend(Validations);

@classic
export default class PracticeWebsiteMarketingInsuranceProvider extends ExtendedFragment {
  @attr() name;
}
