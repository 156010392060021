/* import __COLOCATED_TEMPLATE__ from './new.hbs'; */
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './new.module.scss';

@classic
@classNames(styles.component)
export default class SignInPinStatesNew extends Component {
  @service signIn;
}
