import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@classNames('skeleton-loading')
@classNameBindings('isFadeIn:is-fading-in')
export default class SharedSkeletonContainerTransition extends Component {
  didInsertElement() {
    super.didInsertElement(...arguments);
    this.set('isFadeIn', true);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.set('isFadeIn', false);
    this.element.classList.add('is-fading-out');
  }
}
