import Service from '@ember/service';

export default class BodyScrollManagerService extends Service {
  bodyOverflow = document.body.style.getPropertyValue('overflow');

  lockScroll() {
    document.body.style.setProperty('overflow', 'hidden');
  }

  unlockScroll() {
    document.body.style.setProperty('overflow', this.bodyOverflow);
  }
}
