import { and, not } from '@ember/object/computed';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './header.module.scss';

@classic
@classNameBindings('noActions')
@classNames(styles.component)
export default class SharedFullFlyoutHeader extends Component {
  @not('noActions') hasActions;
  @not('noLeftClose') hasLeftClose;
  @and('hasActions', 'hasLeftClose') showLeftClose;
}
