import { attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import Super from './document-request';
import classic from 'ember-classic-decorator';

@classic
export default class DocumentRequestConsentDocument extends Super {
  documentIcon = 'file-text';
  mixpanelType = 'Consent';

  @attr() documentType;
  @attr() documentExt;
  @attr() documentMimeType;
  @attr() documentBody;
  @equal('documentType', 'text') isText;
}
