import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './stripe-security-code.module.scss';

@classic
@classNames(styles.component)
export default class SharedStripeSecurityCode extends Component {
  @computed('stripeCard.brandPreview')
  get displaySecurityHint() {
    return this.stripeCard.brandPreview && this.stripeCard.brandPreview !== 'unknown';
  }
}
