import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

@classic
export default class GoodFaithEstimateSubmissionData extends Fragment {
  @fragmentArray('good-faith-estimate/client') clients;
  @fragmentArray('good-faith-estimate/service') services;
  @fragment('good-faith-estimate/provider') provider;

  @attr() servicesStartDate;
  @attr() servicesEndDate;
  @attr() diagnosisCodes;
  @attr() notes;

  @computed('services.@each.total')
  get servicesTotal() {
    return this.services.reduce((acc, { total }) => {
      return (acc += total || 0);
    }, 0);
  }
}
