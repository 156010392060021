import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { computed } from '@ember/object';
import { formatMoney } from 'client-portal/helpers/format-money';
import { isPresent } from '@ember/utils';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

export const maxMarketingFeeNameLength = 200;
export const maxMarketingFeeCount = 15;
const Validations = buildValidations({
  name: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: computed('model.{minFee,maxFee}', function () {
        return !(isPresent(this.model.minFee) || isPresent(this.model.maxFee));
      }),
      message: 'Enter a fee explanation',
    }),
    validator('length', {
      max: maxMarketingFeeNameLength,
      message: `Can't be over ${maxMarketingFeeNameLength} characters`,
    }),
  ],
  minFee: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: computed('model.{name,maxFee}', function () {
        return !(this.model.name || isPresent(this.model.maxFee));
      }),
      message: 'Enter a fee',
    }),
  ],
  maxFee: [
    validator('inline', {
      validate(value, _options, _model) {
        if (isPresent(_model.minFee) && isPresent(value) && value <= _model.minFee) {
          return 'Enter larger fee';
        }
        return true;
      },
      dependentKeys: ['model.minFee'],
    }),
  ],
});

@classic
export default class PracticeWebsiteMarketingFee extends Fragment.extend(Validations) {
  @attr() name;
  @attr('number') minFee;
  @attr('number') maxFee;

  @computed('name', 'minFee', 'maxFee')
  get description() {
    let { name, minFee, maxFee } = this;
    if (!name) {
      return undefined;
    }
    let formattedMin = isPresent(minFee) ? formatMoney([minFee], { format: 'en-US' }) : '';
    let formattedMax = isPresent(maxFee) ? formatMoney([maxFee], { format: 'en-US' }) : '';
    return `${name}: ${formattedMin}${formattedMax ? ` - ${formattedMax}` : ''}`;
  }

  @computed('name', 'minFee', 'maxFee')
  get isBlank() {
    return !(this.name || isPresent(this.minFee) || isPresent(this.maxFee));
  }
}
