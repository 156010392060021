/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import { set, setProperties } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import Component from '@ember/component';
import ENV from 'client-portal/config/environment';
import classic from 'ember-classic-decorator';
import styles from './header.module.scss';

@classNameBindings('isEditMode:edit-mode')
@classic
@classNames(styles.component)
export default class PracticeWebsiteHeader extends Component {
  @service practiceWebsiteNav;

  isNavBarSliding = false;
  isNavBarVisible = false;

  // update CSS if changing duration
  get navBarTransition() {
    return isEmberTesting() ? 0 : 800;
  }

  didInsertElement() {
    super.didInsertElement(...arguments);

    this._initNavBar(this.element);
    this._initHighlightLinks();
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);

    if (isEmberTesting() && !ENV.enableForTest) {
      return;
    }

    window.removeEventListener('scroll', this._toggleNavBar);
    window.removeEventListener('scroll', this._highlightLinks);
    window.removeEventListener('resize', this._highlightLinks);
  }

  _initNavBar() {
    if (isEmberTesting() && !ENV.enableForTest) {
      return;
    }

    this._toggleNavBar = this._toggleNavBar.bind(this);
    window.addEventListener('scroll', this._toggleNavBar);
  }

  _initHighlightLinks() {
    if (isEmberTesting() && !ENV.enableForTest) {
      return;
    }

    this._highlightLinks = this._highlightLinks.bind(this);
    window.addEventListener('scroll', this._highlightLinks);
    window.addEventListener('resize', this._highlightLinks);
  }

  _toggleNavBar() {
    let {
      isNavBarVisible,
      isNavBarSliding,
      practiceWebsiteNav: { scrollTarget },
    } = this;
    let isScrolledPastTarget = window.scrollY >= scrollTarget;
    let shouldRevealNavBar = isScrolledPastTarget && !isNavBarVisible && !isNavBarSliding;
    let shouldHideNavBar = !isScrolledPastTarget && isNavBarVisible && !isNavBarSliding;
    if (shouldRevealNavBar) {
      this.slideDownNavBarTask.perform();
    } else if (shouldHideNavBar) {
      this.slideUpNavBarTask.perform();
    }
  }

  @task(function* () {
    set(this, 'isNavBarSliding', true);
    // wait a tick after giving display to start CSS transition
    yield timeout(1);
    setProperties(this, {
      isNavBarSliding: false,
      isNavBarVisible: true,
    });
  })
  slideDownNavBarTask;

  @task(function* () {
    setProperties(this, {
      isNavBarSliding: true,
      isNavBarVisible: false,
    });
    yield timeout(this.navBarTransition);
    set(this, 'isNavBarSliding', false);
  })
  slideUpNavBarTask;

  _highlightLinks() {
    this.practiceWebsiteNav.setActiveLinkOnScroll();
  }
}
