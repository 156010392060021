import { action, setProperties } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SharedQuestionnaireDateInput extends Component {
  @action
  handleSetDate(value) {
    let isDirty = !this.isModelNew || Boolean(value);
    setProperties(this.answer, { isDirty, date: value });
  }
}
