/* import __COLOCATED_TEMPLATE__ from './signature-form.hbs'; */
import { action } from '@ember/object';
import { dismissSticky } from 'client-portal/utils/banners';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SiteDocumentsSharedSignatureForm extends Component {
  @service session;

  @action
  editSignature() {
    this._showSignatureModal('Edit your signature');
  }

  @action
  sign() {
    if (this.session.signatoryName) {
      this.model.set('hasConsented', true);
    } else {
      this._showSignatureModal('Create your signature');
    }
  }

  @action
  onClose() {
    this.session.currentClient.rollbackAttributes();
    this.session.currentClientAccess.client.rollbackAttributes();
    this.set('showSignatureModal', false);
  }

  _showSignatureModal(title) {
    dismissSticky();
    this.setProperties({
      title,
      showSignatureModal: true,
    });
  }
}
