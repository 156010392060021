/* import __COLOCATED_TEMPLATE__ from './state-dropdown.hbs'; */
import Component from '@glimmer/component';
import stateGroups from 'ember-simplepractice/utils/state-groups';

export default class ContactInformationStateDropdown extends Component {
  stateGroups = stateGroups;

  notApplicableValue = '';

  get model() {
    return this.args.model;
  }

  get isDirty() {
    return this.model.get('_isDirty');
  }

  get validation() {
    return this.model.validations.attrs.state;
  }

  get hasError() {
    return this.validation.isInvalid && (this.isDirty || this.model.isValid === false);
  }
}
