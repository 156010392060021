import { buildValidations, validator } from 'ember-cp-validations';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';

const EMAIL_ERROR_MESSAGE = 'Please enter a valid email address';

const Validations = buildValidations({
  email: [
    validator('presence', { presence: true, ignoreBlank: false, message: EMAIL_ERROR_MESSAGE }),
    validator('format', { type: 'email', message: EMAIL_ERROR_MESSAGE }),
  ],
});

@classic
export default class SignInTokenModel extends Model.extend(Validations) {
  @attr() email;
  @attr() expiresIn;
  @attr() redirect;
}
