/* import __COLOCATED_TEMPLATE__ from './loading-skeleton.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { ensureSafeComponent } from '@embroider/util';
import { importSync } from '@embroider/macros';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './loading-skeleton.module.scss';

@classic
@classNameBindings('getClassNames')
@classNames(styles.component)
export default class RequestStepsLoadingSkeleton extends Component {
  @computed('step')
  get getClassNames() {
    return `skeleton-${this.step}`;
  }

  get loadingComponent() {
    let module = importSync(`./loading/loading-${this.step}`);
    return ensureSafeComponent(module.default, this);
  }
}
