/* import __COLOCATED_TEMPLATE__ from './testimonial.hbs'; */
import { MAX_QUOTE_LIMIT } from 'client-portal/models/practice-website/marketing-testimonial';
import { action, computed, get, set } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './testimonial.module.scss';

@classic
@classNames(styles.component)
export default class PracticeWebsiteEditMenuTestimonial extends Component {
  @service store;
  @service testimonialSwiper;

  maxQuoteLength = MAX_QUOTE_LIMIT;

  @computed('model.marketingTestimonials.[]', 'index')
  get testimonial() {
    return this.model.marketingTestimonials.objectAt(this.index);
  }

  @action
  async setValue(path, value) {
    this._prepareTestimonials();
    set(this.testimonial, path, value);
    this._removeEmptyTail();
    await waitForRender();
    this._updateSlider();
    this.notifyDirtyStatus(this.model);
  }

  _prepareTestimonials() {
    for (let i = this.model.marketingTestimonials.length; i <= this.index; i++) {
      this.model.marketingTestimonials.createFragment();
    }
  }

  _removeEmptyTail() {
    if (get(this, 'model.marketingTestimonials.lastObject.isBlank')) {
      this.model.marketingTestimonials.popObject();
      this._removeEmptyTail();
    }
  }

  _updateSlider() {
    if (get(this, 'testimonial.isPresent')) {
      this.testimonialSwiper.slideTo(this._sliderIndex());
    }
  }

  _sliderIndex() {
    let sliderIndex;

    this.model.filteredMarketingTestimonials.forEach((fmt, index) => {
      if (fmt === this.testimonial) {
        sliderIndex = index;
      }
    });

    return sliderIndex;
  }
}
