import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { hasPhysicalAddress } from 'client-portal/models/good-faith-estimate';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

@classic
export default class GoodFaithEstimateProvider extends Fragment {
  @attr() name;
  @attr() npiNumber;
  @attr() tin;
  @attr() location;
  @attr() street;
  @attr() city;
  @attr() state;
  @attr() zip;
  @attr() contactPerson;
  @attr() phoneNumber;
  @attr() emailAddress;

  @computed('street', 'city', 'state', 'zip')
  get hasPhysicalAddress() {
    return hasPhysicalAddress(this);
  }
}
