import Mixin from '@ember/object/mixin';
import Swal from 'sweetalert2';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  didInsertElement() {
    this._super(...arguments);
    // Required for hiding scrollbar further up the dom tree that isn't managed by Ember
    if (!this.isSecondary) {
      document.querySelector('body').classList.add('has-fullscreen-modal');
    }
    this.keyUpListener = this.keyUpListener.bind(this);
    document.addEventListener('keyup', this.keyUpListener);
  },

  willDestroyElement() {
    this._super(...arguments);
    if (!this.isSecondary) {
      document.querySelector('body').classList.remove('has-fullscreen-modal');
    }
    document.removeEventListener('keyup', this.keyUpListener);
  },

  keyUpListener(evt) {
    // We assume that only Swal modals can be on top
    // In other cases we just close
    if (evt.keyCode === 27 && !Swal.isVisible()) {
      this.close();
    }
  },
});
