import { computed } from '@ember/object';
import BillingItemMixin from 'client-portal/mixins/polymorphic/billing-item';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class StatementModel extends Model.extend(BillingItemMixin) {
  @attr() displayName;
  @attr('moment') createdAt;
  @attr() isNewForClient;

  @computed('isNewForClient', 'opened')
  get isUnread() {
    return !this.opened && this.isNewForClient;
  }

  opened = false;
}
