import { buildValidations, validator } from 'ember-cp-validations';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';
import pristine from 'client-portal/utils/macros/pristine';

export const MAX_MESSAGE_LENGTH = 600;

const Validations = buildValidations({
  message: [
    validator('length', {
      max: MAX_MESSAGE_LENGTH,
    }),
  ],
});

@classic
export default class ReservationModel extends Model.extend(Validations) {
  @belongsTo('practice', { async: false }) practice;
  @belongsTo('clinician', { async: false }) clinician;
  @belongsTo('cpt-code', { async: false }) cptCode;
  @belongsTo('office', { async: false }) office;
  @belongsTo('global-monarch-channel', { async: false }) channel;
  @attr('moment') startTime;
  @pristine('startTime') pristineStartTime;

  hasCompletedMessageStep = false;
  hasCompletedInformationStep = false;
  insurancePaymentMethod = 'No response';

  @computed('pristineStartTime', 'cptCode.duration')
  get endTime() {
    if (!this.pristineStartTime) {
      return null;
    }

    return this.pristineStartTime.clone().add(this.get('cptCode.duration'), 'minutes');
  }

  @computed('startTime', 'pristineStartTime')
  get isReserved() {
    return this.startTime && this.pristineStartTime;
  }

  @computed(
    'cptCode.{isCreditCardRequired,isCreditCardRequirementUnset}',
    'practice.requireCreditCardForNewClients'
  )
  get isCreditCardRequired() {
    let { isCreditCardRequired, isCreditCardRequirementUnset } = this.cptCode || {};
    let { requireCreditCardForNewClients } = this.practice;
    return isCreditCardRequired || (isCreditCardRequirementUnset && requireCreditCardForNewClients);
  }
}
