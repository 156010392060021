import Service from '@ember/service';
import classic from 'ember-classic-decorator';

const OUTGOING_MESSAGE_SCOPE = 'client-portal-iframe-to-origin';

@classic
export default class IframeService extends Service {
  startHeightBroadcast() {
    if (typeof document === 'undefined') {
      return;
    }

    let currentHeight = this._getHeight();
    this._broadcastHeight(currentHeight);

    window.addEventListener('resize', () => {
      currentHeight = this._getHeight();
      this._broadcastHeight(currentHeight);
    });

    setInterval(() => {
      let newHeight = this._getHeight();
      if (currentHeight !== newHeight) {
        currentHeight = newHeight;
        this._broadcastHeight(currentHeight);
      }
    }, 1000);
  }

  scrollTop(top) {
    this._postMessage({ action: 'scrollTop', top });
  }

  close() {
    this._postMessage({ action: 'close' });
  }

  appointmentRequested(params) {
    this._postMessage({ action: 'appointmentRequested', params });
  }

  _getHeight() {
    let rect = document.body.getBoundingClientRect();
    return rect.top + window.scrollY + rect.bottom;
  }

  _broadcastHeight(height) {
    this._postMessage({ action: 'height', height });
  }

  _postMessage(data) {
    if (!window.parent) {
      return;
    }

    data.scope = OUTGOING_MESSAGE_SCOPE;
    window.parent.postMessage(data, '*');
  }
}
