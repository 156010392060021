import { TYPES } from 'client-portal/models/document-request';
import { action } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { dateTimeFormat } from 'client-portal/utils/date-time';
import { later, schedule } from '@ember/runloop';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

const SHOEBOX_KEY = 'documents-show';

@classic
export default class SiteDocumentsShowRoute extends Route.extend(FastbootHelpersMixin) {
  @service router;
  @service session;
  @service store;
  @service request;
  @service analytics;
  @service mixpanel;

  model({ id = 'next' }) {
    if (this.hasShoebox) {
      let { id } = this.shoebox.retrieve(SHOEBOX_KEY);
      return TYPES.map(type => this.store.peekRecord(type, id)).compact().firstObject;
    } else {
      return this.store.queryRecord('document-request', { id });
    }
  }

  afterModel(model) {
    super.afterModel(...arguments);

    if (this.isFastBoot) {
      this.shoebox.put(SHOEBOX_KEY, model.getProperties('id'));
    }
  }

  setupController(controller) {
    super.setupController(...arguments);
    let { currentClient } = this.modelFor('application');
    controller.setProperties({
      completeRequestTask: this.completeRequestTask,
      preserveScrollPosition: true,
      currentClient,
    });
  }

  @action
  async error(err) {
    await this.router.transitionTo('site.documents');
    later(() => bannerError(err, { title: 'Document could not be loaded' }));
  }

  @action
  async refreshRequest() {
    await this.refresh();
    schedule('afterRender', () => window.scrollTo(0, 0));
  }

  @task(function* (model, options = {}) {
    yield model.save(options);

    /* eslint-disable camelcase */
    let eventProperties = {
      'client_portal_client_access_id': this.session.currentClientAccess.id,
      'client_document_request_id': model.id,
      'received_at': model.createdAt.format(dateTimeFormat),
      'document_type': model.mixpanelType,
    };

    if (eventProperties.document_type === 'measure') {
      eventProperties.measure_name = model.mixpanelDocumentName;
    }

    if (model.isReviewing) {
      return;
    } else {
      this.analytics.track('Document Submitted', {
        object: 'Document',
        action: 'Submitted',
        'document_title': model.documentTitle,
        'object_id': model.id,
        ...eventProperties,
      });
    }

    this.mixpanel.track(
      `user: Document ${model.isReviewing ? 'Review' : 'Submit'} clicked`,
      eventProperties
    );
    /* eslint-enable */

    if (
      model.constructor.modelName === 'document-request-contact-info' &&
      model.connection.hasRequiredInsuranceInfo
    ) {
      this.mixpanel.track('user: Insurance info added', {
        source: window.navigator.userAgent,
      });
    }

    if (model.meta.isLastDocument) {
      yield this.router.replaceWith('site.documents');
    } else if (this.router.currentURL.indexOf('/next') !== -1) {
      yield this.refresh();
    } else {
      yield this.router.replaceWith('site.documents.next');
    }

    schedule('afterRender', () => window.scrollTo(0, 0));
  })
  completeRequestTask;
}
