import { cancel, later } from '@ember/runloop';
import { namespace } from 'client-portal/adapters/application';
import IdleJs from 'idle-js';
import Service, { service } from '@ember/service';

export const sessionTimeoutMinutes = 15;
const keepaliveInterval = 3 * 60 * 1000;
const keepaliveTtl = 60 * 1000;
const sessionBuffer = 15 * 1000;

export default class KeepaliveService extends Service {
  @service session;

  @service store;

  // Application adapter to make requests with
  adapter;

  // Final session timeout is global since we'll need to clear it
  finalTimeout;

  start() {
    this.adapter = this.store.adapterFor('application');

    let timer = new IdleJs({
      idle: keepaliveTtl,
      onIdle: this.onIdle.bind(this),
      onActive: this.setKeepalive.bind(this),
    });
    timer.start();

    // Keep making keepalive requests while timer is not idle
    setInterval(() => {
      if (timer.idle) {
        return;
      }

      this.setKeepalive();
    }, keepaliveInterval);
  }

  get isSessionActive() {
    if (this.isDestroyed || !this.session.isAuthenticated) {
      return false;
    }

    // Don't trigger invalidation for pending access
    return !this.session.get('currentClientAccess.isOtpPending');
  }

  // Make a keepalive request so session can be either extended or invalidated
  async setKeepalive() {
    // We don't need final session timeout anymore if we are here
    cancel(this.finalTimeout);
    if (!this.isSessionActive) {
      return;
    }

    try {
      await this.adapter.ajax(`/${namespace}/keepalive`, 'POST', {});
    } catch (_err) {
      // ember-simple-auth will handle 401, otherwise noop
    }
  }

  // Retrieve number of seconds left in session
  async getKeepalive() {
    try {
      let data = await this.adapter.ajax(`/${namespace}/keepalive`, 'GET', {});
      return data.meta.secondsLeft;
    } catch (_err) {
      return 0;
    }
  }

  // Once we're idle, retrieve the seconds left and schedule timeout (with buffer)
  // - If we get active again, timeout will be cleared
  // - If we never get active, we'll attempt to make a keepalive request
  //   - It will succeeed if other tab got session active
  //   - It will reload if we're truly inactive now
  async onIdle() {
    if (!this.isSessionActive) {
      return;
    }

    let secondsLeft = await this.getKeepalive();
    this.finalTimeout = later(this.setKeepalive.bind(this), secondsLeft * 1000 + sessionBuffer);
  }
}
