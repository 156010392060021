/* import __COLOCATED_TEMPLATE__ from './validated-input.hbs'; */
import { and, not, oneWay } from '@ember/object/computed';
import { classNames } from '@ember-decorators/component';
import { computed, defineProperty } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './validated-input.module.scss';

@classic
@classNames('validated-input', styles.component)
export default class RequestValidatedInput extends Component {
  validation = null;
  isDirty = false;

  @computed('path', 'model.isValid')
  get attributeError() {
    if (this.model?.isValid) {
      return null;
    }

    let path = this.path;
    let errorsObject = this.get('model.errors');

    if (!errorsObject) {
      return null;
    }

    let errors = errorsObject.errorsFor(path);

    if (!errors.length) {
      return null;
    }

    return errors[0].message || 'An error occurred.';
  }

  @not('validation.isValidating') notValidating;

  @oneWay('validation.isInvalid') isInvalid;

  @computed('isDirty', 'isInvalid', 'model.isValid')
  get isMessageDisplayed() {
    return this.isInvalid && (this.isDirty || this.model.isValid === false);
  }

  @and('notValidating', 'isMessageDisplayed', 'validation') isMarkedInvalid;

  init() {
    super.init(...arguments);
    defineProperty(this, 'validation', oneWay(`model.validations.attrs.${this.path}`));
  }
}
