/* import __COLOCATED_TEMPLATE__ from './note-form.hbs'; */
import { action } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { dismissSticky, info } from 'client-portal/utils/banners';
import { ensureSafeComponent } from '@embroider/util';
import { importSync } from '@embroider/macros';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './note-form.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsNoteForm extends Component {
  @service currentPractice;
  @service session;
  styles = styles;

  @reads('model.diagnosisTreatmentPlan.type') diagnosisTreatmentPlanType;
  @reads('model.diagnosisTreatmentPlan.structure.diagnoses') diagnoses;

  get diagnosisTreatmentPlanComponent() {
    let module = importSync(`./note/diagnosis-treatment-plan/${this.diagnosisTreatmentPlanType}`);
    return ensureSafeComponent(module.default, this);
  }

  didInsertElement() {
    super.didInsertElement();

    info({
      title: 'Please review the document and sign below.',
      isSticky: true,
      classNames: 'info-with-icon',
    });
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    dismissSticky();
  }

  @action
  async nextStep() {
    try {
      await this.completeRequestTask.perform(this.model);
    } catch (err) {
      bannerError(err, { title: 'Document could not be submitted' });
    }
  }
}
