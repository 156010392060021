/* import __COLOCATED_TEMPLATE__ from './document-preview.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { later } from '@ember/runloop';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './document-preview.module.scss';

@classic
@classNameBindings('isScrollable')
@classNames(styles.component)
export default class SiteDocumentPreview extends Component {
  didRender() {
    super.didRender(...arguments);

    if (!this.isScrollable) {
      return;
    }

    later(() => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      let contents = this.element.querySelector('.contents');
      this.element.classList.toggle('is-overflowed', contents.scrollHeight > contents.clientHeight);
    }, 0);
  }
}
