module.exports = [
  'Abandonment',
  'Abortion Issues',
  'Abuse Survivors',
  'ADD/ADHD',
  'Addiction',
  'Adjustment Disorder',
  'Adoption',
  'Aging',
  'Agoraphobia',
  'Alcohol Use',
  "Alzheimer's",
  'Amputations',
  'Anger Management',
  'Anorexia',
  'Antisocial Personality',
  'Anxiety',
  'Arthritis',
  'Asexuality',
  'Asthma',
  'Attachment Issues',
  'Autism Spectrum Disorder',
  'Avoidant Personality',
  'Back Pain',
  'Behavioral Issues',
  'Binge Eating Disorder',
  'Bipolar Disorder',
  'Blended Families',
  'Body Image Disorders',
  'Borderline Personality',
  'Brain Injury/Concussions',
  'Breastfeeding/Lactation Difficulties',
  'Bulimia Nervosa',
  'Bullying',
  'Burnout',
  'Burns',
  'Career Counseling',
  'Caregiver Issues',
  'Carpal Tunnel Syndrome',
  'Cerebral Palsy',
  'Child or Adolescent',
  'Chronic Illness',
  'Chronic Impulsivity',
  'Chronic Pain',
  'Chronic Relapse',
  'Codependency',
  'Cognitive-Communication Disorders',
  'College Mental Health',
  'Communication Issues',
  'Compulsive Behavior',
  'Compulsive Shopping',
  'Coping Skills',
  'Couples Counseling',
  'Cultural Adjustment',
  'Custody Issues',
  'Dementia',
  'Depression',
  'Developmental Disorders',
  'Diabetes',
  'Digestive Problems',
  'Disability',
  'Disruptive Mood Dysregulation (DMDD)',
  'Dissociation',
  'Dissociative Identity Disorder (DID)',
  'Divorce',
  'Domestic Abuse',
  'Domestic Violence',
  'Down Syndrome',
  'Drug Abuse',
  'Dual Diagnosis',
  'Dyslexia',
  'Eating Disorders',
  'Emotional Abuse',
  'Emotional Disturbance',
  'Exercise Addiction',
  'Existential Crisis',
  'Family Conflict',
  'Fatigue',
  'Fears/Phobias',
  'Food Allergies and Intolerances',
  'Foot and Ankle Injuries',
  'Gambling',
  'Gender Identity',
  'Grief',
  'Headaches/Migraines',
  'Heart Disease ',
  'High Blood Pressure',
  'Hip Conditions',
  'HIV/AIDS',
  'Hoarding',
  'Hypochondria',
  'Infertility',
  'Infidelity',
  'Intellectual Disability',
  'Internet Addiction',
  'Joint Replacement',
  'Knee Conditions ',
  'Language Disorders',
  'Learning Disabilities',
  'LGBTQIA+',
  'Life Coaching',
  'Life Transitions',
  'Loneliness',
  'Marital and Premarital',
  'Medical Detox',
  'Medical Nutrition Therapy ',
  'Medical Professional Stress',
  'Medication Management',
  "Men's Issues",
  "Military/Veteran's Issues",
  'Mindfulness',
  'Mood Disorders',
  'Multicultural Concerns',
  'Multiple Sclerosis',
  'Muscle Tension and Joint Pain',
  'Narcissistic Personality',
  'Neck Pain',
  'Obesity',
  'Obsessive-Compulsive Disorder (OCD)',
  'Oppositional Defiance',
  'Panic Disorders',
  'Parenting',
  "Parkinson's Disease",
  'Peer Relationships',
  'Perfectionism',
  'Personality Disorder',
  'Physical Wellness',
  'Open Relationships/Polyamory',
  'Post-Operative Cancer Care',
  'Postpartum Depression',
  'Pregnancy, Prenatal, Postpartum',
  'Prejudice/Discrimination',
  'Premenstrual Syndrome (PMS)',
  'Psychotic Disorders',
  'PTSD',
  'Racial Identity',
  'Relationship Issues',
  'Rheumatoid Arthritis',
  'Schizophrenia',
  'School Issues',
  'Sciatica',
  'Seasonal Affective Disorder (SAD)',
  'Self Esteem',
  'Self-Harming',
  'Separation Anxiety',
  'Sex Therapy',
  'Sexual Abuse',
  'Sexual Addiction',
  'Sexual Dysfunction',
  'Shoulder Condition ',
  'Sleep or Insomnia',
  'Smoking Cessation',
  'Social Anxiety',
  'Social Communication Disorders',
  'Somatoform Disorders',
  'Speech Disorders',
  'Spine Condition',
  'Spirituality and Religion',
  'Sport Injuries ',
  'Sports Performance',
  'Stress',
  'Stroke',
  'Substance Use',
  'Suicidal Ideation',
  'Swallowing Disorders',
  'Teen Violence',
  'Testing and Evaluation',
  'Transgender Issues',
  'Trauma',
  'Traumatic Brain Injury',
  'Video Game Addiction',
  'Voice Modification Therapy',
  'Weight Loss',
  'Whiplash',
  "Women's Health",
  "Women's Issues",
  'Work Stress',
  'Compassion Fatigue',
  'Cultural and Systemic Oppression',
  'Kink/Diverse Sexualities',
  'Racial Identity',
  'White Privilege',
  'Unconscious Bias',
];
