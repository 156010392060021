import { action } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class SiteLegalAgreementsController extends Controller {
  @service store;
  @service session;
  @service router;
  @service mixpanel;

  @reads('session.currentClientAccess.eulaAgreementAccepted') hasAcceptedEula;

  @action
  async acceptLegalAgreements(agreements) {
    try {
      let adapter = this.store.adapterFor('client-access');
      let agreementsAccepted = JSON.stringify(agreements.mapBy('id'));
      let data = {
        data: { type: 'legal-agreements', attributes: { agreementsAccepted } },
      };

      await adapter.acceptLegalAgreements(data);

      this.send('refreshEnvironment');
      this.router.transitionTo('site.home');
      this.trackLegalAgreementsAccepted();
    } catch (err) {
      bannerError(err, { title: 'Could not accept the legal agreements' });
    }
  }

  @action
  trackLegalAgreementsAccepted() {
    this.mixpanel.track('client: legal agreements accepted', { origin: 'client portal' });
  }
}
