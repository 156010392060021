/* import __COLOCATED_TEMPLATE__ from './request-wrapper.hbs'; */
import { attributeBindings, classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './request-wrapper.module.scss';

@classic
@classNames('d-flex', 'flex-wrap', styles.component)
@classNameBindings(
  'fullScreen:full-screen:',
  'currentStepClass',
  'hasStickyFooter:has-sticky-footer'
)
@attributeBindings('hasStickyFooter:data-test-request-wrapper')
export default class RequestRequestWrapper extends Component {
  @service stickyFooter;
  @computed('activeSteps.@each.isActive')
  get currentStep() {
    return this.activeSteps.findBy('isActive');
  }

  @computed('currentStep.route')
  get currentStepClass() {
    if (!this.currentStep) {
      // eslint-disable-next-line getter-return
      return;
    }

    return `${this.currentStep.route}-step`;
  }

  @computed('activeSteps.[]', 'currentStep')
  get previousStep() {
    if (this.get('currentStep.previousStepDisabled')) {
      return null;
    }
    let currentIndex = this.activeSteps.indexOf(this.currentStep);
    return this.activeSteps.objectAt(currentIndex - 1);
  }

  @computed('stickyFooter.liveFooters')
  get hasStickyFooter() {
    return this.stickyFooter.isFooterLive('sticky-footer-container');
  }
}
