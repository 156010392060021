import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { computed, get } from '@ember/object';
import { not } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

export const MAX_QUOTE_LIMIT = 260;

const Validations = buildValidations({
  quote: [
    validator('presence', {
      presence: true,
      disabled: not('model.source'),
      message: "Can't be left blank.",
    }),
    validator('length', {
      max: MAX_QUOTE_LIMIT,
      message: computed('model.quote', function () {
        return `${(get(this, 'model.quote.length') || 0) - MAX_QUOTE_LIMIT} characters over`;
      }),
    }),
  ],
  source: [
    validator('presence', {
      presence: true,
      disabled: not('model.quote'),
      message: "Can't be left blank.",
    }),
    validator('length', {
      max: 60,
      message: "Can't be more than 60 characters.",
    }),
  ],
});

@classic
export default class PracticeWebsiteMarketingTestimonial extends Fragment.extend(Validations) {
  @attr() source;
  @attr() quote;

  @computed('quote', 'source')
  get isBlank() {
    return !(this.quote || this.source);
  }

  @not('isBlank') isPresent;
}
