import { applyValidationError, resetErrors } from 'client-portal/utils/validate-record';
import { bannerError, callbackError } from 'client-portal/utils/error-handling';
import { task } from 'ember-concurrency';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import pht from 'client-portal/utils/persistent-hash-table';

export const ERROR_TOO_MANY_EMAIL_REQUESTS = 'tooManyEmailRequests';
export const ERROR_TOO_MANY_IP_REQUESTS = 'tooManyIpRequests';
export const ERROR_TOO_MANY_REQUESTS = 'tooManyRequests';
export const ERROR_INVALID = 'invalid';
export const ERROR_EXPIRED = 'expired';

const storedEmailKey = 'stored-email';
const storedEmailTtl = [14, 'days'];

@classic
export default class SignInService extends Service {
  @service mixpanel;

  ERROR_TOO_MANY_EMAIL_REQUESTS = ERROR_TOO_MANY_EMAIL_REQUESTS;
  ERROR_TOO_MANY_IP_REQUESTS = ERROR_TOO_MANY_IP_REQUESTS;
  ERROR_TOO_MANY_REQUESTS = ERROR_TOO_MANY_REQUESTS;

  email = undefined;
  lastError = undefined;

  get storedEmail() {
    let data;
    try {
      data = JSON.parse(pht.get(storedEmailKey));
    } catch (err) {
      return null;
    }

    if (!data || typeof data !== 'object') {
      return null;
    }

    if (!data.email || !data.expiresAt || moment(data.expiresAt) < moment()) {
      return null;
    }

    return data.email;
  }

  set storedEmail(email) {
    pht.set(
      storedEmailKey,
      JSON.stringify({
        email,
        expiresAt: moment().add(...storedEmailTtl),
      })
    );
  }

  trackAttempt() {
    if ([ERROR_TOO_MANY_EMAIL_REQUESTS, ERROR_TOO_MANY_IP_REQUESTS].includes(this.lastError)) {
      this.mixpanel.track('visit: Client Portal too many requests page');
    } else if ([ERROR_EXPIRED, ERROR_INVALID].includes(this.lastError)) {
      this.mixpanel.track('visit: Client Portal Expired page');
    }
  }

  resetErrors() {
    this.set('lastError', undefined);
  }

  @task(function* (model) {
    this.resetErrors();
    this.set('email', model.email);
    resetErrors(model);
    yield model.validate();
    if (!model.validations.isValid) {
      applyValidationError(model);
      return;
    }

    try {
      yield model.save();
      return true;
    } catch (err) {
      callbackError(err, ({ status, title }) => {
        if (status === '429') {
          if (title === 'Email request limit reached') {
            this.set('lastError', ERROR_TOO_MANY_EMAIL_REQUESTS);
          } else if (title === 'IP request limit reached') {
            this.set('lastError', ERROR_TOO_MANY_IP_REQUESTS);
          } else {
            this.set('lastError', ERROR_TOO_MANY_REQUESTS);
          }
          this.trackAttempt();
          return true;
        } else if (!model.isValid) {
          this.set('lastError', ERROR_INVALID);
          return true;
        } else {
          bannerError(err, { title: 'Could not make Sign In request' });
        }
      });
    }
  })
  requestTokenTask;

  @task(function* (model) {
    this.resetErrors();
    yield model.validate();
    if (!model.validations.isValid) {
      this.set('lastError', ERROR_INVALID);
      return;
    }

    try {
      yield model.save();
      switch (model.meta.status) {
        case 'verified':
          return true;
        case 'expired':
        case 'merged':
          this.set('lastError', model.meta.status);
          break;
        default:
          this.set('lastError', ERROR_INVALID);
      }
    } catch (err) {
      callbackError(err, ({ status }) => {
        if (status === '429') {
          this.set('lastError', ERROR_TOO_MANY_REQUESTS);
        } else {
          this.set('lastError', ERROR_INVALID);
        }
        return true;
      });
    }
  })
  verifyTokenTask;

  @task(function* (model) {
    this.resetErrors();
    yield model.validate();
    if (!model.validations.isValid) {
      return;
    }

    try {
      yield model.save();
      return true;
    } catch (err) {
      callbackError(err, ({ status }) => {
        if (status === '429') {
          this.set('lastError', ERROR_TOO_MANY_REQUESTS);
        } else {
          model.errors.add('pin', 'The code entered is incorrect or was already used');
        }
        return true;
      });
    }
  })
  verifyPinTask;
}
