import { dismissSticky, error } from 'client-portal/utils/banners';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import Component from '@glimmer/component';

export default class SharedCardError extends Component {
  timeToError = 4000;

  constructor() {
    super(...arguments);
    this.handleError.perform();
  }

  handleError = task(async () => {
    let { card } = this.args;
    let didShow = false;

    try {
      await timeout(this.timeToError);
      if (!card.areElementsReady) {
        didShow = true;
        this.showErrorBanner();
      }
      await waitForProperty(card, 'areElementsReady');
    } finally {
      if (didShow) dismissSticky();
    }
  });

  showErrorBanner() {
    let content = document.createElement('p');
    content.innerText =
      'There was an error loading this page. Please check your internet connection, refresh your browser, and try again.';
    error({
      title: 'Error: Failed to load resources',
      content,
    });
  }
}
