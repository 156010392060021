import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

@classic
export default class GoodFaithEstimateClient extends Fragment {
  @attr() name;
  @attr('moment') birthDate;
  @attr() phoneNumber;
  @attr() emailAddress;
  @attr() street;
  @attr() city;
  @attr() state;
  @attr() zip;
  @attr('boolean') voiceContactPermitted;
  @attr('boolean') textContactPermitted;
  @attr('boolean') emailContactPermitted;

  @computed('voiceContactPermitted')
  get voiceContactText() {
    return this.voiceContactPermitted ? 'Voice message' : '';
  }

  @computed('textContactPermitted')
  get textContactText() {
    return this.textContactPermitted ? 'Text message' : '';
  }

  @computed('emailContactPermitted')
  get emailContactText() {
    return this.emailContactPermitted ? 'Email' : '';
  }

  @computed('voiceContactText', 'textContactText', 'emailContactText')
  get contactPermissionsText() {
    return [this.voiceContactText, this.textContactText, this.emailContactText]
      .filter(Boolean)
      .join(', ');
  }
}
