/* import __COLOCATED_TEMPLATE__ from './highlight-icons.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './highlight-icons.module.scss';

@classic
@classNames(styles.component)
export default class PracticeWebsiteHighlightIcons extends Component {
  @reads('model.telehealthIndicator') telehealthIndicator;
  @reads('model.showOnlinePaymentsIndicator') showOnlinePaymentsIndicator;
  @reads('model.acceptInsuranceIndicator') acceptInsuranceIndicator;
  @reads('model.acceptNewClientsIndicator') acceptNewClientsIndicator;

  @computed(
    'telehealthIndicator',
    'showOnlinePaymentsIndicator',
    'acceptInsuranceIndicator',
    'acceptNewClientsIndicator'
  )
  get icons() {
    return [
      {
        name: 'telehealth',
        icon: 'telehealth',
        title: htmlSafe('Offers Telehealth <div>Appointments</div>'),
        isEnabled: this.telehealthIndicator,
      },
      {
        name: 'online-payments',
        icon: 'card',
        title: htmlSafe('Accepts <div>Online Payments</div>'),
        isEnabled: this.showOnlinePaymentsIndicator,
      },
      {
        name: 'insurance',
        icon: 'check-double',
        title: htmlSafe('Accepts <div>Insurance</div>'),
        isEnabled: this.acceptInsuranceIndicator,
      },
      {
        name: 'new-clients',
        icon: 'client-check',
        title: htmlSafe('Accepting New <div>Clients</div>'),
        isEnabled: this.acceptNewClientsIndicator,
      },
    ];
  }
}
