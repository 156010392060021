import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class ContactWidgetRoute extends Route {
  @service store;
  @service currentPractice;

  queryParams = {
    clinicianId: { refreshModel: true },
  };

  async model({ clinicianId }, _transition) {
    let clinician = clinicianId ? await this.store.findRecord('clinician', clinicianId) : null;
    let client = this.store.createRecord('prospective-client', {
      emails: [this.store.createRecord('email', { prospectiveClient: true })],
      phones: [this.store.createRecord('phone', { prospectiveClient: true })],
      clinician,
    });
    return { client, clinician };
  }
}
