export default {
  get(key) {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to get "${key}".`);
    }
  },
  set(key, value) {
    try {
      return localStorage.setItem(key, value);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to set "${key}".`);
    }
  },
  remove(key) {
    try {
      return localStorage.removeItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to remove "${key}".`);
    }
  },
};
