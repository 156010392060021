/* import __COLOCATED_TEMPLATE__ from './specialties.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import { mapBy, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './specialties.module.scss';

@classic
@classNames(styles.component)
export default class PracticeWebsiteSpecialties extends Component {
  @mapBy('model.marketingSpecialties', 'name') marketingSpecialties;
  @mapBy('model.marketingInsuranceProviders', 'name') insurancePlans;
  @mapBy('model.marketingServices', 'name') services;
  @mapBy('model.marketingFees', 'description') feeDescriptions;
  @notEmpty('marketingSpecialties') hasMarketingSpecialties;

  @computed('insurancePlans.[]', 'services.[]', 'feeDescriptions.[]')
  get offerings() {
    return [
      {
        title: 'Approaches',
        name: 'services',
        items: this.services,
        isEnabled: isPresent(this.services),
      },
      {
        title: 'Insurance Accepted',
        name: 'insurance',
        items: this.insurancePlans,
        isEnabled: isPresent(this.insurancePlans),
      },
      {
        title: 'Fees',
        name: 'fees',
        items: this.feeDescriptions,
        isEnabled: isPresent(this.feeDescriptions),
      },
    ];
  }
}
