/* eslint-disable ember/no-classic-classes */

// Convert once ember-source is 3.16+
import { fullTimeRange } from 'client-portal/components/shared/date-range-picker';
import { service } from '@ember/service';
import ENV from 'client-portal/config/environment';
import InfinityModel from 'ember-infinity/lib/infinity-model';
import Route from '@ember/routing/route';

const ExtendedInfinityModel = InfinityModel.extend({
  buildParams() {
    let params = this._super(...arguments);
    params.page = { cursor: this.cursor, size: ENV.maxPageSize };
    return params;
  },

  afterInfinityModel(model) {
    this.set('canLoadMore', model.length >= ENV.maxPageSize);
    this.set('cursor', model.get('lastObject.cursorId'));
  },
});

export default Route.extend({
  queryParams: {
    timeRange: { refreshModel: true },
  },

  infinity: service(),
  fastboot: service(),

  model({ timeRange, itemStatus }) {
    if (this.fastboot.isFastBoot) {
      return [];
    }

    let filter = {
      thisType: this.thisType,
      timeRange: timeRange ? fullTimeRange(timeRange) : null,
      itemStatus: itemStatus || undefined,
    };

    return this.infinity.model(
      'polymorphic/billing-item',
      {
        filter,
        perPageParam: null,
        pageParam: null,
      },
      ExtendedInfinityModel
    );
  },

  setupController(controller) {
    this._super(...arguments);

    controller.set('client', this.modelFor('site.billing'));
  },

  resetController(controller, isExiting, _transition) {
    this._super(...arguments);

    if (isExiting) {
      controller.set('client', undefined);
      controller.set('itemStatus', undefined);
    }
  },
});
