/* import __COLOCATED_TEMPLATE__ from './style-variables.hbs'; */
import { FALLBACK_FONTS } from 'client-portal/services/style-variables';
import { computed, get } from '@ember/object';
import { fullSourceMap, patternSourceMap } from 'client-portal/utils/practice-website-images';
import { htmlSafe } from '@ember/template';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@tagName('style')
export default class StyleVariables extends Component {
  @service styleVariables;
  @service currentPractice;

  fallbackFonts = FALLBACK_FONTS;

  @reads('styleVariables.globalTheme') globalTheme;
  @reads('globalTheme.primary') primaryColor;
  @reads('globalTheme.primarySpecial') primarySpecialColor;
  @reads('globalTheme.specialButtonColor') specialButtonColor;
  @reads('globalTheme.specialButtonBorderColor') specialButtonBorderColor;
  @reads('globalTheme.disabledButtonColor') disabledButtonColor;
  @reads('globalTheme.secondary') secondaryColor;
  @reads('globalTheme.background') backgroundColor;
  @reads('globalTheme.bodytext') bodytextColor;
  @reads('globalTheme.fonts.source') fontSource;
  @reads('globalTheme.pageMaxWidth') pageMaxWidth;
  @reads('globalTheme.sectionBorderRadius') sectionBorderRadius;
  @reads('globalTheme.smallButtonBorderRadius') smallButtonBorderRadius;
  @reads('globalTheme.mediumButtonBorderRadius') mediumButtonBorderRadius;
  @reads('globalTheme.websiteLargeButtonBorderRadius') websiteLargeButtonBorderRadius;
  @reads('globalTheme.timeSpotButtonBorderRadius') timeSpotButtonBorderRadius;

  @computed('practiceWebsite.introImg')
  get introBackgroundImg() {
    let { practiceWebsite } = this;
    let source;

    if (practiceWebsite.hasGalleryBackground) {
      source = fullSourceMap[practiceWebsite.introImg];
    } else if (practiceWebsite.hasPatternBackground) {
      source = patternSourceMap[practiceWebsite.introImg];
    } else if (practiceWebsite.hasUploadBackground) {
      source = `${this.currentPractice.get('practiceUrl')?.slice(0, -1)}${practiceWebsite.uploadedIntroImgUrl}`;
    }

    return source ? htmlSafe(`url(${source})`) : 'none';
  }

  @computed('practiceWebsite.introReadability')
  get introBackgroundBrightness() {
    return `${100 - get(this, 'practiceWebsite.introReadability')}%`;
  }

  @computed('globalTheme.fonts.header')
  get fontHeader() {
    return `${get(this, 'globalTheme.fonts.header')}, ${this.fallbackFonts}`;
  }

  @computed('globalTheme.fonts.body')
  get fontBody() {
    return `${get(this, 'globalTheme.fonts.body')}, ${this.fallbackFonts}`;
  }
}
