/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { formatPhoneNumber } from 'client-portal/utils/format-phone-number';
import {
  formatValidationErrorsForMixpanel,
  scrollToValidationError,
} from 'client-portal/utils/validate-record';
import { modifier } from 'ember-modifier';
import { phraseDateTimeFormat } from 'client-portal/utils/date-time';
import { service } from '@ember/service';
import { success } from 'client-portal/utils/banners';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { underscore } from '@ember/string';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Component from '@glimmer/component';
import styles from './form.module.scss';

export default class SiteBillingOutOfNetworkForm extends Component {
  @service router;
  @service store;
  @service mixpanel;
  @service oonClaimSubmissionError;
  @service billingModals;
  @service pollTask;

  styles = styles;
  phraseDateTimeFormat = phraseDateTimeFormat;
  isInitialClaim;

  @tracked step = 'insurance';
  @tracked reviewOnly = false;
  @tracked hideInsuranceStatusBanner = true;
  @tracked showTimedOutBanner = false;
  @tracked isLoading = false;

  constructor() {
    super(...arguments);

    this.isInitialClaim = !this.args.client.hasOonClaims;
    if (!this.isInitialClaim) {
      this.setAndTrackReviewStep();
    }
  }

  get client() {
    return this.args.client;
  }

  get claim() {
    return this.args.claim;
  }

  get currentSubmission() {
    return this.claim.currentSubmission;
  }

  get appointment() {
    return this.claim.appointments.firstObject;
  }

  get startDate() {
    return this.appointment.startTime.startOf('day');
  }

  get endDate() {
    return this.startDate.endOf('day');
  }

  get appointmentId() {
    return this.appointment.id;
  }

  get insuranceInfo() {
    return this.client.primaryInsuranceInfo;
  }

  get phone() {
    return this.client.defaultPhone;
  }

  get showBack() {
    return this.isInitialClaim && this.step !== 'insurance';
  }

  get isReview() {
    return this.step === 'review';
  }

  get canEditSectionInReviewStep() {
    return this.isReview && !this.isInitialClaim;
  }

  get continueButtonText() {
    return this.isReview ? 'Submit claim' : 'Continue';
  }

  get backButtonDisabled() {
    return this.saveInsuranceInfoTask.isRunning || this.saveClientTask.isRunning;
  }

  get continueButtonDisabled() {
    return this.backButtonDisabled || (this.isReview && this.submitButtonDisabled);
  }

  get appointmentBalanceUnpaid() {
    return !this.appointment.paid;
  }

  get appointmentBalanceAmount() {
    return this.appointment.uniqueInvoices.reduce((total, invoice) => {
      return total + invoice.remainingAmount;
    }, 0);
  }

  get submitButtonDisabled() {
    return this.appointmentBalanceUnpaid || this.insuranceStatusNotActive;
  }

  get showInsuranceSection() {
    return this.step === 'insurance' || this.isReview;
  }

  get showClientSection() {
    return this.step === 'client' || this.isReview;
  }

  get showStatus() {
    return this.claim.status !== 'prepared';
  }

  get diagnosisCodeCount() {
    return this.claim?.diagnosisCodes ? Object.keys(this.claim.diagnosisCodes).length : '-';
  }

  get serviceCodeCount() {
    return this.appointment?.cptCodes?.length || '-';
  }

  get insuranceStatusNotActive() {
    return ['Inactive', 'Review info', 'Loading'].includes(
      this.insuranceInfo?.coverageStatus?.type
    );
  }

  get showInsuranceStatusBanner() {
    return this.insuranceStatusNotActive && !this.hideInsuranceStatusBanner;
  }

  get insuranceStatusBannerContent() {
    if (this.insuranceInfo?.coverageStatus?.type === 'Inactive') {
      return "This insurance plan is inactive, which usually means it's expired. If you think this plan should be active, make sure the info you've provided matches your insurance card";
    } else {
      return "We couldn't find an insurance plan with this info. Make sure the info you've provided matches your insurance card.";
    }
  }

  @action
  nextStep() {
    if (this.step === 'insurance') {
      this.saveInsuranceInfo();
      this.#createAddress();
      this.#createPhone();
    } else if (this.step === 'client') {
      this.saveClient();
    } else {
      this.#submitInsuranceClaim();
    }
  }

  @action
  previousStep() {
    switch (this.step) {
      case 'client':
        this.client.skipSexPresenceValidation = false;
        this.client.set('skipBirthDatePresenceValidation', true);

        this.step = 'insurance';
        break;
      case 'review':
        this.step = 'client';
        break;
    }
  }

  @action
  openPaymentModal() {
    let invoice = null;
    if (this.appointment.uniqueInvoices.length === 1) {
      invoice = this.appointment.uniqueInvoices[0];
    }
    this.billingModals.showPayment(invoice);
    this.mixpanel.track('client: client submission payment modal opened');
  }

  @action
  goToInsuranceStep() {
    this.step = 'insurance';
  }

  @action
  trackEvent(step) {
    let eventProperties = {
      'step_name': step,
      'first_claim': !this.client.hasOonClaims,
      'has_insurance_details': this.client.hasInsuranceDetails,
      'has_client_info': this.client.hasRequiredFields,
      'diagnosis_count': this.diagnosisCodeCount,
      'service_count': this.serviceCodeCount,
    };
    if (step === 'review') {
      Object.assign(eventProperties, {
        'outstanding_balance': this.billingModals.totalUnpaidAmount,
        'appointment_count_on_invoice': this.appointment.uniqueInvoices[0].numberOfAppointments,
        'appointment_fee': this.appointment.fee,
        'invoice_balance': this.appointmentBalanceAmount,
        'invoice_is_paid': this.appointment.paid,
        'payment_modal_displayed': this.appointmentBalanceUnpaid,
        'coverage_report_status': underscore(this.insuranceInfo?.coverageStatus?.type || ''),
      });
    }
    this.mixpanel.track('client: client submission flow step viewed', eventProperties);
  }

  addPaymentModalCallback = modifier(() => {
    this.billingModals.afterPersist.push(() => {
      this.mixpanel.track('client: client submission payment modal paid');
    });
  });

  willDestroy() {
    super.willDestroy();
    this.billingModals.afterPersist.pop();
  }

  async saveInsuranceInfo() {
    this.client.validate();
    this.client.set('isDirty', !this.client.validations.isValid);
    this.insuranceInfo.set('isDirty', !this.insuranceInfo.validations.isValid);

    if (!this.insuranceInfo.isDirty) {
      this.saveInsuranceInfoTask.perform();
    } else {
      this.trackError(
        'insurance_info',
        formatValidationErrorsForMixpanel(this.client.validations.errors)
      );
      await waitForRender();
      scrollToValidationError();
    }
  }

  async #submitInsuranceClaim() {
    this.claim.setProperties({
      client: this.client,
      insuranceInfo: this.insuranceInfo,
      appointmentIds: this.appointmentId,
      startDate: this.startDate,
      endDate: this.endDate,
    });

    this.claim.validate();
    this.claim.set('isDirty', !this.claim.validations.isValid);

    if (this.claim.validations.isValid) {
      return this.submitInsuranceClaimTask.perform();
    } else {
      await waitForRender();
      scrollToValidationError();
      this.trackError(
        'insurance_claim',
        formatValidationErrorsForMixpanel(this.claim.validations.errors)
      );
    }
  }

  @task(function* () {
    try {
      yield this.claim.save();

      success({ title: 'Insurance claim created and submitted' });
    } catch (err) {
      this.trackError('claim_creation', err.message);
      yield this.handleSuperbillFallbackTask.perform();
    } finally {
      this.router.transitionTo('site.billing');
    }
  })
  submitInsuranceClaimTask;

  @task(function* () {
    try {
      yield this.oonClaimSubmissionError.fetchSuperbill(this.appointment);
    } catch (err) {
      // noop - Banner handles fail state
    }
  })
  handleSuperbillFallbackTask;

  @task(function* () {
    try {
      this.client.skipSexPresenceValidation = true;
      if (this.insuranceInfo.hasDirtyAttributes) {
        yield this.insuranceInfo.save();
        this.mixpanel.track('user: out-of-network submit flow coverage report initiated', {
          'payer_id': this.insuranceInfo.insurancePlan.payer,
          'claim_id': this.claim.id,
        });
        this.pollCoverageTask.perform();
      }
      yield this._saveInsuranceCards();
      if (this.isInitialClaim) {
        this.step = 'client';
        this.hideInsuranceStatusBanner = true;
        this.client.set('skipBirthDatePresenceValidation', false);
      }
    } catch (err) {
      this.trackError('insurance_info', err.message);
      bannerError(err, { title: 'Insurance information could not be saved' });
    }
  })
  saveInsuranceInfoTask;

  async saveClient() {
    this.client.validate();
    this.client.defaultPhone.validate();
    this.client.defaultAddress.validate();

    this.client.set('isDirty', !this.client.validations.isValid);
    this.client.defaultAddress.set('isDirty', !this.client.defaultAddress.validations.isValid);
    this.client.defaultPhone.set('number', formatPhoneNumber(this.client.defaultPhone.number));
    this.client.defaultPhone.set('isDirty', !this.client.defaultPhone.validations.isValid);
    this.client.set(
      'isDirty',
      !this.client.validations.isValid ||
        this.client.defaultPhone.isDirty ||
        this.client.defaultAddress.isDirty
    );

    if (this.client.isDirty) {
      this.#trackClientValidationErrors();
      await waitForRender();
      scrollToValidationError();
    } else {
      await this.saveClientTask.perform();
      this.client.reload();
    }
  }

  async _saveInsuranceCards() {
    let promiseArray = [];
    this.insuranceInfo.documents.forEach(card => {
      if (card.isMarkedForDeletion) {
        promiseArray.push(card.destroyRecord());
      } else if (card.isNew) {
        promiseArray.push(card.save());
      }
    });

    return await Promise.all(promiseArray);
  }

  @task(function* () {
    try {
      yield this.client.save({
        adapterOptions: {
          query: { include: 'phones,addresses' },
          embed: ['phones', 'addresses'],
        },
      });
      if (!this.client.phones.lastObject.id) {
        this.client.phones.lastObject.unloadRecord();
      }
      if (!this.client.addresses.lastObject.id) {
        this.client.addresses.lastObject.unloadRecord();
      }
      this.setAndTrackReviewStep();
      this.hideInsuranceStatusBanner = false;
    } catch (err) {
      this.trackError('client_info', err.message);
      bannerError(err, { title: 'Your information could not be updated' });
    }
  })
  saveClientTask;

  @(task(function* () {
    if (!this.coverageStatusNeedsPolling()) {
      this.trackCoverageStatusReturned();
      return;
    }

    this.isLoading = true;
    yield this.pollTask.scheduleAsyncTask({
      task: async () => {
        await this.store.findRecord('insurance-claim', this.claim.id, {
          adapterOptions: {
            query: {
              fields: {
                insuranceClaim: 'insuranceInfo',
                insuranceInfo: ['coverageStatus'].join(','),
              },
              include: 'insuranceInfo',
            },
          },
          reload: true,
        });
      },
      shouldContinuePolling: () => this.coverageStatusNeedsPolling(),
      finishedCallback: () => {
        this.isLoading = false;
        this.trackCoverageStatusReturned();
      },
      timedOutCallback: () => this.handleCoverageStatusTimedOut(),
      remainingRequests: 25,
      timeoutMilliseconds: 3000,
    });
  })
    .drop()
    .maxConcurrency(1))
  pollCoverageTask;

  onInsert = modifier(
    () => {
      this.client.skipClaimValidations = false;
      this.client.set('skipBirthDatePresenceValidation', true);
      let queryParams = this.router.currentRoute.queryParams;

      if (queryParams.reviewOnly) {
        this.reviewOnly = true;
        this.step = 'review';
      }
    },
    { eager: false }
  );

  #createPhone() {
    if (!this.client.defaultPhone) {
      let newPhone = this.store.createRecord('phone', {
        thisType: 'Mobile',
        leaveTextMessage: true,
        leaveVoiceMessage: true,
        phoneable: this.client,
      });
      this.client.phones.addObject(newPhone);
    }
  }

  #createAddress() {
    if (!this.client.defaultAddress) {
      let newAddress = this.store.createRecord('office', { addressable: this.client });
      this.client.addresses.addObject(newAddress);
    }
  }

  setAndTrackReviewStep() {
    this.step = 'review';
    this.trackEvent('review');
  }

  trackError(step, errors) {
    this.mixpanel.track('client: client submission flow step error', {
      'step_name': step,
      'diagnosis_count': this.diagnosisCodeCount,
      'service_count': this.serviceCodeCount,
      'first_claim': !this.client.hasOonClaims,
      errors,
    });
  }

  trackCoverageStatusReturned() {
    this.mixpanel.track('user: out-of-network submit flow coverage report returned', {
      'payer_id': this.insuranceInfo.insurancePlan.payer,
      'claim_id': this.claim.id,
      'insurance_status': underscore(this.insuranceInfo.coverageStatus.type),
    });
  }

  handleCoverageStatusTimedOut() {
    this.showTimedOutBanner = true;
    this.isLoading = false;
    this.mixpanel.track('user: out-of-network submit flow coverage report timeout', {
      'payer_id': this.insuranceInfo.insurancePlan.payer,
      'claim_id': this.claim.id,
    });
  }

  #trackClientValidationErrors() {
    this.trackError(
      'client_info',
      formatValidationErrorsForMixpanel(this.client.allValidationErrors)
    );
  }

  coverageStatusNeedsPolling() {
    return (
      !this.insuranceInfo?.coverageStatus?.type ||
      this.insuranceInfo.coverageStatus.type === 'Loading'
    );
  }
}
