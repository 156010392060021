export function applyAnswers(record, answers, reverse = false) {
  let templateQuestions = JSON.parse(record.templateQuestions);
  templateQuestions = [
    ...(reverse ? [] : templateQuestions),
    ...answers.map(x => {
      let { id, questionType, question, intakeAnswers, isRequired: required } = x;
      return { id: parseInt(id), questionType, question, intakeAnswers, required };
    }),
    ...(reverse ? templateQuestions : []),
  ];
  let userAnswers = JSON.parse(record.userAnswers);
  userAnswers = [
    ...(reverse ? [] : userAnswers),
    ...answers.map(x => {
      let { id, questionType, freeTextAnswer, date, isSigned, answers } = x;
      return { questionId: parseInt(id), questionType, freeTextAnswer, date, isSigned, answers };
    }),
    ...(reverse ? userAnswers : []),
  ];
  record.update({
    templateQuestions: JSON.stringify(templateQuestions),
    userAnswers: JSON.stringify(userAnswers),
  });
}
