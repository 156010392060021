import { service } from '@ember/service';
import Super from './-resources';

export default class SiteBillingOutOfNetwork extends Super {
  @service session;

  queryParams = ['claimId', 'reviewOnly'];

  get client() {
    return this.model.client;
  }

  get claim() {
    return this.model.claim;
  }
}
