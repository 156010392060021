import { action } from '@ember/object';
import { assert } from '@ember/debug';
import { registerDestructor } from '@ember/destroyable';
import { task, timeout } from 'ember-concurrency';
import ENV from 'client-portal/config/environment';
import Modifier from 'ember-modifier';

const DEFAULT_DEBOUNCE_TIME = 500;

function cleanup(instance) {
  if (!instance.element) return;

  instance.element.removeEventListener(instance.eventName, instance.handleEvent);
  instance.debounce.cancelAll();
}

export default class DebounceCallOn extends Modifier {
  element = null;
  eventName = null;
  callback = null;
  time = null;

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, cleanup);
  }

  modify(element, [eventName, callback], { time }) {
    cleanup(this);

    this.element = element;
    this.eventName = eventName;
    this.callback = callback;
    this.time = time;

    assert('first positional argument "eventName" must be provided', eventName);
    assert('second positional argument "callback" must be provided', callback);
    this.element.addEventListener(this.eventName, this.handleEvent);
  }

  get debounceTime() {
    if (ENV.environment === 'test') {
      return 0;
    }

    return this.time || DEFAULT_DEBOUNCE_TIME;
  }

  @action
  handleEvent(evt) {
    this.debounce.perform(evt);
  }

  debounce = task({ restartable: true }, async evt => {
    await timeout(this.debounceTime);
    return this.callback(evt);
  });
}
