import { buildValidations, validator } from 'ember-cp-validations';
import Super from './treatable-client';
import classic from 'ember-classic-decorator';

const Validations = buildValidations({ clientRelationships: validator('has-many') });

@classic
export default class ClientCouple extends Super.extend(Validations) {
  isCouple = true;
}
