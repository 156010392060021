// recursively remove keys with a value of undefined, null, false, empty string, or NaN from an object
export function compactObject(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      compactObject(obj[key]);
    } else if (!obj[key] && obj[key] !== 0) {
      delete obj[key];
    }
  });
  return obj;
}
