/* import __COLOCATED_TEMPLATE__ from './collapsed-block.hbs'; */
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './collapsed-block.module.scss';

@classic
@classNames(styles.component)
export default class SiteBillingCollapsedBlock extends Component {
  isShown = false;
}
