import { isTest } from './is-testing';
import { timeout, waitForQueue } from 'ember-concurrency';

export async function waitForNext() {
  await timeout(1);
}

export async function waitForLater(delay = 0) {
  await timeout(delay);
}

export async function waitForRender() {
  await waitForQueue('afterRender');
}

export async function testAwareWaitForLater(delay = 0) {
  if (!isTest()) {
    await timeout(delay);
  }
}
