/* import __COLOCATED_TEMPLATE__ from './address.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import stateGroups from 'ember-simplepractice/utils/state-groups';
import styles from './address.module.scss';

const WORK = 'Work';
const HOME = 'Home';

export default class SiteBillingOutOfNetworkFormClientSectionAddress extends Component {
  @service store;

  styles = styles;
  stateGroups = stateGroups;
  phoneTypes = [WORK, HOME];

  get client() {
    return this.args.client;
  }

  get address() {
    return this.client.defaultAddress;
  }
}
