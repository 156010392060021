import { helper } from '@ember/component/helper';
import { importAsset } from 'client-portal/helpers/import-asset';

// explicit paths for fingerprinting
const srcMap = {
  0: '/assets/images/avatars/1.png',
  1: '/assets/images/avatars/2.png',
  2: '/assets/images/avatars/3.png',
  3: '/assets/images/avatars/4.png',
  4: '/assets/images/avatars/5.png',
  5: '/assets/images/avatars/6.png',
};

export default helper(function avatarCount([idx]) {
  return importAsset([srcMap[idx % Object.keys(srcMap).length]]);
});
