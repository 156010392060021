/* import __COLOCATED_TEMPLATE__ from './service-select.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './service-select.module.scss';

@classic
@classNames('service-select text-left justify-content-between', styles.component)
@classNameBindings('cptCodeId')
export default class RequestServiceSelect extends Component {
  @computed('model.id')
  get cptCodeId() {
    let id = this.get('model.id');
    return `cpt-code-${id}`;
  }
}
