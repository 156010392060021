import classic from 'ember-classic-decorator';
// NOTE: localStorage is used to have persistent `sessionId` in Safari
// Rails-based devise session is in `simplepractice-session` cookie which is passed through

import AdaptiveStore from 'ember-simple-auth/session-stores/adaptive';

@classic
export default class Application extends AdaptiveStore {
  localStorageKey = 'client-portal-session';
  _cookieName = 'client-portal-session';
  cookieExpirationTime = 24 * 60 * 60;
}
