export default function getCursiveClass(signatureLength) {
  switch (true) {
    case signatureLength > 46:
      return 'sm';
    case signatureLength > 30:
      return 'md';
    default:
      return 'lg';
  }
}
