/* import __COLOCATED_TEMPLATE__ from './date-range-picker.hbs'; */
import { action, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { dateFormat, isoDateFormat } from 'client-portal/utils/date-time';
import { not } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import styles from './date-range-picker.module.scss';

export function fullTimeRange(value) {
  let [start, end] = value.split(',').map(x => moment(x));
  // NOTE: current time is the maximum
  return `${start.startOf('day').toISOString()},${moment
    .min(moment(), end.endOf('day'))
    .toISOString()}`;
}

@classic
@classNames(styles.component)
export default class SharedDateRangePicker extends Component {
  @service media;
  inputId = 'date-range';
  inputName = 'date-range';
  hasAllTime = true;
  maxDate = 'today';
  isoFormat = isoDateFormat;
  visualFormat = dateFormat;

  @computed('value', 'intermediateValue')
  get parsedValue() {
    if (this.intermediateValue) {
      return this.intermediateValue;
    }

    if (!this.value) {
      return { start: null, end: null };
    }

    let [start, end] = this.value.split(',').map(x => moment(x));
    return { start, end };
  }

  @not('parsedValue.start') isAllTime;

  @computed('leftCenter')
  get rightCenter() {
    return this.leftCenter.clone().add(1, 'month');
  }

  @computed('maxDate')
  get maxDateValue() {
    return this.maxDate === 'today' ? moment() : this.maxDate;
  }

  didInsertElement() {
    super.didInsertElement(...arguments);

    let ranges = this.defaultRanges;
    if (!this.hasAllTime) {
      delete ranges['All Time'];
    }
    this.setProperties({ ranges });
  }

  @action
  setValue(toggle, value) {
    let { start, end } = value || {};
    if (value && (!start || !end)) {
      this.set('intermediateValue', { start, end });
      return;
    }

    this.onSetValue(value ? `${start.format(this.isoFormat)},${end.format(this.isoFormat)}` : null);
    this.set('intermediateValue', null);
    this.set('leftCenter', start);
    toggle();
  }

  get defaultRanges() {
    return {
      'All Time': null,
      'Last 30 days': {
        start: moment().subtract(29, 'days').startOf('day'),
        end: moment().startOf('day'),
      },
      'This Month': { start: moment().startOf('month'), end: moment().startOf('day') },
      'Last Month': {
        start: moment().subtract(1, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month').startOf('day'),
      },
      'This Year': { start: moment().startOf('year'), end: moment().startOf('day') },
      'Last Year': {
        start: moment().subtract(1, 'year').startOf('year'),
        end: moment().subtract(1, 'year').endOf('year').startOf('day'),
      },
    };
  }

  get leftCenter() {
    return this._leftCenter || this.get('parsedValue.start') || moment();
  }

  set leftCenter(value) {
    this._leftCenter = value;
  }
}
