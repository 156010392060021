/* import __COLOCATED_TEMPLATE__ from './client-attendance.hbs'; */
import { CONFIRMED_VIA_TEXT } from 'client-portal/models/unauthenticated-appointment';
import { action, computed } from '@ember/object';
import { callbackError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import { timeFormat, zoneFormat } from 'client-portal/utils/date-time';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './client-attendance.module.scss';

const SWAL_STATIC_CLASS = 'swal-pos-static';

@classic
@classNames(styles.component)
export default class ClientAttendance extends Component {
  @service currentPractice;
  @service clientTimezone;

  CONFIRMED_VIA_TEXT = CONFIRMED_VIA_TEXT;
  confirmedModalDismissed = !!(this.model && this.model.clientConfirmationStatus);
  timeFormat = timeFormat;
  zoneFormat = zoneFormat;

  @reads('currentPractice.isClientAllowedToConfirmAppt') canClientConfirm;

  @reads('currentPractice.isClientAllowedToCancelAppt') canClientCancel;

  @reads('model.office.isVideo') isVideo;

  @reads('model.errors') errors;

  @reads('clientTimezone.timezone') timezone;

  get bodyClass() {
    return `${this.componentCssClassName} ${SWAL_STATIC_CLASS}`;
  }

  @computed('canClientConfirm', 'model.{isConfirmed,isCancelled}')
  get showConfirmButton() {
    return this.canClientConfirm && !(this.model.isConfirmed || this.model.isCancelled);
  }

  @computed('canClientCancel', 'model.isCancelled')
  get showCancelButton() {
    return !this.model.isCancelled && this.canClientCancel;
  }

  @computed('error')
  get apptDeletedOrNotFound() {
    try {
      return callbackError(this.model, message => {
        return message.status === '404';
      });
    } catch {
      return false;
    }
  }

  get isModalShown() {
    return document.querySelectorAll('.swal2-show').length > 0;
  }

  didInsertElement() {
    super.didInsertElement();
    document.body.classList.add(...this.bodyClass.split(' '));
  }

  willDestroyElement() {
    super.willDestroyElement();
    document.body.classList.remove(...this.bodyClass.split(' '));
  }

  @action
  async confirmCancel(model) {
    try {
      if (model.isConfirmed) {
        this.set('isConfirming', true);
      }
      await this.persistModelTask.perform(model);

      if (model.isCancelled) {
        this.setModalStatus('hasCancelledModal', false);
      } else {
        this.setModalStatus('hasConfirmedModal', true);
        this.set('isConfirming', false);
      }
    } catch (err) {
      model.rollbackAttributes();
    }
  }

  @task(function* () {
    this.setModalStatus('hasConfirmedModal', false);
    yield waitForProperty(this, 'isModalShown', false);
    yield timeout(200);
    this.set('confirmedModalDismissed', true);
  })
  dismissConfirmTask;
}
