import { service } from '@ember/service';
import Super from './-resources';

export default class SiteBillingInsuranceClaims extends Super {
  @service currentPractice;
  @service router;

  beforeModel() {
    if (!this.currentPractice.get('hasOonClaimFiling')) {
      return this.router.transitionTo('site.billing');
    }
  }

  thisType = 'insurance-claim';
}
