import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import styles from './drop-zone.module.scss';

export default class SharedDropZone extends Component {
  @tracked styles = styles;
  @service fileQueue;

  get isFastBoot() {
    return typeof FastBoot !== 'undefined';
  }

  get inputId() {
    return this.args.inputId ? this.args.inputId : `file-input-${guidFor(this)}`;
  }

  @action
  flushUploadQueue() {
    let queue = this.fileQueue.find(this.args.queueName);

    if (queue?.files) {
      for (let file of queue.files) {
        queue.remove(file);
      }
    }

    this.args.remove?.();
  }
}
