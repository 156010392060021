import { action, computed } from '@ember/object';
import { error } from 'client-portal/utils/banners';
import { isoDateFormat } from 'client-portal/utils/date-time';
import { service } from '@ember/service';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

@classic
class TimeOfDay {
  constructor({ checked, disabled }) {
    this.checked = checked;
    this.disabled = disabled;
  }
}

@classic
class Filters {
  morning = new TimeOfDay({ checked: true, disabled: false });
  afternoon = new TimeOfDay({ checked: true, disabled: false });
  evening = new TimeOfDay({ checked: true, disabled: false });
}

@classic
export default class RequestDateController extends Controller {
  @service mixpanel;
  @service session;
  @service currentPractice;
  @service router;
  @service request;

  chosenSpot = null;
  activeFilter = 'morning';
  filters = new Filters();

  init() {
    super.init();

    this.router.on('routeDidChange', () => {
      this.checkAndResetChosenSpot();
    });
  }

  @action
  resetActiveFilter() {
    this.resetSpotClick();
    let activeFilter = this.activeFilter;
    if (
      !this.get(`filters.${activeFilter}.checked`) ||
      this.get(`filters.${activeFilter}.disabled`)
    ) {
      let availableFilter = Object.keys(this.filters).find(
        x => this.get(`filters.${x}.checked`) && !this.get(`filters.${x}.disabled`)
      );
      this.set('activeFilter', availableFilter);
    }
  }

  @action
  mixpanelTrack(message) {
    this.mixpanel.track(message);
  }

  @action
  handleSpotClick(spot) {
    if (this.chosenSpot === spot) {
      this.set('chosenSpot', null);
    } else {
      this.set('chosenSpot', spot);
    }
  }

  resetSpotClick() {
    let { filters } = this;
    let noFiltersChecked =
      !filters.morning.checked && !filters.afternoon.checked && !filters.evening.checked;

    if (noFiltersChecked) {
      this.set('chosenSpot', null);
      return;
    }

    if (this.chosenSpot && this.shouldResetSpot) {
      this.set('chosenSpot', null);
    }
  }

  @computed('filters', 'selectedSlot', 'chosenSpot')
  get shouldResetSpot() {
    let timeSlotProperties = ['morning', 'afternoon', 'evening'];

    return timeSlotProperties.some(timeSlot => {
      let spots = this.selectedSlot?.[`${timeSlot}Spots`];

      return (
        !this.filters[timeSlot].checked &&
        spots?.some(spot => moment(spot.start).isSame(this.chosenSpot.start))
      );
    });
  }

  async checkAndResetChosenSpot() {
    if (this.router.currentRouteName !== 'site.request.date') return;

    let selectedSlotQueryParam = this.router.currentRoute.queryParams.selectedSlot;
    if (!selectedSlotQueryParam) {
      this.set('chosenSpot', null);
      return;
    }

    let spotTime = moment(selectedSlotQueryParam);
    let slot = this.model?.find(({ id }) => id === spotTime.format(isoDateFormat));
    let spot = slot ? this.findSpot(slot, spotTime, this.reservation) : null;

    if (spot) {
      this.send('setSelectedDate', spot.start);
      this.set('chosenSpot', spot);
    } else {
      await waitForRender();
      this.showUnavailableBanner();
      this.set('chosenSpot', null);
    }
  }

  showUnavailableBanner() {
    error({ title: 'The time you have chosen is no longer available.' });
  }
}
