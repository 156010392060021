/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { ensureSafeComponent } from '@embroider/util';
import { importSync } from '@embroider/macros';
import Component from '@glimmer/component';

export default class SiteBillingOutOfNetworkFormAppointmentSectionRow extends Component {
  get isServiceOrDiagnosisCodeComponent() {
    return this.args.row.label.match(/(Service|Diagnosis)/);
  }

  get cellComponentModule() {
    switch (this.args.row.cellComponent) {
      case 'site/billing/out-of-network/form/appointment-section/service-code':
        return importSync(`./service-code`);
      case 'site/billing/out-of-network/form/appointment-section/diagnosis-code':
        return importSync(`./diagnosis-code`);
      default:
        return null;
    }
  }

  get cellComponent() {
    let module = this.cellComponentModule;
    return ensureSafeComponent(module?.default, this);
  }

  get value() {
    return this.args.row.value;
  }

  get expanded() {
    return this.args.row.expanded;
  }
}
