export const STATIC_MAP_API_URL = 'https://maps.googleapis.com/maps/api/staticmap';

export function buildStaticMapUrl({ key, markerColor, markerLocation, markerUrl, size, zoom }) {
  let markers = markerUrl
    ? `anchor:center|icon:${markerUrl}|${markerLocation}`
    : `size:mid|color:0x${markerColor}|${markerLocation}`;
  size = encodeSize(size);

  return _buildStaticUrl({
    key,
    markers,
    size,
    zoom,
  });
}

export function buildStaticMapWithCircleUrl({ key, lat, lng, markerColor, markerUrl, size, zoom }) {
  let center = `${lat},${lng}`;
  let circlePolyline = encodeURIComponent(buildCirclePolyline(lat, lng));
  let markers = markerUrl
    ? `anchor:center|icon:${markerUrl}|${lat},${lng}`
    : `size:mid|color:0x${markerColor}|${lat},${lng}`;
  let path = `color:0xBBBBBB|fillcolor:0x00000010|weight:1|${circlePolyline}`;
  size = encodeSize(size);

  return _buildStaticUrl({
    center,
    key,
    markers,
    path,
    size,
    zoom,
  });
}

function encodeSize(size) {
  return encodeURIComponent(`${size?.width}x${size?.height}`);
}

function _buildStaticUrl(params) {
  let url = `${STATIC_MAP_API_URL}?style=feature:poi|visibility:off`;
  for (let [key, value] of Object.entries(params)) {
    url += `&${key}=${value}`;
  }
  return url;
}

// NOTE: Polyline math based on: https://stackoverflow.com/a/35660617/2872622
function buildCirclePolyline(lat, lng) {
  let radiusInMeters = 2414; // ~1.5 miles
  let circleDetail = 8;
  let precision = 4;
  lat = (lat * Math.PI) / 180;
  lng = (lng * Math.PI) / 180;

  let coordinatesSet = [];
  for (let degree = 0; degree <= 360; degree += circleDetail) {
    let { circlePointLat, circlePointLng } = calculateCirclePoint(lat, lng, radiusInMeters, degree);
    coordinatesSet.push([circlePointLat.toFixed(precision), circlePointLng.toFixed(precision)]);
  }
  return coordinatesSet.map(coords => `${coords[0]},${coords[1]}`).join('|');
}

function calculateCirclePoint(lat, lng, radius, degree) {
  let globalRadius = 6371;
  let { PI: pi, sin, cos, asin, atan2 } = Math;
  let d = radius / 1000 / globalRadius;

  let brng = (degree * pi) / 180;
  let circlePointLat = asin(sin(lat) * cos(d) + cos(lat) * sin(d) * cos(brng));
  let circlePointLng =
    ((lng + atan2(sin(brng) * sin(d) * cos(lat), cos(d) - sin(lat) * sin(circlePointLat))) * 180) /
    pi;
  circlePointLat = (circlePointLat * 180) / pi;
  return { circlePointLat, circlePointLng };
}
