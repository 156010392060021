/* import __COLOCATED_TEMPLATE__ from './content-map.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './content-map.module.scss';

@classic
@classNames('location-content-map', styles.component)
export default class RequestLocationContentMap extends Component {
  @computed('size')
  get imageSize() {
    let mapSizeProps = this.size;
    let style = Object.keys(mapSizeProps)
      .map(x => `${x}: ${mapSizeProps[x]}px`)
      .join('; ');
    return htmlSafe(style);
  }
}
