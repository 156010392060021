import { ERROR_EXPIRED, ERROR_INVALID } from 'client-portal/services/sign-in';
import { SIGNIN_PERSISTENCE_KEY } from 'client-portal/utils/feedback-banner-events';
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';
import pht from 'client-portal/utils/persistent-hash-table';

@classic
export default class SignInTokenVerifyRoute extends Route.extend(FastbootHelpersMixin) {
  @service router;
  @service request;
  @service currentPractice;
  @service store;
  @service session;
  @service signIn;

  beforeModel() {
    if (this.fastboot.isFastBoot) {
      return;
    }
    if (this.isVerified) {
      set(this, 'isVerified', false);
      return this.router.transitionTo('site.home');
    }
  }

  model() {
    if (this.fastboot.isFastBoot) {
      return;
    }

    return this.store.createRecord('session', {
      token: location.hash.substring(1),
      type: 'token',
    });
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    controller.set(
      'isInMobileApp',
      this.currentPractice.get('canUseNativeDocRendering') ||
        this.currentPractice.get('hasClientappDocumentAccess')
    );

    if (this.fastboot.isFastBoot) {
      return;
    }

    let success = await this.signIn.verifyTokenTask.perform(model);
    this.signIn.trackAttempt();
    if (success) {
      this.#handleSearchParams();
      set(this, 'isVerified', true);
      this.send('refreshEnvironment');
      this.signIn.storedEmail = model.email;
      this.#persistenceKeyHandler();
    } else if ([ERROR_EXPIRED, ERROR_INVALID].includes(this.signIn.lastError)) {
      let redirect = new URLSearchParams(location.search).get('redirect');
      this.router.transitionTo('sign-in.token.request', {
        queryParams: { hasInvalidToken: '', redirect },
      });
    }
  }

  @action
  signInWithEmail(email) {
    this.signIn.email = email;
    this.router.transitionTo('sign-in.token.request');
  }

  #persistenceKeyHandler() {
    let signinCount = pht.get(SIGNIN_PERSISTENCE_KEY);

    pht.set(SIGNIN_PERSISTENCE_KEY, signinCount ? parseInt(signinCount) + 1 : 1);
  }

  #handleSearchParams() {
    let potentialSearchParams = ['messagingAnchor', 'openAnnouncements'];
    let searchParams = new URLSearchParams(location.search);
    potentialSearchParams.forEach(key => {
      this.#addParamToSession(searchParams, key);
    });
  }

  #addParamToSession(searchParams, key) {
    try {
      let value = searchParams.get(key);
      this.session.setProperties({ [key]: value });
    } catch (_err) {
      // noop
    }
  }
}
