import { attr, belongsTo, hasMany } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import Super from './client';
import moment from 'moment-timezone';

const Validations = buildValidations({
  email: null,
  phoneNumber: null,
  firstName: validator('presence', { presence: true, message: 'First name is required' }),
  lastName: validator('presence', { presence: true, message: 'Last name is required' }),
  birthDate: [
    validator('presence', {
      presence: true,
      message: 'Date of birth is required',
    }),
    validator('inline', {
      validate(value) {
        if (!value || value.isBefore(moment())) return true;

        return 'Please enter a valid date of birth.';
      },
    }),
  ],
  state: [validator('presence', { presence: true, message: 'State is required' })],
  recaptchaResponse: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Complete the reCAPTCHA.',
    }),
  ],
});

export default class ProspectiveClient extends Super.extend(Validations) {
  @hasMany('insurance-claim', { async: false, inverse: 'client' }) insuranceClaims;
  @hasMany('insurance-info', { async: false, inverse: 'client' }) insuranceInfos;
  @hasMany('email', { async: false, inverse: null }) emails;
  @hasMany('phone', { async: false, inverse: null }) phones;

  @belongsTo('clinician', { async: false, inverse: null }) clinician;
  @attr('string', { defaultValue: 'prospective' }) status;
  @attr('string') clientMessage;
  @attr('string') genderInfo;
  @attr('string') state;
  @attr('string', { defaultValue: 'Client' }) thisType;
  @attr('string') recaptchaResponse;
}
