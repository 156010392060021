/* import __COLOCATED_TEMPLATE__ from './trigger.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';

export default class ClientAppBannerTrigger extends Component {
  @service clientAppBanner;

  @action
  displayClientAppBanner() {
    this.clientAppBanner.display({
      location: this.location,
      documentId: this.documentId,
    });
  }

  @action
  hideClientAppBanner() {
    this.clientAppBanner.hide();
  }
}
