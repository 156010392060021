/* import __COLOCATED_TEMPLATE__ from './pattern-table.hbs'; */
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './pattern-table.module.scss';

@classic
@tagName('table')
@classNames(styles.component)
export default class SharedPatternTable extends Component {}
