/* import __COLOCATED_TEMPLATE__ from './announcements.hbs'; */
import { action } from '@ember/object';
import { gt, reads } from 'macro-decorators';
import { humanDateFormat, timeUppercaseFormat } from 'client-portal/utils/date-time';
import { modifier } from 'ember-modifier';
import { momentFormat } from 'client-portal/helpers/moment-format';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { waitForNext } from 'ember-simplepractice/utils/waiters';
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import styles from './announcements.module.scss';

const RECENT_DATE_FORMAT = 'ddd, MMM DD';

class TruncatableAnnouncement {
  @tracked isTruncated;

  attrs;
  isTruncatable = false;
  truncatedMessage;

  get isUnread() {
    return this.attrs.readAt === null;
  }

  constructor(announcement) {
    this.attrs = announcement;
    if (announcement.message.length > 500) {
      this.truncatedMessage = announcement.message.substring(0, 500);
      this.isTruncatable = true;
      this.isTruncated = true;
    }
  }

  @action
  toggle() {
    this.isTruncated = !this.isTruncated;
  }
}

export default class SiteHeaderAnnouncements extends Component {
  @service announcement;
  @service clientTimezone;
  @service session;

  @tracked initiallyOpened = false;

  styles = styles;
  timeFormat = timeUppercaseFormat;

  formatDate = date => {
    let mDate = moment(date);
    let now = new Date();
    let yesterday = new Date().setDate(new Date().getDate() - 1);

    let isToday = mDate.isSame(now, 'day');
    let isYesterday = mDate.isSame(yesterday, 'day');
    let isThisYear = mDate.isSame(now, 'year');

    if (isToday) return 'Today';
    if (isYesterday) return 'Yesterday';
    if (isThisYear) return momentFormat([date, RECENT_DATE_FORMAT, this.timeZone]);
    return momentFormat([date, humanDateFormat, this.timeZone]);
  };

  @gt('unreadCount', 0) hasUnreadAnnouncements;
  @reads('clientTimezone.timezone') timeZone;

  get unreadCount() {
    return this.announcement.announcementsInfinityModel.reduce((sum, announcement) => {
      return sum + (announcement.readAt === null ? 1 : 0);
    }, 0);
  }

  get truncatableAnnouncementsByDate() {
    let announcementsByDate = new Map();
    this.announcement.announcementsInfinityModel.forEach(announcement => {
      let dateKey = new Date(announcement.createdAt).toDateString();
      if (!announcementsByDate.has(dateKey)) {
        announcementsByDate.set(dateKey, [new TruncatableAnnouncement(announcement)]);
      } else {
        announcementsByDate.get(dateKey).push(new TruncatableAnnouncement(announcement));
      }
    });
    return announcementsByDate;
  }

  constructor() {
    super(...arguments);
    this.initiallyOpened = !!this.session.openAnnouncements;
    this.loadAnnouncements();
  }

  async loadAnnouncements() {
    await waitForNext();
    this.announcement.fetchAnnouncements();
  }

  scrollOldestUnreadIntoView() {
    let unread = document.querySelectorAll('[data-unread="true"]');
    if (unread.length) {
      let oldestUnread = unread[unread.length - 1];
      let scrollContainer = oldestUnread.parentElement;
      scrollContainer.scroll(0, oldestUnread.offsetTop - scrollContainer.offsetTop);
    }
  }

  onInsert = modifier(
    () => {
      this.scrollOldestUnreadIntoView();
      this.announcement.markAnnouncementsAsRead();
    },
    { eager: false }
  );
}
