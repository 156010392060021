/* import __COLOCATED_TEMPLATE__ from './address-section.hbs'; */
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SiteDocumentsContactInfoAddressSection extends Component {
  @service currentPractice;
  @service mixpanel;
  @service store;

  @reads('currentPractice.featureAddressAutocompleteSimplified')
  featureAddressAutocompleteSimplified;

  @(task(function* (query) {
    if (query) {
      return yield this.store.query('address-suggestion', {
        filter: {
          query,
        },
      });
    }
  }).restartable())
  addressSuggestionsTask;

  @(task(function* (place) {
    let places = yield this.store.query('address-place', {
      filter: {
        place,
      },
    });
    return places.slice();
  }).restartable())
  addressPlacesTask;

  @action
  setAttr(name, value) {
    this.connection.set(name, value);
  }

  @action
  handleAddressSelect(place, text) {
    let { street, city, state, zip } = place;
    this.setAttr(this.streetAttr, street);
    this.setAttr(this.cityAttr, city);
    this.setAttr(this.stateAttr, state);
    this.setAttr(this.zipAttr, zip);
    this.mixpanel.track('client: address autocomplete', {
      select: text,
    });
  }

  @action
  handleStreetSelect(street) {
    this.setAttr(this.streetAttr, street);
  }

  @action
  handleSearched(query) {
    this.mixpanel.track('client: address autocomplete', {
      lookup: query,
    });
  }
}
