/* import __COLOCATED_TEMPLATE__ from './insurances.hbs'; */
import {
  globalMarketingInsuranceProviders,
  maxMarketingInsuranceCount,
} from 'client-portal/models/practice-website/marketing-insurance-provider';
import { mapBy, setDiff } from '@ember/object/computed';
import { set } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeWebsiteEditMenuInsurances extends Component {
  maxCount = maxMarketingInsuranceCount;

  @mapBy('model.marketingInsuranceProviders', 'name') marketingInsuranceProvidersNames;
  @setDiff('globalMarketingInsuranceProviders', 'marketingInsuranceProvidersNames') globalList;

  init() {
    super.init(...arguments);
    set(
      this,
      'globalMarketingInsuranceProviders',
      [...globalMarketingInsuranceProviders].sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      )
    );
  }
}
