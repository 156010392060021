/* import __COLOCATED_TEMPLATE__ from './stripe-payment-element.hbs'; */
import { action } from '@ember/object';
import {
  buildStripePaymentElement,
  buildStripeSetupModeElement,
} from 'client-portal/utils/stripe-payment-methods';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class SiteBillingStripePaymentElement extends Component {
  @service stripe;

  @tracked isPaymentElementLoaded = false;

  @action
  attachPaymentElement(_element) {
    this.elements = buildStripeSetupModeElement(this.stripe.stripe);
    this.paymentElement = buildStripePaymentElement(this.elements, () => {
      this.isPaymentElementLoaded = true;
    });

    this.paymentElement.mount('#payment-element');
  }

  @action
  async persistStripePaymentMethod() {
    let { model } = this.args;

    model.set('_isDirty', true);

    await model.validate();

    let { error } = await this.elements.submit();

    if (error || !model.name) {
      return;
    }

    this.args.addStripePaymentMethod(this.elements);
  }
}
