/* import __COLOCATED_TEMPLATE__ from './phone.hbs'; */
import { formatPhoneNumber } from 'client-portal/utils/format-phone-number';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './phone.module.scss';

export default class SiteBillingOutOfNetworkFormClientSectionPhone extends Component {
  @service store;

  styles = styles;
  phoneTypes = ['Mobile', 'Work', 'Home', 'Fax'];

  get client() {
    return this.args.client;
  }

  get phone() {
    return this.client.defaultPhone;
  }

  get phoneNumber() {
    return formatPhoneNumber(this.phone?.number);
  }

  get areaCode() {
    return this.phoneNumber?.substr(0, 3);
  }

  get firstThree() {
    return this.phoneNumber?.substr(3, 3);
  }

  get lastFour() {
    return this.phoneNumber?.substr(6, 4);
  }

  get formattedPhoneNumber() {
    return `(${this.areaCode}) ${this.firstThree}-${this.lastFour}`;
  }
}
