import { attr, hasMany } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import Super, { BIRTH_DATE_VALIDATOR } from './base-client';
import classic from 'ember-classic-decorator';

const Validations = buildValidations({
  firstName: validator('presence', { presence: true, ignoreBlank: true }),
  lastName: validator('presence', { presence: true, ignoreBlank: true }),
  emails: validator('has-many'),
  phones: validator('has-many'),
  birthDate: BIRTH_DATE_VALIDATOR,
});

@classic
export default class ClientContact extends Super.extend(Validations) {
  @hasMany({ async: false }) emails;
  @hasMany({ async: false }) phones;
  @attr() firstName;
  @attr() lastName;
}
