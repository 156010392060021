/* import __COLOCATED_TEMPLATE__ from './office-directions.hbs'; */
import { computed } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@tagName('')
export default class SharedOfficeDirections extends Component {
  @computed('model.geocodingAddress')
  get googleMapsUrl() {
    return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      this.get('model.geocodingAddress')
    )}`;
  }
}
