/* import __COLOCATED_TEMPLATE__ from './insurance-section.hbs'; */
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import ENV from 'client-portal/config/environment';
import SiteBillingOutOfNetworkFormEditReviewEditableSection from './edit/review-editable-section';
import styles from './insurance-section.module.scss';

export default class SiteBillingOutOfNetworkFormInsuranceSection extends SiteBillingOutOfNetworkFormEditReviewEditableSection {
  @service store;

  styles = styles;
  insuredRelations = {
    0: 'Self',
    1: 'Spouse',
    2: 'Parent',
    3: 'Other',
  };
  sexOptions = ['Female', 'Male', null];
  sectionName = 'insurance';

  get client() {
    return this.args.client;
  }

  get insuranceCards() {
    return this.insuranceInfo?.validInsuranceCards || [];
  }

  get insuranceInfo() {
    return this.client.primaryInsuranceInfo;
  }

  get insuredRelationText() {
    return this.insuredRelations[this.insuranceInfo.insuredRelation];
  }

  get showInsuranceCardDropZones() {
    return !(this.shouldHideInputs && this.insuranceCards.length === 0);
  }

  get showInsuranceCardBack() {
    return this.shouldHideInputs && this.insuranceCards.length === 1
      ? false
      : this.insuranceCards.length >= 1;
  }

  get insuranceCardFront() {
    return this.insuranceCards.slice().at(0);
  }

  get insuranceCardBack() {
    return this.insuranceCards.slice().at(1);
  }

  @(task(function* (search) {
    return this.store.query('insurance-provider-name', {
      filter: {
        search,
      },
      page: {
        size: ENV.maxPageSize,
      },
    });
  }).restartable())
  searchInsuranceProviderNamesTask;

  onInsert = modifier(
    () => {
      this.args.onTrack('insurance_info');
      if (!this.insuranceInfo) {
        this._createInsuranceInfo();
      }
    },
    { eager: false }
  );

  @action
  async saveEdit() {
    await this.args.form.saveInsuranceInfo();
    this.handleSaveEdit(this.insuranceInfo);
  }

  _createInsuranceInfo() {
    this.store.createRecord('insurance-info', {
      thisType: 'primary',
      insuredRelation: '0',
      client: this.client,
    });
  }
}
