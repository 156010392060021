import { BIRTH_DATE_VALIDATOR } from './base-client';
import { buildValidations, validator } from 'ember-cp-validations';
import { or, reads } from '@ember/object/computed';
import AddressableMixin from 'client-portal/mixins/polymorphic/addressable';
import PhoneableMixin from 'client-portal/mixins/polymorphic/phoneable';
import Super from './treatable-client';
import classic from 'ember-classic-decorator';

const Validations = buildValidations({
  firstName: validator('presence', { presence: true, ignoreBlank: true }),
  lastName: validator('presence', { presence: true, ignoreBlank: true }),
  email: [
    validator('presence', {
      presence: true,
      disabled: reads('model.hasOneClientRelationship'),
      ignoreBlank: true,
    }),
    validator('format', {
      type: 'email',
      allowBlank: true,
    }),
  ],
  phoneNumber: [
    validator('presence', {
      presence: true,
      disabled: or('model.hasOneClientRelationship', 'model.claimValidationsEnabled'),
    }),
    validator('length', {
      allowBlank: true,
      is: 10,
      disabled: reads('model.claimValidationsEnabled'),
    }),
  ],
  clientRelationships: validator('has-many'),
  birthDate: BIRTH_DATE_VALIDATOR,
});

@classic
export default class Client extends Super.extend(Validations, PhoneableMixin, AddressableMixin) {
  static fieldsFor(context) {
    let fields = this.superclass.fieldsFor(context);

    switch (context) {
      case 'environment':
        fields.addObjects(['channelCoveredSessionsCount', 'channelCoveredSessionsCountRemaining']);
        break;
    }

    return fields;
  }
}
