import { STEP_IDS } from 'client-portal/services/widget-steps';
import { action } from '@ember/object';
import { service } from '@ember/service';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';

@classic
export default class RequestCancelled extends RequestBaseRoute {
  @service analytics;
  @service session;
  @service mixpanel;
  @service request;

  templateName = '-request/cancelled';
  stepId = STEP_IDS.REQUEST_CANCELLED;

  @action
  firstStep() {
    this.request.transitionTo(this);
  }
}
