/* import __COLOCATED_TEMPLATE__ from './stripe-payment-element.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import styles from './stripe-payment-element.module.scss';

export default class RequestInformationStripePaymentElement extends Component {
  styles = styles;

  @action
  attachPaymentElement(_element) {
    if (!this.args.paymentElement) {
      return;
    }
    this.args.paymentElement.mount('#payment-element');
  }
}
