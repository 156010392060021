import { reads } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class HeadDataService extends Service {
  @service styleVariables;

  @reads('styleVariables.globalTheme.fonts.source') themeFontSource;
}
