/* eslint-disable no-var, object-shorthand, ember-cleanup/no-settimeout */

function setupBanners() {
  var _isTest = typeof QUnit === 'object';
  var _transientTimeout = _isTest ? 2000 : 5000;
  var _dismissTimeout = _isTest ? 0 : 900;
  var _banners = [];

  // Available params options:
  // - title: string (required)
  // - content: DOM node - appended next to a title
  // - container: DOM node - where to render the banner
  // - isSticky: bool - use fixed positioning
  // - isTransient: bool - dismiss after timeout
  // - isPermanent: bool - don't allow to dismiss
  // - isToast: bool
  // - ctaText: string
  // - ctaAction: fn - passed from component
  function _banner(type, params) {
    if (params.isSticky) {
      dismissSticky(true, function () {
        _create(type, params);
      });
    } else {
      return _create(type, params);
    }
  }

  function _create(type, params) {
    var node = document.createElement('div');
    var record = {
      node: node,
      params: params,
    };
    var bannerType = params.isToast ? 'toast' : 'banner';
    node.className = bannerType + ' pre-entry banner-' + type;

    if (params.classNames) {
      node.className += ' ' + params.classNames;
    }

    if (type !== 'info' && !params.noIcon) {
      node.className += ' has-icon';
    }

    if (params.isSticky) {
      node.className += ' is-sticky';
    }

    node.setAttribute('role', 'alert');

    var title = document.createElement('strong');
    title.innerText = params.title;
    node.appendChild(title);

    if (params.content) {
      node.appendChild(params.content);
    }

    if (params.ctaText) {
      node.className += ' has-cta';
      var cta = document.createElement('span');
      if (typeof params.ctaAction === 'function') {
        cta.setAttribute('role', 'link');
        cta.addEventListener('click', params.ctaAction);
      }
      cta.className = 'cta';
      cta.innerText = params.ctaText;
      node.appendChild(cta);
    }

    if (params.isToast || (!params.isTransient && !params.isPermanent)) {
      var dismissButton = document.createElement('button');
      dismissButton.className = 'dismiss';
      dismissButton.innerHTML = '<i class="fa fa-times"></i>';
      dismissButton.onclick = function () {
        dismiss(record);
      };
      node.className += ' has-dismiss';
      node.appendChild(dismissButton);
    }

    var container =
      params.container || document.querySelector('.' + bannerType + 's-container .content');
    container.appendChild(node);

    if (params.isTransient) {
      record.timeout = setTimeout(function () {
        dismiss(record);
      }, _transientTimeout);
    }

    _banners.push(record);

    setTimeout(function () {
      node.classList.remove('pre-entry');
    });
    return record;
  }

  function _dismiss(record, callback) {
    var node = record.node;

    if (node.parentElement) {
      var idx = _banners.indexOf(record);
      _banners.splice(idx, 1);
      node.parentElement.removeChild(node);
    }

    if (record.params.onDismiss) {
      record.params.onDismiss(record);
    }

    try {
      document.dispatchEvent(new Event('banners:dismiss'));
    } catch (err) {
      // IE11
    }

    if (callback) {
      callback();
    }
  }

  function dismiss(record, callback) {
    if (record.params.isSticky || record.params.isToast) {
      record.node.className += ' fade-out';

      setTimeout(function () {
        _dismiss(record, callback);
      }, _dismissTimeout);
    } else {
      _dismiss(record, callback);
    }
  }

  function dismissSticky(includeTransient, callback) {
    var existing;
    _banners.forEach(function (x) {
      if (!includeTransient && x.params.isTransient) {
        return;
      }

      if (x.params.isSticky) {
        existing = x;
      }
    });

    if (existing) {
      clearTimeout(existing.timeout);
      dismiss(existing, callback);
    } else if (callback) {
      callback();
    }
  }

  function success(params) {
    if (params.isSticky === undefined) {
      params.isSticky = true;
    }

    if (params.isTransient === undefined) {
      params.isTransient = true;
    }

    return _banner('success', params);
  }

  function warning(params) {
    if (params.isSticky === undefined) {
      params.isSticky = true;
    }

    if (params.isTransient === undefined) {
      params.isTransient = true;
    }

    return _banner('warning', params);
  }

  function error(params) {
    if (params.isSticky === undefined) {
      params.isSticky = true;
    }

    return _banner('error', params);
  }

  function info(params) {
    return _banner('info', params);
  }

  return {
    success: success,
    warning: warning,
    error: error,
    info: info,
    dismiss: dismiss,
    dismissSticky: dismissSticky,
  };
}

export default setupBanners;
