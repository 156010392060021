import { action } from '@ember/object';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { later, schedule } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import Component from '@ember/component';
import FlyoutMixin from '../../mixins/component/flyout';
import IsAnimatedMixin from '../../mixins/component/is-animated';
import Swal from 'sweetalert2';
import classic from 'ember-classic-decorator';
import styles from './side-flyout.module.scss';

const ANIMATION_DEBOUNCE_TIME = 300;

@classic
@classNames('side-flyout', styles.component)
@classNameBindings('isClosing:hide:show', 'isInsideFullFlyout')
export default class SharedSideFlyout extends Component.extend(FlyoutMixin, IsAnimatedMixin) {
  @tracked isClosing = false;
  @tracked isInsideFullFlyout = false;

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.switchOutletClasses({ remove: 'forward', add: 'backward' });
    this.checkFullFlyoutParent();
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.switchOutletClasses({ remove: 'backward', add: 'forward' });
  }

  // Outlet classes can't be manipulated declaratively so we're doing this manually
  switchOutletClasses({ remove, add }) {
    let outlet = this.element.closest('.transition-side-flyout');

    if (outlet) {
      schedule('afterRender', () => {
        outlet.classList.remove(remove);
        outlet.classList.add(add);
      });
    }
  }

  checkFullFlyoutParent() {
    if (this.element.closest('.full-flyout')) {
      this.isInsideFullFlyout = true;
    }
  }

  keyUpListener(evt) {
    if (evt.keyCode === 27 && !Swal.isVisible()) {
      this.animateCloseOnEscape ? this.closeFlyout() : this.close?.();
    }
  }

  @action
  async closeFlyout() {
    this.isClosing = true;

    let isCloseAllowed = await this.close?.();
    if (isCloseAllowed === false) {
      return (this.isClosing = false);
    }
    later(() => this.onHide?.(), this.isAnimated ? ANIMATION_DEBOUNCE_TIME : 0);
  }
}
