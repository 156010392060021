import BaseInsuranceCardAdapter from './base-insurance-card-adapter';

export default class InsuranceCard extends BaseInsuranceCardAdapter {
  getData(record) {
    return {
      contactInfoRequestConnectionsId: record.belongsTo('connection')?.id,
      insuranceInfoId: record.belongsTo('insuranceInfo')?.id,
    };
  }
}
