/* import __COLOCATED_TEMPLATE__ from './signature-modal.hbs'; */
import { action, computed } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { buildValidations, validator } from 'ember-cp-validations';
import { or, reads } from '@ember/object/computed';
import { relationshipLabel, relationshipTypes } from 'client-portal/utils/relationship-types';
import { service } from '@ember/service';
import { success } from 'client-portal/utils/banners';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './signature-modal.module.scss';

const Validations = buildValidations({
  name: [
    validator('presence', { presence: true, ignoreBlank: true }),
    validator('length', { allowBlank: true, max: 100 }),
  ],
  clientRelationshipType: validator('presence', { presence: true, ignoreBlank: true }),
});

@classic
export default class SiteDocumentsSharedSignatureModal extends Component.extend(Validations) {
  @service session;
  @service store;

  styles = styles;
  relationshipOptions = relationshipTypes;

  init() {
    super.init(...arguments);
    this.set('newSignature', !this.session.signatoryName);
  }

  @or('isSelf', 'isCouple') isClient;
  @reads('session.currentClient.isCouple') isCouple;

  @computed('session.currentClient.id', 'session.currentClientAccess.client.id')
  get isSelf() {
    return this.session.currentClient.id === this.session.currentClientAccess.client.id;
  }

  @action
  async saveSignatureAndSign() {
    this.validate();
    this.set('isDirty', true);

    if (!this.validations.isValid) {
      return;
    }

    try {
      let { client } = this.session.currentClientAccess;

      client.updateSignature(this.name, this.session.clientRelationshipType);

      await client.save({
        adapterOptions: {
          serializeAttributes: ['relationshipToCurrentClient', 'signatoryName'],
        },
      });

      this.sign();

      if (this.newSignature) {
        success({ title: 'Signature Added Successfully.' });
      }

      this.onClose();
    } catch (err) {
      bannerError(err);
    }
  }

  get clientRelationshipLabel() {
    return relationshipLabel('client');
  }
}
