import { action, computed, get, set, setProperties } from '@ember/object';
import { and, filterBy, not, notEmpty, or, reads } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { pluralize } from 'ember-inflector';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class HomeController extends Controller {
  @service currentPractice;
  @service analytics;
  @service media;
  @service mixpanel;
  showContactModal = false;

  // no editing unless tablet or larger screen size
  @reads('media.tabletUp') allowEditing;

  @not('preview') isNotPreview;
  @and('edit', 'isNotPreview') isEditMode;

  @reads('model.practiceWebsite') practiceWebsite;
  @reads('practiceWebsite.hasHighlightIcons') hasHighlightIcons;
  @reads('practiceWebsite.hasSpecialtiesSectionContent') hasSpecialtiesSectionContent;
  @reads('practiceWebsite.marketingTestimonials') marketingTestimonials;

  @reads('currentPractice.clientPortalEnabled') clientPortalEnabled;
  @reads('currentPractice.requestAsNewClient') requestAsNewClient;
  @notEmpty('practiceWebsiteOffices') hasPracticeWebsiteOffices;
  @and('practiceWebsite.showRequestAppointmentLink', 'requestAsNewClient') hasNewClientRequestLink;
  @or('hasNewClientRequestLink', 'showLoginLink') hasCTAButtons;
  @filterBy('practiceWebsiteOffices', 'isVideo', false) practiceWebsitePhysicalOffices;
  @or('isEditMode', 'hasHighlightIcons') showSectionHighlights;

  @computed('model.offices', 'practiceWebsite.selectedOfficeIds')
  get practiceWebsiteOffices() {
    let { hasSelectedOfficeIds, selectedOfficeIds } = this.practiceWebsite || {};
    if (!hasSelectedOfficeIds) {
      return [];
    }
    return this.model.offices.filter(office => {
      return selectedOfficeIds.includes(office.id);
    });
  }

  @computed('practiceWebsitePhysicalOffices.length')
  get locationsSectionTitle() {
    return pluralize(this.practiceWebsitePhysicalOffices.length, 'Location', {
      withoutCount: true,
    });
  }

  @computed('isEditMode', 'practiceWebsite.{hasSpecialtiesSectionContent,showSectionSpecialties}')
  get showSectionSpecialties() {
    let { showSectionSpecialties, hasSpecialtiesSectionContent } = this.practiceWebsite || {};
    if (this.isEditMode) {
      return showSectionSpecialties;
    } else {
      return showSectionSpecialties && hasSpecialtiesSectionContent;
    }
  }

  @computed('isEditMode', 'practiceWebsite.{showSectionTestimonials,marketingTestimonials.[]}')
  get showSectionTestimonials() {
    let { showSectionTestimonials, hasMarketingTestimonials } = this.practiceWebsite || {};
    if (this.isEditMode) {
      return showSectionTestimonials;
    } else {
      return showSectionTestimonials && hasMarketingTestimonials;
    }
  }

  @computed('isEditMode', 'practiceWebsite.showSectionLocations', 'practiceWebsiteOffices')
  get showSectionLocations() {
    let showSectionLocations = get(this, 'practiceWebsite.showSectionLocations');
    let hasOffices = isPresent(this.practiceWebsiteOffices);
    if (this.isEditMode) {
      return showSectionLocations;
    } else {
      return showSectionLocations && hasOffices;
    }
  }

  @computed('practiceWebsite.showLoginLink', 'clientPortalEnabled')
  get showLoginLink() {
    return get(this, 'practiceWebsite.showLoginLink') && this.clientPortalEnabled;
  }

  @action
  async confirmRollback() {
    let model = this.practiceWebsite;
    if (!model.hasDirtyAttributes) {
      return true;
    }

    let { dismiss } = await this.confirmation();
    if (dismiss) {
      return false;
    } else {
      model.rollbackAttributes();
      this.notifyDirtyStatus(model);
      return true;
    }
  }

  @action
  async selectMenu(id) {
    let shouldSelectMenu = await this.confirmRollback();
    shouldSelectMenu && set(this, 'activeMenuId', id);
  }

  @action
  closeEditMenu() {
    this.notifyIsEditing(false);
    setProperties(this, {
      hasEditMenu: false,
      menuBanner: null,
    });
  }

  @action
  async closeWithConfirmation() {
    let shouldClose = await this.confirmRollback();
    shouldClose && this.closeEditMenu();
  }

  @action
  async editSection(activeMenuId) {
    let shouldOpen = this.allowEditing;
    if (this.hasEditMenu) {
      // a menu is already open, dirty check before opening new one
      shouldOpen = await this.confirmRollback();
    }
    shouldOpen && (this._openMenu(activeMenuId) || this.notifyIsEditing(true));
  }

  @action
  toggleContactModal() {
    if (!this.isEditMode && this.isNotPreview && !this.showContactModal) {
      this.mixpanelTrack('Contact form opened');
      this.analytics.trackAnonymously('Contact Form Opened', {
        object: 'Contact Form',
        action: 'Opened',
        'source_application': 'Professional Website',
      });
    }
    set(this, 'showContactModal', !this.showContactModal);
  }

  @action
  mixpanelTrack(message) {
    this.mixpanel.track(`prof_site: ${message}`);
  }

  _openMenu(activeMenuId) {
    setProperties(this, {
      activeMenuId,
      hasEditMenu: true,
    });
  }
}
