import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class SiteLicenseAgreementRoute extends Route {
  @service router;
  @service session;

  @reads('session.currentClientAccess.eulaAgreementAccepted') eulaAgreementAccepted;
  @reads('session.isAuthenticated') isAuthenticated;

  beforeModel() {
    if (!this.isAuthenticated) {
      this.router.transitionTo('home');
    } else if (this.eulaAgreementAccepted) {
      this.router.transitionTo('site.home');
    }
  }
}
