import { attr, belongsTo } from '@ember-data/model';
import Super from './document';
import classic from 'ember-classic-decorator';

@classic
export default class InsuranceCard extends Super {
  @attr() file;

  @belongsTo('document-request-contact-info-connection', { async: false }) connection;
  @belongsTo('insurance-info', { async: false, inverse: null }) insuranceInfo;
  @belongsTo('insurance-text-extraction', { async: false }) insuranceTextExtraction;
}
