/* import __COLOCATED_TEMPLATE__ from './practice-details.hbs'; */
import { action } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeWebsiteEditMenuPracticeDetails extends Component {
  nppTemplateUrl =
    'https://docs.google.com/document/d/12nUJAr0e4qN7crYHgLmvgSDqGPzCLGfy6BPQYKfy9N8/copy';

  noSurprisesActToggle = [
    {
      title: 'Display No Surprises Act in footer',
      enabledTip:
        'State-licensed or certified healthcare providers must display information about Good Faith Estimates. <a href="https://www.cms.gov/nosurprises" target="_blank" rel="noreferrer noopener">Learn more</a>',
      path: 'showNoSurprisesAct',
      isDisabled: false,
    },
  ];

  @action
  async openNppModal() {
    let { dismiss } = await this.confirmation({
      title: 'Disclaimer',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Continue',
      html: 'Templates provided by SimplePractice are for reference only and should not be considered legal or professional advice. Always seek advice from your state, licensure agency or legal counsel, as appropriate, to ensure the templates are appropriate for your specific use and comply with current applicable law.',
    });

    if (dismiss) {
      return;
    }

    window.open(this.nppTemplateUrl, 'nppTemplate');
  }
}
