import { STEP_IDS } from 'client-portal/services/widget-steps';
import { action } from '@ember/object';
import { service } from '@ember/service';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';

@classic
export default class RequestClientType extends RequestBaseRoute {
  @service analytics;
  @service session;
  @service mixpanel;
  @service request;
  @service pendingAppointment;
  @service widgetToken;

  templateName = '-request/client-type';
  stepId = STEP_IDS.CLIENT_TYPE;

  beforeModel() {
    let reservation = this.modelFor(this.request.baseRouteName);
    if (!reservation.get('isReserved')) {
      this.request.transitionTo(this, 'date');
    }
  }

  model() {
    let reservation = this.modelFor(this.request.baseRouteName);
    let { channel } = reservation;
    let clientType = this.setClientTypeFromToken() || reservation.clientType;
    return { clientType, channel };
  }

  afterModel(model, transition) {
    super.afterModel(...arguments);

    let { queryParams } = transition.to;
    if (queryParams.clientType) {
      transition.trigger(false, 'preselectionLoading');

      let reservation = this.modelFor(this.request.baseRouteName);
      let clientTypes = reservation.practice.clientTypesAllowedToBookAppt;
      if (clientTypes.includes(queryParams.clientType)) {
        return transition.trigger(false, 'nextStepWithPreselection', queryParams.clientType, {
          queryParams,
        });
      }
    }
    transition.trigger(false, 'stopPreselectionLoading', transition);
    this.pendingAppointment.reset();
  }

  setClientTypeFromToken() {
    let therapyFor = this.widgetToken.tokenData?.therapyFor;

    if (!therapyFor) return null;

    switch (therapyFor) {
      case 'Individual':
        return 'client';
      case 'Couples':
        return 'couple';
      default:
        return 'contact';
    }
  }

  @action
  nextStepWithPreselection(selectedType, params) {
    super.nextStep('clientType', selectedType, this.getNextStepForFeatures(), params);
  }

  @action
  nextStep() {
    super.nextStep('clientType', this.currentModel.clientType, this.getNextStepForFeatures());
  }

  @action
  didTransition() {
    let reservation = this.modelFor(this.request.baseRouteName);
    reservation.setProperties({
      clientType: null,
      message: null,
      hasCompletedMessageStep: false,
      channelFields: null,
      channelUploads: null,
      channelUploadsComplete: null,
      hasCompletedInformationStep: false,
    });

    this._trackAppointmentStep(reservation);
    return true;
  }
}
