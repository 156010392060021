/* import __COLOCATED_TEMPLATE__ from './actions.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './actions.module.scss';

export default class RequestConfirmationActions extends Component {
  styles = styles;
  @service router;
  @service session;
  @service iframe;

  @action
  afterCancel() {
    this.session.clearProspectiveClient();
  }

  @action
  returnToAppointment() {
    this.session.clearProspectiveClient();
    this.router.transitionTo('site.appointments.requested');
  }

  @action
  callCloseAction() {
    this.session.clearProspectiveClient();
    try {
      let currentURL = this.router.currentURL;
      if (currentURL.includes('widget')) {
        this.iframe.close();
        this.router.transitionTo('widget.index');
      } else {
        this.router.transitionTo('sign-in');
      }
    } catch (err) {
      // ignore if error on closing widget
    }
  }
}
