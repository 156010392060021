import { buildValidations, validator } from 'ember-cp-validations';
import { not, or } from '@ember/object/computed';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';

const Validations = buildValidations({
  address: [
    validator('presence', {
      presence: true,
      disabled: or('model.skipValidation', 'model.prospectiveClient'),
      ignoreBlank: true,
    }),
    validator('presence', {
      presence: true,
      message: 'Email is required',
      disabled: not('model.prospectiveClient'),
    }),
    validator('format', {
      type: 'email',
      allowBlank: true,
    }),
  ],
});

@classic
export default class EmailModel extends Model.extend(Validations) {
  @attr() address;

  prospectiveClient = false;
}
