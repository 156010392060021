module.exports = [
  'Afrikaans',
  'Albanian',
  'American Sign Language',
  'Arabic',
  'Armenian',
  'Basque',
  'Bengali',
  'Bulgarian',
  'Cantonese',
  'Catalan',
  'Central Khmer',
  'Chinese (Mandarin)',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'Estonian',
  'Fijian',
  'Finnish',
  'French',
  'Georgian',
  'German',
  'Gujarati',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Korean',
  'Latin',
  'Latvian',
  'Lithuanian',
  'Macedonian',
  'Malay',
  'Malayalam',
  'Maltese',
  'Maori',
  'Marathi',
  'Modern Greek (1453-)',
  'Mongolian',
  'Nepali',
  'Norwegian',
  'Panjabi',
  'Farsi (Persian)',
  'Polish',
  'Portuguese',
  'Quechua',
  'Romanian',
  'Russian',
  'Samoan',
  'Serbian',
  'Slovak',
  'Slovenian',
  'Spanish',
  'Swahili',
  'Swedish',
  'Tagalog',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tonga (Tonga Islands)',
  'Turkish',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Vietnamese',
  'Welsh',
  'Xhosa',
  'Yiddish',
];
