import { STEP_IDS } from 'client-portal/services/widget-steps';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { trackAppointmentStep } from 'client-portal/utils/event-tracking';
import PersistAppointmentMixin from '../-mixins/persist-appointment';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class RequestInformationIndexRoute extends Route.extend(PersistAppointmentMixin) {
  @service analytics;
  @service mixpanel;
  @service session;
  @service request;
  @service stripeCards;

  templateName = '-request/information/index';

  beforeModel() {
    let reservation = this.modelFor(this.request.baseRouteName);
    if (!reservation.get('isReserved')) {
      this.request.transitionTo(this, 'date');
    }
  }

  afterModel() {
    super.afterModel(...arguments);

    let appointment = this.getAppointment();
    if (appointment.client && !appointment.client.isNew) {
      return this.persistAppointmentTask.perform();
    }
  }

  @action
  async nextStep(fromPath = '', stripeElements) {
    let isDesktopNextStep = fromPath === 'desktop';
    let { reorderedNavEnabled } = this.session;

    if (reorderedNavEnabled) {
      await this.validateFieldsAndTransition('message', stripeElements);
    } else {
      if (isDesktopNextStep) {
        await this.validateAndPersistAppointmentTask.perform(stripeElements);
      } else {
        await this.validateFieldsAndTransition('information.review', stripeElements);
      }
    }
  }

  async validateFieldsAndTransition(nextRoute, stripeElements) {
    try {
      await this.validateFieldsTask.perform(stripeElements);
      let reservation = this.modelFor(this.request.baseRouteName);
      reservation.set('hasCompletedInformationStep', true);
      this.request.transitionTo(this, nextRoute);
    } catch (err) {
      // noop
    }
  }

  @action
  didTransition() {
    let appointment = this.getAppointment();
    if (appointment.get('card')) {
      appointment.set('card.card', null);
    }

    let reservation = this.modelFor(this.request.baseRouteName);
    trackAppointmentStep({
      analytics: this.analytics,
      session: this.session,
      mixpanel: this.mixpanel,
      request: this.request,
      pageId: STEP_IDS.CLIENT_INFORMATION,
      reservation,
    });

    return true;
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setProperties({
      channel: this.modelFor(this.request.baseRouteName).channel,
    });
  }
}
