import { Resize } from 'ember-animated/motions/resize';
import { action } from '@ember/object';
import { fadeOut } from 'ember-animated/motions/opacity';
import Component from '@glimmer/component';
import getDuration from '../../../../utils/transitions';

class CustomResize extends Resize {
  constructor(sprite, opts) {
    opts.duration = 0;
    super(sprite, opts);
  }
}

export default class SharedDropZoneCompactProgressBar extends Component {
  @action
  *transition({ removedSprites }) {
    let duration = getDuration('medium');
    for (let sprite of removedSprites) {
      yield fadeOut(sprite, { duration });
    }
  }

  motion = CustomResize;
}
