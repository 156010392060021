import { computed } from '@ember/object';
import MONARCH_ENABLED_PROFESSIONS from 'client-portal/utils/monarch-enabled-professions';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class ClinicianModel extends Model {
  @belongsTo('practice', { async: false }) practice;
  @hasMany('cpt-code', { async: false }) cptCodes;
  @attr() firstName;
  @attr() lastName;
  @attr() middleName;
  @attr() suffix;
  @attr() phone;
  @attr() globalProfessionName;

  @computed('globalProfessionName')
  get hasMonarchProfession() {
    let { globalProfessionName = '' } = this;
    return MONARCH_ENABLED_PROFESSIONS.includes(globalProfessionName.toLowerCase());
  }

  @computed('firstName', 'lastName', 'middleName', 'suffix')
  get fullName() {
    let properties = {
      firstName: this.firstName,
      lastName: this.lastName,
      middleName: this.middleName,
      suffix: this.suffix,
    };
    let { firstName, lastName, middleName, suffix } = properties;

    return [
      [firstName, middleName, lastName]
        .filter(string => string !== '')
        .compact()
        .join(' '),
      suffix !== '' ? suffix : null,
    ]
      .compact()
      .join(', ');
  }
}
