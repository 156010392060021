import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class InsuranceTextExtraction extends ApplicationAdapter {
  async extractInsuranceCardInfoFromDocument(documentId) {
    let baseUrl = `${this.buildURL(
      'insurance-text-extraction'
    )}/extract-insurance-card-info-from-document`;
    let payload = await this.ajax(baseUrl, 'POST', { data: { documentId } });

    this.store.pushPayload(payload);
    return payload;
  }
}
