/* import __COLOCATED_TEMPLATE__ from './insurance-card-field.hbs'; */
import {
  DEFAULT_DOCUMENTS_MAX_FILE_SIZE,
  DEFAULT_MAX_FILE_SIZE_TEST,
} from 'client-portal/services/file-upload';
import { action } from '@ember/object';
import { createElement, error } from 'client-portal/utils/banners';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import styles from './insurance-card-field.module.scss';

export default class RequestInformationSharedInsuranceCardField extends Component {
  @service media;
  @service store;
  @service mixpanel;
  @service currentPractice;
  @service fileQueue;

  styles = styles;
  queueNamePrefix = 'insurance-card-field-';
  errorTitle = 'File could not be uploaded';
  defaultErrorMessage = 'Error while uploading file.';
  maxFileSize = isEmberTesting() ? DEFAULT_MAX_FILE_SIZE_TEST : DEFAULT_DOCUMENTS_MAX_FILE_SIZE;
  @tracked currentFile;

  constructor() {
    super(...arguments);
    this.currentFile = this.args.currentFile;
  }

  displayErrorBanner(message) {
    let content = createElement('p', { innerText: message });
    error({ title: this.errorTitle, content });
  }

  fileErrorMessage(fileName) {
    let message = `File could not be uploaded. File ${fileName} exceeds the maximum files size of 50MB.`;
    return this.displayErrorBanner(message);
  }

  fileExceedsMaxSize(file) {
    return file.size > this.maxFileSize;
  }

  currentFileUrl(queueName) {
    let file = this.fileQueue.find(queueName)?.files?.[0]?.file;
    return file ? URL.createObjectURL(file) : null;
  }

  flushQueue(queueName) {
    return this.fileQueue.find(queueName).flush();
  }

  get fileUrl() {
    if (this.currentFile) {
      return this.currentFileUrl(`${this.queueNamePrefix}${this.side}`);
    }
    return undefined;
  }

  get side() {
    return this.args.side;
  }

  @action
  remove() {
    URL.revokeObjectURL(this.currentFile);
    this.flushQueue(`${this.queueNamePrefix}${this.side}`);
    this.currentFile = undefined;
    this.args.onUpdateFile(undefined);
  }

  @(task(function* (file) {
    try {
      if (this.fileExceedsMaxSize(file)) {
        this.fileErrorMessage(file.name);
      } else {
        this.currentFile = file;
        this.args.onUpdateFile(file);
      }
    } catch (err) {
      this.displayErrorBanner(this.defaultErrorMessage);
    }
  }).drop())
  uploadCard;
}
