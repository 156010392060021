/* import __COLOCATED_TEMPLATE__ from './gender-dropdown.hbs'; */
import Component from '@glimmer/component';

export default class ContactInformationGenderDropdown extends Component {
  options = {
    male: 'Male',
    female: 'Female',
    other: 'Non-Binary',
    unknown: 'Prefer Not To Say',
  };
}
