import { waitForNext } from '../utils/waiters';
import Service from '@ember/service';
import StateMachine from './pop-sequencer/-state-machine';
import classic from 'ember-classic-decorator';

@classic
export default class PopSequencerService extends Service {
  transitions = null;
  currentStep = null;

  init() {
    super.init(...arguments);
    this.set('transitions', StateMachine.create());
  }

  async register(stepComponent) {
    stepComponent.transitions = this.transitions;
    await waitForNext();
    this.transitions.addStep(stepComponent);
  }

  async remove(stepComponent) {
    await waitForNext();
    this.transitions.removeStep(stepComponent);
  }
}
