import Super from 'ember-simplepractice/services/stripe';

export function initialize(application) {
  application.register(
    'service:stripe',
    class StripeService extends Super {
      elements() {
        return this.stripe?.elements({
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css2?family=Inter',
            },
          ],
        });
      }
    }
  );
}
export default {
  name: 'patch-stripe-service',
  initialize,
};
