/* import __COLOCATED_TEMPLATE__ from './loading.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './loading.module.scss';

@classic
@classNames(styles.component)
export default class SignInLoading extends Component {
  @service fastboot;

  @computed('fastboot.isFastBoot')
  get showMessage() {
    if (this.fastboot.isFastBoot) {
      return false;
    }

    return true;
  }
}
