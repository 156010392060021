/* import __COLOCATED_TEMPLATE__ from './social-media.hbs'; */
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

const socialMediaFields = [
  {
    label: 'Facebook',
    inputId: 'pw-social-facebook',
    path: 'facebookUrl',
    placeholder: 'https://facebook.com/username',
  },
  {
    label: 'Twitter',
    inputId: 'pw-social-twitter',
    path: 'twitterUrl',
    placeholder: 'https://twitter.com/username',
  },
  {
    label: 'Instagram',
    inputId: 'pw-social-instagram',
    path: 'instagramUrl',
    placeholder: 'https://instagram.com/username',
  },
  {
    label: 'Yelp',
    inputId: 'pw-social-yelp',
    path: 'yelpUrl',
    placeholder: 'https://yelp.com/biz/username',
  },
  {
    label: 'YouTube',
    inputId: 'pw-social-youtube',
    path: 'youtubeUrl',
    placeholder: 'https://www.youtube.com/channel/username',
  },
  {
    label: 'LinkedIn',
    inputId: 'pw-social-linkedin',
    path: 'linkedinUrl',
    placeholder: 'https://www.linkedin.com/in/username',
  },
];

@classic
export default class PracticeWebsiteEditMenuSocialMedia extends Component {
  socialMediaFields = socialMediaFields;
}
