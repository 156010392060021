/* import __COLOCATED_TEMPLATE__ from './card-block.hbs'; */
import {
  buildStripePaymentElement,
  buildStripeSetupModeElement,
} from 'client-portal/utils/stripe-payment-methods';
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './card-block.module.scss';

@classic
@classNames(styles.component)
export default class RequestInformationCardBlock extends Component {
  styles = styles;

  @service currentPractice;
  @service stripe;
  @service session;

  @tracked stripeElement;
  didInsertElement() {
    super.didInsertElement(...arguments);

    if (this.session.canUsePaymentMethods) {
      if (!this.stripe?.stripe) {
        return;
      }
      this.elements = buildStripeSetupModeElement(this.stripe.stripe);
      this.paymentElement = buildStripePaymentElement(this.elements, () => {
        this.setPaymentElementLoaded(this.elements);
      });
    }
  }
}
