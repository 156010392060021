/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@classNames('header')
export default class RequestRequestWrapperHeader extends Component {
  @service session;
  @service request;

  @computed('activeSteps', 'currentStep.title')
  get eventTrackingProperties() {
    let { title } = this.currentStep;

    return {
      'page_name': title,
      'page_number': this.activeSteps.findIndex(step => step.title === title) + 1,
      'client_type': this.request.findClientType(this.session.currentClient),
      'offers_free_service': this.request.hasFreeServices,
    };
  }
}
