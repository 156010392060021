/* import __COLOCATED_TEMPLATE__ from './locations.hbs'; */
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { filterBy, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './locations.module.scss';

@classNames(styles.component)
@classic
export default class PracticeWebsiteEditMenuLocations extends Component {
  @filterBy('offices', 'isVideo', false) physicalOffices;
  @notEmpty('physicalOffices') hasOffices;

  @action
  updateSelectedOfficeIds(id, value) {
    let selectedOfficeIds = [...this.model.selectedOfficeIds];
    if (value) {
      selectedOfficeIds.addObject(id);
    } else {
      selectedOfficeIds.removeObject(id);
    }
    this.setAndNotify('selectedOfficeIds', selectedOfficeIds);
  }
}
