import classic from 'ember-classic-decorator';
/* global mixpanel */
import Service, { service } from '@ember/service';
import config from 'client-portal/config/environment';
import uuidv4 from 'uuid/v4';

@classic
export default class MixpanelService extends Service {
  @service currentPractice;

  initialized = false;
  blacklistedProperties = [
    '$city',
    '$region',
    '$geo_source',
    '$device',
    '$device_id',
    '$initial_referrer',
    '$initial_referring_domain',
    '$referrer',
    '$referring_domain',
    '$screen_height',
    'utm_source',
    'utm_medium',
    '$distinct_id',
    '$os',
    '$browser',
    '$browser_version',
    '$screen_width',
  ];

  widgetEventNames = {
    completed: 'user: appointment request completed',
    closed: 'user: booking widget closed',
  };

  initClient(key) {
    if (!key || typeof mixpanel === 'undefined') {
      return;
    }

    this.set('initialized', true);
    let isTestMode = config.mixpanel.testMode;
    mixpanel.init(
      key,
      {
        test: isTestMode,
        debug: isTestMode,
        'api_host': config.mixpanel.apiHost,
      },
      'default'
    );
    mixpanel.init(
      key,
      {
        ip: false,
        'property_blacklist': this.blacklistedProperties,
      },
      'anonymous'
    );
    mixpanel.anonymous?.identify(uuidv4());
  }

  track(eventName, properties = {}, options = null) {
    if (!this.initialized) {
      return;
    }

    properties.practiceId = this.get('currentPractice.id');
    mixpanel.default.track(eventName, properties, options);
  }

  trackAnonymously(eventName, properties = {}) {
    if (!this.initialized) {
      return;
    }

    properties.practiceId = this.get('currentPractice.id');
    mixpanel.anonymous?.track(eventName, properties);
  }

  trackWidgetEvents(eventType, properties) {
    this.track(this.widgetEventNames[eventType], properties);
  }
}
