import { capitalize } from '@ember/string';
import { registerDestructor } from '@ember/destroyable';
import { waitForNext } from '../utils/waiters';
import Service from '@ember/service';

const INSTANCE_DEFAULT = 'global';

export default class AlertManagerService extends Service {
  events = ['clearBanner', 'displayBanner', 'displayLocalToast', 'displayToast'];
  global = {};

  #generateError({ event, message, type }) {
    throw new Error(message || `No ${event} event associated with alert type ${type}`);
  }

  #parseEventName({ event = 'display', type }) {
    return `${event}${type.charAt(0).toUpperCase()}${type.slice(1)}`;
  }

  #runEvent({ event, eventName, instance = INSTANCE_DEFAULT, message, type }) {
    if (this[instance][eventName]) {
      this[instance][eventName](message);

      return;
    }

    this.#generateError({ event, type });
  }

  bindEventHandler({ context, event, instance = INSTANCE_DEFAULT, handler }) {
    let handlerName = `_bound${capitalize(event)}Handler`;

    context[handlerName] = handler.bind(context);

    this.on(event, context[handlerName], instance);

    registerDestructor(context, () => {
      context.alertManager.off(event, instance);
      delete context[handlerName];
    });
  }

  async broadcast(type, message) {
    let eventName = this.#parseEventName({ type });
    let instance = message?.containerId || INSTANCE_DEFAULT;

    await this.waitForBroadcastChannel(eventName, instance);

    this.#runEvent({ eventName, instance, message, type });
  }

  clear(type, instance = INSTANCE_DEFAULT) {
    let event = 'clear';
    let eventName = this.#parseEventName({ event, type });

    this.#runEvent({ event, eventName, instance, type });
  }

  isValidEvent(eventName) {
    return this.events.includes(eventName);
  }

  off(eventName, instance = INSTANCE_DEFAULT) {
    if (this[instance]) {
      delete this[instance][eventName];
    }
  }

  on(eventName, handler, instance = INSTANCE_DEFAULT) {
    if (this.isValidEvent(eventName)) {
      this[instance][eventName] = handler;

      return;
    }

    this.#generateError({ message: `Undefined event ${eventName}` });
  }

  registerComponent(instance) {
    let { id } = instance.args;

    this[id] = {};

    registerDestructor(instance, () => {
      delete this[id];
    });
  }

  // This ensures that the handler for the event that is being targeted for broadcast
  // is actually registered with the service before attempting to broadcast event data.
  // Specifically, this is to avoid edge cases where broadcasting may be triggered at
  // the same time that a handler is being registered (i.e., on page load), causing
  // a race condition between the broadcast and handler registration.
  // If the eventName provided is not a valid event, we skip waiting and immediately
  // raise an error. If the handler has not registered within 1000ms, we abort
  // waiting and raise an error to avoid an infinite loop.
  async waitForBroadcastChannel(eventName, instance = INSTANCE_DEFAULT) {
    if (this.isValidEvent(eventName)) {
      const countLimit = 1000;
      let count = 0;
      let waiting = !this[instance][eventName];

      while (waiting && count < countLimit) {
        await waitForNext();

        waiting = !this[instance][eventName];
        count++;
      }

      if (waiting) {
        this.#generateError({
          message: `Attempting to wait for broadcast channel for event "${eventName}" but it did not resolve in time`,
        });
      }

      return;
    }

    this.#generateError({
      message: `Attempting to wait for broadcast channel for event "${eventName}" but it will never resolve!`,
    });
  }
}
