/* import __COLOCATED_TEMPLATE__ from './legal-agreements.hbs'; */
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './legal-agreements.module.scss';

const LEGAL_AGREEMENTS_ITEMS = {
  eula: {
    id: 'eula',
    text: 'End User License Agreement',
    url: '/c/license-agreement/',
  },
  terms: {
    id: 'terms_of_service',
    text: 'Terms of Service',
    url: '/c/terms/',
  },
  privacy: {
    id: 'privacy_policy',
    text: 'Privacy Policy',
    url: '/c/privacy/',
  },
};

@classic
@classNames(styles.component)
export default class LegalAgreements extends Component {
  get agreementMessage() {
    let base = 'To sign in to your Client Portal, review and accept SimplePractice’s';
    let suffix = 'terms and policies.';

    return this.hasAcceptedEula ? `${base} updated ${suffix}` : `${base} ${suffix}`;
  }

  get agreementList() {
    let { eula, terms, privacy } = LEGAL_AGREEMENTS_ITEMS;

    return this.hasAcceptedEula ? [terms, privacy] : [eula, terms, privacy];
  }
}
