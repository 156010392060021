import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';
import { A as emberA } from '@ember/array';
import Super from 'ember-simplepractice/models/stripe-card';
import classic from 'ember-classic-decorator';

export const EPHEMERAL_ATTRS = ['name', 'addressZip'];

export const SUPPORTED_BRANDS = [
  'Visa',
  'MasterCard',
  'Discover',
  'American Express',
  'JCB',
  'Diners Club',
];

const Validations = buildValidations({
  name: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      message: "Cardholder's name is required",
    }),
  ],
});

@classic
export default class Card extends Super.extend(Validations) {
  @attr() paymentMethodId;
  @attr() customStripeCustomerId;

  @computed('brand')
  get iconName() {
    let brand = this.brand;

    if (!SUPPORTED_BRANDS.includes(brand)) {
      return 'generic-credit-card';
    }

    if (brand.includes(' ')) {
      brand = dasherize(brand);
    }

    return `${brand.toLowerCase()}-card`;
  }

  static fieldsFor(_context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    fields.removeObjects(['addressZip', 'name']);
    return fields;
  }
}
