import { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, oneWay } from '@ember/object/computed';
import Super from './document-request';
import classic from 'ember-classic-decorator';

@classic
export default class DocumentRequestNote extends Super {
  documentIcon = 'file-text';

  @belongsTo('diagnosis-treatment-plan', { async: false }) diagnosisTreatmentPlan;
  @belongsTo('note-signature-overview', { async: false }) noteSignatureOverview;
  @attr() documentBody;
  @attr() documentDate;
  @attr() documentType;
  @attr('boolean') isSharedGroupNote;
  @attr('boolean') isGenericGroupNote;
  @attr() individualNoteBody;

  @oneWay('isComplete') hasDocumentPdf;
  @equal('documentType', 'DiagnosisTreatmentPlan') isDiagnosisTreatmentPlan;

  @computed('documentType')
  get mixpanelType() {
    switch (this.documentType) {
      case 'DiagnosisTreatmentPlan':
        return 'Diagnosis and Treatment Plan';
      case 'Progress':
        return 'Progress Note';
      default:
        return 'Assessment';
    }
  }
}
