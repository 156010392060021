/* import __COLOCATED_TEMPLATE__ from './date-input.hbs'; */
import { action, computed } from '@ember/object';
import { dateFormat } from 'client-portal/utils/date-time';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

@classic
export default class SharedDateInput extends Component {
  inactivePlaceholder = dateFormat.toLowerCase();

  @computed('_inputValue', 'value')
  get formattedValue() {
    if (this._inputValue) {
      return this._inputValue;
    }

    if (this.value) {
      return this.value.format(dateFormat);
    }

    return null;
  }

  @action
  setText(text) {
    this.set('_inputValue', text);
    let date = text ? moment(text, dateFormat, true) : null;
    this.onSetText(date);
  }
}
