import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';

export default class PollTaskService extends Service {
  @tracked _asyncTaskQueue = [];

  /*
    args:
    {
      task,
      shouldContinuePolling,
      finishedCallback,
      timedOutCallback,
      remainingRequests,
      timeoutMilliseconds
    }
  */
  async scheduleAsyncTask(args) {
    let { timedOutCallback, remainingRequests, timeoutMilliseconds } = args;
    if (remainingRequests > 0) {
      if (isEmberTesting()) {
        this._asyncTaskQueue.push(args);
      } else {
        await timeout(timeoutMilliseconds);
        this.runTaskAndHandlePolling.perform(args);
      }
    } else {
      timedOutCallback();
    }
  }

  runNextAsyncTask() {
    // Used in testing to manually tick through the queue
    let args = this._asyncTaskQueue.shift();
    if (args) {
      this.runTaskAndHandlePolling.perform(args);
    }
  }

  @task(function* (args) {
    let { task, shouldContinuePolling, finishedCallback, timedOutCallback, remainingRequests } =
      args;
    try {
      yield task();
      if (shouldContinuePolling()) {
        this.scheduleAsyncTask({ ...args, remainingRequests: remainingRequests - 1 });
      } else {
        finishedCallback();
      }
    } catch (err) {
      timedOutCallback();
    }
  })
  runTaskAndHandlePolling;
}
