import { action } from '@ember/object';
import { service } from '@ember/service';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class SignInTokenBaseController extends Controller {
  @service signIn;
  @service mixpanel;

  showTips = false;

  @action
  toggleTips(evt) {
    evt?.preventDefault();
    this.toggleProperty('showTips');

    if (this.showTips) {
      this.mixpanel.track('visit: Client Portal Tips page');
    }
  }
}
