/* import __COLOCATED_TEMPLATE__ from './no-availability.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import styles from './no-availability.module.scss';

@classic
@classNames(styles.component)
export default class RequestDateNoAvailability extends Component {
  @service request;

  @computed('currentDate')
  get current() {
    return moment(this.currentDate);
  }
}
