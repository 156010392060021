/* import __COLOCATED_TEMPLATE__ from './input-with-review.hbs'; */
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './input-with-review.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsSharedInputWithReview extends Component {
  focusIn() {
    undefined;

    if (this.model) {
      this.model.errors.clear();
    }
  }
}
