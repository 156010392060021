/* import __COLOCATED_TEMPLATE__ from './seo-structured-data.hbs'; */
import { attribute, tagName } from '@ember-decorators/component';
import { compactObject } from 'client-portal/utils/compact-object';
import { computed } from '@ember/object';
import { formatPhone } from 'client-portal/helpers/format-phone';
import { isEmpty } from '@ember/utils';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@tagName('script')
@classic
export default class PracticeWebsiteSeoStructuredData extends Component {
  @service currentPractice;

  @attribute type = 'application/ld+json';
  @reads('currentPractice.practiceUrl') practiceUrl;

  @computed('offices', 'model.selectedOfficeIds')
  get office() {
    let { hasSelectedOfficeIds, selectedOfficeIds } = this.model || {};
    if (isEmpty(this.offices) || !hasSelectedOfficeIds) {
      return undefined;
    }
    return this.offices.find(office => selectedOfficeIds.includes(office.id));
  }

  @computed('model.{practiceName,practicePhone}', 'practiceUrl', 'office')
  get structuredData() {
    let { practiceName, practicePhone } = this.model || {};
    let { practiceUrl } = this;
    let data = {
      '@context': 'https://schema.org/',
      '@type': 'LocalBusiness',
      '@id': practiceUrl,
      name: practiceName,
      telephone: practicePhone && formatPhone([practicePhone], { format: 'dots' }),
      url: practiceUrl,
    };

    if (this.office) {
      let { street, city, state, zip } = this.office;
      data.address = {
        '@type': 'PostalAddress',
        streetAddress: street,
        addressLocality: city,
        addressRegion: state,
        postalCode: zip,
      };
    }

    return JSON.stringify(compactObject(data), null, '  ');
  }
}
