import ApplicationSerializer from './application';

export default class InsuranceInfo extends ApplicationSerializer {
  serializeHasMany(snapshot, json, relationship) {
    let key = this.keyForRelationship(relationship.key, 'belongsTo', 'serialize');

    if (key !== 'documents') {
      super.serializeHasMany(...arguments);
    }
  }
}
