/* import __COLOCATED_TEMPLATE__ from './tips.hbs'; */
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { later } from '@ember/runloop';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './tips.module.scss';

@classic
@classNames(styles.component)
export default class SignInTips extends Component {
  styles = styles;

  @action
  closeLater() {
    later(() => {
      this.close();
    });
  }
}
