/* import __COLOCATED_TEMPLATE__ from './estimate-details.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { humanDateFormat } from 'client-portal/utils/date-time';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './estimate-details.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsGoodFaithEstimateEstimateDetails extends Component {
  @service currentPractice;
  humanDateFormat = humanDateFormat;

  @reads('goodFaithEstimate.submissionData') submissionData;
  @reads('submissionData.clients') clients;
  @reads('submissionData.provider') provider;

  @computed('clients.[]')
  get clientItems() {
    if (!this.clients) {
      return [];
    }

    return this.clients.map(client => this.buildClientItems(client));
  }

  @computed('provider')
  get providerItems() {
    let { provider } = this;
    let providerInfo = [provider.name];

    if (provider.hasPhysicalAddress) {
      providerInfo.push(provider.street);
      providerInfo.push(`${provider.city}, ${provider.state} ${provider.zip}`);
    } else {
      providerInfo.push(provider.location);
    }

    return [
      providerInfo,
      [`NPI: ${provider.npiNumber}`, `TIN: ${provider.tin}`],
      [provider.contactPerson, provider.phoneNumber, provider.emailAddress],
    ];
  }

  buildClientItems(client) {
    return [
      this.nameAndAddressBuilder(client),
      [`DOB: ${client.birthDate.format(this.humanDateFormat)}`],
      [client.contactPermissionsText, client.phoneNumber, client.emailAddress],
      this.hideContactPermission(client),
    ];
  }

  hideContactPermission(client) {
    return !(client.phoneNumber || client.emailAddress);
  }

  nameAndAddressBuilder(client) {
    let state = client.state === 'unspecified' ? '' : client.state;
    let street = client.street || '';
    let address = !(client.city || state || client.zip)
      ? ''
      : `${client.city} ${state}, ${client.zip}`;
    return [client.name, street, address];
  }
}
