/* import __COLOCATED_TEMPLATE__ from './services.hbs'; */
import {
  globalMarketingServices,
  maxMarketingServicesCount,
} from 'client-portal/models/practice-website/marketing-service';
import { mapBy, setDiff } from '@ember/object/computed';
import { set } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeWebsiteEditMenuServices extends Component {
  maxCount = maxMarketingServicesCount;

  @mapBy('model.marketingServices', 'name') marketingServicesNames;
  @setDiff('globalMarketingServices', 'marketingServicesNames') globalList;

  init() {
    super.init(...arguments);
    set(
      this,
      'globalMarketingServices',
      [...globalMarketingServices].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    );
  }
}
