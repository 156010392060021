/* import __COLOCATED_TEMPLATE__ from './birth-date.hbs'; */
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class RequestInformationSharedBirthDate extends Component {
  get enabled() {
    return !this.channel?.skipBirthDate;
  }
}
