function filterList(collection, term) {
  let filterCallback = getFilterCallback(term);

  return collection.filter(filterCallback);
}

function filterCollection(collection, term, propertyName) {
  let filterCallback = getFilterCallback(term);

  return collection.filter(function (item) {
    return filterCallback(item[propertyName]);
  });
}

function getFilterCallback(term) {
  return function (string) {
    return string.toLowerCase().includes(term.toLowerCase());
  };
}

export default function filterByTerm(collection, term = '', propertyName) {
  if (!term) {
    return collection;
  }

  return propertyName
    ? filterCollection(collection, term, propertyName)
    : filterList(collection, term);
}
