import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';

const MAXIMUM_DURATION_TO_RECOMMEND = 30;

@classic
export default class CptCodeModel extends Model {
  @attr() description;
  @attr() duration;
  @attr('float') rate;
  @attr('boolean') callToBook;
  @attr() requireCreditCardForNewClients;
  @belongsTo({ async: false }) globalMonarchChannel;
  @equal('requireCreditCardForNewClients', 'unset') isCreditCardRequirementUnset;
  @equal('requireCreditCardForNewClients', 'on') isCreditCardRequired;

  @computed('duration', 'rate')
  get eligibleToRecommend() {
    return !this.rate && this.duration <= MAXIMUM_DURATION_TO_RECOMMEND;
  }
}
