import { action, computed } from '@ember/object';
import { attributeBindings } from '@ember-decorators/component';
import { debounce, next, scheduleOnce } from '@ember/runloop';
import { getSelection, setSelection } from 'ember-cli-maskedinput/util/selection';
import { modifier } from 'ember-modifier';
import InputMask from 'inputmask-core';
import MaskedInput from 'ember-cli-maskedinput/components/masked-input';
import classic from 'ember-classic-decorator';

function setInputSelection(selection) {
  setSelection(this.inputEl, selection);
}

export function isAndroid() {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }
  return /Android/i.test(navigator.userAgent);
}

const KEYCODE_BACKSPACE = 8;
const INPUT_TYPE_DELETE = 'deleteContentBackward';
const INPUT_TYPE_INSERT_TEXT = 'insertText';
const INPUT_TYPE_COMPOSITION_TEXT = 'insertCompositionText';

@classic
@attributeBindings('ariaLabel:aria-label')
export default class MaskedInputPatched extends MaskedInput {
  inputEl = null;

  @computed('args.{value,mask,formatCharacters,placeholderChar,formatMask}')
  get inputMask() {
    let options = {
      pattern: this.args.mask || null,
      value: this.args.value,
      formatCharacters: this.args.formatCharacters || null,
      placeholderChar: this.args.placeholderChar || '_',
    };

    let inputMask = new InputMask(options);
    inputMask.emptyValue = this.args.formatMask?.(inputMask.emptyValue) || inputMask.emptyValue;

    return inputMask;
  }

  get displayValue() {
    let inputMaskValue = this.inputMask.getValue();
    let value = this.args.formatMask?.(inputMaskValue) || inputMaskValue;
    return value === this.inputMask.emptyValue ? '' : value;
  }

  @computed('inputMask', 'args.{isFocused,inactivePlaceholder}')
  get placeholder() {
    return this.args.isFocused ? super.placeholder : this.args.inactivePlaceholder;
  }

  onSetup = modifier(
    element => {
      this.inputEl = element;
    },
    { eager: false }
  );

  /**
   * Use `beforeinput` where `keypress` is unavailable (Android)
   * @override
   */
  @action
  onInputBeforeInput(evt) {
    if (!isAndroid()) {
      return;
    }
    let { inputType, data = '', target } = evt;
    let isBackspace = INPUT_TYPE_DELETE === inputType;
    let isComposition = INPUT_TYPE_COMPOSITION_TEXT === inputType;
    let isInsert = INPUT_TYPE_INSERT_TEXT === inputType;

    if (isBackspace) {
      evt.keyCode = KEYCODE_BACKSPACE;
      this.onInputKeyDown(evt);
    } else if (isComposition || isInsert) {
      let key = data.charAt(data.length - 1);
      let { selectionStart } = target;
      let selectionRange = { start: selectionStart, end: selectionStart };
      debounce(this, this.deferredCharInput, { evt, key, selectionRange }, 10);
    }
  }

  deferredCharInput(data) {
    let { evt, key, selectionRange } = data;
    this.handleCharInput(evt, key, selectionRange);
  }

  @action
  onInputKeyPress(evt) {
    // Ignore modified key presses
    // Ignore enter key to allow form submission
    // Ignore tab key to allow focussing other elements
    // On Firefox, ignore any key if input is readonly
    if (
      evt.metaKey ||
      evt.altKey ||
      evt.ctrlKey ||
      evt.keyCode === 13 ||
      evt.keyCode === 9 ||
      this.readonly
    ) {
      return;
    }

    let key = evt.key || String.fromCharCode(evt.charCode);
    this.handleCharInput(evt, key);
  }

  @action
  handleCharInput(evt, key, selectionRange) {
    evt.preventDefault();
    this.updateMaskSelection(selectionRange);
    if (this.inputMask.input(key)) {
      evt.target.value = this.inputMask.getValue();
      this.updateInputSelection();
      this.onChange(evt);
    }
  }

  @action
  onInputChange(e) {
    let maskValue = this.inputMask.getValue();
    if (e.target.value !== maskValue) {
      this.inputMask.setValue(e.target.value);
      // Cut or delete operations will have shortened the value
      if (e.target.value.length < maskValue.length) {
        let sizeDiff = maskValue.length - e.target.value.length;
        this.updateMaskSelection();
        this.inputMask.selection.end = this.inputMask.selection.start + sizeDiff;
        this.inputMask.backspace();
      }
      let value = this.displayValue;
      e.target.value = value;
      if (value) {
        this.updateInputSelection();
      }
    }
    this.onChange(e);
  }

  /**
   * Use `input` instead of buggy `paste`
   * @override
   */
  @action
  onInputPaste(evt) {
    if (this.readonly) {
      return;
    }

    evt.preventDefault();
    this.updateMaskSelection();
    let text = (evt.clipboardData || window.clipboardData).getData('Text');
    text.split('').forEach(s => this.inputMask.input(s));
    evt.target.value = this.inputMask.getValue();
    next(this, this.updateInputSelection);
    this.onChange(evt);
  }

  updateMaskSelection(selectionRange) {
    this.inputMask.selection = selectionRange ? selectionRange : getSelection(this.inputEl);
    if (selectionRange) {
      this.inputEl.selectionStart = selectionRange.start;
      this.inputEl.selectionEnd = selectionRange.end;
    }
  }

  updateInputSelection() {
    scheduleOnce('afterRender', this, setInputSelection, this.inputMask.selection);
  }
}
