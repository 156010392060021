/* eslint-disable ember/no-classic-components */

import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
/* eslint-disable-next-line */
import classic from 'ember-classic-decorator';

@classic
export default class RequestInformationClientBase extends Component {
  @service currentPractice;
  @service session;
  @service media;

  @reads('client._isDirty') isDirty;
  @reads('currentPractice.featureWidgetPaymentMethodDesktopEnabled')
  featureWidgetPaymentMethodDesktop;
  @reads('currentPractice.featureWidgetPaymentMethodGaRolloutEnabled')
  featureWidgetPaymentMethodGaRollout;
  @reads('currentPractice.featureWidgetPaymentMethodEnabled') featureWidgetPaymentMethodEnabled;
  @reads('currentPractice.featureEnableClientPortalPrescreenerSettings')
  featureEnableClientPortalPrescreenerSettings;
  @reads('currentPractice.showPaymentSelection') showPaymentSelection;

  @computed(
    'session.{isMonarchEmbedded,isClientPortalMonarchOrSpWebsite}',
    'featureWidgetPaymentMethodGaRollout',
    'featureWidgetPaymentMethodEnabled',
    'featureEnableClientPortalPrescreenerSettings',
    'showPaymentSelection'
  )
  get canDisplayPaymentOption() {
    let {
      session,
      featureWidgetPaymentMethodGaRollout,
      featureWidgetPaymentMethodEnabled,
      featureEnableClientPortalPrescreenerSettings,
      showPaymentSelection,
    } = this;

    let { isMonarchEmbedded, isClientPortalMonarchOrSpWebsite } = session;

    let isPaymentMethodAvailable =
      (featureWidgetPaymentMethodGaRollout && isClientPortalMonarchOrSpWebsite) ||
      (featureWidgetPaymentMethodEnabled && isMonarchEmbedded);

    if (featureEnableClientPortalPrescreenerSettings) {
      return showPaymentSelection && isPaymentMethodAvailable;
    }

    return isPaymentMethodAvailable;
  }

  @computed('careCoordinatorMode')
  get labelPrefix() {
    return this.careCoordinatorMode ? '' : 'Your ';
  }

  get isDesktopFeatureEnabled() {
    return this.featureWidgetPaymentMethodDesktop && !this.media.isXsDown;
  }

  get dropdownPrimaryInstructions() {
    return this.isDesktopFeatureEnabled ? 'Choose file' : 'Upload a file';
  }

  get dropdownSecondaryInstructions() {
    return this.isDesktopFeatureEnabled ? ' or drag and drop file' : ' or take a picture';
  }

  get fieldClass() {
    return this.isDesktopFeatureEnabled ? 'col-lg-12' : 'col-lg-6';
  }
}
/* eslint-enable ember/no-classic-components */
