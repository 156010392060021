/* import __COLOCATED_TEMPLATE__ from './client-options.hbs'; */
import { classNames } from '@ember-decorators/component';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './client-options.module.scss';

@classNames(styles.component)
@classic
export default class SiteClientOptions extends Component {
  @service clientSelection;

  @reads('clientSelection.selectClientAndRedirectTask') selectClientAndRedirectTask;
}
