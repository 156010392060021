/* import __COLOCATED_TEMPLATE__ from './question.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@tagName('')
export default class FeedbackBannerQuestion extends Component {
  @service feedbackBanner;

  @action
  onAnswer(action) {
    this.feedbackBanner[action]?.();
  }
}
