import { computed } from '@ember/object';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import pht from 'client-portal/utils/persistent-hash-table';

@classic
export default class SiteDocumentsAutosaveForm extends Component {
  @service request;
  @service clientTimezone;

  @computed('model.id')
  get autosaveKey() {
    return `document-request-autosave/${this.model.id}`;
  }

  @computed('model.isReview', 'autosaveTask.performCount', 'autosaveTask.last.isError')
  get isAutosaveDisplayed() {
    if (this.model.isReview || !this.autosaveTask.last) {
      // eslint-disable-next-line getter-return
      return;
    }

    // If autosave fails, we can display that right away and performCount could be 1
    if (this.autosaveTask.last.isError) {
      return true;
    }

    // If multiple tabs are open at the same time, first time autosave succeeds in all of them
    // So we want to defer status displaying until it's stabilized
    return this.autosaveTask.performCount > 2;
  }

  didInsertElement() {
    super.didInsertElement(...arguments);

    this.autosaveRecord.autosaveRestore(this.autosaveData);
    this.autosaveTask.perform(moment());
  }

  get autosaveData() {
    let data;
    try {
      data = JSON.parse(pht.get(this.autosaveKey));
    } catch (err) {
      // noop
    }

    if (data && typeof data === 'object') {
      return data;
    } else {
      return {};
    }
  }

  set autosaveData(data) {
    pht.set(this.autosaveKey, JSON.stringify(data));
  }

  showValidationError() {
    let firstError = document.querySelector('.input.invalid');
    if (firstError) {
      firstError.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  @task(function* (startedAt) {
    yield timeout(isEmberTesting() ? 0 : 5 * 1000);

    // When newer autosave appears, it takes over
    // Old ones stop working permanently, ensuring there's only one active
    if (this.autosaveData._timestamp > startedAt.valueOf()) {
      return Promise.reject();
    }

    // If we're saving, block until we don't
    yield waitForProperty(this.completeRequestTask, 'isIdle');

    // If we're in review, block until we're back to viewing
    yield waitForProperty(this.model, 'isViewed');

    let data = this.autosaveRecord.autosaveSerialize();
    data._timestamp = startedAt.valueOf();
    this.set('autosaveData', data);

    if (!isEmberTesting() || this.autosaveTask.performCount <= 2) {
      this.autosaveTask.perform(startedAt);
    }

    return moment.tz(this.clientTimezone.timezone);
  })
  autosaveTask;
}
