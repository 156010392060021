/* import __COLOCATED_TEMPLATE__ from './client-data.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './client-data.module.scss';

export default class RequestConfirmationClientData extends Component {
  @service session;
  styles = styles;

  get client() {
    return this.args.reservation?.appointment?.client;
  }

  get relatedClient() {
    return this.args.reservation?.appointment?.relatedClient;
  }

  get clientIsCouple() {
    return this.client?.isCouple;
  }

  get clientIsMinor() {
    return this.client?.isMinor;
  }

  get clientName() {
    let { client } = this;
    return `${client.firstNickname} ${client.isCouple ? '' : client.lastName}`;
  }

  get contactName() {
    let { relatedClient } = this;
    return `${relatedClient.firstNickname} ${relatedClient.lastName}`;
  }

  get careCoordinatorMode() {
    return this.args.reservation?.channel?.careCoordinatorMode;
  }
}
