import moment from 'moment-timezone';

const CI_BROWSER_DEFAULT = 'Africa/Abidjan';
const CI_REQUIRED_TIMEZONE = 'America/Los_Angeles';

// This runs before ember-data-fastboot
// https://github.com/cardstack/ember-data-fastboot/blob/637a20ce52cd27e56dae05b1ae2d3aec0ae6f206/app/instance-initializers/ember-data-fastboot.js
export function initialize(application) {
  let clientTimezone = application.lookup('service:client-timezone');
  if (moment.tz.guess() === CI_BROWSER_DEFAULT) {
    clientTimezone.updateDefault(CI_REQUIRED_TIMEZONE);
    moment.tz.guess = () => CI_REQUIRED_TIMEZONE;
  }

  let shoebox = application.lookup('service:fastboot').get('shoebox');
  if (!shoebox) {
    return;
  }
  let dump = shoebox.retrieve('ember-data-store');
  if (!dump) {
    return;
  }

  let practice = dump.records.data.find(x => x.type === 'practices');
  if (!practice) {
    return;
  }

  moment.tz.setDefault(practice.attributes.timeZone);
}

export default {
  name: 'practice-timezone',
  before: 'ember-data-fastboot',

  initialize() {
    if (typeof FastBoot === 'undefined') {
      initialize(...arguments);
    }
  },
};
