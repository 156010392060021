import {
  attributeBindings,
  classNameBindings,
  classNames,
  tagName,
} from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@attributeBindings('type', 'tabindex', 'ariaPressed:aria-pressed')
@classNameBindings('active:activated')
@classNames('button secondary')
@tagName('button')
export default class SharedButtonToggleButton extends Component {
  type = 'button';
  tabindex = null;
  value = null;

  init() {
    super.init(...arguments);
    return this.onRegister?.(this.elementId, this.value);
  }

  click() {
    this.onButtonClick?.(this.elementId);
    this.onClick?.();
  }

  @computed('_selectedValue', 'value')
  get active() {
    return JSON.stringify(this._selectedValue) === JSON.stringify(this.value);
  }

  @computed('active')
  get ariaPressed() {
    return this.active ? 'true' : 'false';
  }
}
