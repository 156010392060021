import { action, computed } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

const TEL_DEFAULTS = {
  mask: '(111) 111-1111',
};

const DATE_DEFAULTS = {
  mask: 'mM/dD/yYYY',
  type: 'text',
  placeholderChar: '_',
  formatCharacters: {
    'd': {
      validate(char) {
        return /[0-3]|d/.test(char);
      },
    },
    'D': {
      validate(char) {
        return /[0-9]|d/.test(char);
      },
    },
    'm': {
      validate(char) {
        return /[0-1]|m/.test(char);
      },
    },
    'M': {
      validate(char) {
        return /[0-9]|m/.test(char);
      },
    },
    'y': {
      validate(char) {
        return /[1-2]|y/.test(char);
      },
    },
    'Y': {
      validate(char) {
        return /[0-9]|y/.test(char);
      },
    },
  },
  formatMask(value) {
    if (!value) {
      return value;
    }

    let FORMATTERS = {
      0: string => string.replace(/_/g, 'm'),
      1: string => string.replace(/_/g, 'd'),
      2: string => string.replace(/_/g, 'y'),
    };

    return value
      .split('/')
      .map((chunk, index) => FORMATTERS[index](chunk))
      .join('/');
  },
};

const SSN_DEFAULTS = {
  mask: '111-11-1111',
  type: 'text',
  placeholderChar: '_',
};

const EXPIRY_DEFAULTS = {
  mask: '11 / 11',
  type: 'text',
  placeholderChar: ' ',
};

const PIN_DEFAULTS = {
  mask: '111111',
  type: 'text',
  placeholderChar: '_',
};

const DEFAULTS = {
  tel: TEL_DEFAULTS,
  date: DATE_DEFAULTS,
  ssn: SSN_DEFAULTS,
  expiry: EXPIRY_DEFAULTS,
  pin: PIN_DEFAULTS,
};

@classic
@tagName('')
export default class SharedMaskedInput extends Component {
  @computed('type')
  get actualOptions() {
    let defaultOptions = DEFAULTS[this.type] || { mask: 'A' };

    return {
      mask: this.mask || defaultOptions.mask,
      formatCharacters: this.formatCharacters || defaultOptions.formatCharacters,
      placeholderChar: this.placeholderChar || defaultOptions.placeholderChar,
      type: defaultOptions.type || this.type || 'text',
      formatMask: defaultOptions.formatMask,
    };
  }

  @action
  emitChanges({ target }) {
    let { value } = target;

    if (this.type === 'date') {
      let newSubStr = this.placeholderChar || DATE_DEFAULTS.placeholderChar;
      value = target.value.replace(/[mdy]/g, newSubStr);
    }

    this.onChange?.(value);
  }
}
