export default () => {
    const callbacks = [];
    let destroyed = false;
    return {
        destroy(error) {
            destroyed = true;
            callbacks.forEach(callback => {
                callback(error);
            });
        },
        onDestroy(callback) {
            destroyed ? callback() : callbacks.push(callback);
        }
    };
};
