/* import __COLOCATED_TEMPLATE__ from './hamburger-button.hbs'; */
import {
  attributeBindings,
  classNameBindings,
  classNames,
  tagName,
} from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './hamburger-button.module.scss';

@attributeBindings('ariaExpanded:aria-expanded', 'type')
@classNameBindings('isOpen:open')
@tagName('button')
@classic
@classNames(styles.component)
export default class SharedHamburgerButton extends Component {
  type = 'button';
  openedMessage = 'Close Menu';
  closedMessage = 'Open Menu';

  @computed('isOpen')
  get ariaExpanded() {
    return this.isOpen ? 'true' : 'false';
  }

  click() {
    this.onClick();
  }

  onClick() {
    this.toggleProperty('isOpen');
  }
}
