import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class StripeSetupIntentModel extends Model {
  @belongsTo('treatable-client', { async: false }) client;

  @attr() clientSecret;
  @attr() stripeCustomerId;
  @attr('boolean') isTest;
  @attr('json') paymentMethodTypes;
}
