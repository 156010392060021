import { action } from '@ember/object';
import { service } from '@ember/service';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';

@classic
export default class RequestChannelUploads extends RequestBaseRoute {
  @service request;
  templateName = '-request/channel-uploads';

  beforeModel() {
    let reservation = this.modelFor(this.request.baseRouteName);

    if (!reservation.clientType) {
      this.request.transitionTo(this, 'client-type');
    }

    if (!reservation.channel?.hasUploads) {
      this.request.replaceWith(
        this,
        reservation.channel?.hasTextFields ? 'channel-fields' : 'information'
      );
    }
  }

  model(_params, transition) {
    transition.trigger(false, 'stopPreselectionLoading', transition);

    let reservation = this.modelFor(this.request.baseRouteName);
    let { channelUploads } = reservation;
    return { channelUploads: channelUploads || [], isValid: true };
  }

  @action
  nextStep() {
    let reservation = this.modelFor(this.request.baseRouteName);
    reservation.set('channelUploadsComplete', true);
    super.nextStep('channelUploads', this.currentModel.channelUploads, 'channel-fields');
  }

  @action
  didTransition() {
    let reservation = this.modelFor(this.request.baseRouteName);
    reservation.setProperties({
      channelFields: null,
      channelUploads: null,
      channelUploadsComplete: null,
    });

    return true;
  }
}
