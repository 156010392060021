import { action, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { htmlSafe } from '@ember/template';
import { modifier } from 'ember-modifier';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './range-input.module.scss';

const THUMB_WIDTH = 26; // The width of the range thumb including borders
const DEFAULT_BTN_WIDTH = 30; // The width of the default button
const DEFAULT_MARK_OFFSET = 3; // This is to fix the position since the markElement is larger than the thumb

function getElementOffsetLeft({ elementWidth, rangeWidth, rangeProgress, rangeSize }) {
  let elementHalfWidth = elementWidth / 2;
  let offsetLeft = (rangeProgress / rangeSize) * (rangeWidth - elementHalfWidth) + elementHalfWidth;

  return offsetLeft;
}

@classic
@classNames(styles.component)
export default class SharedRangeInput extends Component {
  defaultMin = 0;
  defaultMax = 100;
  defaultStep = 1;
  hasStepButtons = true;
  buttonsStep = 1;

  get minVal() {
    return this.min || this.defaultMin;
  }

  get maxVal() {
    return this.max || this.defaultMax;
  }

  get rangeStep() {
    return this.step || this.defaultStep;
  }

  get range() {
    return this.element.querySelector('input');
  }

  get rangeValue() {
    return parseInt(this.range.value);
  }

  @computed('maxVal', 'minVal')
  get rangeSize() {
    return this.maxVal - this.minVal;
  }

  @computed('value', 'minVal', 'rangeSize')
  get rangeProgressVar() {
    let progress = ((this.value - this.minVal) / this.rangeSize) * 100;

    return htmlSafe(`--range-progress: ${progress}%`);
  }

  @computed('value')
  get defaultMarkZIndex() {
    let {
      value,
      minVal,
      rangeSize,
      defaultValue,
      range: { offsetWidth },
    } = this;
    let thumbOffsetLeft = getElementOffsetLeft({
      elementWidth: THUMB_WIDTH,
      rangeWidth: offsetWidth,
      rangeProgress: value - minVal,
      rangeSize,
    });
    let defaultMarkOffsetLeft = getElementOffsetLeft({
      elementWidth: DEFAULT_BTN_WIDTH,
      rangeWidth: offsetWidth,
      rangeProgress: defaultValue - minVal,
      rangeSize,
    });
    let startInterceptionPosition = defaultMarkOffsetLeft - THUMB_WIDTH / 2;
    let shouldHideDefaultButton =
      thumbOffsetLeft >= startInterceptionPosition &&
      thumbOffsetLeft <= startInterceptionPosition + DEFAULT_BTN_WIDTH;

    return htmlSafe(`z-index: ${shouldHideDefaultButton ? -1 : 0}`);
  }

  placeDefaultMark = modifier(
    markElement => {
      let markElementStep = (this.range.offsetWidth - THUMB_WIDTH) / this.rangeSize;
      let defaultValueProgress = this.defaultValue - this.minVal;

      markElement.style['margin-left'] = `${
        defaultValueProgress * markElementStep - DEFAULT_MARK_OFFSET
      }px`;
    },
    { eager: false }
  );

  @action
  onRangeInputClick({ target }) {
    target.focus();
  }

  @action
  onPlusClick(evt) {
    this.onStepButtonClick(evt, this.buttonsStep);
  }

  @action
  onMinusClick(evt) {
    this.onStepButtonClick(evt, this.buttonsStep * -1);
  }

  @action
  onStepButtonClick({ target }, step) {
    target.focus();

    let { range, rangeValue, maxVal, minVal } = this;
    let newRangeValue = rangeValue + step;
    let isOutOfRange = newRangeValue > maxVal || newRangeValue < minVal;

    if (!isOutOfRange) {
      range.value = newRangeValue;
      this.onChange(newRangeValue);
    }
  }
}
