import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { modifier } from 'ember-modifier';
import { waitForLater } from '../../../utils/waiters';
import Component from '@ember/component';
import IsAnimatedMixin from '../../../mixins/component/is-animated';
import classic from 'ember-classic-decorator';

@classic
@classNames('header')
export default class SharedSideFlyoutHeader extends Component.extend(IsAnimatedMixin) {
  @computed('iconType')
  get icon() {
    if (this.iconType) {
      return this.iconType;
    }
    return 'times';
  }

  onSetup = modifier(
    async element => {
      await waitForLater(this.isAnimated ? 500 : 0);
      element.focus();
    },
    { eager: false }
  );
}
