/* import __COLOCATED_TEMPLATE__ from './reservation-data.hbs'; */
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './reservation-data.module.scss';

@classic
@classNames(styles.component)
export default class RequestConfirmationReservationData extends Component {
  mapSize = { width: 250, height: 240 };
  mobileMapSize = { width: 335, height: 185 };
}
