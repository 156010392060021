/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
import { classNames } from '@ember-decorators/component';
import Super from './base';
import classic from 'ember-classic-decorator';
import styles from './status.module.scss';

@classic
@classNames(styles.component)
export default class ClientAttendanceModalsStatus extends Super {
  styles = styles;
}
