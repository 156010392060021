import { set } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SharedEnableFieldValidations extends Component {
  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    set(this, 'model.activeValidationFields', this.activeFields);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    set(this, 'model.activeValidationFields', null);
  }
}
