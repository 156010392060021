/* import __COLOCATED_TEMPLATE__ from './appointment-card.hbs'; */
import { ATTENDANCE_STATUSES_MAP } from 'client-portal/models/unauthenticated-appointment';
import { action, computed } from '@ember/object';
import { and, reads } from '@ember/object/computed';
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import dateTime from 'client-portal/utils/date-time';
import styles from './appointment-card.module.scss';

@classic
@classNames('appointment-card', styles.component)
export default class SiteAppointmentsAppointmentCard extends Component {
  @service currentPractice;
  @service session;
  @service clientTimezone;

  attendanceStatusesMap = ATTENDANCE_STATUSES_MAP;
  timeFormat = dateTime.timeFormat;
  zoneFormat = dateTime.zoneFormat;
  humanDateFormat = dateTime.humanDateFormat;

  @and('session.currentClient.permissions.selfScheduling', 'model.isCancellable') isCancellable;
  @reads('clientTimezone.timezone') timezone;

  @computed('model.office')
  get mapsUrl() {
    let office = this.model.office;
    let address = encodeURIComponent(
      `${office.street} ${office.city} ${office.state} ${office.zip}`
    );
    return `https://maps.google.com/maps?q=${address}`;
  }

  @action
  cancel() {
    this.set('destroyTask', this.destroyModel());
  }
}
