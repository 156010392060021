/* import __COLOCATED_TEMPLATE__ from './payment-methods.hbs'; */
import { action, computed } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { empty, or, reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { success } from 'client-portal/utils/banners';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './payment-methods.module.scss';

@classic
@classNames(styles.component)
export default class SiteBillingPaymentMethods extends Component {
  styles = styles;

  @service stripeCards;
  @service currentPractice;

  showUpdateModal = false;
  showCreateModal = false;

  @reads('model.cards') cards;
  @reads('model.defaultCard') defaultCard;
  @or('stripeCards.areNewPaymentMethodTasksRunning', 'refreshTask.isRunning')
  areNewPaymentMethodActionsDisabled;
  @empty('cards') hasNoCards;
  @or('stripeCards.areTasksRunning', 'refreshTask.isRunning') isLoading;

  @computed('isLoading', 'newCard.areElementsReady')
  get isNewActionsDisabled() {
    return this.isLoading || !this.get('newCard.areElementsReady');
  }

  @computed('isLoading', 'defaultCard.areElementsReady')
  get isDefaultActionsDisabled() {
    return this.isLoading || !this.get('defaultCard.areElementsReady');
  }

  @action
  setDefaultCard(card) {
    this.setProperties({
      previousCard: this.defaultCard,
      showUpdateModal: true,
    });

    this.set('defaultCard.isDefault', false);
    card.set('isDefault', true);
  }

  @action
  hideUpdateDefault() {
    this.set('defaultCard.isDefault', false);
    this.set('showUpdateModal', false);
    this.set('previousCard.isDefault', true);
  }

  @action
  async updateDefaultCard() {
    let card = this.defaultCard;

    try {
      await this.stripeCards.saveCardTask.perform(card);
      await this.refreshTask.perform();
      this.set('showUpdateModal', false);
      success({ title: 'Default card updated' });
    } catch (err) {
      this.set('showUpdateModal', false);
      bannerError(err, { title: 'Default card could not be updated' });
    }
  }

  @action
  showCreateCard() {
    this.setProperties({
      newCard: this.stripeCards.createCard(),
      showCreateModal: true,
    });
  }

  @action
  hideCreateCard() {
    if (this.newCard.isNew) {
      this.newCard.deleteRecord();
    }

    this.set('showCreateModal', false);
  }

  @action
  async addStripeCard() {
    let card = this.newCard;

    try {
      await this.stripeCards.createTokenAndSave(card);
      await this.refreshTask.perform();
      await this.hideCreateCard();
      if (card.isDefault) {
        success({ title: 'Default card added' });
      } else {
        success({ title: 'New card added' });
      }
    } catch (err) {
      if (card.validations.isValid) {
        bannerError(err, { title: 'Card could not be added' });
      }
    }
  }

  @action
  async addStripePaymentMethod(elements) {
    try {
      let savedPaymentMethod = await this.stripeCards.addPaymentMethodTask.perform({
        elements,
        newCard: this.newCard,
      });

      if (!savedPaymentMethod) {
        return;
      }

      await this.refreshTask.perform();
      await this.hideCreateCard();

      if (this.newCard.isDefault) {
        success({ title: 'Default card added' });
      } else {
        success({ title: 'New card added' });
      }
    } catch (err) {
      bannerError(err, { title: 'Card could not be added' });
    }
  }

  @task(function* () {
    yield this.refreshRequest();
  })
  refreshTask;
}
