import { action } from '@ember/object';
import { callbackError } from 'client-portal/utils/error-handling';
import { copy } from 'ember-copy';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

const SHOEBOX_KEY = 'client-confirmation-status';
const MODEL_TYPE = 'unauthenticated-appointment';

@classic
export default class ClientAttendanceRoute extends Route.extend(FastbootHelpersMixin) {
  @service store;
  async model({ token }) {
    let params = {
      token,
      include: 'clinician,office',
    };

    if (this.hasShoebox) {
      let shoeBoxData = this.shoebox.retrieve(SHOEBOX_KEY);
      return shoeBoxData.id ? this.store.peekRecord(MODEL_TYPE, shoeBoxData.id) : shoeBoxData;
    } else {
      try {
        return await this.store.queryRecord(MODEL_TYPE, copy(params));
      } catch (err) {
        try {
          callbackError(err, ({ status }) => {
            if (status === '401') {
              return true;
            }
          });
          return await this.store.queryRecord(MODEL_TYPE, copy(params));
        } catch (err) {
          return err;
        }
      }
    }
  }

  async afterModel(model) {
    super.afterModel(...arguments);
    if (this.isFastBoot) {
      this.shoebox.put(SHOEBOX_KEY, model.id ? { id: model.id } : { errors: model.errors });
    }
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('persistModelTask', this.persistModelTask);
  }

  @(task(function* (appointment) {
    try {
      return yield appointment.save();
    } catch (err) {
      try {
        callbackError(err, ({ status }) => {
          if (status === '401') {
            return true;
          }
          // the error is re-thrown, so catch it again.
        });
        return yield appointment.save();
      } catch (err) {
        this.controller.set('hasErrorModal', true); // eslint-disable-line ember/no-controller-access-in-routes
        throw err;
      }
    }
  })
    .cancelOn('deactivate')
    .keepLatest())
  persistModelTask;

  @action
  setModalStatus(modal, status) {
    this.controller.set(modal, status); // eslint-disable-line ember/no-controller-access-in-routes
  }

  @action
  resetModalStatus() {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controller.setProperties({
      hasConfirmedModal: false,
      hasCancelledModal: false,
      hasErrorModal: false,
    });
  }
}
