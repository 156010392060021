import { timeout } from 'ember-concurrency';

export default class RecaptchaMock {
  selector = '#recaptcha-container';
  response = '456efg';

  get element() {
    return document.querySelector(this.selector);
  }

  ready(callback) {
    this.waitForEl(() => callback());
  }

  render(id, { sitekey }) {
    let html = `<label for="recaptcha">recaptcha</label>
                       <input name="recaptcha" type="checkbox" value="${sitekey}" />`;
    this.element.innerHTML = html;
  }

  reset() {
    this.element.checked = false;
  }

  getResponse() {
    if (document.querySelector(`${this.selector} input`).checked) return this.response;
    return null;
  }

  async waitForEl(callback) {
    if (this.element) {
      callback();
    } else {
      await timeout(100);
      await this.waitForEl(callback);
    }
  }
}
