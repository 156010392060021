import { createElement } from './banners';
import setupNotifications from './setup-banners';

const toasts = setupNotifications();
const { dismiss, dismissSticky } = toasts;

const filter = function (method, params) {
  let defParams = {
    ...params,
    isToast: true,
    isTransient: true,
    isPermanent: false,
    isSticky: false,
  };
  return toasts[method](defParams);
};

const success = params => filter('success', params);
const warning = params => filter('warning', params);
const error = params => filter('error', params);
const info = params => filter('info', params);

export { success, warning, error, info, dismiss, dismissSticky, createElement };
