/* import __COLOCATED_TEMPLATE__ from './new-card-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class SiteBillingPaymentModalNewCardForm extends Component {
  @tracked _saveCard;

  @service billingModals;
  @service currentPractice;

  constructor() {
    super(...arguments);
    this._saveCard = this.args.model.card._saveCard;
  }

  get hasNoCards() {
    return this.billingModals.client.cards.length === 0;
  }

  get isMakeDefaultDisabled() {
    return !this._saveCard || this.hasNoCards || this.args.disablePaymentMethodActions;
  }

  @action
  toggleSaveCard() {
    this._saveCard = !this._saveCard;
    this.args.model.card.set('_saveCard', this._saveCard);

    let setCardAsDefault = this._saveCard && this.hasNoCards;
    this.args.model.card.set('isDefault', setCardAsDefault);
  }
}
