/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import { action, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { dateFormat } from 'client-portal/utils/date-time';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import styles from './date-picker.module.scss';

@classic
@classNames(styles.component)
export default class SharedDatePicker extends Component {
  inputId = 'date';
  inputName = 'date';

  @computed('value')
  get parsedValue() {
    if (!this.value) {
      return null;
    }
    return this.value?.format ? this.value : moment(this.value, dateFormat);
  }

  @action
  setValue(value) {
    this.onSetDate(this.useMoment ? value : value.format(dateFormat));
    if (!value || value.isValid()) {
      this.set('center', value);
    }
  }
}
