import { buildValidations, validator } from 'ember-cp-validations';
import BillingItemMixin from 'client-portal/mixins/polymorphic/billing-item';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

const Validations = buildValidations({
  clientAcknowledged: validator('inclusion', {
    in: [true],
    message: 'Authorization is required to submit an out-of-network claim',
  }),
});

export default class InsuranceClaimModel extends Model.extend(Validations, BillingItemMixin) {
  @belongsTo('client', { async: false }) client;
  @belongsTo('insurance-info', { async: false }) insuranceInfo;
  @belongsTo('claim-submission', { async: false, inverse: 'insuranceClaim' }) currentSubmission;
  @hasMany('appointments', { async: false }) appointments;

  @attr() appointmentIds;
  @attr() startDate;
  @attr() endDate;
  @attr() status;
  @attr('boolean') clientAcknowledged;
  @attr() diagnosisCodes;

  get claimStatus() {
    let status = this.status;

    if (status === 'prepared') {
      status = 'Not submitted';
    }

    if (status === 'pending_submission') {
      status = 'Submitted';
    }

    if (status === 'error_scrub') {
      status = 'Submission failed';
    }

    return status;
  }
}
