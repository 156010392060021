import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { isPresent } from '@ember/utils';
import { modifier } from 'ember-modifier';
import { waitForRender } from '../../utils/waiters';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@classNames('toggle-switch')
export default class SharedToggleSwitch extends Component {
  inputElement = null;

  get isChecked() {
    return this.inputElement?.checked;
  }

  mouseUp(evt) {
    // clicks in the input are done by a screen reader and will be handled in onInputChanged
    if (this.disabled || evt.target.tagName === 'INPUT') return;

    evt.preventDefault();
    this.toggleSwitch(!this.isChecked);
  }

  get toggleId() {
    return `${this.element.id}-toggle-switch`;
  }

  onSetup = modifier(
    element => {
      this.set('inputElement', element);
      if (isPresent(this.labelPos)) this.updateStatus();
    },
    { eager: false }
  );

  @action
  updateStatus() {
    this.set('status', this.isChecked ? 'On' : 'Off');
  }

  @action
  onInputChanged() {
    this.toggleSwitch?.(this.isChecked);
  }

  @action
  async toggleSwitch(checked) {
    this.onSwitch?.(checked);

    if (isPresent(this.labelPos)) {
      await waitForRender();
      this.updateStatus();
    }
  }
}
