import BaseValidator from 'ember-cp-validations/validators/base';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

const parseDate = str => {
  let date = moment(str, 'MM / YY', true).endOf('month');

  // moment.js parses two digit year to be before 2000, so "11 / 69" parses as 1969-11
  date.add(date.year() < 2000 ? 100 : 0, 'years');

  return date;
};

const validationMessage = value => {
  let date = parseDate(value);

  if (!date.isValid()) {
    return 'Expiration Date is invalid';
  }

  if (moment().isAfter(date)) {
    return `Your card's expiration ${
      Math.abs(date.year() - moment().year()) < 1 ? 'date' : 'year'
    } is in the past.`;
  }
  if (Math.abs(date.year() - moment().year()) > 50) {
    return "Your card's expiration year is invalid.";
  }
};

@classic
class StripeCardExpiry extends BaseValidator {
  validate(value, _options, model) {
    if (model.isNew) {
      return model.get('cardExpiryElement.errorMessage') || true;
    }
    if (!/\d{2} \/ \d{2}/.test(value)) {
      return "Your card's expiration date is incomplete.";
    }

    return validationMessage(value) || true;
  }
}

StripeCardExpiry.reopenClass({
  getDependentsFor(/* attribute, options */) {
    return ['model.cardExpiryElement.errorMessage', 'model.expMonth', 'model.expYear'];
  },
});

export default StripeCardExpiry;
