import { helper } from '@ember/component/helper';

export function formatZipcode([zipcode]) {
  if (zipcode?.length > 5) {
    // test for hyphen or Canada postal code
    if (/[-]|^[a-z]\d/i.test(zipcode)) {
      return zipcode;
    }

    return [zipcode.slice(0, 5), '-', zipcode.slice(5)].join('');
  } else {
    return zipcode;
  }
}

export default helper(formatZipcode);
