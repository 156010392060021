import { buildValidations, validator } from 'ember-cp-validations';
import { A as emberA } from '@ember/array';
import { not, or } from '@ember/object/computed';
import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';

const Validations = buildValidations({
  number: [
    validator('presence', {
      presence: true,
      disabled: or('model.skipValidation', 'model.prospectiveClient'),
    }),
    validator('presence', {
      presence: true,
      message: 'Phone is required',
      disabled: not('model.prospectiveClient'),
    }),
    validator('length', {
      allowBlank: true,
      is: 10,
    }),
  ],
});

@classic
export default class PhoneModel extends Model.extend(Validations) {
  @belongsTo('polymorphic/phoneable', { polymorphic: true, async: false }) phoneable;

  @attr() thisType;
  @attr() number;
  @attr('boolean') leaveTextMessage;
  @attr('boolean') leaveVoiceMessage;

  prospectiveClient = false;

  static fieldsFor(_context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    fields.removeObject('client');
    return fields;
  }
}
