import { createElement, error } from './banners';

const adapterErrorTitles = {
  0: 'Your device seems to be offline. Please try checking your internet connection',
  422: 'A time out has occurred. Please try logging into your account and try again',
};

export function callbackError(err, callback) {
  let first = err && err.errors && err.errors[0];
  if (!first) {
    throw err;
  } else {
    // NOTE: handling a case when there is no specific error message
    // Default copy is defined here: https://github.com/emberjs/data/blob/e0732481885abde4eb187d3a1573276374068a2f/packages/adapter/addon/error.js#L93
    if (first.title === 'Adapter Error' || first.status === '401') {
      let status = first.status;
      if (status === undefined) {
        status = '';
      }
      if (
        status === '401' ||
        err.message === 'The adapter rejected the commit because it was invalid'
      ) {
        status = 422;
      }

      first.title = adapterErrorTitles[status.toString()] || '';
    } else if (first.detail?.error) {
      // NOTE: handling error data structure from stripe API
      first.title = first.detail.error;
    }

    let result = callback(first);
    if (result !== undefined) {
      return result;
    } else {
      throw err;
    }
  }
}

export function bannerError(err, params) {
  callbackError(err, message => {
    let bannerParams = Object.assign(
      {
        isTransient: true,
        content: createElement('p', { innerText: message.title }),
      },
      params
    );

    error(bannerParams);
    return true;
  });
}
