import { buildValidations, validator } from 'ember-cp-validations';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

export const UNCONFIRMED = null;
export const CANCELLED_VIA_TEXT = 'cancelled_via_text';
export const CONFIRMED_VIA_TEXT = 'confirmed_via_text';

export const CANCEL_REASON_MAX_CHARS = 150;

export const ATTENDANCE_STATUS_SHOW = 'Show';
export const ATTENDANCE_STATUS_NO_SHOW = 'No Show';
export const ATTENDANCE_STATUS_CANCELLED = 'Cancelled';
export const ATTENDANCE_STATUS_LATE_CANCELLED = 'Late Cancelled';
export const ATTENDANCE_STATUS_CLINICIAN_CANCELLED = 'Clinician Cancelled';

export const ATTENDANCE_STATUSES_MAP = {
  [ATTENDANCE_STATUS_SHOW]: 'Show',
  [ATTENDANCE_STATUS_NO_SHOW]: 'No show',
  [ATTENDANCE_STATUS_CANCELLED]: 'Canceled',
  [ATTENDANCE_STATUS_LATE_CANCELLED]: 'Late canceled',
  [ATTENDANCE_STATUS_CLINICIAN_CANCELLED]: 'Clinician canceled',
};

export const CANCELLED_STATUSES = [
  ATTENDANCE_STATUS_CANCELLED,
  ATTENDANCE_STATUS_NO_SHOW,
  ATTENDANCE_STATUS_LATE_CANCELLED,
  ATTENDANCE_STATUS_CLINICIAN_CANCELLED,
];

const Validations = buildValidations({
  cancelReason: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Please enter a reason for cancellation',
    }),
    validator('length', {
      max: 150,
      message: `Message exceeds ${CANCEL_REASON_MAX_CHARS} characters`,
    }),
  ],
});

@classic
export default class UnauthenticatedAppointmentModel extends Model.extend(Validations) {
  @belongsTo('clinician', { async: false }) clinician;
  @belongsTo('office', { async: false }) office;
  @attr() icalUrl;
  @attr() gcalendarUrl;
  @attr() cancelReason;
  @attr() visitReason;
  @attr() visitTherapyReasons;
  @attr() token;
  @attr('boolean') isCancellable;
  @attr('moment') startTime;
  @attr('moment') endTime;
  @attr() serviceDescription;
  @attr() clientConfirmationStatus;
  @attr() confirmationStatus;
  @equal('clientConfirmationStatus', UNCONFIRMED) isUnconfirmed;
  @equal('clientConfirmationStatus', CONFIRMED_VIA_TEXT) isConfirmed;
  @equal('confirmationStatus', ATTENDANCE_STATUS_CLINICIAN_CANCELLED) isClinicianCancelled;
  @attr() mentalHealthHistory;
  @attr() careTypes;
  @attr() insurancePaymentMethod;
  @attr() insuranceMemberId;
  @attr() insuranceGroupId;
  @equal('confirmationStatus', 'Pending') isPending;
  @attr() videoRoomUrl;

  @computed('endTime')
  get isExpired() {
    return moment().isAfter(this.endTime);
  }

  @computed('confirmationStatus')
  get isCancelled() {
    return CANCELLED_STATUSES.includes(this.confirmationStatus);
  }
}
