import { buildValidations, validator } from 'ember-cp-validations';
import { computed } from '@ember/object';
import { isEmberTesting } from '../utils/is-testing';
import { service } from '@ember/service';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

const Validations = buildValidations({
  number: [
    validator('inline', {
      validate(_value, _options, model) {
        return model.get('cardNumberElement.errorMessage') || true;
      },
      dependentKeys: ['model.cardNumberElement.errorMessage'],
    }),
  ],
  expiry: [validator('stripe-card-expiry')],
  cvc: [
    validator('inline', {
      validate(_value, _options, model) {
        return model.get('cardCvcElement.errorMessage') || true;
      },
      dependentKeys: ['model.cardCvcElement.errorMessage'],
    }),
  ],
  addressZip: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Zipcode is required',
    }),
  ],
  expiryCvcZip: [
    validator('inline', {
      validate(_value, _options, model) {
        let { attrs } = model.validations;
        return (
          attrs.get('expiry.message') ||
          attrs.get('cvc.message') ||
          attrs.get('addressZip.message') ||
          true
        );
      },
      dependentKeys: ['expiry', 'cvc', 'addressZip'],
    }),
  ],
});

@classic
export default class StripeCardModel extends Model.extend(Validations) {
  brandPreview = null;
  #stripeCardNumberElement = null;

  @service() stripe;

  @attr() addressZip;
  @attr() customCardToken;
  @attr() customStripeCardId;
  @attr() expMonth;
  @attr() expYear;
  @attr() expiry;
  @attr() last4;
  @attr() brand;
  @attr('boolean') isDefault;
  @attr() name;

  @computed
  get elements() {
    return this.stripe.elements();
  }

  get cardNumberElement() {
    if (!this.#stripeCardNumberElement) {
      this._createCardNumberElement();
    }

    return this.#stripeCardNumberElement;
  }

  _createCardNumberElement() {
    let stripeElement = this.stripe.createCardElement(this.elements, 'cardNumber');

    // NOTE: Specs from the client portal forcing stripe load failure
    // depend on this safe navigation
    stripeElement?.element.on('change', event => {
      this.set('brandPreview', event.brand);
    });

    this.#stripeCardNumberElement = stripeElement;
  }

  @computed
  get cardCvcElement() {
    return this.stripe.createCardElement(this.elements, 'cardCvc');
  }

  @computed
  get cardExpiryElement() {
    return this.stripe.createCardElement(this.elements, 'cardExpiry');
  }

  @computed('brand', 'last4', 'expMonth', 'expYear')
  get infoString() {
    return `${this.brand} • *${this.last4} • Exp ${this.expMonth}/${this.expYear}`;
  }

  @computed(
    'cardNumberElement.isMounted',
    'cardCvcElement.isMounted',
    'cardExpiryElement.isMounted'
  )
  get areElementsMounted() {
    return [
      'cardNumberElement.isMounted',
      'cardExpiryElement.isMounted',
      'cardCvcElement.isMounted',
    ].every(element => this.get(element));
  }

  @computed('isNew', 'areElementsMounted')
  get areElementsReady() {
    return this.isNew ? this.areElementsMounted : true;
  }

  async createToken() {
    if (isEmberTesting()) {
      return new Promise((resolve, _reject) => {
        this.setProperties({
          expMonth: '01',
          expYear: moment().add(1, 'Y').format('YY'),
          last4: '4242',
          brand: 'Visa',
        });

        return resolve(this);
      });
    }

    let response = await this.stripe.createToken(this.cardNumberElement.element, {
      name: this.name,
      // eslint-disable-next-line camelcase
      address_zip: this.addressZip,
    });

    if (response.error) {
      throw new Error({ errors: [{ title: response.error.message }] });
    }

    let token = response.token;
    let card = token.card;

    this.setProperties({
      customCardToken: token.id,
      customStripeCardId: token.card.id,
      expMonth: card.exp_month,
      expYear: card.exp_year,
      last4: card.last4,
      brand: card.brand || card.type,
    });

    return this;
  }
}
