import Super from 'client-portal/components/site/appointments/add-to-calendar-modal';
import classic from 'ember-classic-decorator';
import stylesSuperOne from 'client-portal/components/site/appointments/add-to-calendar-modal.module.scss';
import stylesSuperTwo from 'client-portal/components/client-attendance/modals/status.module.scss';

@classic
export default class ClientAttendanceModalsAddToCalendar extends Super {
  get subClassName() {
    return `${stylesSuperOne.component} ${stylesSuperTwo.component} add-to-calendar`;
  }
}
