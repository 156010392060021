import { later } from '@ember/runloop';
import { set } from '@ember/object';
import Service from '@ember/service';
import config from '../config/environment';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';
import kountSDK from '@kount/kount-web-client-sdk';

export default class KountDeviceDataCollectorService extends Service {
  nextPromise = null;

  getSessionId() {
    let lastPromise = this.nextPromise || this._collectDataPromise();

    this.collectData();

    return lastPromise;
  }

  collectData() {
    let promise = this._collectDataPromise();

    set(this, 'nextPromise', promise);

    return promise;
  }

  _collectDataPromise() {
    return new Promise(resolve => {
      let sessionId = generateUUID().replace(/-/g, '');

      try {
        let kountConfig = {
          clientID: config.kountMerchantId,
          environment: config.kountEnvironment,
          isDebugEnabled: config.kountEnvironment === 'TEST',
          isSinglePageApp: true,
          callbacks: {
            'collect-end': params => resolve(params.SessionID),
          },
        };

        kountSDK(kountConfig, sessionId);

        later(() => resolve(sessionId), config.kountResponseTimeout);
      } catch (err) {
        // If device data collection fails, just return the session id anyway
        resolve(sessionId);
      }
    });
  }
}
