/* import __COLOCATED_TEMPLATE__ from './contact.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed, get } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './contact.module.scss';

@classic
@classNames(styles.component)
export default class PracticeWebsiteEditMenuContact extends Component {
  @service request;
  @service currentPractice;

  @reads('currentPractice.requestAsNewClient') isRequestBtnEnabled;

  @computed('currentPractice.clientPortalEnabled')
  get existingClientsToggleList() {
    return [
      {
        title: 'Client Portal Button',
        enabledTip: 'Allows existing clients to sign in to your Client Portal.',
        disabledTip:
          'You need to enable the Client Portal in your SimplePractice Settings before you can turn this on.',
        path: 'showLoginLink',
        isDisabled: !get(this, 'currentPractice.clientPortalEnabled'),
      },
    ];
  }

  @computed('contactCtaErrorMsg', 'isRequestBtnEnabled', 'isShowContactCtaRequired')
  get newClientsToggleList() {
    return [
      {
        title: 'Request Appointment Button',
        enabledTip: 'Allow new clients to request an appointment through your Client Portal.',
        disabledTip:
          'You need to enable Client Portal, Online Booking and booking by new clients in your SimplePractice Settings before you can turn this on.',
        path: 'showRequestAppointmentLink',
        isDisabled: !this.isRequestBtnEnabled,
      },
      {
        title: 'Contact Form',
        classNames: 'contact-form-toggle',
        enabledTip: 'Give new clients a way to send you a message.',
        path: 'showContactCta',
        required: this.isShowContactCtaRequired,
        errorMessage: this.contactCtaErrorMsg,
      },
    ];
  }
}
