/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import './assets/vendor.scss';
import './assets/app.scss';
/* eslint-enable sort-imports-es6-autofix/sort-imports-es6 */
import { importSync, isDevelopingApp, macroCondition } from '@embroider/macros';
import Application from '@ember/application';
import Resolver from 'ember-resolver';
import config from './config/environment';
import loadInitializers from 'ember-load-initializers';

// NOTE: for full stack testing code coverage
if (typeof FastBoot === 'undefined') {
  if (macroCondition(isDevelopingApp())) {
    let { sendCoverage } = importSync('ember-cli-code-coverage');
    window.sendCoverage = sendCoverage;
  }
}

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
