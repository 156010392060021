import { action, set } from '@ember/object';
import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SharedQuestionnaireTextFieldsInput extends Component {
  @reads('answer.answers') answers;

  init() {
    super.init(...arguments);

    this.question.intakeAnswers.forEach(intakeAnswer => {
      if (this.answers.findBy('answer_id', `${intakeAnswer.id}`)) {
        return;
      }

      /* eslint-disable camelcase */
      this.answers.pushObject({ answer_id: `${intakeAnswer.id}`, answer_text: intakeAnswer.text });
      /* eslint-enable */
    });
  }

  @action
  handleSetValue(answerData, value) {
    set(answerData, 'text', value);

    let isDirty = !this.isModelNew || this.answers.some(answer => answer.text);
    set(this.answer, 'isDirty', isDirty);
  }
}
