import { buildValidations, validator } from 'ember-cp-validations';
import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';

const Validations = buildValidations({ relatedClient: validator('belongs-to') });

@classic
export default class ClientRelationshipModel extends Model.extend(Validations) {
  @belongsTo('base-client', { polymorphic: true, async: false }) relatedClient;
  @attr('boolean', { defaultValue: false }) billingDelegation;

  static fieldsFor(_context) {
    return ['billingDelegation'];
  }
}
