import { action } from '@ember/object';
import { and, empty, notEmpty } from '@ember/object/computed';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { isEmpty } from '@ember/utils';
import { next } from '@ember/runloop';
import Component from '@ember/component';
import IsAnimatedMixin from '../../mixins/component/is-animated';
import classic from 'ember-classic-decorator';
import styles from './typeahead.module.scss';

@classic
@classNameBindings('isAnimated', 'isOpen')
@classNames(styles.component)
export default class SharedTypeahead extends Component.extend(IsAnimatedMixin) {
  optionName = 'name';
  searchMinChars = 1;
  searchDelayTime = 300;
  triggerPlaceholder = 'Select';
  inputPlaceholder = '';
  inputValue = '';
  onInputChange = null;
  onSelect = null;
  isCachingEnabled = true;
  useAsInputField = false;

  query = '';
  isSearching = false;
  hasNoResults = false;
  options = [];
  initialOptionsCache = [];
  isOpen = false;
  isInfinity = false;

  @notEmpty('initialOptionsCache') hasInitialOptionsCache;
  @empty('query') isEmptyQuery;
  @and('isCachingEnabled', 'isEmptyQuery', 'hasInitialOptionsCache') isCacheHit;

  @action
  handleSelect(value, sb) {
    this._focusElement(sb);
    this.onSelect?.(value, sb);
    sb.close();
  }

  @action
  handleOpen(sb) {
    this.set('isOpen', true);
    this._focusInput(sb);
    sb.search(this.useAsInputField ? this.inputValue : '');
    this.onOpen?.(sb);
  }

  @action
  handleClose(sb) {
    this.set('isOpen', false);
    sb.deactivateOptions();
    if (!this.useAsInputField) {
      sb.setInputValue('');
    }
    this.set('query', '');
    this.onClose?.(sb);
  }

  @action
  handleClear(sb) {
    this.set('query', '');
    this._restoreOptionsFromCache(sb);
  }

  @action
  async handleSearch(query, sb) {
    let options = [];
    let isSearching = true;
    let hasNoResults = false;

    this.setProperties({ isSearching, hasNoResults, query });

    try {
      options = await this._search(sb);
    } catch {
      // noop
    }

    return options;
  }

  @action
  handleSearched(options, _query, _sb) {
    let hasNoResults = isEmpty(options);
    let isSearching = false;

    if (this.isEmptyQuery) {
      this._setInitialOptionsCache(options);
    }

    this.setProperties({ options, hasNoResults, isSearching });
    this.onSearched?.(...arguments);
  }

  @action
  handlePressUp(evt, sb) {
    evt.preventDefault();
    this._focusElement(sb);
    sb.activatePreviousOption();
  }

  @action
  handlePressDown(evt, sb) {
    evt.preventDefault();
    this._focusElement(sb);
    sb.activateNextOption();
    sb.open();
  }

  @action
  handlePressEnter(_evt, sb) {
    if (!sb.isOpen) {
      sb.open();
    }
  }

  @action
  handlePressEscape(_evt, sb) {
    this._focusElement(sb);
    sb.close();
  }

  @action
  handleFocusLeave(evt, sb) {
    if (sb.isOpen && !evt.relatedTarget) {
      this._focusElement(sb);
    }
    this.onFocusLeave?.(evt, sb);
    sb.close();
  }

  @action
  clearInput(sb, evt) {
    evt.stopImmediatePropagation();
    sb.focusInput();
    sb.setInputValue('');
    this.handleClear(sb);
  }

  @action
  preventFocus(evt) {
    evt.preventDefault();
  }

  @action
  handleInputChange(evt) {
    this.onInputChange?.(evt.target.value);
  }

  _search(sb) {
    return this.isCacheHit
      ? Promise.resolve(this.initialOptionsCache)
      : this.optionsProvider?.(this.query, sb);
  }

  _focusInput(sb) {
    // workaround for case when input is descendant of element with display:none
    next(this, function () {
      sb.focusInput();
    });
  }

  _focusElement(sb) {
    sb.element.focus();
  }

  _setInitialOptionsCache(options) {
    if (this.isCachingEnabled) {
      this.set('initialOptionsCache', options);
    }
  }

  _restoreOptionsFromCache(sb) {
    if (this.isCachingEnabled) {
      this.handleSearched(this.initialOptionsCache, '', sb);
    }
  }
}
