export default [
  'behavioral health therapy',
  'counseling',
  'marriage and family therapy',
  'psychology',
  'social work',
  'substance use counseling',
  'psychiatry',
  'psychiatric-mental health nursing',
];
