import { action } from '@ember/object';
import { and, reads } from 'macro-decorators';
import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import moment from 'moment-timezone';

export default class SharedSelectDatePicker extends Component {
  @tracked dateParts = {};
  #defaultOptions = ['DD', 'MM', 'YYYY', 'Month'];
  #isInternalReset = false;

  onValueChanged = modifier(
    () => {
      if (!this.isValueValid && !this.isManuallyClearable) {
        this.dateParts = {};
      }
      this.#onValueReset();
    },
    { eager: false }
  );

  @reads('parts.date') date;
  @reads('parts.month') month;
  @reads('parts.year') year;
  @and('hasDefaultOption', 'args.isSelectablePlaceholder') isManuallyClearable;

  get isValueValid() {
    return this.args.value?.isValid();
  }

  get hasDefaultOption() {
    return this.args.isOptional || !this.isValueValid;
  }

  get years() {
    let currentYear = moment().year();
    let { endYear, startYear } = this.args;
    return [...Array(endYear - startYear + 1).keys()]
      .map(x => currentYear + startYear + x)
      .reverse();
  }

  get months() {
    return moment.months();
  }

  get days() {
    let { month: monthPart, year: yearPart } = this.dateParts;
    let total = 31;
    if (this.isValueValid) {
      total = this.args.value.daysInMonth();
    } else if (monthPart && yearPart) {
      yearPart = +yearPart;
      monthPart = this.months.indexOf(monthPart);
      total = moment().set({ month: monthPart, year: yearPart }).daysInMonth();
    }
    return [...Array(total).keys()].map(x => x + 1);
  }

  @action
  setProperty(property, { target: { value } }) {
    this.dateParts = { ...this.dateParts, [property]: value };
    if (this.isManuallyClearable && this.#defaultOptions.includes(value)) return this.#resetValue();
    this.#setDate(this.isValueValid ? this.args.value.clone().set(property, value) : null);
  }

  get parts() {
    if (this.isValueValid) {
      return {
        date: this.args.value.date(),
        month: this.months[this.args.value.month()],
        year: this.args.value.year(),
      };
    }

    let { month, date, year } = this.dateParts;
    return {
      date: +date,
      month,
      year: +year,
    };
  }

  get parsedDate() {
    let { month, date, year } = this.dateParts;
    let hasDefaultValue = this.#defaultOptions.some(option =>
      Object.values(this.dateParts).includes(option)
    );
    return month && date && year && !hasDefaultValue
      ? moment().startOf('day').set({ year, month, date })
      : null;
  }

  #setDate(newValue) {
    let date = newValue?.isValid?.() ? newValue : this.parsedDate;
    if (!date) return;
    this.args.onSetDate(date);
  }

  #resetValue() {
    if (this.args.value === null) return;
    this.#isInternalReset = true;
    this.args.onSetDate(null);
  }

  #onValueReset() {
    if (this.args.value !== null) return;
    if (this.#isInternalReset) {
      this.#isInternalReset = false;
    } else {
      this.dateParts = {};
    }
  }
}
