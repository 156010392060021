export const MOBILE_APP_ID = 'com.simplepractice.clients';
const MIN_ANDROID_CLIENT_APP_FOR_NATIVE_RENDERING = '1.2.0';
const MIN_IOS_CLIENT_APP_FOR_NATIVE_RENDERING = '1.8.0';
const MOBILE_APP_VERSION_CAPTURE = `${MOBILE_APP_ID}:([\\d]+(\\.[\\d]+)?(\\.[\\d]+)?)\\s.+`;

export function isMobileApp() {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }

  let hasMobileAppId = new RegExp(MOBILE_APP_ID, 'g').test(navigator.userAgent);

  return hasMobileAppId || isIphoneWebview() || isAndroid();
}

function splitVersions(versionString = '0.0.0') {
  return versionString.split('.').map(v => (isNaN(Number(v)) ? 0 : parseInt(v)));
}

export function isGreaterOrEqualVersion(userAgent, minVersion) {
  let [minMajor = 0, minMinor = 0, minPatch = 0] = minVersion;
  let [uaMajor = 0, uaMinor = 0, uaPatch = 0] = userAgent;
  return (
    uaMajor > minMajor ||
    (uaMajor === minMajor && uaMinor > minMinor) ||
    (uaMajor === minMajor && uaMinor === minMinor && uaPatch >= minPatch)
  );
}

export function isClientAppMinVersion(minVersionStr) {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }
  let { userAgent } = navigator;
  let minVersion = splitVersions(minVersionStr);
  let [match, uaVersion = '0.0.0'] = new RegExp(MOBILE_APP_VERSION_CAPTURE).exec(userAgent) || [];
  let userAgentVersion = splitVersions(uaVersion);
  return !!match && isGreaterOrEqualVersion(userAgentVersion, minVersion);
}

export function isIOSMinNativeDocVersion() {
  return isIphone() && isClientAppMinVersion(MIN_IOS_CLIENT_APP_FOR_NATIVE_RENDERING);
}

export function isAndroidMinNativeDocVersion() {
  return isAndroid() && isClientAppMinVersion(MIN_ANDROID_CLIENT_APP_FOR_NATIVE_RENDERING);
}

function isIphoneWebview() {
  let { userAgent } = navigator;

  return (
    /iPhone/i.test(userAgent) &&
    !/Safari/i.test(userAgent) &&
    !isGoogleApp() &&
    !/FxiOS/i.test(userAgent)
  );
}

export function isIphone() {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }
  return /iPhone/i.test(navigator.userAgent);
}

export function isAndroid() {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }
  return /Android/i.test(navigator.userAgent);
}

export function isSafari() {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }
  let { userAgent } = navigator;
  return /Safari/i.test(userAgent) && !isGoogleApp() && !isUnsupportedBrowsers();
}

export function isChrome() {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }
  return /Chrome/.test(navigator.userAgent) && !isEdge() && !isOpera();
}

export function isEdge() {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }
  return /EdgA/.test(navigator.userAgent);
}

export function isOpera() {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }
  return /OPR/.test(navigator.userAgent);
}

export function isSamsung() {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }
  return /SamsungBrowser/.test(navigator.userAgent);
}

export function isIosChrome() {
  if (typeof FastBoot !== 'undefined') {
    return false;
  }
  let { userAgent } = navigator;
  return /Safari/i.test(userAgent) && isGoogleApp() && !isUnsupportedBrowsers();
}

function isGoogleApp() {
  let { userAgent } = navigator;
  return /CriOS/i.test(userAgent) || /GSA/i.test(userAgent) || /Chrome/i.test(userAgent);
}

function isUnsupportedBrowsers() {
  // Currently, we are not supported FF, Edge, Opera mini, and Opera
  // for some features (e.g. client app banner)
  let { userAgent } = navigator;
  return (
    /FxiOS/i.test(userAgent) ||
    /EdgiOS/i.test(userAgent) ||
    /OPiOS/i.test(userAgent) ||
    /OPT/i.test(userAgent)
  );
}
