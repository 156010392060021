import { service } from '@ember/service';
import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

export const KOUNT_SESSION_HEADER = 'Kount-Session-Id';

@classic
export default class Payment extends ApplicationAdapter {
  @service currentPractice;
  @service kountDeviceDataCollector;

  kountSessionId = null;

  get headers() {
    let paymentHeaders = super.headers;

    if (this.kountSessionId) {
      paymentHeaders[KOUNT_SESSION_HEADER] = this.kountSessionId;
    }

    return paymentHeaders;
  }

  createWithInvoiceItemPayments(data) {
    let baseUrl = this.buildURL('payments');

    return this.ajax(`${baseUrl}/create-with-invoice-item-payments`, 'POST', { data });
  }

  async ajax(url) {
    if (url.split('/').pop().includes('create')) {
      // Since headers() isn't async, wait here for Kount data collection process to complete.
      let kountSessionId = await this.kountDeviceDataCollector.getSessionId();

      this.set('kountSessionId', kountSessionId);
    }

    return super.ajax(...arguments);
  }
}
