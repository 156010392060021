import Service from '@ember/service';

export default class TestimonialSwiperService extends Service {
  // 3rd party slider plugin initialized in testimonials component
  swiper;

  slideTo(index) {
    this.swiper?._swiper?.slideTo(index + 1);
  }
}
