import { action } from '@ember/object';
import { callbackError } from 'client-portal/utils/error-handling';
import { error, success } from 'client-portal/utils/banners';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import ENV from 'client-portal/config/environment';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import InfinityModel from 'ember-infinity/lib/infinity-model';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

const { maxPageSize } = ENV;

const ExtendedInfinityModel = InfinityModel.extend({
  afterInfinityModel(model) {
    this.set('canLoadMore', model.get('length') >= maxPageSize);
  },
});

@classic
export default class SiteAppointmentsResourceRoute extends Route.extend(FastbootHelpersMixin) {
  @service infinity;

  @service appointment;
  @service prospectiveClient;

  @(task(function* (model) {
    try {
      let result = yield this.appointment.cancel(model);
      success({ title: 'Appointment cancelled successfully.' });

      // NOTE: cancel resulted in access destruction
      if (!result) {
        this.send('refreshEnvironment');
      } else {
        yield this.prospectiveClient.updatePendingAppointmentCount();
        this.refresh();
      }
    } catch (err) {
      callbackError(err, message => {
        error({ title: message.title });
        return true;
      });
    }
  })
    .cancelOn('deactivate')
    .enqueue())
  destroyModelTask;

  @action
  destroyModel(model) {
    return this.destroyModelTask.perform(model);
  }

  _model(filter) {
    return this.infinity.model(
      'appointment',
      {
        include: 'clinician,office,client',
        filter,
        perPage: maxPageSize,
        startingPage: 1,
        perPageParam: 'page[size]',
        pageParam: 'page[number]',
      },
      ExtendedInfinityModel
    );
  }
}
