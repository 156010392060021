/* import __COLOCATED_TEMPLATE__ from './sticky-footer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import styles from './sticky-footer.module.scss';

const DEFAULT_DESTINATION_ID = 'sticky-footer-container';

export default class SharedStickyFooter extends Component {
  @service stickyFooter;
  @tracked destinationElement;

  styles = styles;

  get destinationId() {
    return this.args.destinationId || DEFAULT_DESTINATION_ID;
  }

  @action
  componentInserted() {
    this.stickyFooter.register(this);
    this.destinationElement = document.getElementById(this.destinationId);
  }

  willDestroy() {
    super.willDestroy();
    this.stickyFooter.unregister(this);
  }
}
