import { action, set, setProperties } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import Super from './unsupported-browser-modal';
import browserUpdate from 'browser-update';
import classic from 'ember-classic-decorator';
import styles from './browser-update-banner.module.scss';

const LATEST_VERSION = 'is latest version of the browser';
const NO_DEVICE_UPDATE = 'no device update';

@classic
@classNames('browser-update-banner', styles.component)
export default class SharedBrowserUpdateBanner extends Super {
  compatLevel = 'compatible';
  ignoreHidReasons = false;

  @action
  onDismiss() {
    set(this, 'browserUpdateBanner.isDismissed', true);
  }

  checkBrowser(props) {
    if (!this.isIgnored() && !this.isMSIE()) {
      props.container = document.getElementById('update-banner-container');
      if (this._isInBetween(this.browserInfo)) {
        browserUpdate(this.browserUpdateConfig);
        if (this.ignoreHidReasons && (!this._isLatestVersion() || this._hasNoDeviceUpdate())) {
          setProperties(this, props);
        }
      }
    }
    props.showBanner = false;
  }

  _hasNoDeviceUpdate() {
    return this.browserUpdateConfig.hide_reasons?.includes(NO_DEVICE_UPDATE);
  }

  _isLatestVersion() {
    return this.browserUpdateConfig.hide_reasons?.includes(LATEST_VERSION);
  }

  _isInBetween({ n: name, v: version }) {
    let parsedVersion = parseFloat(version);
    return (
      parsedVersion >=
        parseFloat(this.environment.browserUpdateConfig.incompatible.required?.[name]) &&
      parsedVersion < parseFloat(this.environment.browserUpdateConfig.compatible.required?.[name])
    );
  }
}
