import { action } from '@ember/object';
import { buildStripePaymentModeElement } from 'client-portal/utils/stripe-payment-methods';
import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';

export default class StripePaymentModeElementServiceService extends Service {
  @tracked element = null;
  @tracked _paymentOptions;

  get isLoaded() {
    return !!this.element;
  }

  get paymentOptions() {
    return this._paymentOptions;
  }

  @action
  createElement(stripe, paymentOptions = {}) {
    this._paymentOptions = paymentOptions;
    this.element = buildStripePaymentModeElement(stripe, paymentOptions);

    return this.element;
  }

  @action
  destroyElement() {
    this.element = null;
    this._paymentOptions = {};
  }

  @action
  update(params = {}) {
    this.element.update(params);
  }
}
