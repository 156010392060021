// Remove me on Ember 4.4+
// https://github.com/emberjs/ember.js/releases/tag/v4.4.0
import { helper } from '@ember/component/helper';

export function uniqId() {
  return ([3e7] + -1e3 + -4e3 + -2e3 + -1e11).replace(/[0-3]/g, a =>
    ((a * 4) ^ ((Math.random() * 16) >> (a & 2))).toString(16)
  );
}

export default helper(uniqId);
