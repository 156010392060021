/* import __COLOCATED_TEMPLATE__ from './edit-button.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './edit-button.module.scss';

@classNameBindings('isHovered:highlight', 'isGlobal:global')
@classic
@classNames(styles.component)
export default class PracticeWebsiteEditButton extends Component {
  @equal('sectionName', 'global') isGlobal;
}
