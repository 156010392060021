/* import __COLOCATED_TEMPLATE__ from './pdf-modal.hbs'; */
/* eslint-disable ember/use-ember-data-rfc-395-imports */
import { action, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { loadPdfJsLib, printPdf, renderPdf } from 'client-portal/utils/pdf';
import { namespace } from 'client-portal/adapters/application';
import { service } from '@ember/service';
import Component from '@ember/component';
import DS from 'ember-data';
import classic from 'ember-classic-decorator';
import styles from './pdf-modal.module.scss';

const { PromiseObject } = DS;

@classic
@classNames(styles.component)
export default class SiteBillingPdfModal extends Component {
  @service media;
  @service billingModals;

  @service store;

  @computed('model.cursorId')
  get pdfUrl() {
    return this._pdfUrl();
  }

  @computed('model.cursorId')
  get downloadPdfUrl() {
    return `${this._pdfUrl()}?download=true`;
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    this._loadPdf();

    this.billingModals.afterPersist.push(() => {
      return this.store.queryRecord('polymorphic/billing-item', { cursorId: this.model.cursorId });
    });
    this.billingModals.afterPersist.push(() => this._loadPdf());
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.billingModals.afterPersist.pop();
    this.billingModals.afterPersist.pop();
  }

  @action
  reloadPdf() {
    this._loadPdf();
  }

  @action
  printModel() {
    printPdf(this.pdfUrl);
  }

  @action
  downloadModel() {
    window.open(this.downloadPdfUrl);
  }

  /* eslint-disable promise/prefer-await-to-then */
  _loadPdf() {
    // Clean up if we need to re-render
    this.element.querySelector('.pdf-container canvas')?.remove();
    // see: https://github.com/mozilla/pdf.js/issues/11412#issuecomment-829460479
    Object.defineProperty(Array.prototype, '_super', { enumerable: false });
    let promise = loadPdfJsLib().then(pdfjsLib => {
      pdfjsLib.disableStream = isEmberTesting();
      return renderPdf(pdfjsLib, this.pdfUrl, this.element.querySelector('.pdf-container'));
    });

    let pdfPromise = PromiseObject.create({ promise });
    pdfPromise
      .catch(() => {})
      .finally(() => {
        Object.defineProperty(Array.prototype, '_super', { enumerable: true });
      });

    this.set('pdfPromise', pdfPromise);
  }

  _pdfUrl() {
    return `/${namespace}/billing-items/${encodeURIComponent(this.model.cursorId)}.pdf`;
  }
}
