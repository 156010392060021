/* import __COLOCATED_TEMPLATE__ from './contact-form.hbs'; */
import Component from '@glimmer/component';
import styles from './contact-form.module.scss';

export default class ContactContactForm extends Component {
  styles = styles;

  get client() {
    return this.args.client;
  }

  get isClient() {
    return this.client.thisType === 'Client';
  }
}
