import { service } from '@ember/service';
import PersistAppointmentMixin from './-mixins/persist-appointment';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class RequestInformationRoute extends Route.extend(PersistAppointmentMixin) {
  @service media;
  @service request;
  @service session;
  @service pendingAppointment;

  templateName = '-request/information';

  init() {
    super.init(...arguments);
    this._resize = this._resize.bind(this);
  }

  model(params, transition) {
    transition.trigger(false, 'stopPreselectionLoading', transition);
    return this.getInformationModel();
  }

  activate() {
    super.activate(...arguments);
    window.addEventListener('resize', this._resize);
  }

  deactivate() {
    super.deactivate(...arguments);
    window.removeEventListener('resize', this._resize);
  }

  _resize() {
    let routeName = this.request.activeRouteName;
    if (
      routeName.indexOf(`${this.request.baseRouteName}.information.`) === 0 &&
      !this.get('media.isMdDown')
    ) {
      this.request.transitionTo(this, 'information.index');
    }
  }
}
