import { copy } from 'ember-copy';
import { getOwner } from '@ember/application';
import { service } from '@ember/service';
import { setProperties } from '@ember/object';
import { waitForRender } from '../../utils/waiters';
import Component from '@ember/component';
import browserUpdate from 'browser-update';
import classic from 'ember-classic-decorator';

const SAFARI_MOBILE = 'ios';
const SAFARI_CODES = [SAFARI_MOBILE, 's'];
export const INTERNET_EXPLORER_CODES = ['i', 'ie'];

@classic
export default class SharedUnsupportedBrowserModal extends Component {
  @service browserUpdateBanner;

  showUpdateMsg = true;
  compatLevel = 'incompatible';

  get environment() {
    return getOwner(this).resolveRegistration('config:environment');
  }

  get fastboot() {
    return getOwner(this).lookup('service:fastboot');
  }

  init() {
    super.init(...arguments);
    if (this.fastboot?.isFastBoot) {
      return;
    }

    this.browserInfo = window.$bu_getBrowser();
    this.browserUpdateConfig = copy(this.environment.browserUpdateConfig[this.compatLevel], true);

    this.detect();
  }

  async detect() {
    let props = {
      showBanner: this.showUpdateMsg,
    };

    this.browserUpdateConfig.onshow = () => {
      setProperties(this, props);
    };

    if (!this.browserUpdateBanner.isDismissed) {
      await waitForRender();
      this.checkBrowser(props);
    }
  }

  checkBrowser(props) {
    if (!this.isIgnored()) {
      if (this.isMSIE()) {
        INTERNET_EXPLORER_CODES.forEach(
          browser => delete this.browserUpdateConfig.required[browser]
        );
      }
      props.container = document.getElementById('update-banner-container');
      if (this._isSafari() && this._isLtRequiredIOS()) {
        setProperties(this, props);
      } else {
        browserUpdate(this.browserUpdateConfig);
      }
    }
  }

  isInIncompatible({ n: name, v: version }) {
    return (
      parseFloat(version) <=
      parseFloat(this.environment.browserUpdateConfig.incompatible.required?.[name])
    );
  }

  isMSIE() {
    let { n: name } = this.browserInfo;
    return name === 'i';
  }

  isIgnored() {
    let { n: name } = this.browserInfo;
    return this.browserUpdateConfig?.ignore?.includes(name);
  }

  _isLtRequiredIOS() {
    let { v: version, n: name } = this.browserInfo;
    return (
      name === SAFARI_MOBILE &&
      parseFloat(version) < parseFloat(this.browserUpdateConfig.required.s)
    );
  }

  _isSafari() {
    let { n: name } = this.browserInfo;
    return SAFARI_CODES.includes(name);
  }
}
