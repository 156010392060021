/* import __COLOCATED_TEMPLATE__ from './demographics-section.hbs'; */
import { classNames } from '@ember-decorators/component';
import { dateFormat } from 'client-portal/utils/date-time';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './demographics-section.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsContactInfoDemographicsSection extends Component {
  dateFormat = dateFormat;
}
