import Service, { service } from '@ember/service';

export default class WidgetTokenService extends Service {
  @service store;

  #token = null;
  #tokenData = null;

  set token(value) {
    this.#token = value;
  }

  get token() {
    return this.#token;
  }

  set tokenData(value) {
    this.#tokenData = value;
  }

  get tokenData() {
    return this.#tokenData;
  }

  async create(tokenString) {
    this.token = this.store.createRecord('widget-token', { token: tokenString });

    try {
      await this.token.save();
      this.tokenData = this.token.data;
    } catch (err) {
      window._bugsnagClient?.notify(err);
    }
  }
}
