import { importAsset } from 'client-portal/helpers/import-asset';

const numberOfFullIntros = 60;
const numberOfPatterns = 11;

export const fullSourceMap = Array.from({ length: numberOfFullIntros }, (_, i) => i + 1).reduce(
  (acc, key) => {
    if (key === 59) return acc; // NOTE: lol

    acc[key] = importAsset([`/assets/images/practice-website/intro/full/${key}.jpg`]);
    return acc;
  },
  {}
);

const thumbnailSourceKeys = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '57',
  '36',
  '37',
  '54',
  '58',
  '38',
  '55',
  '39',
  '40',
  '56',
  '41',
  '51',
  '42',
  '43',
  '52',
  '44',
  '45',
  '60',
  '53',
  '46',
  '47',
  '48',
  '49',
  '50',
];
export const thumbnailSourceMap = thumbnailSourceKeys.reduce((acc, key) => {
  acc[`t${key}`] = importAsset([`/assets/images/practice-website/intro/thumbnail/${key}.jpg`]);
  return acc;
}, {});

export const patternSourceMap = Array.from({ length: numberOfPatterns }, (_, i) => i + 1).reduce(
  (acc, key) => {
    acc[`p${key}`] = importAsset([`/assets/images/practice-website/intro/pattern/${key}.svg`]);
    return acc;
  },
  {}
);
