/* import __COLOCATED_TEMPLATE__ from './diagnosis-code.hbs'; */
import Component from '@glimmer/component';

export default class SiteBillingOutOfNetworkFormAppointmentSectionDiagnosisCode extends Component {
  get diagnosisCodes() {
    return this.args.value || {};
  }

  get diagnosisCodeCount() {
    return Object.keys(this.diagnosisCodes).length || 1;
  }

  get adjustedCount() {
    return this.diagnosisCodeCount - 1;
  }

  get primaryDiagnosis() {
    let firstDiagnosisCode = Math.min(...Object.keys(this.diagnosisCodes));
    return this.diagnosisCodes[firstDiagnosisCode];
  }

  get formattedDiagnosisCodes() {
    return Object.entries(this.diagnosisCodes).map(code => code[1]);
  }
}
