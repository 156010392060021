/* eslint-disable camelcase */
export const relationshipTypes = {
  '': 'Select one',
  child: 'Child',
  family_member: 'Family Member', // eslint-disable-line camelcase
  guardian: 'Legal Guardian',
  physician: 'Physician',
  parent: 'Parent',
  partner: 'Partner',
  spouse: 'Spouse',
  other: 'Other',
};
/* eslint-enable */

export function relationshipName(type) {
  return type === 'client' ? 'Client' : relationshipTypes[type];
}

export function relationshipLabel(type) {
  return type === 'client' ? 'Client (Self)' : relationshipTypes[type];
}
