import { belongsTo } from '@ember-data/model';
import Super from './document-request';
import classic from 'ember-classic-decorator';

@classic
export default class DocumentRequestContactInfo extends Super {
  documentIcon = 'user';
  mixpanelType = 'Demographic info';

  @belongsTo('document-request-contact-info-connection', { async: false }) connection;
}
