/* import __COLOCATED_TEMPLATE__ from './body.hbs'; */
import { action } from '@ember/object';
import { and, empty, reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@tagName('')
export default class RequestRequestWrapperBody extends Component {
  @service currentPractice;
  @service request;
  @service session;
  @service mixpanel;

  @empty('session.currentClient') isNewClient;
  @reads('activeSteps.firstObject.isActive') isFirstStep;
  @and('isNewClient', 'isFirstStep') showSignInBox;

  @action
  trackSignIn() {
    if (this.activeSteps.findBy('isActive').route === 'service') {
      this.mixpanel.track('user: booking widget sign in cta clicked', {
        'widget_step': 'select_service',
        'source': this.request.sourceType,
      });
    }
  }
}
