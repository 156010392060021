/* import __COLOCATED_TEMPLATE__ from './insurance-payment-method-field.hbs'; */
import { HAS_INSURANCE_TYPE, NO_INSURANCE_TYPE } from 'client-portal/models/base-client';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import styles from './insurance-payment-method-field.module.scss';

export const NO_ANSWER = 'No answer';
export const NO_RESPONSE = 'No response';
export const INSURANCE = 'Insurance';
export const OUT_OF_POCKET = 'Out-of-pocket';
export const SELF_PAY = 'Self-pay';

export default class RequestInformationSharedInsurancePaymentMethodField extends Component {
  styles = styles;
  insuranceOption = INSURANCE;

  insurancePaymentOptions = {
    [NO_ANSWER]: NO_RESPONSE,
    [INSURANCE]: INSURANCE,
    [OUT_OF_POCKET]: SELF_PAY,
  };

  @action
  updatePaymentMethod(event) {
    let paymentMethod = event.target.value;
    this.args.onUpdatePaymentMethod(paymentMethod);
    this.args.onUpdateBillingType(this._getBillingType(paymentMethod));
  }

  _getBillingType(paymentMethod) {
    return paymentMethod === INSURANCE ? HAS_INSURANCE_TYPE : NO_INSURANCE_TYPE;
  }
}
