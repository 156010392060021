import { STEP_IDS } from 'client-portal/services/widget-steps';
import { action } from '@ember/object';
import { service } from '@ember/service';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';
import uuidv4 from 'uuid/v4';

@classic
export default class RequestClinician extends RequestBaseRoute.extend(FastbootHelpersMixin) {
  @service analytics;
  @service session;
  @service mixpanel;
  @service store;
  @service request;
  @service pendingAppointment;
  templateName = '-request/clinician';
  stepId = STEP_IDS.SELECT_CLINICIAN;

  model(params, transition) {
    let reservation = this.modelFor(this.request.baseRouteName);
    if (!reservation) {
      return;
    }

    if (this.hasShoebox) {
      return this.store.peekAll('clinician');
    } else {
      if (params.clinicianId) {
        transition.trigger(false, 'preselectionLoading');
      }
      return this.findClinicians(transition, reservation);
    }
  }

  afterModel(model, transition) {
    super.afterModel(...arguments);

    if (model && !model.get('length')) {
      this.request.transitionTo(this, 'unavailable', {
        queryParams: { reason: 'clinician does not exist' },
      });
    }

    if (this.skipStepWithPreselection(model, transition, 'clinicianId')) return;

    if (model && model.get('length') === 1) {
      transition.trigger(false, 'nextStep', model.get('firstObject'));
    }
    this.pendingAppointment.reset();
  }

  findClinicians(transition, reservation) {
    let filter = {};
    let globalMonarchChannelId = reservation.get('channel.id');

    let { searchByOffice, officeId } = transition.to.queryParams;
    if (searchByOffice && officeId) {
      filter.officeId = officeId;
    }

    if (globalMonarchChannelId) {
      filter.globalMonarchChannelId = globalMonarchChannelId;
    }
    return this.store.query('clinician', { filter });
  }

  @action
  nextStep(clinician, params) {
    super.nextStep('clinician', clinician, 'service', params);

    let controller = this.controllerFor(this.request.baseRouteName);
    controller.updateNavigationSteps();
  }

  @action
  didTransition() {
    let reservation = this.modelFor(this.request.baseRouteName);
    if (!reservation) {
      return;
    }

    reservation.setProperties({
      clinician: null,
      cptCode: null,
      office: null,
      startTime: null,
      clientType: null,
      message: null,
      hasCompletedMessageStep: false,
      channelFields: null,
      channelUploads: null,
      channelUploadsComplete: null,
      hasCompletedInformationStep: false,
      flowId: uuidv4(),
    });

    this._trackAppointmentStep(reservation);
    return true;
  }
}
