/* import __COLOCATED_TEMPLATE__ from './cancel.hbs'; */
import {
  ATTENDANCE_STATUS_CANCELLED,
  CANCELLED_VIA_TEXT,
  CANCEL_REASON_MAX_CHARS,
} from 'client-portal/models/unauthenticated-appointment';
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import Super from './base';
import classic from 'ember-classic-decorator';
import styles from './cancel.module.scss';

@classic
@classNames(styles.component)
export default class ClientAttendanceModalsCancel extends Super {
  @service currentPractice;
  styles = styles;

  CANCEL_REASON_MAX_CHARS = CANCEL_REASON_MAX_CHARS;

  @action
  cancelAppt(model) {
    model.set('isDirty', true);
    model.validate();
    if (model.validations.isValid) {
      model.setProperties({
        clientConfirmationStatus: CANCELLED_VIA_TEXT,
        confirmationStatus: ATTENDANCE_STATUS_CANCELLED,
      });
      this.cancel(model);
    }
  }
}
