/* import __COLOCATED_TEMPLATE__ from './thank-you.hbs'; */
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './thank-you.module.scss';

@classic
@classNames('thank-you', styles.component)
export default class RequestConfirmationThankYou extends Component {
  @service session;
}
