module.exports = [
  'Armenian',
  'Asian',
  'Asian American',
  'Black/African American',
  'Eastern European',
  'Hispanic/Latinx',
  'Jewish',
  'Middle Eastern/North African',
  'Native American/Indigenous',
  'Pacific Islander',
  'White',
  'Biracial/Multiracial',
];
