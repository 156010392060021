/* import __COLOCATED_TEMPLATE__ from './fees.hbs'; */
import { action, set, setProperties } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { gte, reads } from '@ember/object/computed';
import { maxMarketingFeeCount } from 'client-portal/models/practice-website/marketing-fee';
import { menuError } from 'client-portal/utils/practice-website-banners';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './fees.module.scss';

@classic
@classNames(styles.component)
export default class PracticeWebsiteEditMenuFees extends Component {
  @service store;
  @reads('marketingFeesProxy.length') count;
  @reads('model.hasMarketingFees') hasMarketingFees;
  @gte('count', maxMarketingFeeCount) isMaxCapacity;

  init() {
    super.init(...arguments);
    let { hasMarketingFees, marketingFees } = this.model;
    let marketingFeesProxy = hasMarketingFees ? marketingFees.map(x => x) : [this._createRecord()];
    setProperties(this, { marketingFeesProxy });
  }

  @action
  reorder(marketingFeesProxy) {
    this._setModelFromProxy(marketingFeesProxy);
    setProperties(this, { marketingFeesProxy });
    this.notifyDirtyStatus(this.model);
  }

  @action
  setValue(model, path, value) {
    set(model, path, value);
    this._setModelFromProxy(this.marketingFeesProxy);
  }

  @action
  addNewRecord() {
    if (this.isMaxCapacity) {
      return menuError({
        title: `Can't add more than ${maxMarketingFeeCount} fees.`,
      });
    }

    let record = this._createRecord();
    this.marketingFeesProxy.addObject(record);
  }

  @action
  remove(item) {
    this.marketingFeesProxy.removeObject(item);
    if (this.model.marketingFees.includes(item)) {
      this._setModelFromProxy(this.marketingFeesProxy);
    }
    if (this.marketingFeesProxy.length === 0) {
      this.addNewRecord();
    }
  }

  _createRecord() {
    return this.store.createFragment('practice-website/marketing-fee');
  }

  _setModelFromProxy(proxy) {
    set(
      this,
      'model.marketingFees',
      proxy.reject(fee => fee.isBlank)
    );
    this.notifyDirtyStatus(this.model);
  }
}
