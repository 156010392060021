import { STEP_IDS } from 'client-portal/services/widget-steps';
import { action, computed } from '@ember/object';
import { service } from '@ember/service';
import PersistAppointmentMixin from './-mixins/persist-appointment';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';

@classic
export default class RequestMessage extends RequestBaseRoute.extend(PersistAppointmentMixin) {
  @service analytics;
  @service mixpanel;
  @service session;
  @service request;
  @service currentPractice;
  templateName = '-request/message';
  stepId = STEP_IDS.INTAKE_QUESTIONS;

  @computed('request.baseRouteName')
  get reservation() {
    return this.modelFor(this.request.baseRouteName);
  }

  beforeModel() {
    let { reservation } = this;

    if (
      reservation.get('practice.clientTypesAllowedToBookAppt').length > 1 &&
      !this.reservation.get('clientType')
    ) {
      this.request.transitionTo(this, 'client-type');
    } else if (!reservation.get('isReserved')) {
      this.request.transitionTo(this, 'date');
    }
  }

  model() {
    this.reservation.setProperties({ reasonsForVisit: [], mentalHealthHistory: [], careTypes: [] });
    return this.reservation;
  }

  afterModel(model, transition) {
    transition.trigger(false, 'stopPreselectionLoading', transition);
  }

  @action
  async nextStep(fromPath) {
    let { currentModel, session } = this;
    let { reorderedNavEnabled } = session;

    if (reorderedNavEnabled) {
      await this.reorderedNextStep({ isDesktopNextStep: fromPath === 'desktop' });
    } else {
      let nextStep = session.channel ? 'channel-uploads' : 'information';
      currentModel.set('hasCompletedMessageStep', true);
      super.nextStep('message', currentModel.message, nextStep);
    }
  }

  async reorderedNextStep({ isDesktopNextStep }) {
    let { currentModel } = this;

    let appointment = this.getAppointment();
    let {
      reasonsForVisit: visitTherapyReasons,
      message: visitReason,
      mentalHealthHistory: mentalHealthHistory,
      careTypes: careTypes,
    } = currentModel;
    appointment.setProperties({
      visitTherapyReasons,
      visitReason,
      mentalHealthHistory,
      careTypes,
    });
    try {
      if (isDesktopNextStep) {
        await this.validateAndPersistAppointmentTask.perform();
      } else {
        await this.validateFieldsTask.perform();
        this.request.transitionTo(this, 'information.review');
      }
      currentModel.set('hasCompletedMessageStep', true);
    } catch (err) {
      // noop
    }
  }

  @action
  didTransition() {
    this.reservation.setProperties({
      hasCompletedMessageStep: false,
      channelFields: null,
      channelUploads: null,
      channelUploadsComplete: null,
    });
    let reservation = this.modelFor(this.request.baseRouteName);

    this._trackAppointmentStep(reservation);
    return true;
  }
}
