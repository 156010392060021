/* import __COLOCATED_TEMPLATE__ from './client-app-banner.hbs'; */
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './client-app-banner.module.scss';

@classic
@classNames(styles.component)
export default class ClientAppBanner extends Component {
  @service clientAppBanner;

  @reads('clientAppBanner.showBanner') showBanner;

  @action
  close() {
    this.clientAppBanner.hide();
    this.clientAppBanner.trackDismissEvent();
  }

  @action
  continueToAppStore() {
    this.clientAppBanner.visitAppStore();
  }
}
