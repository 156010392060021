/* import __COLOCATED_TEMPLATE__ from './invoices-table.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { humanDateFormat } from 'client-portal/utils/date-time';
import { lt, or } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './invoices-table.module.scss';

@classic
@classNames(styles.component)
export default class SiteBillingInvoicesTable extends Component {
  @service media;
  @service billingModals;

  humanDateFormat = humanDateFormat;

  @lt('totalBalance', 0) hasTotalBalance;
  @or('client.clientBillingOverview.unallocatedPaymentAmount', 'hasTotalBalance') showTotalCharges;

  @computed('records.@each.{totalAmount,isPaid}')
  get totalBalance() {
    let balance = this.records.reduce((sum, x) => (sum += x.isPaid ? 0 : x.totalAmount), 0);
    return Math.round(balance * 100) / 100;
  }

  @computed('records.@each.remainingAmount')
  get unallocatedTotalBalance() {
    let balance = this.records.reduce((sum, x) => (sum += x.remainingAmount), 0);
    return isNaN(balance) ? 0 : Math.round(balance * 100) / 100;
  }
}
