import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Mixin from '@ember/object/mixin';

function routeShoeboxKey(routeName) {
  return `has-model-for-${routeName.replace(/\./g, '-')}`;
}

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  fastboot: service(),
  router: service(),

  isFastBoot: reads('fastboot.isFastBoot'),
  shoebox: reads('fastboot.shoebox'),

  hasShoebox: computed('_hasRouteShoebox', '_shoeboxUnusable', function () {
    return this._hasRouteShoebox && !this._shoeboxUnusable;
  }),

  afterModel() {
    this._super(...arguments);

    if (this.isFastBoot) {
      this.set('_hasRouteShoebox', true);
    }
  },

  actions: {
    didTransition() {
      this.set('_shoeboxUnusable', true);
      return true;
    },
  },

  _hasRouteShoebox: computed({
    get() {
      let value = this.shoebox.retrieve(routeShoeboxKey(this.routeName));
      // console.log(routeShoeboxKey(this.routeName), value);
      return value;
    },

    set(_, value) {
      return this.shoebox.put(routeShoeboxKey(this.routeName), value);
    },
  }),
});
