import { service } from '@ember/service';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class RequestIndexRoute extends Route {
  @service request;
  @service geolocation;
  @service clientTimezone;

  templateName = '-request/index';

  beforeModel({ to: { queryParams } }) {
    // FIXME: https://github.com/ember-fastboot/ember-cli-fastboot/issues/202
    // We'd rather do transition.abort() here (after redirect) instead of returning null model
    this.resetFlowId();

    this.request.replaceWith(this, queryParams.clinicianId ? 'service' : 'clinician');

    this.clientTimezone.reset();
  }

  resetFlowId() {
    let reservation = this.modelFor(this.request.baseRouteName);

    if (reservation && reservation.flowId) {
      delete reservation.flowId;
    }
  }
}
