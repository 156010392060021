import { action } from '@ember/object';
import { buildStripePaymentElement } from 'client-portal/utils/stripe-payment-methods';
import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';

export default class StripePaymentElementServiceService extends Service {
  @tracked _element = null;
  @tracked isLoaded = false;

  @action
  destroyElement() {
    this._element?.destroy();
    this._element = null;
    this.isLoaded = false;
  }

  @action
  getOrCreateElement(stripeElement) {
    if (this._element) {
      return this._element;
    }

    this._element = buildStripePaymentElement(stripeElement, () => (this.isLoaded = true));

    return this._element;
  }
}
