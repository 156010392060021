import { attr, belongsTo } from '@ember-data/model';
import { oneWay } from '@ember/object/computed';
import Super from './document-request';
import classic from 'ember-classic-decorator';

@classic
export default class DocumentRequestGoodFaithEstimate extends Super {
  documentIcon = 'file-text';
  mixpanelType = 'Good faith estimate';

  @belongsTo('good-faith-estimate', { async: false }) goodFaithEstimate;
  @belongsTo('good-faith-estimate-overview', { async: false }) goodFaithEstimateOverview;
  @attr('moment') documentDate;

  @oneWay('isComplete') hasDocumentPdf;
}
