import { modifier } from 'ember-modifier';

export default modifier(
  (element, positional, { shouldAutoscroll }) => {
    if (shouldAutoscroll === undefined || shouldAutoscroll) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  },
  { eager: false }
);
