/* import __COLOCATED_TEMPLATE__ from './empty.hbs'; */
import { classNames } from '@ember-decorators/component';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './empty.module.scss';

@classic
@classNames(styles.component)
export default class PracticeWebsiteEmpty extends Component {
  // random image empty-1 to empty-4
  get imageName() {
    let randomDigit = Math.floor(Math.random() * 4) + 1;
    return isEmberTesting() ? 'empty-1' : `empty-${randomDigit}`;
  }
}
