/* import __COLOCATED_TEMPLATE__ from './document-page.hbs'; */
import { ensureSafeComponent } from '@embroider/util';
import { importSync } from '@embroider/macros';
import Component from '@glimmer/component';

export default class SiteDocumentPage extends Component {
  get requestTypeComponent() {
    let module = importSync(`./documents/${this.args.model.requestType}-form`);
    return ensureSafeComponent(module.default, this);
  }
}
