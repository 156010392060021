import { ACTIVE_FEEDBACK_BANNER_ID } from 'client-portal/utils/feedback-banner-events';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class SiteDocumentsRoute extends Route {
  @service currentPractice;
  @service session;

  async beforeModel(transition) {
    await super.beforeModel(...arguments);

    this.session.requireAuthentication(transition, 'home');
    let { isHeaderFooterHidden, redirect } = transition.to.queryParams;

    if (isHeaderFooterHidden || redirect?.includes('documents')) {
      this.session.set('isHeaderFooterHidden', true);
    }
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.setProperties({
      activeFeedbackBannerId: ACTIVE_FEEDBACK_BANNER_ID,
      hideCookieBanner: this.currentPractice.get('hasClientappDocumentAccess'),
    });
  }
}
