import { action } from '@ember/object';
import { applyValidationError, resetErrors } from 'client-portal/utils/validate-record';
import { service } from '@ember/service';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';

@classic
export default class RequestChannelFields extends RequestBaseRoute {
  @service request;
  templateName = '-request/channel-fields';

  beforeModel() {
    let reservation = this.modelFor(this.request.baseRouteName);

    if (!reservation.clientType) {
      this.request.transitionTo(this, 'client-type');
    }

    if (!reservation.channel?.hasTextFields) {
      this.request.transitionTo(this, 'information');
    }
  }

  model() {
    let reservation = this.modelFor(this.request.baseRouteName);
    let { channelFields } = reservation;
    return { channelFields: channelFields || reservation.channel.textFields };
  }

  afterModel(model, transition) {
    super.afterModel(...arguments);

    let { referenceId, coveredSessions } = transition.to.queryParams;
    let channel = this.modelFor(this.request.baseRouteName).channel;

    if (coveredSessions) {
      model.channelFields.findBy('name', 'coveredSessions')?.set('value', coveredSessions);
    }

    if (referenceId) {
      transition.trigger(false, 'preselectionLoading');

      model.channelFields.findBy('name', 'referenceId')?.set('value', referenceId);
    }

    let hasCustomFieldRefId = channel.hasCustomField('reference_id');
    if (!hasCustomFieldRefId || (hasCustomFieldRefId && referenceId)) {
      this.nextStep();
    }

    transition.trigger(false, 'stopPreselectionLoading', transition);
  }

  @action
  async nextStep() {
    let model = this.modelFor(this.routeName);
    let isValid = await model.channelFields.every(f => {
      resetErrors(f);
      f.validate();
      applyValidationError(f);
      return f.validations.isValid;
    });

    if (isValid) {
      super.nextStep('channelFields', model.channelFields, 'information');
    }
  }

  @action
  didTransition() {
    let reservation = this.modelFor(this.request.baseRouteName);
    reservation.set('channelFields', null);

    return true;
  }
}
