import { computed } from '@ember/object';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class InsuranceProviderNameModel extends Model {
  @attr() nameWithPayer;

  @computed('nameWithPayer')
  get name() {
    return this.nameWithPayer.replace(/(.+)\(.*/, '$1').trim();
  }

  @computed('nameWithPayer', 'name')
  get payer() {
    let payer = this.nameWithPayer.replace(/.+\((.+)\)/, '$1').trim();
    return payer === this.name ? '' : payer;
  }
}
