/* import __COLOCATED_TEMPLATE__ from './expertises.hbs'; */
import {
  globalMarketingSpecialties,
  maxMarketingSpecialtiesCount,
} from 'client-portal/models/practice-website/marketing-specialty';
import { mapBy, setDiff } from '@ember/object/computed';
import { set } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeWebsiteEditMenuExpertises extends Component {
  maxCount = maxMarketingSpecialtiesCount;

  @mapBy('model.marketingSpecialties', 'name') marketingSpecialtyNames;
  @setDiff('globalMarketingSpecialties', 'marketingSpecialtyNames') globalList;

  init() {
    super.init(...arguments);
    set(
      this,
      'globalMarketingSpecialties',
      [...globalMarketingSpecialties].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    );
  }
}
