import classic from 'ember-classic-decorator';
/* global Stripe */
import { isEmberTesting } from '../utils/is-testing';
import Service from '@ember/service';
import emberO, { computed } from '@ember/object';

@classic
export default class StripeService extends Service {
  stripe = null;

  setup(key) {
    if (!key || typeof Stripe === 'undefined') {
      return;
    }

    this._silenceWarnings(() => {
      this.set('stripe', new Stripe(key, { canCreateRadarSession: true }));
    });
  }

  createToken() {
    return this.stripe.createToken(...arguments);
  }

  elements() {
    let { stripe } = this;
    return (
      stripe &&
      stripe.elements({
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans',
          },
        ],
      })
    );
  }

  createCardElement(elements, elementType) {
    if (!elements) {
      return;
    }
    let element = elements.create(elementType);
    let subject = emberO
      .create({
        element,
        elementType,
        isMounted: false,
        isEmpty: !isEmberTesting(),
        isComplete: false,
        isFocused: false,
        errorMessage: null,
      })
      .reopen({
        isValid: computed('isEmpty', 'isComplete', 'errorMessage', function () {
          return isEmberTesting() || (!this.errorMessage && (this.isEmpty || this.isComplete));
        }),
      });

    element.on('focus', () => {
      subject.set('isFocused', true);
    });

    element.on('blur', () => {
      subject.set('isFocused', false);
    });

    element.on('change', evt => {
      subject.set('isEmpty', evt.empty);
      subject.set('isComplete', evt.complete);
      subject.set('errorMessage', evt.error && evt.error.message);
    });

    element.on('ready', () => {
      subject.set('isMounted', true);
    });

    return subject;
  }

  createRadarSession() {
    return this.stripe.createRadarSession(...arguments);
  }

  /* eslint-disable no-console */
  _silenceWarnings(fn) {
    let consoleWarn;
    if (isEmberTesting()) {
      consoleWarn = console.warn;
      console.warn = () => {};
    }

    fn();

    if (isEmberTesting()) {
      console.warn = consoleWarn;
    }
  }
}
