import { action, set } from '@ember/object';
import Super from './single-select-input';
import classic from 'ember-classic-decorator';

@classic
export default class SharedQuestionnaireMultiSelectInput extends Super {
  @action
  selectAnswer(intakeAnswer) {
    let existing = this.answers.findBy('answer_id', `${intakeAnswer.id}`);
    if (existing) {
      this.answers.removeObject(existing);
    } else {
      /* eslint-disable camelcase */
      this.answers.pushObject({
        answer_id: `${intakeAnswer.id}`,
        answer_text: intakeAnswer.text,
      });
      /* eslint-enable */
    }

    let isDirty = !this.isModelNew || Boolean(this.answers.length);
    set(this.answer, 'isDirty', isDirty);
  }
}
