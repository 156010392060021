import { helper } from '@ember/component/helper';
import { importSync } from '@embroider/macros';

// NOTE: this is for images only right now, but can be extended to other subdirs
// We can't do just /assets because webpack would go bonkers
export function importAsset([asset]) {
  return importSync(`/assets/images/${asset.replace(/^\/assets\/images\//, '')}`).default;
}

export default helper(importAsset);
