import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import ENV from 'client-portal/config/environment';
import Service, { inject as service } from '@ember/service';

const DEFAULT_DEBOUNCE_TIME = 50;

export default class ProspectiveClientService extends Service {
  @service store;
  @service session;

  @tracked canScheduleNewAppointments;
  @tracked hasAcceptedAppointment;
  @tracked hasRequestedAppointment;

  #hasCancelledOnly;

  constructor() {
    super(...arguments);
    this.reset();
  }

  async getUpcomingAppointmentCounts() {
    try {
      const adapter = this.store.adapterFor('appointment');
      return await adapter.getUpcomingAppointmentCounts();
    } catch (error) {
      return {};
    }
  }

  get canSelfSchedule() {
    return this.session.currentClient?.permissions?.selfScheduling;
  }

  get canShowAppointments() {
    return this.session.currentClient?.permissions?.appointments;
  }

  get debounceTime() {
    if (ENV.environment === 'test') {
      return 0;
    }

    return this.time || DEFAULT_DEBOUNCE_TIME;
  }

  get hasProspectiveClientFeature() {
    return this.session.currentPractice.get('featureProspectiveClient');
  }

  get useProspectiveClientFlow() {
    let { session, hasProspectiveClientFeature } = this;
    let { currentClientAccess } = session;
    let isFullySignedIn = currentClientAccess && !currentClientAccess.isOtpPending;

    return (
      session.currentClient?.isProspectiveOrArchived &&
      isFullySignedIn &&
      hasProspectiveClientFeature
    );
  }

  get recommendedRoute() {
    let { useProspectiveClientFlow, hasAcceptedAppointment, hasRequestedAppointment } = this;
    let route = 'requested';

    if (
      useProspectiveClientFlow &&
      (!hasRequestedAppointment || (hasAcceptedAppointment && this.#hasCancelledOnly))
    ) {
      route = 'upcoming';
    }
    return `site.appointments.${route}`;
  }

  async updatePendingAppointmentCount() {
    let { useProspectiveClientFlow, canSelfSchedule, canShowAppointments } = this;
    if (useProspectiveClientFlow && canShowAppointments) {
      try {
        let { appointments, confirmations } = await this.getUpcomingCountsWithDebounce.perform();

        let { requested = 0, accepted = 0 } = confirmations || {};
        let { show = 0, cancelled = 0 } = appointments || {};

        let canShowUpcoming = accepted || show > requested || cancelled;
        this.hasAcceptedAppointment = !!canShowUpcoming;
        this.hasRequestedAppointment = !!requested;

        let hasScheduledAppointments = requested || accepted || show;
        this.canScheduleNewAppointments = canSelfSchedule && !hasScheduledAppointments;

        this.#hasCancelledOnly =
          cancelled && this.hasAcceptedAppointment && !this.hasRequestedAppointment;
      } catch (error) {
        this.reset();
      }
    } else {
      this.reset();
    }
  }

  reset() {
    let { canShowAppointments, canSelfSchedule } = this;
    this.hasAcceptedAppointment = canShowAppointments;
    this.hasRequestedAppointment = canShowAppointments;
    this.canScheduleNewAppointments = canSelfSchedule;
  }

  @(task(function* () {
    yield timeout(this.debounceTime);
    return this.getUpcomingAppointmentCounts();
  }).restartable())
  getUpcomingCountsWithDebounce;
}
