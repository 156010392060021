/* import __COLOCATED_TEMPLATE__ from './site-error.hbs'; */
import { action, computed } from '@ember/object';
import { attributeBindings, classNames } from '@ember-decorators/component';
import { ensureSafeComponent } from '@embroider/util';
import { equal } from '@ember/object/computed';
import { importSync } from '@embroider/macros';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './site-error.module.scss';

@classic
@attributeBindings('status:data-test-error-status')
@classNames(styles.component)
export default class SiteError extends Component {
  @service currentPractice;
  @service router;

  @computed('statusCode')
  get status() {
    return ['403', '404', '500'].includes(this.statusCode) ? this.statusCode : '500';
  }

  @computed('status')
  get statusComponent() {
    let module = importSync(`./site-error/status-${this.status}`);
    return ensureSafeComponent(module.default, this);
  }

  @equal('status', '500') isRetry;

  @action
  refresh() {
    let { router } = this;
    let routeName = router.get('currentRoute.name');
    router.transitionTo(routeName);
  }
}
