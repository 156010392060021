// NOTE: this expects bugsnag JS loaded before the ember

import Ember from 'ember'; // eslint-disable-line no-restricted-imports
import config from 'ember-get-config';

export function initialize() {
  if (!window.Bugsnag || !config.bugsnag.apiKey) {
    return;
  }

  window._bugsnagClient = window.Bugsnag.start(config.bugsnag.apiKey);
  Ember.onerror = err => {
    window._bugsnagClient.notify(err);
    console.error(err); // eslint-disable-line no-console
  };
}

export default {
  name: 'bugsnag',
  initialize,
};
