import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { hasPhysicalAddress } from 'client-portal/models/good-faith-estimate';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

@classic
export default class GoodFaithEstimateService extends Fragment {
  @attr() serviceDetails;
  @attr() diagnosisCode;
  @attr() location;
  @attr() street;
  @attr() city;
  @attr() state;
  @attr() zip;
  @attr('number') quantity;
  @attr('number') fee;

  @computed('quantity', 'fee')
  get total() {
    let { quantity = 0, fee = 0 } = this;

    return quantity * fee;
  }

  @computed('street', 'city', 'state', 'zip', 'location')
  get hasPhysicalAddress() {
    return hasPhysicalAddress(this);
  }
}
