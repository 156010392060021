import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

export const maxReferenceIdLength = 50;

const Validations = buildValidations({
  value: [
    validator('length', {
      max: maxReferenceIdLength,
      message: `Can't be more than ${maxReferenceIdLength} characters`,
    }),
    validator('inline', {
      validate(value, _options, _model) {
        if (value && !value.match(/^[a-z0-9]+$/i)) {
          return 'Please enter an alphanumeric ID';
        } else {
          return true;
        }
      },
    }),
  ],
});

const ExtendedFragment = Fragment.extend(Validations);

@classic
export default class ChannelCustomFieldsReferenceId extends ExtendedFragment {
  @attr('string', { defaultValue: 'referenceId' }) name;
  @attr('string', { defaultValue: null }) value;
}
