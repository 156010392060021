import { equal } from '@ember/object/computed';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';

import { buildValidations, validator } from 'ember-cp-validations';

const Validations = buildValidations({
  token: validator('presence', {
    disabled: equal('model.type', 'pin'),
    presence: true,
    ignoreBlank: false,
  }),

  pin: [
    validator('presence', {
      disabled: equal('model.type', 'token'),
      presence: true,
      ignoreBlank: false,
    }),
    validator('format', {
      disabled: equal('model.type', 'token'),
      regex: /^\d{6}$/,
      message: 'Pin Code must be 6 digits',
    }),
    validator('ds-error'),
  ],

  email: [
    validator('presence', {
      disabled: equal('model.type', 'token'),
      presence: true,
      ignoreBlank: false,
    }),
    validator('format', {
      disabled: equal('model.type', 'token'),
      type: 'email',
      ignoreBlank: false,
    }),
    validator('ds-error'),
  ],
});

@classic
export default class SessionModel extends Model.extend(Validations) {
  @attr() meta;
  @attr() type;
  @attr() token;
  @attr() pin;
  @attr() email;
  @attr() expiresIn;
}
