/* import __COLOCATED_TEMPLATE__ from './design.hbs'; */
import { action, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { colorSets, themes } from 'client-portal/models/practice-website';
import { htmlSafe } from '@ember/template';
import { later } from '@ember/runloop';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './design.module.scss';

export const COLOR_FILTER_COLORS = {
  blue: '#4c66c1',
  green: '#41a858',
  purple: '#9e4bab',
  red: '#c04c45',
  orange: '#eb9c02',
  'dark-gray': '#686868',
};

const COLOR_ORDER = [
  '1',
  '2',
  '3',
  '4',
  '19',
  '9',
  '10',
  '11',
  '20',
  '21',
  '22',
  '23',
  '24',
  '5',
  '6',
  '7',
  '8',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
];

@classic
@classNames(styles.component)
export default class PracticeWebsiteEditMenuDesign extends Component {
  activeTab = 'colors';
  activeColorFilterId = 'all';
  colorFilterOrder = Object.keys(COLOR_FILTER_COLORS);
  colorOrder = COLOR_ORDER;
  rawColorSets = colorSets;

  init() {
    super.init(...arguments);

    this.themes = themes.mapBy('id');
    let unsorted = this._buildUnsortedColorSets(this.rawColorSets);
    let colorSets = this._sortColorSets(unsorted, this.colorOrder);
    this.colorSets = colorSets;
    this.colorFilters = this._buildColorFilters(colorSets, this.colorFilterOrder);
  }

  @computed('activeTab')
  get tabs() {
    return [
      {
        id: 'colors',
        isActive: this.activeTab === 'colors',
      },
      {
        id: 'themes',
        isActive: this.activeTab === 'themes',
      },
    ];
  }

  @computed('activeColorFilterId')
  get activeColorFilter() {
    return this.colorFilters.findBy('filter', this.activeColorFilterId);
  }

  @action
  focusFirstRadio() {
    later(() => {
      this.element.querySelector('.radio-btn input')?.focus();
    }, 350);
  }

  _buildColorFilters(colorSets, colorFilterOrder) {
    return [
      {
        filter: 'all',
        buttonType: 'text',
        colorSets,
      },
      ...colorFilterOrder.map(filter => ({
        filter,
        buttonType: 'dot',
        colorSets: colorSets.filterBy('filter', filter),
      })),
    ];
  }

  _buildUnsortedColorSets(colorSets) {
    return colorSets.map(({ filter, id, primary, primaryName, secondary, secondaryName }) => ({
      id,
      filter,
      name: `${primaryName} | ${secondaryName}`,
      primaryStyle: htmlSafe(`background-color: ${primary}`),
      secondaryStyle: htmlSafe(`fill: ${secondary}`),
    }));
  }

  _sortColorSets(colorSets, colorOrder) {
    return colorSets.sort((a, b) => colorOrder.indexOf(a.id) - colorOrder.indexOf(b.id));
  }
}
