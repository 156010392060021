/* import __COLOCATED_TEMPLATE__ from './card-form.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { not, reads } from '@ember/object/computed';
import { observes } from '@ember-decorators/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './card-form.module.scss';

@classic
@classNameBindings('isLoading:card-loading')
@classNames(styles.component)
export default class SharedCardForm extends Component {
  styleBase = {
    color: '#212121', // $token-color-neutral-900
    fontSize: '16px',
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    fontSmoothing: 'unset',
    '::placeholder': {
      color: '#747474', // $token-color-neutral-600
    },
  };

  @reads('model.areElementsReady') isLoaded;
  @not('isLoaded') isLoading;

  // NOTE: workaround for Stripe element Safari render bug: https://github.com/stripe/stripe-js/issues/128
  // eslint-disable-next-line ember/no-observers
  @observes('isLoaded')
  forceReflow() {
    if (this.isLoaded) {
      let cardIframe = this.element.querySelector('.card-number iframe');
      if (cardIframe) {
        this._forceReflow(cardIframe);
      }
    }
  }

  _forceReflow(element) {
    let initialDisplay = element.style.display;
    element.style.display = 'none';
    element.offsetHeight;
    element.style.display = initialDisplay;
  }
}
