/* import __COLOCATED_TEMPLATE__ from './document-requests-table.hbs'; */
import { classNames } from '@ember-decorators/component';
import { humanDateFormat } from 'client-portal/utils/date-time';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './document-requests-table.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentRequestsTable extends Component {
  @service media;
  humanDateFormat = humanDateFormat;
}
