import { computed } from '@ember/object';

export default function inactiveValidationField(fieldName) {
  return computed('model.activeValidationFields', function () {
    if (this.model.activeValidationFields) {
      return !this.model.activeValidationFields.includes(fieldName);
    }
    return true;
  });
}
