/* import __COLOCATED_TEMPLATE__ from './contact-modal.hbs'; */
import { action } from '@ember/object';
import { asyncScriptLoader } from 'ember-simplepractice/utils/async-script-loader';
import { buildValidations, validator } from 'ember-cp-validations';
import { callbackError } from 'client-portal/utils/error-handling';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { error, success } from 'client-portal/utils/banners';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { maxLengthValidator, presenceValidator } from 'client-portal/models/practice-website';
import { notEmpty, or } from '@ember/object/computed';
import { service } from '@ember/service';
import { waitForNext } from 'ember-simplepractice/utils/waiters';
import Component from '@ember/component';
import ENV from 'client-portal/config/environment';
import classic from 'ember-classic-decorator';
import styles from './contact-modal.module.scss';

const Validations = buildValidations({
  firstName: [presenceValidator(), maxLengthValidator(100)],
  lastName: [presenceValidator(), maxLengthValidator(100)],
  email: [
    presenceValidator(),
    maxLengthValidator(191),
    validator('format', {
      type: 'email',
      message: 'Enter a valid email e.g. jamie@gmail.com',
    }),
  ],
  message: [maxLengthValidator(1500)],
  inquiryReasons: [
    validator('inline', {
      validate(_value, _options, model) {
        if (model.inquiryReasons.length === 0) {
          return 'Select at least one reason.';
        } else {
          return true;
        }
      },
      dependentKeys: ['model.inquiryReasons.[]'],
    }),
  ],
  phone: [
    validator('length', {
      allowBlank: true,
      min: 10,
      message: 'Enter a valid 10-digit phone number.',
    }),
  ],
  recaptchaResponse: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Complete the reCAPTCHA.',
    }),
  ],
});

@classNameBindings('preview')
@classic
@classNames(styles.component)
export default class PracticeWebsiteContactModal extends Component.extend(Validations) {
  @service currentPractice;
  @service analytics;

  styles = styles;

  reasons = [
    'Practice Information',
    'Services Offered',
    'Fees & Insurance',
    'Types of Appointments',
    'Other',
  ];

  inquiryReasons = [];

  @notEmpty('inquiryReasons') hasInquiryReasons;
  @or('firstName', 'lastName', 'email', 'phone', 'message', 'hasInquiryReasons') canSubmit;

  async didInsertElement() {
    super.didInsertElement(...arguments);
    this._scrollModalTop();
    await asyncScriptLoader('https://www.google.com/recaptcha/api.js');

    if (!isEmberTesting() && ENV.railsEnv !== 'test' && window.grecaptcha) {
      window.grecaptcha.ready(() => {
        if (!(this.isDestroyed || this.isDestroying)) {
          window.grecaptcha.render('recaptcha-container', {
            sitekey: this.get('currentPractice.recaptchaSiteKey'),
          });
        }
      });
    }
  }

  @action
  toggleReason(index) {
    if (this.inquiryReasons.includes(this.reasons[index])) {
      this.inquiryReasons.removeObject(this.reasons[index]);
    } else {
      this.inquiryReasons.pushObject(this.reasons[index]);
    }

    this.inquiryReasons.sort((r1, r2) => {
      let index1 = this.reasons.indexOf(r1);
      let index2 = this.reasons.indexOf(r2);
      return index1 < index2 ? -1 : 1;
    });
  }

  @action
  async save() {
    this.set('errorMessage', null);
    this.set(
      'recaptchaResponse',
      isEmberTesting() || ENV.railsEnv === 'test' ? 'test' : window.grecaptcha.getResponse()
    );
    this.validate();
    this.set('isDirty', true);

    if (this.validations.isValid) {
      try {
        let { firstName, lastName, email, phone, message, inquiryReasons, recaptchaResponse } =
          this;

        await this.submitContactTask.perform({
          firstName,
          lastName,
          email,
          phone,
          message,
          inquiryReasons,
          recaptchaResponse,
        });

        this.toggleContactModal();
        success({
          title: `Your message has been sent to ${this.model.practiceName}`,
        });
        this.mixpanelTrack('Contact form submitted');
        this.analytics.trackAnonymously('Contact Form Submitted', {
          object: 'Contact Form',
          action: 'Submitted',
          'source_application': 'Professional Website',
        });
      } catch (err) {
        callbackError(err, message => {
          if (message.status === '422') {
            this.set('errorMessage', message.title);

            if (!isEmberTesting() && ENV.railsEnv !== 'test' && window.grecaptcha) {
              window.grecaptcha.reset();
            }

            document.querySelector('.swal2-container').scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            this.toggleContactModal();
            error({
              title:
                "An error occurred and your message couldn't be sent at this time. Please check your Internet connection and try again.",
            });
          }

          return true;
        });
      }
    }
  }

  // NOTE: iOS fix for modal opening in center of form
  async _scrollModalTop() {
    await waitForNext();
    document.querySelector('.swal2-container')?.scrollTo(0, 0);
  }
}
