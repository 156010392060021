/* import __COLOCATED_TEMPLATE__ from './section-subtitle.hbs'; */
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './section-subtitle.module.scss';

@classNames('section-subtitle', styles.component)
@tagName('h3')
@classic
export default class PracticeWebsiteSectionSubtitle extends Component {}
