import { TinyColor } from '@ctrl/tinycolor';
import { helper } from '@ember/component/helper';

export function colorMix([originalColor], { type, amount }) {
  let tc = new TinyColor(originalColor);
  if (type === 'lighten') {
    return tc.tint(amount).toHexString();
  } else if (type === 'darken') {
    return tc.shade(amount).toHexString();
  }
  return originalColor;
}

export default helper(colorMix);
