/* import __COLOCATED_TEMPLATE__ from './autosave-message.hbs'; */
import { dateFormat, timeFormat } from 'client-portal/utils/date-time';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SiteDocumentsSharedAutosaveMessage extends Component {
  dateFormat = dateFormat;
  timeFormat = timeFormat;
}
