/* import __COLOCATED_TEMPLATE__ from './time-zone-section.hbs'; */
import { action } from '@ember/object';
import { otherTimeZones, usTimeZones } from 'client-portal/utils/time-zones';
import Component from '@glimmer/component';
import moment from 'moment-timezone';

const US_TIMEZONES = {
  'Hawaii UTC-10:00': 'Hawaii',
  'Alaska UTC-09:00': 'Alaska',
  'Pacific Time (US & Canada) UTC-08:00': 'Pacific Time (US & Canada)',
  'Mountain Time (US & Canada) UTC-07:00': 'Mountain Time (US & Canada)',
  'Arizona UTC-07:00': 'Arizona',
  'Central Time (US & Canada) UTC-06:00': 'Central Time (US & Canada)',
  'Eastern Time (US & Canada) UTC-05:00': 'Eastern Time (US & Canada)',
  'Indiana (East) UTC-05:00': 'Indiana (East)',
};

const OTHER_TIMEZONES = {
  'International Date Line West UTC-12:00': 'International Date Line West',
  'Midway Island UTC-11:00': 'Midway Island',
  'American Samoa UTC-11:00': 'American Samoa',
  'Tijuana UTC-08:00': 'Tijuana',
  'Chihuahua UTC-07:00': 'Chihuahua',
  'Mazatlan UTC-07:00': 'Mazatlan',
  'Central America UTC-06:00': 'Central America',
  'Mexico City UTC-06:00': 'Mexico City',
  'Monterrey UTC-06:00': 'Monterrey',
  'Saskatchewan UTC-06:00': 'Saskatchewan',
  'Bogota UTC-05:00': 'Bogota',
  'Quito UTC-05:00': 'Quito',
  'Atlantic Time (Canada) UTC-04:00': 'Atlantic Time (Canada)',
  'Caracas UTC-04:00': 'Caracas',
  'Georgetown UTC-04:00': 'Georgetown',
  'La Paz UTC-04:00': 'La Paz',
  'Puerto Rico UTC-04:00': 'Puerto Rico',
  'Newfoundland UTC-03:30': 'Newfoundland',
  'Brasilia UTC-03:00': 'Brasilia',
  'Buenos Aires UTC-03:00': 'Buenos Aires',
  'Greenland UTC-03:00': 'Greenland',
  'Montevideo UTC-03:00': 'Montevideo',
  'Santiago UTC-03:00': 'Santiago',
  'Mid-Atlantic UTC-02:00': 'Mid-Atlantic',
  'Azores UTC-01:00': 'Azores',
  'Cape Verde Is. UTC-01:00': 'Cape Verde Is.',
  'UTC+00:00': 'UTC',
  'Dublin UTC+00:00': 'Dublin',
  'Lisbon UTC+00:00': 'Lisbon',
  'London UTC+00:00': 'London',
  'Monrovia UTC+00:00': 'Monrovia',
  'Casablanca UTC+01:00': 'Casablanca',
  'Amsterdam UTC+01:00': 'Amsterdam',
  'Belgrade UTC+01:00': 'Belgrade',
  'Berlin UTC+01:00': 'Berlin',
  'Bratislava UTC+01:00': 'Bratislava',
  'Brussels UTC+01:00': 'Brussels',
  'Budapest UTC+01:00': 'Budapest',
  'Copenhagen UTC+01:00': 'Copenhagen',
  'Ljubljana UTC+01:00': 'Ljubljana',
  'Madrid UTC+01:00': 'Madrid',
  'Paris UTC+01:00': 'Paris',
  'Prague UTC+01:00': 'Prague',
  'Rome UTC+01:00': 'Rome',
  'Sarajevo UTC+01:00': 'Sarajevo',
  'Skopje UTC+01:00': 'Skopje',
  'Stockholm UTC+01:00': 'Stockholm',
  'Vienna UTC+01:00': 'Vienna',
  'Warsaw UTC+01:00': 'Warsaw',
  'West Central Africa UTC+01:00': 'West Central Africa',
  'Zagreb UTC+01:00': 'Zagreb',
  'Zurich UTC+01:00': 'Zurich',
  'Vilnius UTC+02:00': 'Vilnius',
  'Bucharest UTC+02:00': 'Bucharest',
  'Cairo UTC+02:00': 'Cairo',
  'Harare UTC+02:00': 'Harare',
  'Helsinki UTC+02:00': 'Helsinki',
  'Jerusalem UTC+02:00': 'Jerusalem',
  'Kaliningrad UTC+02:00': 'Kaliningrad',
  'Kyiv UTC+02:00': 'Kyiv',
  'Pretoria UTC+02:00': 'Pretoria',
  'Riga UTC+02:00': 'Riga',
  'Sofia UTC+02:00': 'Sofia',
  'Tallinn UTC+02:00': 'Tallinn',
  'Athens UTC+02:00': 'Athens',
  'Kuwait UTC+03:00': 'Kuwait',
  'Baghdad UTC+03:00': 'Baghdad',
  'Istanbul UTC+03:00': 'Istanbul',
  'Minsk UTC+03:00': 'Minsk',
  'Nairobi UTC+03:00': 'Nairobi',
  'Riyadh UTC+03:00': 'Riyadh',
  'St. Petersburg UTC+03:00': 'St. Petersburg',
  'Tehran UTC+03:30': 'Tehran',
  'Muscat UTC+04:00': 'Muscat',
  'Samara UTC+04:00': 'Samara',
  'Tbilisi UTC+04:00': 'Tbilisi',
  'Volgograd UTC+04:00': 'Volgograd',
  'Yerevan UTC+04:00': 'Yerevan',
  'Baku UTC+04:00': 'Baku',
  'Kabul UTC+04:30': 'Kabul',
  'Ekaterinburg UTC+05:00': 'Ekaterinburg',
  'Karachi UTC+05:00': 'Karachi',
  'Tashkent UTC+05:00': 'Tashkent',
  'New Delhi UTC+05:30': 'New Delhi',
  'Sri Jayawardenepura UTC+05:30': 'Sri Jayawardenepura',
  'Kathmandu UTC+05:45': 'Kathmandu',
  'Almaty UTC+06:00': 'Almaty',
  'Dhaka UTC+06:00': 'Dhaka',
  'Urumqi UTC+06:00': 'Urumqi',
  'Rangoon UTC+06:30': 'Rangoon',
  'Hanoi UTC+07:00': 'Hanoi',
  'Jakarta UTC+07:00': 'Jakarta',
  'Krasnoyarsk UTC+07:00': 'Krasnoyarsk',
  'Novosibirsk UTC+07:00': 'Novosibirsk',
  'Kuala Lumpur UTC+08:00': 'Kuala Lumpur',
  'Beijing UTC+08:00': 'Beijing',
  'Chongqing UTC+08:00': 'Chongqing',
  'Hong Kong UTC+08:00': 'Hong Kong',
  'Irkutsk UTC+08:00': 'Irkutsk',
  'Perth UTC+08:00': 'Perth',
  'Singapore UTC+08:00': 'Singapore',
  'Taipei UTC+08:00': 'Taipei',
  'Ulaanbaatar UTC+08:00': 'Ulaanbaatar',
  'Seoul UTC+09:00': 'Seoul',
  'Yakutsk UTC+09:00': 'Yakutsk',
  'Osaka UTC+09:00': 'Osaka',
  'Darwin UTC+09:30': 'Darwin',
  'Vladivostok UTC+10:00': 'Vladivostok',
  'Brisbane UTC+10:00': 'Brisbane',
  'Guam UTC+10:00': 'Guam',
  'Port Moresby UTC+10:00': 'Port Moresby',
  'Adelaide UTC+10:30': 'Adelaide',
  'Sydney UTC+11:00': 'Sydney',
  'Canberra UTC+11:00': 'Canberra',
  'Srednekolymsk UTC+11:00': 'Srednekolymsk',
  'Hobart UTC+11:00': 'Hobart',
  'Magadan UTC+11:00': 'Magadan',
  'New Caledonia UTC+11:00': 'New Caledonia',
  'Solomon Is. UTC+11:00': 'Solomon Is.',
  'Marshall Is. UTC+12:00': 'Marshall Is.',
  'Kamchatka UTC+12:00': 'Kamchatka',
  'Tokelau Is. UTC+13:00': 'Tokelau Is.',
  'Fiji UTC+13:00': 'Fiji',
  'Wellington UTC+13:00': 'Wellington',
  "Nuku'alofa UTC+13:00": "Nuku'alofa",
  'Chatham Is. UTC+13:45': 'Chatham Is.',
  'Samoa UTC+14:00': 'Samoa',
};

export default class SiteDocumentsContactInfoTimeZoneSection extends Component {
  usTimeZoneOptions = US_TIMEZONES;
  otherTimeZoneOptions = OTHER_TIMEZONES;

  constructor() {
    super(...arguments);
  }

  get defaultTimeZone() {
    if (this.args.connection.timeZone) return this.args.connection.timeZone;

    return this.clientLocalTimeZone || this.args.practiceTimeZone;
  }

  get clientLocalTimeZone() {
    let momentTimeZoneMap = { ...usTimeZones, ...otherTimeZones };
    let deviceTimeZone = momentTimeZoneMap[this.localMomentTimeZone];

    return deviceTimeZone;
  }

  get localMomentTimeZone() {
    return moment.tz.guess();
  }

  @action
  setAttr(name, value) {
    this.args.connection.set(name, value);
  }
}
