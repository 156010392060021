import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

export default class OonClaimSubmissionErrorService extends Service {
  @service store;
  @tracked showErrorBanner = false;
  @tracked appointment = null;
  @tracked superbill;

  get appointmentDate() {
    return this.appointment?.startTime?.format('MMMM D, YYYY');
  }

  dismissBanner() {
    this.showErrorBanner = false;
  }

  async fetchSuperbill(appointment) {
    this.appointment = appointment;
    this.superbill = appointment.superbill;
    try {
      if (!this.superbill) {
        this.superbill = await this.store
          .createRecord('superbill', {
            appointments: [this.appointment],
          })
          .save({
            adapterOptions: {
              appointmentId: this.appointment.id,
            },
          });
      }
    } finally {
      this.showErrorBanner = true;
    }
  }
}
