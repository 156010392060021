import { computed } from '@ember/object';
import { A as emberA } from '@ember/array';
import { equal } from '@ember/object/computed';
import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class ClientAccessModel extends Model {
  @belongsTo('base-client', { polymorphic: true, async: false }) client;
  @attr() accessStatus;
  @attr('boolean') eulaAgreementAccepted;
  @attr('boolean') termsOfServiceAgreementAccepted;
  @attr('boolean') privacyPolicyAgreementAccepted;
  @equal('accessStatus', 'otp_pending') isOtpPending;
  @equal('accessStatus', 'password_pending') isPasswordPending;

  @computed('client.constructor.modelName')
  get clientType() {
    return this.client.constructor.modelName;
  }

  static fieldsFor(_context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    return fields;
  }
}
