/* import __COLOCATED_TEMPLATE__ from './good-faith-estimate-form.hbs'; */
import { action } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { dismissSticky, info } from 'client-portal/utils/banners';
import { humanDateFormat } from 'client-portal/utils/date-time';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './good-faith-estimate-form.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsGoodFaithEstimateForm extends Component {
  @service currentPractice;
  @service session;
  styles = styles;

  humanDateFormat = humanDateFormat;

  @reads('model.goodFaithEstimate.submissionData') submissionData;

  didInsertElement() {
    super.didInsertElement();
    info({
      title: 'Signature required',
      isSticky: true,
      classNames: 'info-with-icon',
    });
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    dismissSticky();
  }

  @action
  async nextStep() {
    try {
      await this.completeRequestTask.perform(this.model);
    } catch (err) {
      bannerError(err, { title: 'Document could not be submitted' });
    }
  }
}
