import { computed } from '@ember/object';
import { dasherize } from '@ember/string';
import { A as emberA } from '@ember/array';
import Model, { attr } from '@ember-data/model';
import channelFieldsComponents from 'client-portal/components/request/channel-fields/components';
import classic from 'ember-classic-decorator';

const UPLOADS_KEY = 'uploads';
const COVERED_SESSIONS_KEY = 'covered_sessions';

@classic
export default class GlobalMonarchChannelModel extends Model {
  @attr() name;
  @attr() displayName;
  @attr('boolean') isUploadsEnabled;
  @attr('boolean') careCoordinatorMode;
  @attr('array', { defaultValue: () => [] }) customFields;
  @attr('boolean') skipBirthDate;
  @attr('number') firstAvailableAppointmentOffsetHours;
  @attr('number') lastAvailableAppointmentOffsetHours;
  @attr('boolean') widgetSelectChannelService;

  @computed('_textFields')
  get textFields() {
    return this._textFields.map(f => {
      let name = dasherize(f);
      let fragment = this.store.createFragment(`channel-custom-fields/${name}`);
      fragment.component = channelFieldsComponents[name];
      return fragment;
    });
  }

  @computed('_textFields')
  get hasTextFields() {
    return this._textFields.length > 0;
  }

  @computed('isUploadsEnabled', 'customFields')
  get hasUploads() {
    return this.isUploadsEnabled && this.customFields.includes(UPLOADS_KEY);
  }

  @computed('customFields')
  get _textFields() {
    return this.customFields.filter(
      field =>
        field === COVERED_SESSIONS_KEY ||
        (field !== UPLOADS_KEY && channelFieldsComponents[dasherize(field)])
    );
  }

  showStep({ referenceId }) {
    return this.hasCustomField('reference_id') && !referenceId;
  }

  hasCustomField(name) {
    return this._textFields.includes(name);
  }

  static fieldsFor(_context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    return fields;
  }
}
