import { action, computed } from '@ember/object';
import { and, bool, equal, reads } from '@ember/object/computed';
import { resetScroll } from 'client-portal/utils/reset-scroll';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import classic from 'ember-classic-decorator';

@classic
export default class RequestInformationIndexController extends Controller {
  @service session;
  @service currentPractice;

  @tracked stripeElements = null;
  @tracked isPaymentElementLoaded = false;

  @bool('appointment.card') isCardRequired;
  @reads('model.appointment') appointment;
  @reads('appointment.client.constructor.modelName') clientModelName;
  @reads('appointment.client.isCouple') isCouple;
  @equal('clientModelName', 'client') isClientModelType;
  @and('isClientModelType', 'appointment.client.hasNoClientRelationships') isClient;
  @and('isClientModelType', 'appointment.client.hasOneClientRelationship') isContact;

  @computed('isCardRequired', 'isCardReady')
  get isReady() {
    return this.isCardRequired ? this.isCardReady : true;
  }

  @computed('isReady', 'validateFieldsTask.isRunning')
  get enableMobileSubmit() {
    return this.isReady && !this.validateFieldsTask.isRunning;
  }

  @computed('isReady', 'validateAndPersistAppointmentTask.isRunning')
  get enableDesktopSubmit() {
    return this.isReady && !this.validateAndPersistAppointmentTask.isRunning;
  }

  @computed('model.channel.careCoordinatorMode')
  get subtitle() {
    if (this.model.channel?.careCoordinatorMode) {
      return 'Enter the name, email, and phone number of the person you are requesting an appointment on behalf of. The email and phone number will be used to send appointment confirmations and reminders.';
    }

    return 'This will only be shared with the practice';
  }

  @computed(
    'appointment.card.areElementsReady',
    'isPaymentElementLoaded',
    'session.canUsePaymentMethods'
  )
  get isCardReady() {
    if (this.session.canUsePaymentMethods) {
      return this.isPaymentElementLoaded;
    } else {
      return this.appointment?.card?.areElementsReady;
    }
  }

  @action
  setPaymentElementLoaded(stripeElements) {
    this.stripeElements = stripeElements;
    this.isPaymentElementLoaded = true;
  }

  @action
  onInsertForm(element) {
    resetScroll(element);
  }
}
