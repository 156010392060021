import { A as emberA } from '@ember/array';
import { reads, sort } from '@ember/object/computed';
import EmberObject, { set } from '@ember/object';
import StepNode from './-step-node';
import classic from 'ember-classic-decorator';

@classic
export default class PopSequencerStateMachine extends EmberObject {
  currentStep = null;
  stepTransitions = emberA();
  sortBy = ['idx'];

  @sort('stepTransitions', 'sortBy') sortedStepTransitions;

  @reads('sortedStepTransitions.firstObject') firstNode;

  addStep({ idx, onTransition, name }) {
    let node = StepNode.create({
      transitions: this,
      idx,
      onTransition,
      name,
    });
    this.stepTransitions.pushObject(node);
    set(this, 'currentStep', this.firstNode.idx);
    let dupes = this.findAllByName(node);
    if (dupes && dupes.length > 1) {
      this.removeStep(dupes.lastObject);
    }
  }

  removeStep({ idx }) {
    let node = this.stepTransitions.findBy('idx', idx);
    this.stepTransitions.removeObject(node);
  }

  findAllByName({ name }) {
    if (name) {
      return this.sortedStepTransitions.filterBy('name', name);
    }
  }

  pickNext(currentStep = this.currentStep) {
    let currentIndex = this.sortedStepTransitions.findIndex(node => node.idx === currentStep);
    let currentNode = this.sortedStepTransitions[currentIndex + 1];

    if (currentNode) {
      return currentNode.idx;
    }
  }

  activate(step) {
    this.set('currentStep', step);
  }
}
