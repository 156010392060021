import ObjectProxy from '@ember/object/proxy';
import classic from 'ember-classic-decorator';

class CurrentPracticeProxy extends ObjectProxy {
  static isServiceFactory = true;
}

@classic
class CurrentPracticeService extends CurrentPracticeProxy {
  content = null;

  init(practice) {
    super.init(...arguments);
    this.set('content', practice);
  }
}

export default CurrentPracticeService;
