import { service } from '@ember/service';
import Super from 'client-portal/routes/-request';
import classic from 'ember-classic-decorator';

@classic
export default class SiteRequest extends Super {
  @service router;
  @service session;
  @service currentPractice;
  beforeModel() {
    super.beforeModel(...arguments);

    if (this.session.currentClient && this.session.currentClient.permissions.selfScheduling) {
      return;
    }

    if (this.currentPractice.get('requestAsNewClient')) {
      return;
    }

    this.router.transitionTo('site.home');
  }
}
