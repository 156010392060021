/* import __COLOCATED_TEMPLATE__ from './appointment-section.hbs'; */
import { action } from '@ember/object';
import { dateFormat } from 'client-portal/utils/date-time';
import { formatMoney } from 'client-portal/helpers/format-money';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import styles from './appointment-section.module.scss';

export default class SiteBillingOutOfNetworkFormAppointmentSection extends Component {
  @service store;
  @service currentPractice;
  @tracked expanded = false;

  styles = styles;
  dateFormat = dateFormat;

  get appointment() {
    return this.args.appointment;
  }

  get office() {
    return this.appointment?.office;
  }

  get placeOfService() {
    return (
      this.office?.insurancePlaceOfService ||
      this.currentPractice.get('defaultOffice.insurancePlaceOfService')
    );
  }

  get serviceCodeCount() {
    return this.appointment?.cptCodes?.length || 1;
  }

  get diagnosisCodeCount() {
    let diagnosisCodes = this.appointment?.insuranceClaim?.diagnosisCodes || {};
    return Object.keys(diagnosisCodes).length || 1;
  }

  get hasMultipleServicesOrDiagnosisCodes() {
    return this.serviceCodeCount > 1 || this.diagnosisCodeCount > 1;
  }

  @action
  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  get rows() {
    let baseRows = [
      {
        label: 'Date',
        value: this.appointment?.startTime.format(this.dateFormat),
      },
      {
        label: 'Place of service',
        value: this.placeOfService,
        tooltip:
          'The location where your provider has determined health services and health related services are provided',
      },
      {
        label: 'Service',
        value: this.appointment?.cptCodes,
        expanded: this.expanded,
        cellComponent: 'site/billing/out-of-network/form/appointment-section/service-code',
      },
      {
        label: 'Diagnosis',
        value: this.appointment?.insuranceClaim?.diagnosisCodes,
        expanded: this.expanded,
        cellComponent: 'site/billing/out-of-network/form/appointment-section/diagnosis-code',
      },
      {
        label: 'Fee',
        value: formatMoney([this.appointment?.fee], { precision: 2 }),
      },
    ];
    let unitsRow = {
      label: 'Units',
      value: this.appointment?.units,
    };

    if (!this.hasMultipleServicesOrDiagnosisCodes) {
      baseRows.splice(3, 0, unitsRow);
    }
    return baseRows;
  }
}
