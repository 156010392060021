/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './form.module.scss';

@classic
@tagName('form')
@classNames('text-left', styles.component)
export default class RequestChannelFieldsForm extends Component {}
