/* import __COLOCATED_TEMPLATE__ from './social-media-link.hbs'; */
import { attributeBindings, classNames, tagName } from '@ember-decorators/component';
import { on } from '@ember-decorators/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@tagName('a')
@classNames('social-media-link')
@attributeBindings('target', 'rel')
export default class SharedSocialMediaLink extends Component {
  @service media;
  target = '_blank';
  rel = 'noreferrer noopener';

  @on('click')
  trackMixpanelClick() {
    if (this.mixpanelTrack) {
      this.mixpanelTrack(`Social media - ${this.title} clicked`);
    }
  }
}
