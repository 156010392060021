import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';
import { filterBy, gt } from '@ember/object/computed';
import { service } from '@ember/service';
import Super from './document-request';
import classic from 'ember-classic-decorator';

export const ARM5 = 'ARM-5';
export const ASRS = 'ASRS-v1.1';
export const AUDIT = 'The Alcohol Use Disorders Identification Test: Self-Report Version';
export const AUDITCPLUS = 'AUDIT-C Plus 3';
export const CSSRS = 'C-SSRS';
export const DASS21 = 'DASS-21';
export const DSSB = 'DSS-B';
export const FS = 'FS';
export const GAD7 = 'GAD-7';
export const ICG = 'ICG';
export const PCL5 = 'PCL-5';
export const PEG = 'PEG';
export const PHQ9 = 'PHQ-9';
export const PHQ15 = 'PHQ-15';
export const SF20 = 'SF-20';
export const SWLS = 'SWLS';
export const WSAS = 'WSAS';
export const YBOCS = 'Y-BOCS';

@classic
export default class DocumentRequestQuestionnaire extends Super {
  @service store;

  documentIcon = 'file-text';

  @attr('json') templateQuestions;
  @attr('json') userAnswers;
  @attr() mixpanelType;
  @attr() mixpanelDocumentName;

  @filterBy('templateQuestions', 'required') requiredQuestions;
  @gt('requiredQuestions.length', 0) hasRequiredQuestion;

  @computed('templateQuestions.@each.id', 'userAnswers.@each.questionId')
  get templateQuestionsAndAnswers() {
    if (!this.templateQuestions) {
      return [];
    }

    return this.templateQuestions.map(question => {
      let normalizedType = dasherize(question.questionType.toLowerCase());
      let answer = this.userAnswers.findBy('questionId', question.id);
      return {
        normalizedType,
        answer,
        htmlId: `${normalizedType}-${question.id}`,
        ...question,
      };
    });
  }

  @computed('userAnswers')
  get userAnswersWithoutScores() {
    return this.userAnswers
      .filter(a => a.questionType === 'SINGLE_SELECT')
      .map(answer => {
        let answerCopy = { ...answer.answers[0] };
        let answerText = answerCopy.answer_text;
        let scorePattern = /\(\d+(?:\.\d+)?\)/g;

        if (answerText?.match(scorePattern)) {
          // eslint-disable-next-line camelcase
          answerCopy.answer_text = answerCopy.answer_text.replace(scorePattern, '');
        }

        if (answer.questionId === 4 && this.documentTitle === AUDIT) {
          // eslint-disable-next-line camelcase
          answerCopy.answer_text = answerCopy.answer_text?.replace('7, 8, or 9', '7 to 9');
        }

        return { ...answer, answers: Object.keys(answerCopy).length ? [answerCopy] : [] };
      });
  }

  autosaveSerialize() {
    let { data } = this.serialize({});
    let { userAnswers } = data.attributes;
    return { userAnswers: JSON.parse(userAnswers) };
  }

  autosaveRestore({ userAnswers }) {
    if (userAnswers) {
      this.set('userAnswers', userAnswers);
    }
  }
}
