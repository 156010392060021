/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { classNames } from '@ember-decorators/component';
import { or } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './footer.module.scss';

@classNames(styles.component)
@classic
export default class PracticeWebsiteEditMenuFooter extends Component {
  get noDirtyAttributes() {
    return !this.model.hasDirtyAttributes;
  }

  @or('persistModelTask.isRunning', 'isUploading') isSaveDisabled;
}
