/* import __COLOCATED_TEMPLATE__ from './messaging-client.hbs'; */
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { tagName } from '@ember-decorators/component';
import { task, timeout } from 'ember-concurrency';
import $ from 'jquery';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@tagName('')
export default class MessagingClient extends Component {
  @service fastboot;
  @service session;

  @reads('session.environment.messagingEmbed') messagingEmbed;
  @reads('session.currentClientAccess') clientAccess;
  @reads('clientAccess.client') currentSignedInClient;

  didInsertElement() {
    super.didInsertElement(...arguments);

    if (this.fastboot.isFastBoot) {
      return;
    }

    if (
      this.currentSignedInClient &&
      this.currentSignedInClient.permissions &&
      this.currentSignedInClient.permissions.messaging
    ) {
      this._renderMessaging();
    }
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    if (window.MessagingClientInstance) {
      window.MessagingClientInstance.destroy();
      window.MessagingClientInstance = undefined;
    }
  }

  _renderMessaging() {
    if (window.messagingSetup) {
      // Fastboot rendered the script tags
      this._setupMessaging();
    } else if (this.messagingEmbed) {
      // Load the scripts, and then poll until setup
      $(this.messagingEmbed).appendTo('body');
      this._setupMessagingTask.perform();
    }
  }

  _setupMessaging() {
    let messagingAnchor = this.session.get('messagingAnchor');
    if (messagingAnchor) {
      location.hash = messagingAnchor;
    }

    window.MessagingClientInstance = window.messagingSetup(this.features);
    return window.MessagingClientInstance;
  }

  @task(function* () {
    if (
      (!window.messagingSetup || !this._setupMessaging()) &&
      this._setupMessagingTask.performCount < 3000
    ) {
      yield timeout(100);
      this._setupMessagingTask.perform();
    }
  })
  _setupMessagingTask;
}
