import { callbackError } from 'client-portal/utils/error-handling';
import { error } from 'client-portal/utils/banners';
import { handleTransitionError } from 'client-portal/utils/handle-transition-error';
import { setProperties } from '@ember/object';
import { task } from 'ember-concurrency';
import Service, { service } from '@ember/service';

export default class ClientSelectionService extends Service {
  @service session;
  @service store;
  @service router;
  @service announcement;

  @(task(function* (currentClient, callback) {
    try {
      yield this.store.adapterFor('treatable-client').manage(currentClient.id);

      let environment = this.store.peekAll('environment').firstObject;
      setProperties(environment, { currentClient });
      this.session.authenticate('authenticator:environment', environment);

      if (typeof callback === 'function') {
        callback();
      }

      this.announcement.fetchAnnouncements(); // Refresh announcements for new profile.

      try {
        yield this.router.transitionTo('site.home', {
          queryParams: this.session.get('data.redirect') ? { isHeaderFooterHidden: true } : {},
        });
      } catch (err) {
        handleTransitionError(err);
      }
    } catch (err) {
      callbackError(err, message => {
        error({ title: message.title });
        return true;
      });
    }
  }).drop())
  selectClientAndRedirectTask;
}
