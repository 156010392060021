/* import __COLOCATED_TEMPLATE__ from './add-to-calendar-modal.hbs'; */
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './add-to-calendar-modal.module.scss';

@classic
@classNames(styles.component)
export default class SiteAppointmentsAddToCalendarModal extends Component {
  styles = styles;

  @reads('icalUrl') selectedOption;

  @action
  onConfirm() {
    window.open(this.selectedOption);
  }
}
