import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { A as emberA } from '@ember/array';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@classNames('button-toggle')
export default class SharedButtonToggle extends Component {
  selectedValue = null;

  init() {
    super.init(...arguments);
    this.setProperties({
      _buttons: {},
      children: emberA([]),
    });
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.setSelectedValue(this.selectedValue);
  }

  didUpdateAttrs() {
    super.didUpdateAttrs();
    this.setSelectedValue(this.selectedValue);
  }

  @action
  register(buttonId, value) {
    this.children.pushObject(buttonId);
    this.set(`_buttons.${buttonId}`, value);
  }

  @action
  onClick(buttonId) {
    let value = this.get(`_buttons.${buttonId}`);
    this.setSelectedValue(value);
    return this.onButtonClick?.(value);
  }

  setSelectedValue(value) {
    if (this.children.length) {
      this.set('_selectedValue', value);
    }
  }
}
