import setupBanners from './setup-banners';

const { success, warning, error, info, dismiss, dismissSticky } = setupBanners();

const createElement = function (tagName, options) {
  let el = document.createElement(tagName);
  for (let key in options) {
    el[key] = options[key];
  }
  return el;
};

export { success, warning, error, info, dismiss, dismissSticky, createElement };
