import { bool } from '@ember/object/computed';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';

export const FEEDBACK_CHARS_LIMIT = 500;

@classic
export default class ReviewModel extends Model {
  @belongsTo('base-client', { polymorphic: true, async: false }) client;

  @attr('number') rating;
  @attr('string', { defaultValue: '' }) feedback;
  @attr('string', { defaultValue: 'web' }) origin;

  @bool('feedback') hasFeedback;

  get maxCharsLimit() {
    return FEEDBACK_CHARS_LIMIT;
  }

  @computed('feedback', 'maxCharsLimit')
  get numCharsRemaining() {
    let { maxCharsLimit, feedback } = this;
    return maxCharsLimit - feedback.length;
  }

  @computed('numCharsRemaining', 'rating')
  get isFeedbackValid() {
    let { numCharsRemaining, rating } = this;
    return !isNaN(parseInt(rating)) && numCharsRemaining >= 0;
  }
}
