import { attr } from '@ember-data/model';
import Super from './document-request';
import classic from 'ember-classic-decorator';

@classic
export default class DocumentRequestStoredDocument extends Super {
  documentIcon = 'file-text';
  mixpanelType = 'PDF';

  @attr() documentExt;
  @attr() documentMimeType;
}
