import { STEP_IDS } from 'client-portal/services/widget-steps';
import { action, setProperties } from '@ember/object';
import { service } from '@ember/service';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';

@classic
export default class RequestLocation extends RequestBaseRoute {
  @service analytics;
  @service session;
  @service mixpanel;
  @service store;
  @service request;
  @service geolocation;
  @service pendingAppointment;
  @service clientTimezone;

  templateName = '-request/location';
  stepId = STEP_IDS.SELECT_LOCATION;

  beforeModel() {
    this.clientTimezone.reset();
    let reservation = this.modelFor(this.request.baseRouteName);
    if (!reservation.belongsTo('cptCode').id()) {
      this.request.transitionTo(this, 'service');
    }
  }

  async model(params, transition) {
    if (params.officeId) {
      transition.trigger(false, 'preselectionLoading');
    }

    let filter = {};
    let { officeId, searchByOffice } = transition.to.queryParams;
    if (searchByOffice) {
      filter = { id: officeId };
    } else {
      let reservation = this.modelFor(this.request.baseRouteName);
      let clinicianId = reservation.get('clinician.id');
      let cptCodeId = reservation.get('cptCode.id');
      filter = { filter: { clinicianId, cptCodeId } };
    }

    return {
      geo: false,
      offices: await this.store.query('office', filter),
    };
  }

  afterModel(model, transition) {
    super.afterModel(...arguments);

    if (model.offices && !model.offices.get('length')) {
      return this.request.transitionTo(this, 'unavailable', {
        queryParams: { reason: 'location unavailable' },
      });
    }

    if (this.skipStepWithPreselection(model.offices, transition, 'officeId')) return;

    this.setupModel(model);
    this.pendingAppointment.reset();
  }

  async setupModel(model) {
    try {
      if (navigator.geolocation && model.offices.get('length') > 1) {
        let geolocation = this.geolocation;

        let position = await geolocation.getCurrentPosition();

        model.offices.forEach(office => {
          if (office.get('geolocation')) {
            let distance = geolocation.findDistanceTo(office.get('geolocation'), position);
            office.setProperties({ distance });
          }
        });

        setProperties(model, {
          geo: model.offices.any(x => !x.isVideo && x.isPublic),
          offices: model.offices.sortBy('isVideo', 'notPublic', 'distance'),
        });
      }
    } catch (err) {
      // noop
    }
  }

  @action
  nextStep(office, params) {
    super.nextStep('office', office, 'date', params);
  }

  @action
  didTransition() {
    let reservation = this.modelFor(this.request.baseRouteName);
    reservation.setProperties({
      office: null,
      startTime: null,
      clientType: null,
      message: null,
      hasCompletedMessageStep: false,
      channelFields: null,
      channelUploads: null,
      channelUploadsComplete: null,
      hasCompletedInformationStep: false,
    });

    this._trackAppointmentStep(reservation);
    return true;
  }
}
