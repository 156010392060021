/* import __COLOCATED_TEMPLATE__ from './activities-table.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { fullTimeRange } from 'client-portal/components/shared/date-range-picker';
import { humanDateFormat } from 'client-portal/utils/date-time';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import InfinityModel from 'ember-infinity/lib/infinity-model';
import classic from 'ember-classic-decorator';
import styles from './activities-table.module.scss';

const ExtendedInfinityModel = InfinityModel.extend({
  buildParams() {
    let params = this._super(...arguments);
    params.page = { cursor: this.cursor, size: 20 };
    return params;
  },

  afterInfinityModel(model) {
    this.set('canLoadMore', model.length >= 20);
    this.set('cursor', model.get('lastObject.cursorId'));
  },
});

@classic
@classNames(styles.component)
export default class SiteBillingActivitiesTable extends Component {
  @service media;
  @service store;

  @service infinity;

  @service billingModals;

  humanDateFormat = humanDateFormat;

  @reads('queryTask.lastSuccessful.value') activities;

  @computed('activities.@each.{activityCharges,activityPayments}', 'activities.meta.endBalance')
  get activitiesWithBalance() {
    if (!this.activities) {
      return [];
    }

    let runningBalance = this.activities.meta.endBalance;
    this.activities.forEach(record => {
      record.set('activityBalance', Math.round(runningBalance * 100) / 100);
      runningBalance =
        runningBalance - (record.activityCharges || 0) + (record.activityPayments || 0);
    });

    return this.activities;
  }

  async didInsertElement() {
    super.didInsertElement(...arguments);

    try {
      await this.queryTask.perform();
    } catch {
      // NOTE: template handles isError
    }
    this.billingModals.afterPersist.push(() => this.queryTask.perform());
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.billingModals.afterPersist.pop();
  }

  @(task(function* () {
    let activities = this.infinity.model(
      'polymorphic/billing-item',
      {
        filter: {
          thisType: 'invoice,payment',
          timeRange: this.timeRange ? fullTimeRange(this.timeRange) : null,
        },

        perPageParam: null,
        pageParam: null,
      },
      ExtendedInfinityModel
    );

    return yield activities;
  }).keepLatest())
  queryTask;
}
