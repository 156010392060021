export { default } from 'ember-simplepractice/utils/persistent-hash-table';

export const session = {
  get(key) {
    try {
      return sessionStorage.getItem(key);
    } catch {
      // eslint-disable-next-line no-console
      console.warn(`Failed to get sessionStorage "${key}".`);
    }
  },
  set(key, value) {
    try {
      return sessionStorage.setItem(key, value);
    } catch {
      // eslint-disable-next-line no-console
      console.warn(`Failed to set sessionStorage"${key}".`);
    }
  },
  remove(key) {
    try {
      return sessionStorage.removeItem(key);
    } catch {
      // eslint-disable-next-line no-console
      console.warn(`Failed to remove sessionStorage"${key}".`);
    }
  },
};
