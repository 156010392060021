import { registerDestructor } from '@ember/destroyable';
import Modifier from 'ember-modifier';
import autosize from 'autosize';

function preventNewLinesCallback(evt) {
  if (evt.keyCode === 13) {
    evt.preventDefault();
  }
}

function cleanup(instance) {
  autosize.destroy(instance.element);
  instance.observer.disconnect?.();
  instance.preventNewlines &&
    instance.element.removeEventListener('keydown', preventNewLinesCallback);
}

export default class TextareaAutosizeModifier extends Modifier {
  element = null;
  preventNewlines = null;
  waitForElSelector = null;
  observer = {};

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, cleanup);
  }

  modify(element, _, { preventNewlines, waitForElSelector }) {
    this.preventNewlines = preventNewlines;
    this.waitForElSelector = waitForElSelector;

    if (!this.element) {
      this.element = element;
      this.#autosize();

      if (!preventNewlines) return;

      this.element.addEventListener('keydown', preventNewLinesCallback);
    } else {
      autosize.update(this.element);
    }
  }

  #autosize() {
    let hiddenEl = document.querySelector(this.waitForElSelector);
    if (hiddenEl) {
      this.#waitForHiddenElement(hiddenEl);
    } else {
      autosize(this.element);
    }
  }

  #waitForHiddenElement(hiddenEl) {
    let previousValue = window.getComputedStyle(hiddenEl)?.display;
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName !== 'style') return;
        previousValue = this.#checkStyleDisplayValue(mutation, previousValue);
      });
    });

    // eslint-disable-next-line ember/no-observers
    this.observer.observe(hiddenEl, { attributes: true });
  }

  #checkStyleDisplayValue(mutation, previousValue) {
    let currentValue = mutation.target.style.display;
    if (currentValue !== previousValue) {
      if (previousValue === 'none' && currentValue !== 'none') {
        autosize(this.element);
      }
      return currentValue;
    }
  }
}
