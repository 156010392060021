import { action } from '@ember/object';
import { formatValidationErrorsForMixpanel } from 'client-portal/utils/validate-record';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import fade from 'ember-animated/transitions/fade';

export default class SiteBillingOutOfNetworkFormEditReviewEditableSection extends Component {
  transition = fade;

  @service mixpanel;

  @tracked isCollapsed = this.allowCollapse;
  @tracked isEditing = false;

  get allowCollapse() {
    return this.args.canEditSectionInReviewStep;
  }

  get showHeader() {
    return !this.args.canEditSectionInReviewStep;
  }

  get shouldHideInputs() {
    if (this.allowCollapse) {
      return !this.isEditing;
    } else {
      return this.args.isReview;
    }
  }

  @action
  toggleEdit() {
    this.isCollapsed = false;
    this.isEditing = !this.isEditing;
    this.#trackToggleEdit();
  }

  @action
  handleSaveEdit(record) {
    if (!record.isDirty) {
      this.toggleEdit();
    }
    this.#trackSaveEdit(record);
  }

  @action
  toggleCollapse() {
    this.isEditing = false;
    this.isCollapsed = !this.isCollapsed;
  }

  #trackToggleEdit() {
    if (this.isEditing) {
      this.mixpanel.track('client: out of network claim section edit', {
        'section_name': this.sectionName,
      });
    }
  }

  #trackSaveEdit(record) {
    if (record.isDirty) {
      let errors = formatValidationErrorsForMixpanel(
        record.allValidationErrors || record.validations.errors
      );
      this.mixpanel.track('client: out of network claim section error', {
        'section_name': this.sectionName,
        errors,
      });
    } else {
      this.mixpanel.track('client: out of network claim section saved', {
        'section_name': this.sectionName,
      });
    }
  }
}
