import { initialize as addon } from 'ember-responsive/initializers/responsive';

// NOTE: this import turns off test mocking which we don't use anyway
import 'ember-responsive/services/media';

export function initialize(application) {
  addon(application);
}

export default {
  name: 'responsive',
  initialize,
};
