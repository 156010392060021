/* import __COLOCATED_TEMPLATE__ from './home-header.hbs'; */
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './home-header.module.scss';

@classic
@tagName('header')
@classNames(styles.component)
export default class HomeHeader extends Component {}
