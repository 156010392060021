import { action } from '@ember/object';
import { service } from '@ember/service';
// eslint-disable-next-line ember/no-mixins
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';

const INCLUDE_RELATIONSHIPS = [
  'appointments',
  'appointments.invoiceItems',
  'appointments.invoiceItems.invoice',
  'appointments.office',
  'appointments.superbill',
  'currentSubmission',
];

const APPOINTMENT_FIELDS = [
  'startTime',
  'cptCodes',
  'serviceDescription',
  'fee',
  'units',
  'office',
  'invoiceItems',
  'superbill',
];

const ExtendedRoute = Route.extend(FastbootHelpersMixin);

export default class SiteBillingOutOfNetwork extends ExtendedRoute {
  @service router;
  @service store;
  @service session;
  @service currentPractice;
  @service mixpanel;

  queryParams = {
    claimId: { refreshModel: true },
  };

  beforeModel() {
    super.beforeModel(...arguments);
    let model = this.modelFor('site.billing');

    if (!model.hasInvoicedAppointments) {
      return this.router.transitionTo('site.billing');
    }
  }

  async model(params) {
    let client = this.modelFor('site.billing');
    let claimId = params.claimId || 'error';
    let claim = await this.store.findRecord('insurance-claim', claimId, {
      adapterOptions: {
        query: {
          fields: {
            appointments: APPOINTMENT_FIELDS.join(','),
            invoiceItems: ['allocatedAmount', 'invoice'].join(','),
            invoices: ['id', 'remainingAmount', 'displayStatus'].join(','),
          },
          include: INCLUDE_RELATIONSHIPS.join(','),
        },
      },
      reload: true,
    });

    return { client, claim };
  }

  afterModel(model) {
    super.afterModel(...arguments);

    let featureDisabledAndClaimUnsubmitted =
      model.claim.currentSubmission === null && !this.currentPractice.get('hasOonClaimFiling');

    let appointment = model.claim.appointments.firstObject;
    if (!appointment || featureDisabledAndClaimUnsubmitted) {
      return this.router.transitionTo('site.billing');
    }
  }

  @action
  didTransition() {
    let model = this.modelFor('site.billing');

    this.mixpanel.track('client: client submission flow viewed', {
      'first_claim': !model.hasOonClaims,
      'has_insurance_details': model.hasInsuranceDetails,
      'has_client_info': model.hasRequiredFields,
    });
  }
}
