import Component from '@glimmer/component';
import getCursiveClass from '../../../utils/signatures';
import moment from 'moment-timezone';
import styles from './client-signature.module.scss';

export default class SharedSignatureClientSignature extends Component {
  styles = styles;
  signedAt = moment().format('MMMM D, YYYY h:mm a');

  get cursiveClass() {
    return getCursiveClass(this.args.signatoryName?.length);
  }
}
