/* import __COLOCATED_TEMPLATE__ from './highlights.hbs'; */
import { computed } from '@ember/object';
import { not, reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeWebsiteEditMenuHighlights extends Component {
  @service request;
  @service currentPractice;

  @not('currentPractice.hasCustomStripeAccount') isOnlinePaymentsDisabled;
  @reads('currentPractice.effectiveSelfScheduling') effectiveSelfScheduling;

  @computed('isTelehealthDisabled', 'isOnlinePaymentsDisabled', 'effectiveSelfScheduling')
  get icons() {
    return [
      {
        title: 'Offers Telehealth Appointments Icon',
        path: 'telehealthIndicator',
      },
      {
        title: 'Accepts Online Payments Icon',
        disabledTip:
          'You need to enable the Client Portal and Online Payments in your SimplePractice Settings before you can turn this on.',
        path: 'onlinePaymentsIndicator',
        toggleHidden: this.isOnlinePaymentsDisabled,
      },
      {
        title: 'Accepts Insurance Icon',
        path: 'acceptInsuranceIndicator',
      },
      {
        title: 'Accepting New Clients Icon',
        disabledTip: 'Visible if your practice allows new clients to request appointments.',
        path: 'acceptNewClientsIndicator',
        toggleHidden: this.effectiveSelfScheduling,
      },
    ];
  }
}
