module.exports = [
  'African Spirituality',
  'Agnostic',
  'Atheist',
  'Buddhist',
  'Catholic',
  'Christian',
  'Hindu',
  'Interfaith',
  'Islam',
  'Jain',
  "Jehovah's Witnesses",
  'Jewish',
  'Latter-day Saints/Mormon',
  'Multi-Faith',
  'Pagan',
  'Sikh',
  'Taoist',
];
