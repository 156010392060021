import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';
import { service } from '@ember/service';
import Modifier from 'ember-modifier';

const TRACKED_EVENTS = ['scroll', 'resize'];

function cleanup(instance) {
  if (!instance.element) return;

  instance.removeEvents(TRACKED_EVENTS);
}

export default class ClassOnScroll extends Modifier {
  @service media;

  className = 'scrolled';
  isScrolled = false;
  element = null;
  offset = null;
  scrollTarget = null;

  // NOTE: using offset prevents getting stuck in jittering collapsing/expanding loop
  get _scrollTarget() {
    let { scrollTarget, offset } = this;

    return this.isScrolled && offset ? scrollTarget - offset : scrollTarget;
  }

  constructor(owner, args) {
    super(owner, args);
    registerDestructor(this, cleanup);
  }

  modify(element, _, { scrollTarget, offset }) {
    cleanup(this);

    this.element = element;
    this.scrollTarget = scrollTarget;
    this.offset = offset;
    this.addEvents(TRACKED_EVENTS);
    this.handleScroll();
  }

  addEvents(events) {
    events.forEach(event => window.addEventListener(event, this.handleScroll, true));
  }

  removeEvents(events) {
    events.forEach(event => window.removeEventListener(event, this.handleScroll, true));
  }

  @action
  handleScroll() {
    // NOTE: below desktop width we set body to 100%, so body scrolls instead of window
    let scrollPosition = this.media.isMdDown ? document.body.scrollTop : window.scrollY;
    this.isScrolled = scrollPosition >= this._scrollTarget;
    this.toggleClass();
  }

  toggleClass() {
    this.element?.classList.toggle(this.className, this.isScrolled);
  }
}
