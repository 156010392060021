import Transform from '@ember-data/serializer/transform';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

@classic
export default class MomentTransform extends Transform {
  deserialize(serialized) {
    if (!serialized) {
      return serialized;
    }

    return moment.tz(serialized, moment.defaultZone?.name);
  }

  serialize(deserialized, { format } = {}) {
    if (!deserialized) {
      return deserialized;
    }

    return deserialized.format(format);
  }
}
