/**
  @hide
*/
let association = function (...traitsAndOverrides) {
  let __isAssociation__ = true;

  return {
    __isAssociation__,
    traitsAndOverrides,
  };
};

export default association;
