import { action, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import filterByTerm from '../../utils/filter-by-term';
import styles from './marketing-select.module.scss';

@classic
@classNames(styles.component)
export default class SharedMarketingSelect extends Component {
  hasCustomEntrySupport = true;
  isCachingEnabled = false;

  @computed('selectedRecords.@each.name', 'model.name')
  get isDuplicate() {
    let query = this.model?.name.toUpperCase().trim();

    if (!query) {
      return false;
    }

    return this.selectedRecords.any(({ name }) => name?.toUpperCase() === query);
  }

  @action
  onChange(name, select) {
    select.update(null);
    select.focusInput();
    this.model.set('name', '');

    if (this.selectedRecords.length >= this.maxCount) {
      return this._showLimitError();
    }

    let newFragment = this._createFragment(name);
    let updatedModel = [...this.selectedRecords.toArray(), newFragment];

    this.update(updatedModel);
  }

  @action
  filterOptions(query) {
    return filterByTerm(this.options, query);
  }

  @action
  clearInput(sb, evt) {
    evt.stopImmediatePropagation();
    this.model.set('name', '');
    sb.focusInput();
    sb.setInputValue('');
    sb.search('');
  }

  @action
  handleFocus(evt) {
    this.onFocus?.(evt);
  }

  @action
  handleFocusLeave(evt, sb) {
    if (sb.isOpen && !evt.relatedTarget) {
      sb.focusInput();
    }
  }

  @action
  handleClear(sb) {
    this.model.set('name', '');
    sb.search('');
  }

  @action
  handleInput(sb, name = '') {
    this.model?.set('name', name.trim());
    sb.open();
  }

  @action
  handleOpen(sb) {
    if (this.model.name) {
      sb.search(this.model.name);
    }
  }

  @action
  handleClose(sb) {
    if (this.model.name) {
      sb.setInputValue(this.model.name);
    }
  }

  _createFragment(name) {
    this.selectedRecords.createFragment({ name });
    return this.selectedRecords.popObject();
  }

  _showLimitError() {
    this.showErrorBanner?.({
      title: `Can't add more than ${this.maxCount} ${this.typeDescription}.`,
    });
  }
}
