/* import __COLOCATED_TEMPLATE__ from './wrapper.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './wrapper.module.scss';

@classic
@classNames(styles.component)
export default class SignInWrapper extends Component {
  @service session;
  @service currentPractice;
  @service fastboot;

  @reads('currentPractice.hasClientappDocumentAccess') hasClientappDocumentAccess;

  @computed('hasClientappDocumentAccess', 'fastboot.isFastBoot')
  get hideHeaderFooter() {
    if (this.fastboot.isFastBoot) {
      return true;
    }

    return this.session.get('data.redirect') && this.hasClientappDocumentAccess;
  }
}
