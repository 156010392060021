/* import __COLOCATED_TEMPLATE__ from './sections.hbs'; */
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeWebsiteEditMenuSections extends Component {
  @service currentPractice;

  @computed('isLocationsEnabled', 'currentPractice.clientPortalEnabled')
  get sectionsList() {
    return [
      {
        title: 'Services',
        enabledTip:
          'Includes a section for your focus areas, approaches, fees, and insurance accepted.',
        path: 'showSectionSpecialties',
        isDisabled: false,
      },
      {
        title: 'Testimonials',
        enabledTip: 'Includes a section for testimonials from clients or colleagues.',
        path: 'showSectionTestimonials',
        isDisabled: false,
      },
      {
        title: 'Location(s)',
        enabledTip: 'Includes a section for a list of your physical office locations.',
        disabledTip:
          'Includes a section for a list of your physical office locations. You need to add a physical office location in your SimplePractice Settings before you can turn this on.',
        path: 'showSectionLocations',
        isDisabled: !this.isLocationsEnabled,
      },
    ];
  }

  @computed('offices.@each.isVideo')
  get isLocationsEnabled() {
    return this.offices.any(office => !office.isVideo);
  }
}
