import EmberObject from '@ember/object';
import classic from 'ember-classic-decorator';

/**
 * transitions - a reference to the state machine this node is contained in.
 * idx - number of the popover. this is used for identification and ordering
 *       of the popover.
 * action - the action property of the popover,  If we dismiss the popover
 *          we need a way to walk the array and fire of the remaining popover
 *          actions.
 */

@classic
export default class PopSequencerStepNode extends EmberObject {
  transitions = null;
  idx = null;
  action = null;
  text = null;
}
