module.exports = [
  '1199 SEIU National Benefit Fund',
  'AARP',
  'Access Behavioral Care',
  'ACI Specialty Benefits',
  'Aetna',
  'All Savers Insurance',
  'Ambetter',
  'Amerigroup',
  'Anthem',
  'Beacon',
  'Blue Cross',
  'Blue Shield',
  'BlueCross and BlueShield',
  'CareSource',
  'CHIPA',
  'Cigna',
  'Claremont EAP',
  'ComPsych',
  'Emblem Health',
  'Excellus',
  'Fidelis Care',
  'First Choice Health',
  'First Health',
  'GEHA',
  'GHI',
  'HAP | Health Alliance Plan of Michigan',
  'Harvard Pilgrim',
  'Health Net',
  'Health Plan of Nevada',
  'Healthcare Partners',
  'Healthfirst',
  'HealthPartners',
  'Highmark',
  'HIP | Health Insurance Plan of New York',
  'Humana',
  'Inland Empire Health Plan',
  'Kaiser Permanente',
  'Lyra',
  'Magellan',
  'Managed Health Networks (MHN)',
  'MEDICA',
  'Medicaid',
  'Medicare',
  'Meridian Health Plan',
  'Meritain Health',
  'MHNet Behavioral Health',
  'MINES and Associates',
  'Modern Health Insurance',
  'Molina',
  'MultiPlan',
  'MVP Healthcare',
  'NYSHIP',
  'Optima Health Plan',
  'Optum',
  'Oscar',
  'Oxford',
  'PacificSource Health Plans',
  'Paramount Health Plan',
  'Premera Blue Cross',
  'Providence Behavioral Health',
  'SEIU',
  'SelectHealth',
  'Sierra Health',
  'SilverSummit',
  'SW Service Administrators',
  'The Empire Plan',
  'TRICARE East',
  'TriWest',
  'Tufts',
  'UMR',
  'United Healthcare',
  'United Healthcare Oxford',
  'UPMC Health Plan',
  'Wellcare HMO',
  'Out of Network',
  'Out of Pocket',
];
