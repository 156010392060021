/* import __COLOCATED_TEMPLATE__ from './information-index.hbs'; */
import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './information-index.module.scss';

@classic
@classNames('info-index', styles.component)
export default class RequestInformationInformationIndex extends Component {
  @service request;
  @service mixpanel;

  @action
  onInput({ target }) {
    this.mixpanel.track('user: booking widget your information input changed', {
      source: this.request.sourceType,
      'input_name': target.labels[0]?.textContent.trim(),
      'value_not_empty': !!target.value,
    });
  }
}
