/* import __COLOCATED_TEMPLATE__ from './file-upload.hbs'; */
import { action, set } from '@ember/object';
import { callbackError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { menuBannerError } from 'client-portal/utils/practice-website-banners';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './file-upload.module.scss';

const defaultErrorMessage = "Your file couldn't be uploaded";

@classic
@classNames(styles.component)
export default class PracticeWebsiteFileUpload extends Component {
  @service store;
  @service fileUpload;
  @service fileQueue;

  fileToCrop = null;

  init() {
    super.init(...arguments);
    this.resetErrors?.(this.model);
  }

  get queueName() {
    return `websiteUploads-${this.path}`;
  }

  @action
  removeFile() {
    set(this.model, this.path, null);
  }

  @action
  triggerFileSelection({ target, currentTarget }) {
    if (!['LABEL', 'INPUT', 'IMG', 'circle'].includes(target.tagName)) {
      currentTarget.querySelector('input').click();
    }
  }

  @task(function* (file) {
    try {
      yield this.validateTask.perform(file);
      this.set('fileToCrop', file);
    } catch (err) {
      return;
    }
  })
  selectFileToCrop;

  @(task(function* (file) {
    try {
      yield this.validateTask.perform(file);
      yield this.uploadTask.perform(file);
    } catch (err) {
      this.fileQueue.find(this.queueName).remove(file);
      if (err.name !== 'ValidationError') {
        throw err;
      }
    }
  }).drop())
  uploadFileTask;

  @(task(function* (file) {
    try {
      yield this.fileUpload.validateTask.perform(file);
      yield this.additionalValidationsTask?.perform(file);
    } catch (err) {
      err.name = 'ValidationError';

      if (this.onValidationError) {
        this.onValidationError(err);
      } else {
        this._setValidationError(err.message);
      }

      throw err;
    }
  }).drop())
  validateTask;

  @(task(function* () {
    try {
      let requestPayload = yield this.requestUpload(this.uploadType);
      this.store.pushPayload(requestPayload);
      return this.store.peekRecord('practice-website-upload', requestPayload.data.id);
    } catch (err) {
      callbackError(err, message => {
        let title = message.title || defaultErrorMessage;
        return menuBannerError(err, { title });
      });
    }
  }).drop())
  requestTask;

  @(task(function* (file) {
    try {
      let upload = yield this.requestTask.perform();
      yield this.fileUpload.uploadTask.perform(file, {
        route: this.store.adapterFor('practice-website-upload').urlForUpload(),
        headers: this.store.adapterFor('application').headers,
        fileKey: 'uploadFile',
        data: upload.getProperties('id', 'uploadToken'),
      });
      this._setValidationError(undefined);
      this.setAndNotify(this.path, upload.id);
    } catch (err) {
      // bubble error from requestTask
      if (err.errors) {
        throw err;
      }
      return menuBannerError(
        { errors: this.fileUpload.normalizeError(err) },
        { title: defaultErrorMessage }
      );
    }
  }).drop())
  uploadTask;

  _setValidationError(message) {
    set(this.model, `${this.path}Error`, message);
  }
}
