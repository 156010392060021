/* import __COLOCATED_TEMPLATE__ from './documents-table.hbs'; */
import { classNames } from '@ember-decorators/component';
import { humanDateFormat } from 'client-portal/utils/date-time';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './documents-table.module.scss';

@classic
@classNames(styles.component)
export default class SiteBillingDocumentsTable extends Component {
  @service media;
  @service store;

  @service billingModals;

  humanDateFormat = humanDateFormat;

  @reads('queryTask.lastSuccessful.value') records;

  async didInsertElement() {
    super.didInsertElement(...arguments);

    if (this.records) {
      return;
    }

    try {
      await this.queryTask.perform();
    } catch {
      // NOTE: template handles isError
    }
  }

  @(task(function* () {
    return yield this.store.query('polymorphic/billing-item', {
      filter: {
        thisType: this.thisType,
      },
      page: {
        size: 5,
      },
    });
  }).keepLatest())
  queryTask;
}
