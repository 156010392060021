/* import __COLOCATED_TEMPLATE__ from './time-select.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { humanFullDateFormat } from 'client-portal/utils/date-time';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import styles from './time-select.module.scss';

@classic
@classNames(styles.component)
export default class RequestDateTimeSelect extends Component {
  @service currentPractice;
  @service clientTimezone;

  humanFullDateFormat = humanFullDateFormat;

  @computed('filters.{morning,afternoon,evening}.checked')
  get timeTitleColumns() {
    let activeFiltersCount = Object.keys(this.filters).reduce(
      (sum, x) => sum + (this.get(`filters.${x}.checked`) ? 1 : 0),
      0
    );
    return activeFiltersCount && 12 / activeFiltersCount;
  }

  @computed('selectedSlot.{morningSpots,afternoonSpots,eveningSpots}.[]')
  get largestSpotsCount() {
    let spots = this.selectedSlot.getProperties('morningSpots', 'afternoonSpots', 'eveningSpots');

    return Object.keys(spots).reduce((largest, key) => {
      return spots[key].length > largest ? spots[key].length : largest;
    }, 0);
  }

  @computed('largestSpotsCount')
  get displayableSpotsCount() {
    let largestSpotsCount = this.largestSpotsCount;
    return largestSpotsCount > 5 ? 6 : largestSpotsCount;
  }

  @computed('currentTimeZone')
  get currentTimeZoneShort() {
    return moment.tz(this.currentTimeZone).format('z');
  }

  @computed('currentPractice.timeZone')
  get currentPracticeTimeZoneShort() {
    return moment.tz(this.currentPractice.get('timeZone')).format('z');
  }

  init() {
    super.init(...arguments);

    this.set('localTimeZoneShort', moment.tz(this.clientTimezone.timezone).format('z'));
  }
}
