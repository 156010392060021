import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class AppointmentService extends Service {
  @service store;

  async cancel(appointment) {
    let adapter = this.store.adapterFor('appointment');
    let type = this.store.modelFor('appointment');
    let result = await adapter.deleteRecord(this.store, type, appointment._createSnapshot());
    if (result) {
      this.store.pushPayload(result);
      return true;
    } else {
      appointment.unloadRecord();
      return false;
    }
  }
}
