import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeWebsiteUpload extends ApplicationAdapter {
  urlForUpload() {
    return `/${this.namespace}/practice-website-uploads`;
  }

  urlForUploadedFile(id, { getAdjusted = false } = {}) {
    return `/${this.namespace}/practice-website-uploads/${id}${getAdjusted ? '?adjusted' : ''}`;
  }
}
