/* eslint-disable camelcase */
import { convertToCents } from '../helpers/format-money';
import { createElement, error } from 'client-portal/utils/banners';

const appearance = {
  variables: {
    borderRadius: '2px',
    colorDanger: '#ea3917',
    colorText: '#212121',
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    fontLineHeight: '1.2em',
    fontSizeBase: '16px',
    gridColumnSpacing: '30px',
    gridRowSpacing: '23px',
  },
  rules: {
    '.Input': {
      padding: '16px 12px',
      border: '1px solid #ddd',
      outline: 'none',
    },
    '.Input:focus': {
      borderColor: '#339967',
      boxShadow: '0 0 0 3px #e6f2ec',
    },
    '.Input:hover': {
      borderColor: '#339967',
    },
    '.Input--invalid, .Input--invalid:hover, .Input--invalid:focus': {
      border: '1px solid #ea3917',
      boxShadow: 'none',
    },
    '.Label': {
      fontWeight: '600',
      marginBottom: '8px',
      color: '#333',
      fontSize: '14px',
    },
    '.Error': {
      marginTop: '8px',
    },
  },
};

export function buildStripeSetupModeElement(stripe) {
  let options = {
    mode: 'setup',
    paymentMethodTypes: ['card'],
    appearance,
  };

  return stripe.elements(options);
}

export function buildStripePaymentModeElement(stripe, opts = {}) {
  let paymentOptions = {
    mode: 'payment',
    currency: opts.currency || 'usd',
    onBehalfOf: opts.onBehalfOf,
    paymentMethodTypes: opts.paymentMethodTypes || ['card'],
    paymentMethodCreation: 'manual',
    amount: convertToCents(opts.amount),
    appearance,
  };
  return stripe.elements(paymentOptions);
}

export function buildStripePaymentElement(elements, readyCallback = () => {}) {
  let options = {
    terms: {
      card: 'never',
    },
    wallets: {
      googlePay: 'never',
      applePay: 'never',
    },
  };
  let paymentElement = elements.create('payment', options);

  paymentElement.on('ready', readyCallback);

  return paymentElement;
}

export function buildStripeExpressCheckoutElement(elements, callBackOptions = {}) {
  let expressCheckoutOptions = {
    wallets: {
      googlePay: 'never',
      applePay: 'never',
    },
  };
  let events = [
    ['ready', 'onReady'],
    ['loaderror', 'onLoadError'],
    ['confirm', 'onConfirm'],
    ['click', 'onClick'],
    ['cancel', 'onCancel'],
  ];

  let expressCheckoutElement = elements.create('expressCheckout', expressCheckoutOptions);

  events.forEach(([event, callback]) => {
    expressCheckoutElement.on(event, callBackOptions[callback] || (() => {}));
  });

  return expressCheckoutElement;
}

export async function confirmSetupIntent(
  setupIntent,
  elements,
  stripe,
  options = {},
  errorHandler = _errorBanner
) {
  if (setupIntent.isTest) {
    return _buildTestPaymentMethod(setupIntent.stripeCustomerId);
  } else {
    try {
      let setupIntentConfirmation = await _confirmSetupIntent(
        setupIntent,
        elements,
        stripe,
        options
      );

      return setupIntentConfirmation.setupIntent;
    } catch (err) {
      errorHandler(err.message);
    }
  }
}

async function _confirmSetupIntent(setupIntent, elements, stripe, options) {
  let confirmation = await stripe.confirmSetup({
    elements,
    clientSecret: setupIntent.clientSecret,
    confirmParams: {
      return_url: window.location.href,
      expand: ['payment_method'],
      payment_method_data: {
        billing_details: {
          name: options.cardholderName,
        },
      },
    },
    handleActions: false,
  });

  if (confirmation.error) {
    throw new Error(confirmation.error.message);
  }

  if (confirmation.setupIntent.status === 'requires_action') {
    throw new Error(
      'The card was declined because it requires authentication. Please try another card'
    );
  }

  return confirmation;
}

function _buildTestPaymentMethod(stripeCustomerId) {
  return {
    payment_method: {
      id: 'pm_123',
      card: {
        brand: 'visa',
        last4: '4242',
        exp_month: 10,
        exp_year: 2023,
      },
      billing_details: {
        name: 'Test payment method name',
      },
      customer: stripeCustomerId,
    },
  };
}

function _errorBanner(message) {
  error({
    title: 'Could not add a card',
    isTransient: true,
    content: createElement('p', { innerText: message }),
  });
}
