/* import __COLOCATED_TEMPLATE__ from './balance-banner.hbs'; */
import { and, gt, reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SiteBillingBalanceBanner extends Component {
  @service billingModals;

  @reads('model.client') client;
  @reads('client.channelCoveredSessionsCountRemaining') channelCoveredSessionsCountRemaining;
  @reads('client.globalMonarchChannel') globalMonarchChannel;
  @reads('globalMonarchChannel.displayName') channelName;
  @gt('client.clientBillingOverview.balanceDue', 0) clientHasBalanceDue;
  @reads('billingModals.client.permissions.payments') clientPaymentEnabled;
  @reads('billingModals.hasCustomStripeAccount') practiceStripeEnabled;
  @and('clientHasBalanceDue', 'clientPaymentEnabled', 'practiceStripeEnabled') showPayNowButton;
  @reads('billingModals.refreshBillingProfile.isRunning') disablePayNowButton;

  @computed('channelCoveredSessionsCountRemaining')
  get channelCovered() {
    return (
      this.channelCoveredSessionsCountRemaining && this.channelCoveredSessionsCountRemaining > 0
    );
  }
}
