import { action, set } from '@ember/object';
import { reads } from '@ember/object/computed';

import Component from '@ember/component';
import classic from 'ember-classic-decorator';

export const FREE_TEXT_TYPES = {
  none: '0',
  singleLine: '1',
  multiLine: '2',
};

@classic
export default class SharedQuestionnaireSingleSelectInput extends Component {
  FREE_TEXT_TYPES = FREE_TEXT_TYPES;

  @reads('answer.answers') answers;

  @action
  selectAnswer(intakeAnswer) {
    set(this.answer, 'isDirty', true);

    this.answers.clear();
    /* eslint-disable camelcase */
    this.answers.pushObject({ answer_id: `${intakeAnswer.id}`, answer_text: intakeAnswer.text });
    /* eslint-enable */
  }
}
