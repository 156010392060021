/* import __COLOCATED_TEMPLATE__ from './consent-document-form.hbs'; */
import { MIME_TYPE_ICONS } from 'client-portal/models/document-request';
import { action, computed } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { htmlSafe } from '@ember/template';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './consent-document-form.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsConsentDocumentForm extends Component {
  @service currentPractice;
  styles = styles;

  @computed('model.documentBody')
  get documentBody() {
    return htmlSafe(this.model.documentBody);
  }

  @computed('model.documentMimeType')
  get documentIcon() {
    return MIME_TYPE_ICONS[this.model.documentMimeType] || 'unknown';
  }

  @action
  rememberContentsNode(node) {
    this._contentsNode = node;
  }

  @action
  scrollContentsNode() {
    this._contentsNode.scrollTo(0, 0);
  }

  @action
  openDownload() {
    window.open(`/client-portal-api/document-requests/${this.model.id}.pdf`);
  }

  @action
  async nextStep() {
    try {
      await this.completeRequestTask.perform(this.model);
    } catch (err) {
      bannerError(err, { title: 'Document could not be submitted' });
    }
  }
}
