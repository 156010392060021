/* import __COLOCATED_TEMPLATE__ from './submit-button.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './submit-button.module.scss';

@classic
@classNames('submit-button', styles.component)
@classNameBindings('isActive:active')
export default class RequestSubmitButton extends Component {}
