import Model, { attr } from '@ember-data/model';

export default class StripePaymentIntentModel extends Model {
  @attr('number') amount;
  @attr() clientSecret;
  @attr() currency;
  @attr() idempotencyKey;
  @attr('json') paymentMethodTypes;
  @attr('boolean') useCredit;
  @attr('json') invoiceIdsWithAmounts;
}
