import Mixin from '@ember/object/mixin';

const INCOMING_MESSAGE_SCOPE = 'client-portal-origin-to-iframe';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  init() {
    this._super(...arguments);
    this._onMessage = this._onMessage.bind(this);
  },

  activate() {
    this._super(...arguments);

    if (typeof document === 'undefined') {
      return;
    }

    window.addEventListener('message', this._onMessage, false);
  },

  deactivate() {
    this._super(...arguments);

    if (typeof document === 'undefined') {
      return;
    }

    window.removeEventListener('message', this._onMessage, false);
  },

  _onMessage(message) {
    if (message.data.scope !== INCOMING_MESSAGE_SCOPE) {
      return;
    }

    // eslint-disable-next-line
    console && console.log(`SPWidget: incoming message ${JSON.stringify(message.data)}`);

    if (message.data.action) {
      this.send(message.data.action, message.data);
    }
  },
});
