const isFastboot = typeof FastBoot !== 'undefined';
const buildImage = fileName => (isFastboot ? null : new File([new Blob()], fileName));

const serialize = (insuranceTextExtractionId, insuranceCardId, attributes) => ({
  'data': {
    'id': insuranceTextExtractionId,
    'type': 'insuranceTextExtractions',
    attributes,
    'relationships': {
      'insuranceCard': {
        'data': {
          'type': 'insuranceCards',
          'id': insuranceCardId,
        },
      },
    },
  },
});

export const FRONT_CARD_COMPLETE = 'front_complete_image.png';
export const FRONT_CARD_LOW_MEMBER_ID_CONFIDENCE = 'front_low_member_id_confidence.png';
export const FRONT_CARD_NO_DATA = 'front_no_data.png';
export const BACK_CARD_PLAN_ID = 'back_plan_id.png';

export const SAMPLE_CARDS = {
  [FRONT_CARD_COMPLETE]: {
    attributes: {
      'memberId': 'COMPLETE',
      'memberIdConfidenceScore': 98,
      'groupId': '123456789A',
      'groupIdConfidenceScore': 98,
      'payerName': 'Anthem BlueCross',
      'payerNameConfidenceScore': 99,
      'payerPhone': '(209) 536-5110',
      'payerPhoneConfidenceScore': 91,
      'planId': null,
      'planIdConfidenceScore': null,
      'payerId': null,
      'payerIdConfidenceScore': null,
      'claimAddress': null,
      'claimAddressConfidenceScore': null,
    },
    serialize(insuranceTextExtractionId, insuranceCardId) {
      return serialize(insuranceTextExtractionId, insuranceCardId, this.attributes);
    },
    file: buildImage(FRONT_CARD_COMPLETE),
  },
  [FRONT_CARD_LOW_MEMBER_ID_CONFIDENCE]: {
    attributes: {
      'memberId': 'LOW',
      'memberIdConfidenceScore': 39,
      'groupId': '123456789A',
      'groupIdConfidenceScore': 98,
      'payerName': 'Anthem BlueCross',
      'payerNameConfidenceScore': 92,
      'payerPhone': '(209) 536-5110',
      'payerPhoneConfidenceScore': 90,
      'planId': 'XYZ1234567',
      'planIdConfidenceScore': 96,
      'payerId': null,
      'payerIdConfidenceScore': null,
      'claimAddress': null,
      'claimAddressConfidenceScore': null,
    },
    serialize(insuranceTextExtractionId, insuranceCardId) {
      return serialize(insuranceTextExtractionId, insuranceCardId, this.attributes);
    },
    file: buildImage(FRONT_CARD_LOW_MEMBER_ID_CONFIDENCE),
  },
  [FRONT_CARD_NO_DATA]: {
    attributes: {
      'memberId': null,
      'memberIdConfidenceScore': null,
      'groupId': null,
      'groupIdConfidenceScore': null,
      'payerName': null,
      'payerNameConfidenceScore': null,
      'payerPhone': null,
      'payerPhoneConfidenceScore': null,
      'planId': null,
      'planIdConfidenceScore': null,
      'payerId': null,
      'payerIdConfidenceScore': null,
      'claimAddress': null,
      'claimAddressConfidenceScore': null,
    },
    serialize(insuranceTextExtractionId, insuranceCardId) {
      return serialize(insuranceTextExtractionId, insuranceCardId, this.attributes);
    },
    file: buildImage(FRONT_CARD_NO_DATA),
  },
  [BACK_CARD_PLAN_ID]: {
    attributes: {
      'memberId': null,
      'memberIdConfidenceScore': null,
      'groupId': null,
      'groupIdConfidenceScore': null,
      'payerName': null,
      'payerNameConfidenceScore': null,
      'payerPhone': null,
      'payerPhoneConfidenceScore': null,
      'planId': 'XYZ1234567',
      'planIdConfidenceScore': 90,
      'payerId': null,
      'payerIdConfidenceScore': null,
      'claimAddress': null,
      'claimAddressConfidenceScore': null,
    },
    serialize(insuranceTextExtractionId, insuranceCardId) {
      return serialize(insuranceTextExtractionId, insuranceCardId, this.attributes);
    },
    file: buildImage(BACK_CARD_PLAN_ID),
  },
};
