import classic from 'ember-classic-decorator';
// A generalized mixin for uploading documents with 2 usage patterns:
//
//   Pattern 1: when you don't care what error happened (server or fileValidation)
//     this.fileUpload.validateAndUploadTask.perform(...)
//       .catch((err) => genericErrorHandler(err));
//
//   Pattern 2: when you want to handle the fileValidation and server errors separately
//     this.fileUpload.validateAndUploadTask.perform(file)
//       .catch((err) => validationError(err))
//       .then(() => this.fileUploadTask.perform(...))
//       .catch((err) => uploadError(err));
import { isEmberTesting } from '../utils/is-testing';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import Service, { service } from '@ember/service';

export const DEFAULT_MAX_FILE_SIZE = 11534336; // 11MB
export const DEFAULT_MAX_FILE_SIZE_TEST = 2000;

async function parseUploadResponse(res) {
  let contentType = res.headers?.get('content-type');
  let body = null;

  if (!contentType) return res;

  body = await res.json();

  return {
    ...res,
    ...(body && { body }),
  };
}

@classic
export default class FileUploadService extends Service {
  @service csrfToken;

  @(task(function* (file, params) {
    let { name, size, type } = file;
    let maxFileSize = params?.maxFileSize;
    let defaultMaxFileSize = isEmberTesting() ? DEFAULT_MAX_FILE_SIZE_TEST : DEFAULT_MAX_FILE_SIZE;
    let maxSize = maxFileSize || defaultMaxFileSize;
    return yield new Promise((resolve, reject) => {
      if (size <= maxSize) {
        return resolve();
      } else {
        if (type === '' && size < 100) {
          return reject({ headers: 'Could not upload a folder' });
        }

        let humanReadableSize = maxSize / 1024 / 1024;
        let msg = `File ${name} exceeds the maximum file size of ${humanReadableSize} Mb.`;
        return reject(new Error(msg));
      }
    });
  }).enqueue())
  validateTask;

  // Parameter description:
  //   file: the file to upload
  //   params: a hash with the following keys
  //     csrfToken: token
  //     method: 'PUT' or 'POST'
  //     route: '/path/to/endpoint'
  //     opts: a hash of additional params to send with the request
  @(task(function* (file, params) {
    let { route, method, opts, data, fileKey, headers } = params;
    try {
      let payload = yield file.upload(
        route,
        Object.assign(
          {
            headers: Object.assign({ 'X-CSRF-Token': this.csrfToken.token() }, headers || {}),
            method: method || 'POST',
            fileKey: fileKey || 'file',
            data: { data: JSON.stringify(data) },
          },
          opts
        )
      );
      return yield parseUploadResponse(payload);
    } catch (err) {
      let parsedError = yield parseUploadResponse(err);
      throw parsedError;
    }
  }).enqueue())
  uploadTask;

  // see uploadTask for description of parameters
  @(task(function* (file, params = {}) {
    yield this.validateTask.perform(file, params);
    return this.uploadTask.perform(file, params);
  }).enqueue())
  validateAndUploadTask;

  normalizeError(err) {
    if (err.body) {
      return err.body.errors;
    } else if (isPresent(err.headers)) {
      // File upload failed for network reasons
      return [err];
    }
    return [{ title: err }];
  }
}
