import { action } from '@ember/object';
import { buildStripeExpressCheckoutElement } from 'client-portal/utils/stripe-payment-methods';
import { error as errorBanner } from 'ember-simplepractice/utils/banners';
import { success } from 'client-portal/utils/banners';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

export default class StripeExpressCheckoutElementServiceService extends Service {
  @tracked _element;
  @tracked isOpen = false;
  @tracked isPersisting = false;
  @tracked isLoaded = false;
  @tracked _paymentOptions;
  @tracked _stripeElement;

  @service billingModals;

  updatePaymentOptionsAmount(amount) {
    this._paymentOptions.amount = amount;
  }

  @action
  getOrCreateElement(stripeElement, paymentOptions = {}) {
    if (this._element) {
      return this._element;
    }

    return this._createElement(stripeElement, paymentOptions);
  }

  @action
  destroyElement() {
    this._element?.destroy();
    this._element = null;
    this.isOpen = false;
    this.isPersisting = false;
    this.isLoaded = false;
    this._paymentOptions = null;
    this._stripeElement = null;
  }

  _createElement(stripeElement, paymentOptions = {}) {
    this._stripeElement = stripeElement;
    this._paymentOptions = paymentOptions;

    let callbackOptions = {
      onReady: this._onReady.bind(this),
      onLoadError: this._onLoadError.bind(this),
      onConfirm: this._onConfirm.bind(this),
      onClick: this._onClick.bind(this),
      onCancel: this._onCancel.bind(this),
    };

    this._element = buildStripeExpressCheckoutElement(stripeElement, callbackOptions);

    return this._element;
  }

  _onReady() {
    let expressCheckoutElement = document.querySelector(
      '#express-checkout-element .__PrivateStripeElement'
    );

    if (!expressCheckoutElement) {
      throw 'Express Checkout element was not rendered properly!';
    }

    this.isLoaded = true;
  }

  _onLoadError(event) {
    throw `Error loading express checkout element: ${event.type} - ${event.error}`;
  }

  async _onConfirm(event) {
    try {
      this.isPersisting = true;
      await this._confirmExpressCheckout();
    } catch (_err) {
      if (event.paymentFailed) {
        event.paymentFailed({ reason: 'fail' });
      }
      errorBanner({
        title: 'Your payment could not be processed',
        isTransient: true,
      });
    } finally {
      this.isPersisting = false;
    }
  }

  async _onClick(event) {
    if (this.isOpen || this.billingModals.isPersisting) {
      return;
    }

    this.isOpen = true;
    return event.resolve();
  }

  _onCancel(_event) {
    this.isOpen = false;
  }

  async _confirmExpressCheckout() {
    let paymentIntent = await this.billingModals.confirmExpressCheckout(
      this._stripeElement,
      this._paymentOptions
    );

    if (!paymentIntent) {
      return;
    }

    this.billingModals.hidePayment();
    success({ title: 'Payment made successfully.' });
  }
}
