/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action, computed } from '@ember/object';
import { attributeBindings } from '@ember-decorators/component';
import { confirmation } from 'client-portal/utils/modals';
import { createElement, error, success, warning } from 'client-portal/utils/banners';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

const REQUEST_TIMEOUT_MESSAGE = 'Request timeout';
const TIMEOUT = 10000;
const TIMEOUT_ANIMATION_INTERVAL = 1000;
const AMOUNT_TO_FILL_EACH_INTERVAL = 1 / (TIMEOUT / TIMEOUT_ANIMATION_INTERVAL);

@classic
@attributeBindings('side:data-test-insurance-card')
export default class SiteDocumentsContactInfoInsuranceSectionCard extends Component {
  @service media;
  @service store;
  @service mixpanel;
  @service currentPractice;

  @service analytics;
  @reads('connection.insuranceCards') insuranceCards;
  @reads('connection.insuranceCardFrontId') insuranceCardFrontId;
  @reads('connection.insuranceCardBackId') insuranceCardBackId;
  @reads('currentPractice.featureInsuranceTextRecognition') featureInsuranceTextRecognition;

  displayLoadingModal = false;
  textExtractionProgress = 0;

  @computed('model.documentExt')
  get isImage() {
    return /^(jpeg|jpg|png)$/.test(this.model?.documentExt);
  }

  @computed('insuranceCardFrontId', 'insuranceCardBackId', 'side', 'insuranceCards.[]')
  get model() {
    let id = this.connection.get(this.mappedField);
    return this.insuranceCards.findBy('id', id?.toString());
  }

  @computed('side')
  get mappedField() {
    return this.side === 'Front' ? 'insuranceCardFrontId' : 'insuranceCardBackId';
  }

  @computed('model.id')
  get fileUrl() {
    if (this.model?.id) {
      if (isEmberTesting()) {
        return '/test-fixtures/test-logo.png';
      }

      let adapter = this.store.adapterFor('insurance-card');
      return adapter.urlForFindRecord(this.model.id, 'insurance-card', {});
    }

    return undefined;
  }

  @action
  async remove() {
    await this.model.destroyRecord();
    this.connection.set(this.mappedField, null);
  }

  @(task(function* (file) {
    try {
      let card = yield this.store
        .createRecord('insurance-card', {
          file,
          connection: this.connection,
        })
        .save();
      this.mixpanel.track('user: Insurance card uploaded', {
        'card_type': `${this.side} of card`,
        'Client Profile page': false,
        'source': window.navigator.userAgent,
      });
      this.analytics.track('Insurance Card Uploaded', {
        object: `Insurance Card`,
        action: 'Uploaded',
        source: window.navigator.userAgent,
        'object_id': card.id,
        'card_type': `${this.side} of Card`,
        'client_profile_page': false,
      });
      this.connection.set(this.mappedField, card.id);

      if (!this.featureInsuranceTextRecognition) return;

      if (this._extractionFieldsFilled()) {
        let { dismiss } = yield confirmation({
          title: 'Update insurance info using new images?',
          text: 'Existing info will be deleted and replaced with info from the new images',
          confirmButtonText: 'Update info',
          cancelButtonText: 'Save without updating',
        });

        if (dismiss) return;
      }
      yield this._extractText(card.id);
    } catch (err) {
      let content = createElement('p', { innerText: err.message });
      error({ title: 'File could not be uploaded', content });
    }
  }).drop())
  uploadCard;

  _extractionFieldsFilled() {
    return (
      this.connection.insuranceMemberId ||
      this.connection.insurancePlanId ||
      this.connection.insuranceGroupId
    );
  }

  async _extractText(cardId) {
    this.set('displayLoadingModal', true);
    let intervalId = this._startTextExtractionProgressAnimation();
    try {
      let adapter = this.store.adapterFor('insurance-text-extraction');
      await this._requestWithTimeout(adapter.extractInsuranceCardInfoFromDocument(cardId), TIMEOUT);
      this._fillExtractedFields();
    } catch (error) {
      this._handleExtractionError();
    } finally {
      clearInterval(intervalId);
      this.setProperties({
        displayLoadingModal: false,
        textExtractionProgress: 0,
      });
    }
  }

  _startTextExtractionProgressAnimation() {
    return setInterval(() => {
      if (this.textExtractionProgress + AMOUNT_TO_FILL_EACH_INTERVAL < 1) {
        this.set(
          'textExtractionProgress',
          this.textExtractionProgress + AMOUNT_TO_FILL_EACH_INTERVAL
        );
      }
    }, TIMEOUT_ANIMATION_INTERVAL);
  }

  _handleExtractionError() {
    warning({
      title: 'Cannot autofill insurance info',
      content: createElement('p', {
        innerText: `Information couldn't be filled from the insurance card`,
      }),
    });
  }

  _requestWithTimeout(request, timeout) {
    return Promise.race([request, this._promiseTimeout(timeout)]);
  }

  _promiseTimeout(timeout) {
    return new Promise((_, reject) => {
      // eslint-disable-next-line ember-cleanup/no-settimeout
      setTimeout(() => {
        reject(new Error(REQUEST_TIMEOUT_MESSAGE));
      }, timeout);
    });
  }

  _fillExtractedFields() {
    this._fillField('memberId', 'insuranceMemberId');
    this._fillField('planId', 'insurancePlanId');
    this._fillField('groupId', 'insuranceGroupId');
    if (this._extractionFieldsFilled()) {
      success({ title: 'Insurance info added' });
    } else {
      warning({
        title: 'Cannot autofill insurance info',
        content: createElement('p', {
          innerText: `Information couldn't be filled from the insurance card`,
        }),
      });
    }
  }

  _fillField(textExtractionField, connectionField) {
    let fieldValue =
      this._insuranceTextExtractions()
        .map(insuranceTextExtraction =>
          this._getFieldValue(insuranceTextExtraction, textExtractionField)
        )
        .find(value => value) ?? null;

    this.connection.set(connectionField, fieldValue);
  }

  _getFieldValue(insuranceTextExtraction, textExtractionField) {
    if (insuranceTextExtraction?.[`${textExtractionField}ConfidenceScore`] >= 90) {
      return insuranceTextExtraction[textExtractionField];
    }
    return null;
  }

  _insuranceTextExtractions() {
    return [
      this.insuranceCardFrontId &&
        this.store.peekRecord('insurance-card', this.insuranceCardFrontId).insuranceTextExtraction,
      this.insuranceCardBackId &&
        this.store.peekRecord('insurance-card', this.insuranceCardBackId).insuranceTextExtraction,
    ].filter(Boolean);
  }
}
