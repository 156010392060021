/* import __COLOCATED_TEMPLATE__ from './appointments-list.hbs'; */
import { classNames } from '@ember-decorators/component';
import { filterBy } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './appointments-list.module.scss';

@classic
@classNames(styles.component)
export default class SiteAppointmentsList extends Component {
  @filterBy('model', 'isDeleted', false) appointments;
}
