import { reset } from 'timekeeper';
import ENV from 'client-portal/config/environment';

const TRACKING_COOKIE_NAME = 'tracking_enabled';
const TIME_TO_EXPIRATION = 86400000; // 1 day in milliseconds

// Probability function parameters:
const SAMPLE_SIZE = 0.3;
const MONTHLY_USAGE_COUNT = 8;

export function writeCookie(cookieService, cookieName, value, options = {}) {
  cookieService.write(cookieName, value, options);
}

export function getCookieOptions(isWidget) {
  if (ENV.environment === 'test') {
    reset();
  }

  let expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + TIME_TO_EXPIRATION);

  let options = {
    expires: expirationDate,
    path: '/',
  };

  if (isWidget) {
    options.sameSite = 'None';
    options.secure = true;
  }

  return options;
}

export function calculateProbability() {
  return 1 - (1 - SAMPLE_SIZE) ** (1 / MONTHLY_USAGE_COUNT);
}

export function sampleUser(randomDraw, isSampling) {
  if (isSampling) {
    return randomDraw < calculateProbability();
  }

  return true;
}

export function getTrackingStatus(cookieService, isWidget, isSampling) {
  if (cookieService.exists(TRACKING_COOKIE_NAME)) {
    return cookieService.read(TRACKING_COOKIE_NAME) === 'true';
  }

  // Random number ranging from 0 to <1:
  let randomDraw = Math.random();

  let trackingStatus = sampleUser(randomDraw, isSampling);
  let options = getCookieOptions(isWidget);

  writeCookie(cookieService, TRACKING_COOKIE_NAME, trackingStatus, options);

  return cookieService.exists(TRACKING_COOKIE_NAME) ? trackingStatus : false;
}
