/* import __COLOCATED_TEMPLATE__ from './payment-modal.hbs'; */
import { action } from '@ember/object';
import { alias, gt, not, or } from '@ember/object/computed';
import { classNames, tagName } from '@ember-decorators/component';
import { convertToCents } from 'client-portal/helpers/format-money';
import { error as errorBanner } from 'ember-simplepractice/utils/banners';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Component from '@ember/component';
import ENV from 'client-portal/config/environment';
import classic from 'ember-classic-decorator';
import styles from './payment-modal.module.scss';

@classic
@tagName('')
@classNames(styles.component)
export default class SiteBillingPaymentModal extends Component {
  styles = styles;
  @tracked stripeElement;
  @tracked stripeExpressCheckoutElement;

  @service billingModals;
  @service stripe;
  @service currentPractice;
  @service stripePaymentModeElementService;
  @service stripeExpressCheckoutElementService;

  @gt('billingModals.client.cards.length', 0) hasSavedCard;
  @alias('model.card.isNew') addingNewCard;
  @not('addingNewCard') notAddingNewCard;
  @or(
    'stripeExpressCheckoutElementService.isOpen',
    'stripeExpressCheckoutElementService.isPersisting'
  )
  isExpressCheckoutElementBusy;
  @or('billingModals.isPersisting', 'isExpressCheckoutElementBusy') disablePaymentMethodActions;
  @not('stripeExpressCheckoutElementService.isLoaded') isExpressCheckoutElementLoading;

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.collectDeviceData();

    try {
      this._createStripePaymentModeElement();

      if (!isEmberTesting()) {
        this._createStripeExpressCheckoutElement();
      }
    } catch (_err) {
      errorBanner({
        title: 'Unable to load additional payment methods',
        isTransient: true,
      });
    }
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);

    this.stripeExpressCheckoutElementService.destroyElement();
    this.stripePaymentModeElementService.destroyElement();
  }

  @action
  async addNewCard() {
    this.stripePaymentModeElementService.update({ paymentMethodTypes: ['card'] });
    // Adding a timeout to prevent Link from flickering in the payment element on transition
    await timeout(500);

    this.billingModals.createNewCard();
  }

  @action
  setInvoicePaymentAmount(invoice = null) {
    let amount = invoice ? invoice.remainingAmount : this.billingModals.balanceDue;
    this.stripePaymentModeElementService.update({ amount: convertToCents(amount) });
    this.stripeExpressCheckoutElementService.updatePaymentOptionsAmount(amount);

    this.billingModals.setPaymentInvoice(invoice);
  }

  @action
  async persistPaymentMethod() {
    let { card } = this.model;

    if (card.isNew) {
      let { error } = await this.stripeElement.submit();

      card.set('_isDirty', true);
      await card.validate();

      if (error || !card.name || this.disablePaymentMethodActions) {
        return;
      }
    }

    this.billingModals.persistPaymentWithPaymentMethodTask.perform(this.stripeElement);
  }

  collectDeviceData() {
    if (ENV.railsEnv !== 'test' && window.ka?.ClientSDK) {
      new window.ka.ClientSDK().autoLoadEvents();
    }
  }

  _createStripePaymentModeElement() {
    let paymentOptions = this._paymentOptions();

    this.stripeElement = this.stripePaymentModeElementService.createElement(
      this.stripe.stripe,
      paymentOptions
    );
  }

  _createStripeExpressCheckoutElement() {
    this.stripeExpressCheckoutElement = this.stripeExpressCheckoutElementService.getOrCreateElement(
      this.stripeElement,
      this.stripePaymentModeElementService.paymentOptions
    );
  }

  _paymentOptions() {
    return {
      amount: this.model.amount,
      currency: this.currentPractice.get('currency'),
      onBehalfOf: this.currentPractice.get('stripeConnectedAccountId'),
      paymentMethodTypes: ['card', 'link'],
    };
  }
}
