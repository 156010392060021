import Model, { attr, belongsTo } from '@ember-data/model';

export default class InsuranceTextExtractionModel extends Model {
  @belongsTo('insurance-card', { async: false }) insuranceCard;

  @attr('string') memberId;
  @attr('number') memberIdConfidenceScore;
  @attr('string') groupId;
  @attr('number') groupIdConfidenceScore;
  @attr('string') payerName;
  @attr('number') payerNameConfidenceScore;
  @attr('string') payerPhone;
  @attr('number') payerPhoneConfidenceScore;
  @attr('string') planId;
  @attr('number') planIdConfidenceScore;
  @attr('string') payerId;
  @attr('number') payerIdConfidenceScore;
  @attr('string') claimAddress;
  @attr('number') claimAddressConfidenceScore;
}
