/* import __COLOCATED_TEMPLATE__ from './invoice-payment-amount.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SiteBillingPaymentModalInvoicePaymentAmount extends Component {
  @service billingModals;
  @service stripeExpressCheckoutElementService;

  get totalBalanceIsNotPositive() {
    return this.billingModals.balanceDue <= 0;
  }

  get totalBalanceCreditSuffix() {
    return this.billingModals.balanceDue < 0 ? 'Credit' : '';
  }

  get totalBalanceIsLessThanInvoice() {
    return this.billingModals.balanceDue < this.args.model._invoice.remainingAmount;
  }

  get isExpressCheckoutElementBusy() {
    return (
      this.stripeExpressCheckoutElementService.isOpen ||
      this.stripeExpressCheckoutElementService.isPersisting
    );
  }
}
