import * as banners from '../../utils/banners';
import { action, computed } from '@ember/object';
import { getOwner } from '@ember/application';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import pht from '../../utils/persistent-hash-table';

function live(eventType, selector, callback, options) {
  document.addEventListener(
    eventType,
    function (evt) {
      if (evt.target.matches && evt.target.matches(selector)) {
        callback.call(evt.target, evt);
      }
    },
    options
  );
}

@classic
export default class SharedBannerMessage extends Component {
  banners = banners;

  @computed
  get fastboot() {
    return getOwner(this).lookup('service:fastboot');
  }

  @computed('isToast')
  get fastbootBannerClass() {
    return this.isToast ? 'toast' : 'banner';
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    let {
      classNames = '',
      container,
      dismissKey,
      isPermanent,
      noIcon,
      title = '',
      isToast,
      ctaText,
      ctaAction,
    } = this;

    if (dismissKey && pht.get(dismissKey)) {
      this.set('isDismissed', true);
      return;
    }

    live(
      'click',
      '[data-action]',
      evt => {
        evt.preventDefault();
        this.onClickOrDataAction();
        this.banners.dismiss(this.banner);
      },
      { once: true }
    );

    if (container === 'self') {
      container = this.element;
      classNames += ' banner-inline';
    }

    let content = this.element.querySelector('.content');

    let banner = this.banners[this.type]({
      title,
      content,
      container,
      classNames,
      isSticky: false,
      isTransient: false,
      isPermanent,
      noIcon,
      isToast,
      ctaText,
      ctaAction,
      onDismiss: () => {
        this.send('onDismiss');
      },
    });

    this.set('banner', banner);
  }

  willDestroyElement() {
    if (this.banner) {
      this.banner.params.onDismiss = null;
      this.banners.dismiss(this.banner);
    }

    super.willDestroyElement(...arguments);
  }

  @action
  onDismiss() {
    if (this.dismiss) {
      this.dismiss();
    }

    if (this.dismissKey) {
      pht.set(this.dismissKey, true);
      this.set('isDismissed', true);
    }
  }
}
