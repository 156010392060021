import Mixin from '@ember/object/mixin';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  serializeAttribute(snapshot, _json, key, _attributes) {
    let serializeAttributes =
      snapshot.adapterOptions && snapshot.adapterOptions.serializeAttributes;
    if (!serializeAttributes) {
      return this._super(...arguments);
    }

    if (serializeAttributes.includes(key)) {
      this._super(...arguments);
    }
  },
});
