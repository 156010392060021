/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { action, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { htmlSafe } from '@ember/template';
import { menuBannerError } from 'client-portal/utils/practice-website-banners';
import { patternSourceMap, thumbnailSourceMap } from 'client-portal/utils/practice-website-images';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitForNext } from 'ember-simplepractice/utils/waiters';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './intro.module.scss';

const DEFAULT_INTRO_READABILITY = 24;
const RECOMMENDED_MIN_INTRO_WIDTH = 1500;
const INTRO_BACKGROUND_TABS = {
  IMAGES: 'images',
  PATTERNS: 'patterns',
};

@classic
@classNames(styles.component)
export default class PracticeWebsiteEditMenuIntro extends Component {
  @service fileUpload;

  activeTab = INTRO_BACKGROUND_TABS.IMAGES;
  defaultIntroReadability = DEFAULT_INTRO_READABILITY;
  hasBlurryIntroAdvise = false;
  acceptedBackgroundFileTypes = 'image/jpeg,image/jpg,image/png';

  init() {
    super.init(...arguments);
    this._setImageList();
    this._setPatternList();
  }

  @computed('activeTab')
  get tabs() {
    let { IMAGES, PATTERNS } = INTRO_BACKGROUND_TABS;

    return [
      {
        id: IMAGES,
        isActive: this.activeTab === IMAGES,
      },
      {
        id: PATTERNS,
        isActive: this.activeTab === PATTERNS,
      },
    ];
  }

  @computed('activeTab')
  get hasBrightnessAdjust() {
    return this.activeTab === INTRO_BACKGROUND_TABS.IMAGES;
  }

  _setImageList() {
    this.imageList = Object.keys(thumbnailSourceMap).map(key => ({
      id: key.replace(/t/i, ''),
      source: thumbnailSourceMap[key],
    }));
  }

  _setPatternList() {
    let defaultItem = {
      id: '',
      source: '',
    };
    let patternList = Object.keys(patternSourceMap).map(key => ({
      id: key,
      style: htmlSafe(`background-image: url(${patternSourceMap[key]})`),
    }));
    this.patternList = [defaultItem, ...patternList];
  }

  @action
  setIntroImg(introImg) {
    this.model.set('introReadability', this.defaultIntroReadability);
    this.setAndNotify('introImg', introImg);
  }

  @action
  setUploadedIntroImg(path, value) {
    this.model.setProperties({
      introReadability: this.defaultIntroReadability,
      introImg: `u${value}`,
    });
    this.setAndNotify(path, value);
  }

  @action
  onBackgroundLoad() {
    let img = document.createElement('img');

    img.onload = async ({ target: { naturalWidth } }) => {
      await waitForNext();
      this.set('hasBlurryIntroAdvise', naturalWidth < RECOMMENDED_MIN_INTRO_WIDTH);
    };
    img.src = this.model.uploadedIntroImgUrl;
  }

  @action
  showValidationErrorBanner(err) {
    let { message } = err;
    let title = message.includes('file size') ? message.replace('11 Mb', '10 Mb') : message;

    menuBannerError({ errors: this.fileUpload.normalizeError(err) }, { title });
  }

  @task(function* ({ file }) {
    return yield new Promise((resolve, reject) => {
      if (this.acceptedBackgroundFileTypes.split(',').includes(file.type)) {
        return resolve();
      }

      return reject(new Error('The image must be a .jpg or .png file.'));
    });
  })
  validateBackgroundFileTypeTask;
}
