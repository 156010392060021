import { action } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class ClientAttendanceModalsBase extends Component {
  @action
  dismiss(model, disabled) {
    if (model && !disabled) {
      model.set('isDirty', false);
    }
    this.onClose();
  }
}
