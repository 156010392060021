module.exports = {
  ageGroups: require('./lib/age-groups'),
  communities: require('./lib/communities'),
  faiths: require('./lib/faiths'),
  genders: require('./lib/genders'),
  insuranceProviders: require('./lib/insurance-providers'),
  languages: require('./lib/languages'),
  professions: require('./lib/professions'),
  pronouns: require('./lib/pronouns'),
  raceEthnicities: require('./lib/race-ethnicities'),
  services: require('./lib/services'),
  serviceDefinitions: require('./lib/service-definitions'),
  specialties: require('./lib/specialties'),
  treatmentTypes: require('./lib/treatment-types'),
};
