/* import __COLOCATED_TEMPLATE__ from './mobile-submit-button.hbs'; */
import { action } from '@ember/object';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { later } from '@ember/runloop';
import Component from '@ember/component';
import ENV from 'client-portal/config/environment';
import classic from 'ember-classic-decorator';
import styles from './mobile-submit-button.module.scss';

@classic
@classNames('mobile-submit-button', styles.component)
@classNameBindings('isActive:active')
export default class RequestMobileSubmitButton extends Component {
  @action
  completeSubmission() {
    if (!this.isActive) {
      return;
    }

    // Lose focus to hide keyboard for proper height calculation in Safari on iOS
    document.activeElement.blur();

    let timeout = ENV.environment === 'test' ? 0 : 500;

    later(() => {
      if (this.isDestroyed) {
        return;
      }

      let nextStep = this.nextStep;
      if (nextStep) {
        nextStep();
      } else {
        this.submitForm();
      }
    }, timeout);
  }
}
