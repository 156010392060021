import { STEP_IDS } from 'client-portal/services/widget-steps';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';

@classic
export default class RequestUnavailable extends RequestBaseRoute {
  @service analytics;
  @service session;
  @service mixpanel;
  @service request;

  @tracked reason = '';

  templateName = '-request/unavailable';
  stepId = STEP_IDS.UNAVAILABLE;
  queryParams = {
    reason: {},
  };

  model(params) {
    let { reason } = params || {};
    this.reason = reason;
    return this.modelFor(this.request.baseRouteName);
  }
}
