import { action } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './marketing-sortable-list.module.scss';

@classic
@classNames(styles.component)
export default class SharedMarketingSortableList extends Component {
  @action
  onChange(model) {
    this.update(model);
  }

  @action
  removeItem(itemToRemove) {
    if (this.remove) {
      return this.remove(itemToRemove);
    }

    this.model.removeObject(itemToRemove);
    this.update(this.model);
  }
}
