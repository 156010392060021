/* import __COLOCATED_TEMPLATE__ from './trigger.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';

export default class FeedbackBannerTrigger extends Component {
  @service currentPractice;
  delay = 500;

  @service feedbackBanner;
  @service session;
  @service store;

  @action
  displayFeedbackBanner(survey) {
    let { currentPractice } = this.store.peekAll('environment').firstObject;
    if (this.session.isAuthenticated && currentPractice.featureClientFeedbackBanner) {
      this.feedbackBanner.display(survey);
    }
  }

  @action
  hideFeedbackBanner() {
    this.feedbackBanner.hide();
  }
}
