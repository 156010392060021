import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { services } from '@simplepractice/marketing-options';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

export const maxMarketingServicesCount = 15;
export const maxMarketingServicesLength = 60;
export const globalMarketingServices = services;

const Validations = buildValidations({
  name: [
    validator('length', {
      max: maxMarketingServicesLength,
      message: `Can't be more than ${maxMarketingServicesLength} characters.`,
    }),
  ],
});

const ExtendedFragment = Fragment.extend(Validations);

@classic
export default class PracticeWebsiteMarketingService extends ExtendedFragment {
  @attr() name;
}
