import { isMobileApp } from 'client-portal/utils/mobile-detect';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class SessionTimeoutRoute extends Route {
  @service router;
  @service session;
  @service mixpanel;
  activate() {
    super.activate(...arguments);

    this.mixpanel.track('mobile: displayed document session timeout page');
  }

  beforeModel() {
    super.beforeModel(...arguments);

    if (!isMobileApp()) {
      return this.router.transitionTo('not-found', 'errors/404');
    }
  }
}
