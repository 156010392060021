/* import __COLOCATED_TEMPLATE__ from './mobile-menu.hbs'; */
import { action, set } from '@ember/object';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './mobile-menu.module.scss';

@classic
@classNameBindings('isOpen:open')
@classNames(styles.component)
export default class PracticeWebsiteMobileMenu extends Component {
  @service fastboot;
  @service keyboardNavigation;

  openMenuBodyClass = 'body-scroll-locked';

  @reads('fastboot.isFastBoot') isFastBoot;

  async didInsertElement() {
    super.didInsertElement(...arguments);
    let bodyScrollLock = await import('body-scroll-lock');

    if (!this.isDestroyed) {
      this.setProperties({ bodyScrollLock });
    }
  }

  @action
  toggleMenu() {
    this.isOpen ? this._enableBodyScroll() : this._disableBodyScroll();
    set(this, 'isOpen', !this.isOpen);
  }

  @action
  closeMenu() {
    this._enableBodyScroll();
    set(this, 'isOpen', false);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    if (this.isFastBoot) {
      return;
    }

    this._enableBodyScroll();
  }

  _enableBodyScroll() {
    this._toggleOpenClass(false);
    this.element && this.bodyScrollLock?.enableBodyScroll(this.element);
  }

  _disableBodyScroll() {
    this._toggleOpenClass(true);
    this.element && this.bodyScrollLock?.disableBodyScroll(this.element);
  }

  _toggleOpenClass(toggleValue) {
    [document.documentElement, document.body].forEach(el => {
      el.classList.toggle(this.openMenuBodyClass, toggleValue);
    });
  }
}
