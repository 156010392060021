/* import __COLOCATED_TEMPLATE__ from './client-section.hbs'; */
import { action } from '@ember/object';
import { dateFormat } from 'client-portal/utils/date-time';
import { modifier } from 'ember-modifier';
import { service } from '@ember/service';
import SiteBillingOutOfNetworkFormEditReviewEditableSection from './edit/review-editable-section';
import styles from './client-section.module.scss';

export default class SiteBillingOutOfNetworkFormClientSection extends SiteBillingOutOfNetworkFormEditReviewEditableSection {
  @service store;

  styles = styles;
  dateFormat = dateFormat;
  sectionName = 'client_info';

  onInsert = modifier(
    () => {
      this.args.onTrack('client_info');
      this.args.client.set('isDirty', false);
    },
    { eager: false }
  );

  get client() {
    return this.args.client;
  }

  @action
  async saveEdit() {
    await this.args.form.saveClient();
    this.handleSaveEdit(this.client);
  }
}
