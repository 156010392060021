/* import __COLOCATED_TEMPLATE__ from './testimonials.hbs'; */
import { classNames } from '@ember-decorators/component';
import { gt } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './testimonials.module.scss';

@classic
@classNames(styles.component)
export default class PracticeWebsiteTestimonials extends Component {
  @service testimonialSwiper;

  @gt('model.filteredMarketingTestimonials.length', 1) showIndicators;
}
