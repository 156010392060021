/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import {
  DEFAULT_DOCUMENTS_MAX_FILE_SIZE,
  DEFAULT_MAX_FILE_SIZE_TEST,
} from 'client-portal/services/file-upload';
import { action, set, setProperties } from '@ember/object';
import { classNames, tagName } from '@ember-decorators/component';
import { numberToHumanSize } from 'client-portal/helpers/number-to-human-size';
import { service } from '@ember/service';
import Component from '@ember/component';
import ENV from 'client-portal/config/environment';
import classic from 'ember-classic-decorator';
import styles from './form.module.scss';

@classic
@tagName('form')
@classNames('text-left', styles.component)
export default class RequestChannelUploadsForm extends Component {
  @service fileUpload;

  maxFilesCount = 5;
  maxFileSize =
    ENV.environment === 'test' ? DEFAULT_MAX_FILE_SIZE_TEST : DEFAULT_DOCUMENTS_MAX_FILE_SIZE;
  queueName = 'RequestChannelUploadsQueue';
  accept = [
    'application/msword',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'audio/m4a',
    'audio/mp3',
    'audio/mp4',
    'audio/mpeg',
    'audio/x-m4a',
    'image/jpeg',
    'image/png',
    'text/csv',
    'video/mp4',
  ].join(',');

  @action
  selectFile(file) {
    if (this.model.channelUploads.length === this.maxFilesCount) return null;
    this.fileUpload.validateTask
      .perform(file, {
        maxFileSize: this.maxFileSize,
      })
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch(this.handleFileSizeError.bind(this));

    this.model.channelUploads.pushObject(file.file);
  }

  @action
  handleDrop(drop) {
    if (!drop.files[0]) {
      setProperties(this.model, { isValid: true, validations: null });
    }
  }

  @action
  removeFile(e) {
    let index = e.currentTarget.parentElement.parentElement.dataset.index;
    set(this.model, 'channelUploads', this.model.channelUploads.removeAt(index));
    if (this.model.channelUploads.length === 0) {
      setProperties(this.model, { isValid: true, validations: null });
    }
  }

  handleFileSizeError(_err) {
    setProperties(this.model, {
      isValid: false,
      validations: {
        attrs: {
          channelUploads: {
            isInvalid: true,
            message: `The file you selected is bigger than ${numberToHumanSize([
              this.maxFileSize,
            ])}. To reduce the total file size, try lowering the resolution on any image files you include in the upload.`,
          },
        },
      },
    });
  }
}
