import { set } from '@ember/object';
import FeedbackBannerEffortScore from '../components/feedback-banner/effort-score';
import FeedbackBannerQuestion from '../components/feedback-banner/question';
import FeedbackBannerSurveyCompleted from '../components/feedback-banner/survey-completed';
import moment from 'moment-timezone';
import pht from 'client-portal/utils/persistent-hash-table';

// any new events will need to be defined here
// handlers will be bound to the feedback-banner component
// when it is inserted into the page
export const FEEDBACK_PERSISTENCE_KEY = 'feedback-banner';
export const ACTIVE_FEEDBACK_BANNER_ID = 'effort-score';
export const SIGNIN_PERSISTENCE_KEY = 'signin-count/effort-score';

const SHOW_STATES = {
  started: 'started',
  dismissed: 'dismissed',
  completed: 'completed',
};

export const TIME_FORMAT = 'YYYY-MM-DDTHH:mmZZ';

const MIN_SHOW_SURVEY_COUNT = 3;

const DISMISSED_FEEDBACK_RETRY_MONTHS = 1;
const COMPLETE_FEEDBACK_RETRY_MONTHS = 3;
const IS_QUESTION_STATE = /^question.*/i;

const DEFAULT_SHOW_AFTER = {
  afterMinSignIn: {
    minSignIn: MIN_SHOW_SURVEY_COUNT,
  },
  afterDismiss: {
    minSignIn: MIN_SHOW_SURVEY_COUNT,
    months: DISMISSED_FEEDBACK_RETRY_MONTHS,
  },
  afterComplete: {
    minSignIn: MIN_SHOW_SURVEY_COUNT,
    months: COMPLETE_FEEDBACK_RETRY_MONTHS,
  },
};

const DEFAULT_START = {
  id: 'start',
  header: {
    titleSvg: 'sp-butterfly-forest',
    title: 'Help us improve',
    subtitle: 'Share your feedback?',
  },
  model: {
    label: 'Your response will help SimplePractice improve your Client Portal experience.',
    accept: {
      label: "sure, i'll help",
      action: 'next',
    },
    reject: {
      label: 'not now',
      action: 'dismiss',
    },
  },
  body: FeedbackBannerQuestion,
  class: 'feedback-banner-question',
  next: 'question',
};

const DEFAULT_CONFIRM_EXIT = {
  id: 'confirm-exit',
  header: {
    titleSvg: 'sp-butterfly-forest',
    title: 'Help us improve',
    subtitle: 'Are you sure?',
  },
  model: {
    label: 'If you exit without submitting this form, your feedback will not be sent.',
    accept: {
      label: 'yes, exit',
      action: 'dismiss',
    },
    reject: {
      label: 'go back',
      action: 'continue',
    },
  },
  body: FeedbackBannerQuestion,
  class: 'feedback-banner-confirm-exit',
};

const DEFAULT_COMPLETE = {
  id: 'complete',
  body: FeedbackBannerSurveyCompleted,
  class: 'feedback-banner-complete',
};

export const BANNER_STATES = [
  {
    id: ACTIVE_FEEDBACK_BANNER_ID,
    showAfter: DEFAULT_SHOW_AFTER,
    states: [
      DEFAULT_START,
      {
        id: 'question',
        header: {
          titleSvg: 'sp-butterfly-forest',
          title: 'Help us improve',
          subtitle: 'How easy or difficult is it to use the Client Portal?',
        },
        type: 'review',
        body: FeedbackBannerEffortScore,
        class: 'feedback-banner-score',
        next: 'complete',
      },
      DEFAULT_CONFIRM_EXIT,
      DEFAULT_COMPLETE,
    ],
  },
];

function parseStorage() {
  let statuses = [];
  let feedbackStatusStr = pht.get(FEEDBACK_PERSISTENCE_KEY);
  try {
    statuses = feedbackStatusStr ? JSON.parse(feedbackStatusStr) : [];
  } catch {
    // catching in case errors in parsing
  }
  return statuses;
}

function hasTimePassed({ lastSeen: lastSeenStr, months }) {
  let lastSeen = moment(lastSeenStr, TIME_FORMAT);
  let now = moment();
  let showAgain = lastSeen.add(months, 'month');
  return now.isSameOrAfter(showAgain);
}

function hasCriteria({ status, signInCount, afterMinSignIn, afterDismiss, afterComplete }) {
  let { lastSeen, lastSignInCount = 0, state } = status;
  let completed = state === SHOW_STATES.completed;
  let { minSignIn, months } = !lastSeen ? afterMinSignIn : completed ? afterComplete : afterDismiss;
  let hasEnoughSignIns = signInCount >= lastSignInCount + minSignIn;
  return hasEnoughSignIns && (!lastSeen || hasTimePassed({ lastSeen, months }));
}

export function getFeedbackStatus(id) {
  let statuses = parseStorage();
  return statuses.findBy('id', id) || { id };
}

export function setFeedbackStatus(status) {
  let { id } = status;
  if (id) {
    let storedStatuses = parseStorage();
    let statuses = storedStatuses.rejectBy('id', id);
    statuses.push(status);
    try {
      pht.set(FEEDBACK_PERSISTENCE_KEY, JSON.stringify(statuses));
    } catch {
      // catching in case errors in serialization
    }
  }
}

function canShowSurvey({ id, showAfter }) {
  if (!id || !showAfter) {
    return false;
  }
  let shouldShow = false;
  let signInCount = parseInt(pht.get(SIGNIN_PERSISTENCE_KEY));
  let status = getFeedbackStatus(id);
  let criteria = { status, signInCount, ...showAfter };

  if (hasCriteria(criteria)) {
    shouldShow = true;
    status.lastSeen = moment().format(TIME_FORMAT);
    status.lastSignInCount = signInCount;
    setFeedbackStatus(status);
  }
  return shouldShow;
}

export const events = [
  {
    eventName: 'display',
    handler(id) {
      let { states, showAfter } = BANNER_STATES.findBy('id', id) || {};
      if (canShowSurvey({ id, showAfter })) {
        set(this, 'id', id);
        this.setStates(states);
        this.setCurrentStateId('start');
        this.mixpanel.trackAnonymously('client: survey banner viewed', this.mixpanelEventData);
        this.analytics.trackAnonymously('Survey Banner Viewed', {
          object: 'Survey Banner',
          action: 'Viewed',
          ...this.mixpanelEventData,
        });
      }
    },
  },
  {
    eventName: 'started',
    handler() {
      let status = getFeedbackStatus(this.id);
      let { state } = status;
      if (!state || state !== SHOW_STATES.started) {
        status.state = SHOW_STATES.started;
        setFeedbackStatus(status);
        this.mixpanel.trackAnonymously('client: survey banner started', this.mixpanelEventData);
      }
    },
  },
  {
    eventName: 'next',
    handler() {
      let { nextState, currentStateId } = this;
      this.lastStateId = currentStateId;
      this.setCurrentStateId(nextState);
    },
  },
  {
    eventName: 'continue',
    handler() {
      let { lastStateId } = this;
      let status = getFeedbackStatus(this.id);
      status.state = SHOW_STATES.started;
      setFeedbackStatus(status);
      this.setCurrentStateId(lastStateId);
    },
  },
  {
    eventName: 'complete',
    handler() {
      let status = getFeedbackStatus(this.id);
      status.state = SHOW_STATES.completed;
      setFeedbackStatus(status);
      this.feedbackBanner.hide();
    },
  },
  {
    eventName: 'dismiss',
    handler() {
      let { currentStateId, lastStateId } = this;
      let status = getFeedbackStatus(this.id);
      let { state } = status;
      let isConfirmDismiss = currentStateId === 'confirm-exit';
      let isNeverSeen = !state;
      let isStarted = state === SHOW_STATES.started;
      let isDismissedWithoutStart = !(
        IS_QUESTION_STATE.test(currentStateId) || IS_QUESTION_STATE.test(lastStateId)
      );

      if (isConfirmDismiss || isNeverSeen) {
        state = isNeverSeen || isStarted ? SHOW_STATES.dismissed : state;
      } else if (isStarted) {
        this.lastStateId = currentStateId;
        this.setCurrentStateId('confirm-exit');
      }

      if (state === SHOW_STATES.dismissed || state === SHOW_STATES.completed) {
        setFeedbackStatus({ ...status, state });
        this.mixpanel.trackAnonymously('client: survey banner abandoned', {
          ...this.mixpanelEventData,
          'click_location': isDismissedWithoutStart ? 'alert' : 'banner',
        });
        this.feedbackBanner.hide();
      }
    },
  },
  {
    eventName: 'hide',
    handler() {
      this.clearStates();
      this.setCurrentStateId(null);
    },
  },
];

export const bannerEvents = events.map(item => item.eventName);
