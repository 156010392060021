import { UploadFile } from 'ember-file-upload';
import { action, computed, set, setProperties } from '@ember/object';
import { and, not, or } from '@ember/object/computed';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

const DEFAULT_FILE_TYPE = 'png';
const SUPPORTED_FILE_TYPES = [DEFAULT_FILE_TYPE, 'jpeg'];
const IMG_QUALITY = 0.97;
const DEFAULT_ZOOM = 1;
const DEFAULT_VIEWPORT_RATIO = 1;
const DEFAULT_VIEWPORT_BASE_SIZE = 200;
const MAX_SIZE_IN_PX = 2000;

@classic
export default class SharedImageCrop extends Component {
  rangeInputValue = 0;
  isStrictRecommendation = false;

  @and('isNotRecommendedHeight', 'isNotRecommendedWidth') isBothNotRecommendedSize;
  @or('isNotRecommendedHeight', 'isNotRecommendedWidth') isEitherNotRecommendedSize;
  @and('isImageMinWidth', 'isImageMinHeight') isNotAllowedToEdit;
  @or('isImageMinWidth', 'isImageMinHeight') isNotAllowedToZoom;
  @not('isNotAllowedToZoom') isAllowedToZoom;

  async init() {
    super.init(...arguments);
    let Croppie = (await import('croppie')).default;
    let { imgWidth, imgHeight } = await this._imageDimensions();
    this._setFileType();
    setProperties(this, { Croppie, imgWidth, imgHeight });
  }

  @computed('file.file')
  get fileUrl() {
    return URL.createObjectURL(this.file.file);
  }

  @computed('imgWidth', 'imgHeight')
  get isLandscape() {
    return this.imgWidth > this.imgHeight;
  }

  @computed('imgHeight', 'recommendedMinImgHeight')
  get isNotRecommendedHeight() {
    return this.imgHeight < this.recommendedMinImgHeight;
  }

  @computed('imgWidth', 'recommendedMinImgWidth')
  get isNotRecommendedWidth() {
    return this.imgWidth < this.recommendedMinImgWidth;
  }

  @computed('viewportWidthRatio', 'viewportBaseSize')
  get viewportWidth() {
    return this._viewportSize(this.viewportWidthRatio, this.viewportBaseSize);
  }

  @computed('viewportHeightRatio', 'viewportBaseSize')
  get viewportHeight() {
    return this._viewportSize(this.viewportHeightRatio, this.viewportBaseSize);
  }

  @computed('minImgWidth', 'imgWidth')
  get isImageMinWidth() {
    return this.imgWidth && this.minImgWidth === this.imgWidth;
  }

  @computed('minImgHeight', 'imgHeight')
  get isImageMinHeight() {
    return this.imgHeight && this.minImgHeight === this.imgHeight;
  }

  @computed('viewportHeight', 'imgHeight')
  get zoomOnHeight() {
    return this.viewportHeight / this.imgHeight;
  }

  @computed('viewportWidth', 'imgWidth')
  get zoomOnWidth() {
    return this.viewportWidth / this.imgWidth;
  }

  @computed('isStrictRecommendation', 'isBothNotRecommendedSize', 'isEitherNotRecommendedSize')
  get isNotRecommendedSize() {
    return this.isStrictRecommendation
      ? this.isBothNotRecommendedSize
      : this.isEitherNotRecommendedSize;
  }

  @computed('isNotAllowedToZoom', 'isLandscape', 'zoomOnHeight', 'zoomOnWidth')
  get zoom() {
    if (this.isNotAllowedToZoom) {
      return DEFAULT_ZOOM;
    }
    return this.isLandscape ? this.zoomOnHeight : this.zoomOnWidth;
  }

  @action
  createCropPreview(el, opts) {
    set(this, 'cropPreview', new this.Croppie(el, opts));
  }

  @action
  bindCropPreview(opts) {
    this.cropPreview?.bind(opts);
  }

  @action
  destroyCropPreview() {
    this.cropPreview?.destroy();
  }

  @action
  async applyChange() {
    let { points } = this.cropPreview.get();
    let [leftX, , rightX] = points;
    let { fileType: format } = this;

    let blob = await this.cropPreview.result({
      size: rightX - leftX > MAX_SIZE_IN_PX ? { width: MAX_SIZE_IN_PX } : 'original',
      quality: IMG_QUALITY,
      format,
    });

    let file = UploadFile.fromDataURL(blob);
    this.onConfirm(file);
    this.onClose();
  }

  @action
  onRangeInputChange(value) {
    this._updateZoom(value);
  }

  _viewportSize(viewportRatio, viewportBaseSize) {
    return (
      (viewportRatio || DEFAULT_VIEWPORT_RATIO) * (viewportBaseSize || DEFAULT_VIEWPORT_BASE_SIZE)
    );
  }

  _updateZoom(value) {
    set(this, 'rangeInputValue', value);
    this.cropPreview.setZoom(this.zoom * ((value / 100) * 2 + 1));
  }

  _setFileType() {
    let { type } = this.file.file;
    let fileType =
      SUPPORTED_FILE_TYPES.find(iFileType => type.includes(iFileType)) || DEFAULT_FILE_TYPE;
    set(this, 'fileType', fileType);
  }

  async _imageDimensions() {
    let src = await this.file.readAsDataURL();
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        resolve({
          imgWidth: img.naturalWidth,
          imgHeight: img.naturalHeight,
        });
      };
      img.onerror = reject;
      img.src = src;
    });
  }
}
