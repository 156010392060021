/* import __COLOCATED_TEMPLATE__ from './oon-claim-submission-banner.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './oon-claim-submission-banner.module.scss';

export default class SiteBillingOonClaimSubmissionBanner extends Component {
  styles = styles;
  @service oonClaimSubmissionError;
  @service billingModals;

  errorText =
    'To manually submit a claim to your insurance for reimbursement, you may need documentation ' +
    'that includes appointment and billing info. We recommend using an insurance reimbursement statement.';

  get errorTitle() {
    return `Claim wasn't submitted for ${this.oonClaimSubmissionError.appointmentDate} appointment`;
  }

  @action
  sendToInsuranceReimbursementStatement() {
    this.oonClaimSubmissionError.dismissBanner();
    this.billingModals.showPdf(this.oonClaimSubmissionError.superbill);
  }
}
