import Velocity from 'velocity';
import config from './config/environment';

// For more info on disabling animations in testing:
// https://github.com/ember-animation/liquid-fire/issues/405

function noAnimations() {
  return config.environment === 'test';
}

export function getDuration(speed) {
  switch (speed) {
    case 'slow':
      return 400;
    case 'medium':
      return 200;
    case 'fast':
      return 125;
    default:
      return 250;
  }
}

export default function () {
  if (noAnimations()) {
    Velocity.mock = true;
  }

  this.transition(
    this.matchSelector('.transition-side-flyout.forward'),
    this.use(
      'explode',
      {
        pick: '.side-flyout-backdrop',
        use: ['fade', { maxOpacity: 0.3, duration: getDuration('slow') }],
      },
      {
        pick: '.side-flyout-wrapper',
        use: ['toLeft', { duration: getDuration('slow') }],
      }
    )
  );

  this.transition(
    this.matchSelector('.transition-side-flyout.backward'),
    this.use(
      'explode',
      {
        pick: '.side-flyout-backdrop',
        use: ['fade', { maxOpacity: 0.6, duration: getDuration() }],
      },
      {
        pick: '.side-flyout-wrapper',
        use: ['toRight', { duration: getDuration() }],
      }
    )
  );

  this.transition(this.hasClass('transition-fade'), this.use('fade', { duration: getDuration() }));

  this.transition(
    this.hasClass('transition-fade-fast'),
    this.use('fade', { duration: getDuration('fast') })
  );

  this.transition(
    this.matchSelector('.transition-nav-menu-slide'),
    this.toValue(function (newValue, oldValue) {
      return newValue.parent === oldValue.id;
    }),
    this.use('toLeft', { duration: getDuration() }),
    this.reverse('toRight', { duration: getDuration() })
  );
}
