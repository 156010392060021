/* import __COLOCATED_TEMPLATE__ from './location-item.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './location-item.module.scss';

@classic
@classNames('location-item', styles.component)
@classNameBindings('itemSize', 'officeId', 'nearest:nearest')
export default class RequestLocationLocationItem extends Component {
  @computed('count')
  get mapSize() {
    if (this.count < 3) {
      return { width: 260, height: 170 };
    } else if (this.count === 3) {
      return { width: 220, height: 170 };
    } else {
      return { width: 335, height: 170 };
    }
  }

  @computed('count')
  get itemSize() {
    if (this.count < 3) {
      return 'middle';
    } else if (this.count === 3) {
      return 'small';
    } else {
      return 'large';
    }
  }

  @computed('model.id')
  get officeId() {
    let id = this.get('model.id');
    return `office-${id}`;
  }
}
