import { computed } from '@ember/object';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class RequestService extends Service {
  @service router;
  @service store;
  @service currentPractice;
  @service session;
  @service fastboot;

  cptCodes = [];

  // NOTE: can't rely just on `router.currentRouteName` because we need
  // this for loading as well
  /* eslint-disable ember/no-private-routing-service */
  @computed('router.currentRouteName')
  get activeRouteName() {
    let _router = this.router._router;
    if (_router._routerMicrolib.activeTransition) {
      return _router._routerMicrolib.activeTransition.targetName.replace('.index.index', '.index');
    } else {
      return this.router.currentRouteName;
    }
  }
  /* eslint-enable ember/no-private-routing-service */

  @computed('activeRouteName')
  get baseRouteName() {
    if (!this.activeRouteName) {
      // eslint-disable-next-line getter-return
      return;
    }
    return this.activeRouteName.indexOf('widget') === 0 ? 'widget' : 'site.request';
  }

  @computed('baseRouteName')
  get schedulingSource() {
    return this.baseRouteName === 'widget' ? 'scheduling_widget' : 'client_portal';
  }

  @computed('baseRouteName')
  get trackingPrefix() {
    return this.baseRouteName === 'widget' ? 'SimpleBooking Widget' : 'Client Portal';
  }

  @computed('cptCodes')
  get hasFreeServices() {
    return this.cptCodes?.toArray().some(cptCode => cptCode.rate === 0);
  }

  @computed('cptCodes')
  get hasRecommendedServiceBadge() {
    return this.session.isMonarchEmbedded && this.cptCodes?.isAny('eligibleToRecommend');
  }

  @computed('schedulingSource')
  get sourceType() {
    return this.schedulingSource === 'scheduling_widget' ? this._findHostType() : 'client portal';
  }

  transitionTo(_route, nestedRoute, params) {
    let finalName = this.baseRouteName;
    if (nestedRoute) {
      finalName += `.${nestedRoute}`;
    }

    return params
      ? this.router.transitionTo(finalName, params)
      : this.router.transitionTo(finalName);
  }

  replaceWith(_route, nestedRoute) {
    this.router.replaceWith(`${this.baseRouteName}.${nestedRoute}`);
  }

  findClientType(currentClient) {
    return currentClient ? 'existing' : 'new';
  }

  _findHostType() {
    if (this.fastboot.isFastBoot) {
      return 'embed';
    }
    let { referrer } = document;

    if (referrer.includes('monarch')) {
      return 'monarch';
    } else if (referrer.includes('simplepractice') || referrer.includes('clientsite')) {
      return 'professional website';
    } else {
      return 'embed';
    }
  }
}
