/* import __COLOCATED_TEMPLATE__ from './time-title.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './time-title.module.scss';

@classic
@classNames('col-lg-4', 'time-title', styles.component)
@classNameBindings('mobileColumns', 'mobileDisplay', 'isActive:active')
export default class RequestDateTimeTitle extends Component {
  @computed('columns')
  get mobileColumns() {
    return `col-${this.columns}`;
  }

  @computed('isEnabled')
  get mobileDisplay() {
    return this.isEnabled ? '' : 'd-none d-lg-block';
  }
}
