import Transform from '@ember-data/serializer/transform';
import classic from 'ember-classic-decorator';

@classic
export default class ArrayTransform extends Transform {
  deserialize(serialized) {
    // This is so prop.findBy works in fastboot
    return [...serialized];
  }

  serialize(deserialized) {
    return deserialized;
  }
}
