/* import __COLOCATED_TEMPLATE__ from './time-zone-modal.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { otherTimeZones, usTimeZones } from 'client-portal/utils/time-zones';
import { service } from '@ember/service';
import { timeFormat } from 'client-portal/utils/date-time';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import styles from './time-zone-modal.module.scss';

function zonesWithTime(zones) {
  return () => {
    return Object.keys(zones).map(zone => {
      return { zone, name: zones[zone], now: moment.tz(zone).format(timeFormat) };
    });
  };
}

@classic
@classNames(styles.component)
export default class RequestDateTimeZoneModal extends Component {
  @service currentPractice;
  @service clientTimezone;

  styles = styles;

  @computed(zonesWithTime(usTimeZones)) usTimeZones;

  @computed(zonesWithTime(otherTimeZones)) otherTimeZones;

  @computed('currentPractice.timeZone')
  get currentPracticeTimeZoneName() {
    return (
      usTimeZones[this.currentPractice.get('timeZone')] ||
      otherTimeZones[this.currentPractice.get('timeZone')]
    );
  }

  init() {
    super.init(...arguments);

    let localTz = this.clientTimezone.timezone;
    this.set('localTimeZoneShort', moment.tz(localTz).format('z'));
    this.set('localTimeZoneName', usTimeZones[localTz] || otherTimeZones[localTz]);
  }
}
