import { task, timeout } from 'ember-concurrency';
import ENV from 'client-portal/config/environment';
import Service from '@ember/service';
import classic from 'ember-classic-decorator';
// scroll Y position when we slide down nav bar
export const scrollTarget = 700;
// header height offset for scrolling to sections
export const scrollOffset = 140;

@classic
export default class PracticeWebsiteNavService extends Service {
  activeLink = 'home';
  scrollTarget = scrollTarget;
  scrollOnLoadDelay = 700;
  // distance from viewport top to be considered active
  activeSectionTolerance = 100;

  // one-time scroll to section on page load
  @(task(function* (el, offset) {
    if (this.scrollOnLoadTask.performCount < 2) {
      yield timeout(this.scrollOnLoadDelay);
      this.scrollToElementTask.perform(el, offset);
    }
  }).drop())
  scrollOnLoadTask;

  @task(function* (el, offset) {
    if (el) {
      offset = offset || scrollOffset || 0;
      let top = el.offsetTop - offset;
      let focusSelector = `.top-nav${
        top > 0 ? '.nav-bar' : ':not(.nav-bar)'
      } .jump-link[href="#${el.getAttribute('data-anchor-name')}"]`;
      window.scrollTo({ top, behavior: ENV.windowScrollToBehavior });

      if (ENV.windowScrollToBehavior === 'smooth') {
        // duration for scroll
        yield timeout(1000);
      }

      document.querySelector(focusSelector).focus({ focusVisible: true });
    }
  })
  scrollToElementTask;

  setActiveLink(name) {
    this.set('activeLink', name);
  }

  setActiveLinkOnScroll() {
    // don't observe scrolling for jump links
    if (this.scrollToElementTask.isRunning) {
      return;
    }
    let sections = document.querySelectorAll('[data-anchor-name]');
    sections.forEach(section => {
      let top = section.getBoundingClientRect().top - scrollOffset;
      if (window.pageYOffset === 0) {
        // if at the top, activate first link
        this._activateFirstLink();
      } else if (
        window.innerHeight + window.pageYOffset >=
        document.getElementById('pw-page-wrapper').offsetHeight - 2
      ) {
        // if at the bottom, activate last link
        this._activateLastLink();
      } else if (top >= 0 && top <= this.activeSectionTolerance) {
        this._activateSection(section);
      }
    });
  }

  _activateSection(section) {
    let name = section.getAttribute('data-anchor-name');
    this.setActiveLink(name);
  }

  _activateFirstLink() {
    let links = this._getNavLinks();
    let link = links[0];
    this._activateLink(link);
  }

  _activateLastLink() {
    let links = this._getNavLinks();
    let link = links[links.length - 1];
    this._activateLink(link);
  }

  _getNavLinks() {
    return document.querySelectorAll('.nav-list .jump-link');
  }

  _activateLink(link) {
    let target = this._getTargetFromLink(link);
    this.setActiveLink(target);
  }

  _getTargetFromLink(el) {
    let href = el.getAttribute('href');
    return href.replace('#', '');
  }
}
