/* import __COLOCATED_TEMPLATE__ from './pdf-modal-buttons.hbs'; */
import { and, equal, not, reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SiteBillingPdfModalButtons extends Component {
  @service billingModals;

  @reads('billingModals.client.permissions.payments') clientPaymentEnabled;
  @not('model.isPaid') isUnpaid;
  @equal('model.constructor.modelName', 'invoice') isInvoice;
  @reads('billingModals.hasCustomStripeAccount') practiceStripeEnabled;
  @and('clientPaymentEnabled', 'isUnpaid', 'isInvoice', 'practiceStripeEnabled')
  showInvoicePayNowButton;
  @reads('billingModals.refreshBillingProfile.isRunning') disablePayNowButton;
}
