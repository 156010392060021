import NoneLocation from 'ember-cli-fastboot/locations/none';

NoneLocation.reopen({
  setURL(path) {
    this._super(path);

    // `_super` above includes host in the redirect URL,
    // which does not work with x-forwarded-host
    if (this.fastboot.isFastBoot) {
      this.fastboot.response.headers.set('location', path);
    }
  },
});

export function initialize() {}
export default {
  name: 'patch-location',
  initialize,
};
