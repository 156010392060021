/* import __COLOCATED_TEMPLATE__ from './time-spots.hbs'; */
import { classNameBindings, classNames } from '@ember-decorators/component';
import { oneWay } from '@ember/object/computed';
import { service } from '@ember/service';
import { timeFormat } from 'client-portal/utils/date-time';
import Component from '@ember/component';
import ENV from 'client-portal/config/environment';
import classic from 'ember-classic-decorator';
import diffAttrs from 'ember-diff-attrs';
import emberO, { action, computed } from '@ember/object';
import moment from 'moment-timezone';
import styles from './time-spots.module.scss';

// eslint-disable-next-line ember/no-classic-classes
const DidReceiveAttrsComponent = Component.extend({
  didReceiveAttrs: diffAttrs('filteredSpots', function (changedAttrs, ...args) {
    this._super(...args);

    if (changedAttrs && changedAttrs.filteredSpots) {
      this.set('isExtended', false);
    }
  }),
});

@classic
@classNames('time-spots', styles.component)
@classNameBindings('isEnabled:enabled')
export default class RequestDateTimeSpots extends DidReceiveAttrsComponent {
  @service media;
  @service currentPractice;
  @service clientTimezone;
  @service session;

  timeFormat = timeFormat;
  isExtended = false;

  @oneWay('currentPractice.selfScheduleMinimumLeadTime') leadTime;

  @oneWay('reservation.cptCode.callToBook') callToBook;

  @oneWay('reservation.startTime') startTime;

  @oneWay('reservation.isReserved') isReserved;

  @oneWay('reservation.pristineStartTime') pristineStartTime;

  @oneWay('clientTimezone.timezone') timezone;

  init() {
    super.init(...arguments);
    this.shouldToggleIsExtended();
  }

  @computed(
    'leadTime',
    'callToBook',
    'startTime',
    'isReserved',
    'pristineStartTime',
    'filteredSpots.[]'
  )
  get spots() {
    return this.filteredSpots.map(spot => {
      let start = moment(spot.start).tz(this.timezone);
      let end = moment(spot.end).tz(this.timezone);
      let pristineStartTime = this.pristineStartTime?.tz(this.timezone);
      let startTime = this.startTime?.tz(this.timezone);

      let minimumBookTime = moment().add(this.leadTime, 'seconds');
      let callToBook = this.callToBook || start.isSameOrBefore(minimumBookTime);
      let isCurrent = startTime && startTime.isSame(start);
      let isPristineCurrent =
        this.isReserved && pristineStartTime && pristineStartTime.isSame(start);

      return emberO.create({
        start,
        end,
        callToBook,
        isCurrent,
        isPristineCurrent,
      });
    });
  }

  @action
  shouldToggleIsExtended() {
    let idx = this.spots.findIndex(spot => spot.start.isSame(this.chosenSpot?.start));
    if (idx >= this.displayableCount) {
      this.toggleProperty('isExtended');
    }
  }

  @action
  handleClick(spot, evt) {
    if (this.session.currentClient) {
      evt.target.classList.toggle('clicked');

      if (evt.target.classList.contains('clicked')) {
        evt.target.focus();
      } else {
        evt.target.blur();
      }

      document.querySelectorAll('.spot .button.primary').forEach(button => {
        if (button !== evt.target) {
          button.classList.remove('clicked');
          button.blur();
        }
      });

      this.handleSpotClick(spot);
    } else {
      this.nextStep(spot, false);
    }
  }

  @action
  toggleIsExtended(e) {
    this.toggleProperty('isExtended');
    if (!this.get('media.isMdDown')) {
      this.element.closest('.scrollable').scrollTo({
        top: e.target.getBoundingClientRect().top,
        behavior: ENV.windowScrollToBehavior,
      });
    }
  }
}
