/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SignInTokenForm extends Component {
  @service currentPractice;
  @service signIn;
}
