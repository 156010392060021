import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { A as emberA } from '@ember/array';
import AddressableMixin from 'client-portal/mixins/polymorphic/addressable';
import PhoneableMixin from 'client-portal/mixins/polymorphic/phoneable';
import Super from './base-client';
import classic from 'ember-classic-decorator';

@classic
export default class TreatableClient extends Super.extend(PhoneableMixin, AddressableMixin) {
  @belongsTo({ async: false }) clientBillingOverview;
  @belongsTo({ async: false }) globalMonarchChannel;
  @hasMany('invoice', { async: false }) unpaidInvoices;
  @hasMany({ async: false }) cards;
  @hasMany('appointment', { async: false, inverse: null }) uninvoicedAppointments;
  @hasMany('insurance-claim', { async: true }) insuranceClaims;
  @attr('boolean') hasIncompleteDocument;
  @attr() supervisorInfo;
  @attr() channelCoveredSessionsCount;
  @attr() channelCoveredSessionsCountRemaining;
  @attr('boolean') hasOonClaims;
  @attr('boolean') hasInvoicedAppointments;

  // Virtual
  @attr() registrationSource;
  @attr() channelCustomFields;

  @computed('cards.@each.isDefault')
  get defaultCard() {
    return this.cards.findBy('isDefault');
  }

  static fieldsFor(context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    fields.removeObjects([
      'uninvoicedAppointments',
      'registrationSource',
      'channelCustomFields',
      'isMinor',
    ]);

    switch (context) {
      case 'environment':
        fields.removeObjects([
          'clientRelationships',
          'channelCoveredSessionsCount',
          'channelCoveredSessionsCountRemaining',
        ]);
        break;
    }

    return fields;
  }
}
