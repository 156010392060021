import { computed } from '@ember/object';
import { A as emberA } from '@ember/array';
import { equal, gt, reads } from '@ember/object/computed';
import BillingItemMixin from 'client-portal/mixins/polymorphic/billing-item';
import Model, { attr, hasMany } from '@ember-data/model';
import classic from 'ember-classic-decorator';

@classic
export default class InvoiceModel extends Model.extend(BillingItemMixin) {
  @hasMany('invoice-item', { async: false }) invoiceItems;

  @attr() displayName;
  @attr() displayStatus;
  @attr('moment') invoiceDate;
  @attr() totalAmount;
  @attr() remainingAmount;
  @attr() isNewForClient;
  @reads('invoiceDate') activityDate;
  @reads('totalAmount') activityCharges;
  @equal('displayStatus', 'paid') isPaid;
  @gt('allocatedAmount', 0) hasPayments;

  @computed('isPaid', 'displayStatus')
  get badgeClass() {
    if (this.isPaid) {
      return 'mint';
    } else if (this.displayStatus) {
      return 'wine';
    }

    return null;
  }

  @computed('isPaid', 'isNewForClient', 'opened')
  get isUnread() {
    return !this.isPaid && !this.opened && this.isNewForClient;
  }

  @computed('remainingAmount', 'totalAmount')
  get allocatedAmount() {
    return this.totalAmount - this.remainingAmount;
  }

  @computed('invoiceItems')
  get numberOfAppointments() {
    return this.invoiceItems.map(invoiceItem => invoiceItem.appointment).uniqBy('startTime').length;
  }

  opened = false;
  activityPayments = null;

  static fieldsFor(_context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    return fields;
  }
}
