import { action } from '@ember/object';
import { pluralize } from 'ember-inflector';
import { service } from '@ember/service';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

const ExtendedRoute = Route.extend(FastbootHelpersMixin);

@classic
export default class SiteBilling extends ExtendedRoute {
  @service router;
  @service store;
  @service session;
  @service billingModals;

  beforeModel(transition) {
    super.beforeModel(...arguments);

    this.session.requireAuthentication(transition, 'home');
    if (!this.session.currentClient.permissions.billingDocuments) {
      this.router.transitionTo('site.home');
    }
  }

  model() {
    if (this.hasShoebox) {
      return this.session.currentClient;
    } else {
      let include = [
        'clientBillingOverview',
        'unpaidInvoices',
        'cards',
        'globalMonarchChannel',
        'insuranceInfos',
        'phones',
        'addresses',
        'clientRelationships',
      ].join(',');

      let models = [
        'treatable-client',
        'client-billing-overview',
        'invoice',
        'card',
        'global-monarch-channel',
        'client-relationship',
      ];

      let fields = models.reduce((acc, modelName) => {
        let model = this.store.modelFor(modelName);
        acc[pluralize(model.modelName)] = model.fieldsFor('show').join(',');
        return acc;
      }, {});

      fields['treatable-clients'] += `,${include}`;
      include = include.concat(',insuranceInfos.insurancePlan,insuranceInfos.documents');

      return this.session.currentClient.reload({
        adapterOptions: {
          query: {
            fields,
            include,
          },
        },
      });
    }
  }

  @action
  async refreshRequest() {
    await this.refresh();
  }

  activate() {
    super.activate(...arguments);
    this.billingModals.set('afterPersist', [() => this.refresh()]);
  }
}
