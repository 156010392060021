/* import __COLOCATED_TEMPLATE__ from './date-calendar.hbs'; */
import { action, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import styles from './date-calendar.module.scss';

@classic
@classNames('date-calendar', styles.component)
export default class RequestDateDateCalendar extends Component {
  @service router;

  @computed('currentDate')
  get current() {
    return moment(this.currentDate);
  }

  @computed('selectedDate')
  get selected() {
    return moment(this.selectedDate);
  }

  @computed('current')
  get hasPrevious() {
    return !this.current.isSame(moment(), 'month');
  }

  @action
  moveCenterAndRemoveQueryParam(moveCenterAction, direction, unit) {
    this.router.transitionTo({ queryParams: { selectedSlot: null } });
    moveCenterAction(direction, unit);
  }
}
