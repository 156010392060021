import { getOwner } from '@ember/application';
import { isTesting, macroCondition } from '@embroider/macros';
import config from 'ember-get-config';

function isEmberTesting() {
  if (macroCondition(isTesting())) {
    return true;
  } else {
    return false;
  }
}

function isLocalhost() {
  return location?.host.includes('localhost');
}

function isMirage() {
  return config['ember-cli-mirage']?.enabled;
}

function isLocalhostAndMirage() {
  return isMirage() && isLocalhost();
}

function isTest(emberObject) {
  if (emberObject) {
    let owner = getOwner(emberObject);

    if (owner.lookup('service:fastboot')?.isFastBoot) {
      return false;
    }
  }

  let { environment, railsEnv } = config;
  return isEmberTesting() || environment === 'test' || railsEnv === 'test';
}

function isTestingOrMirage(emberObject) {
  return isTest(emberObject) || isLocalhostAndMirage();
}

export {
  config,
  isLocalhost,
  isMirage,
  isEmberTesting,
  isLocalhostAndMirage,
  isTest,
  isTestingOrMirage,
};
