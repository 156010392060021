import { attribute, classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import FlyoutMixin from '../../mixins/component/flyout';
import IsAnimatedMixin from '../../mixins/component/is-animated';
import classic from 'ember-classic-decorator';
import styles from './full-flyout.module.scss';

@classic
@classNames('full-flyout', styles.component)
@classNameBindings('isAnimated', 'noActions:is-transparent')
export default class SharedFullFlyout extends Component.extend(IsAnimatedMixin, FlyoutMixin) {
  @attribute role = 'dialog';
}
