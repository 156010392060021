import { attr } from '@ember-data/model';
import { buildValidations } from 'ember-cp-validations';
import Fragment from 'ember-data-model-fragments/fragment';
import classic from 'ember-classic-decorator';

export const maxReferenceIdLength = 50;

const Validations = buildValidations({});

@classic
export default class ChannelCustomFieldsCoveredSessions extends Fragment.extend(Validations) {
  @attr('string', { defaultValue: 'coveredSessions' }) name;
  @attr('string', { defaultValue: null }) value;
}
