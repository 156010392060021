let trait = function (extension) {
  let __isTrait__ = true;
  return {
    extension,
    __isTrait__,
  };
};

/**
  @hide
*/
export default trait;
