import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import styles from './infinity-loader.module.scss';

export default class SharedInfinityLoader extends Component {
  @service infinity;

  @tracked isError = false;

  styles = styles;

  get triggerOffset() {
    return this.args.triggerOffset ?? 300;
  }

  @action
  async infinityLoad(model) {
    try {
      this.isError = false;
      await this.infinityLoadFn(model);
    } catch {
      this.isError = true;
    }
  }

  infinityLoadFn(model) {
    return this.args.infinityLoad
      ? this.args.infinityLoad(model)
      : this.infinity.infinityLoad(model, 1);
  }
}
