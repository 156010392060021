/* import __COLOCATED_TEMPLATE__ from './time-formatted.hbs'; */
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import dateTime from 'client-portal/utils/date-time';

@classic
export default class RequestDateTimeFormatted extends Component {
  @service clientTimezone;

  @reads('clientTimezone.timezone') timezone;

  timeFormat = dateTime.timeFormat;
  zoneFormat = dateTime.zoneFormat;
  humanFullDateFormat = dateTime.humanFullDateFormat;
}
