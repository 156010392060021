/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { classNames } from '@ember-decorators/component';
import { scheduleOnce } from '@ember/runloop';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './form.module.scss';

@classic
@classNames(styles.component)
export default class PracticeWebsiteEditMenuForm extends Component {
  didInsertElement() {
    super.didInsertElement(...arguments);
    this.setRadioChecked = this.setRadioChecked.bind(this);
    scheduleOnce('afterRender', this.setRadioChecked);
  }

  setRadioChecked() {
    // liquid-fire removes 'checked' property from radio button, so we need to set it manually
    let input = this.element.querySelector('input[type=radio][data-checked]');
    if (input) {
      input.checked = true;
    }
  }
}
