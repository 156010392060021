/* import __COLOCATED_TEMPLATE__ from './survey-completed.hbs'; */
import { classNames } from '@ember-decorators/component';
import { later } from '@ember/runloop';
import { modifier } from 'ember-modifier';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './survey-completed.module.scss';

@classic
@classNames(styles.component)
export default class FeedbackBannerSurveyCompleted extends Component {
  delay = 1000;

  @service feedbackBanner;

  onSetup = modifier(
    () => {
      later(() => {
        this.feedbackBanner.complete?.();
      }, this.delay);
    },
    { eager: false }
  );
}
