import Transform from '@ember-data/serializer/transform';
import classic from 'ember-classic-decorator';

@classic
export default class FloatTransform extends Transform {
  deserialize(serialized) {
    return parseFloat(serialized);
  }

  serialize(deserialized) {
    return String(deserialized);
  }
}
